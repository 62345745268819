import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $, { isNumeric } from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decserviceid;
let Decservicename;
let Decplan;
let Decuserid;
let Decjwttoken;
let mainaction = "upload";
let Decagentid;
let temp = "general";
let HideProceedBtn = "No";
let ChooseNowBtnsDisable = "No";
let supportName;
let supportContact;
let supportEmail;

// Sole P/I
let mainactionSAadhar = "upload";
let SAadharStatus;
let mainactionSPremisepic = "upload";
let SPremisepicStatus;
let mainactionSSelfDecl = "upload";
let SSelfDeclStatus;
let mainactionSTaxReceipt = "upload";
let STaxReceiptStatus;
let mainactionSPic = "upload";
let SPicStatus;
let mainactionSCopyOfRegistry = "upload";
let SCopyOfRegistryStatus;
let mainactionSRentAgreement = "upload";
let SRentAgreementStatus;

let SStatusArray = [
  {
    doc: "SAadharStatus",
    status: SAadharStatus,
  },
  {
    doc: "SPicStatus",
    status: SPicStatus,
  },
  {
    doc: "SSelfDeclStatus",
    status: SSelfDeclStatus,
  },
  {
    doc: "STaxReceiptStatus",
    status: STaxReceiptStatus,
  },
  {
    doc: "SPremisepicStatus",
    status: SPremisepicStatus,
  },
];
let SOptStatusArray = [
  {
    doc: "SCopyOfRegistryStatus",
    status: SCopyOfRegistryStatus,
  },
  {
    doc: "SRentAgreementStatus",
    status: SRentAgreementStatus,
  },
];
let SadditionalReupload = false;
let SadditionalFilesIndex;
let SadditionalReuploadIndex;
let SadditionalReuploadStatus;
let SadditionalFilesNames = [];
let Sproceednowforword;
let AnyReuploadOccuredS = "No";

// Partnership
let mainactionPAadhar1 = "upload";
let PAadhar1Status;
let mainactionPAadhar2 = "upload";
let PAadhar2Status;
let mainactionPSelfDecl = "upload";
let PSelfDeclStatus;
let mainactionPTaxReceipt = "upload";
let PTaxReceiptStatus;
let mainactionPPDeed = "upload";
let PPDeedStatus;
let mainactionPPremisepic = "upload";
let PPremisepicStatus;
let mainactionPPic1 = "upload";
let PPic1Status;
let mainactionPPic2 = "upload";
let PPic2Status;
let mainactionPRentAgreement = "upload";
let PRentAgreementStatus;
let mainactionPOwnerDeed = "upload";
let POwnerDeedStatus;

let PStatusArray = [
  {
    doc: "PPic1Status",
    status: PPic1Status,
  },
  {
    doc: "PPic2Status",
    status: PPic2Status,
  },
  {
    doc: "PAadhar1Status",
    status: PAadhar1Status,
  },
  {
    doc: "PAadhar2Status",
    status: PAadhar2Status,
  },
  {
    doc: "PPDeedStatus",
    status: PPDeedStatus,
  },
  {
    doc: "PSelfDeclStatus",
    status: PSelfDeclStatus,
  },
  {
    doc: "PTaxReceiptStatus",
    status: PTaxReceiptStatus,
  },
  {
    doc: "PPremisepicStatus",
    status: PPremisepicStatus,
  },
];
let POptStatusArray = [
  {
    doc: "POwnerDeedStatus",
    status: POwnerDeedStatus,
  },
  {
    doc: "PRentAgreementStatus",
    status: PRentAgreementStatus,
  },
];
let PadditionalReupload = false;
let PadditionalFilesIndex;
let PadditionalReuploadIndex;
let PadditionalReuploadStatus;
let PadditionalFilesNames = [];
let Pproceednowforword;
let AnyReuploadOccuredP = "No";

// Private Limited
let mainactionPLAadhar1 = "upload";
let PLAadhar1Status;
let mainactionPLAadhar2 = "upload";
let PLAadhar2Status;
let mainactionPLPic1 = "upload";
let PLPic1Status;
let mainactionPLPic2 = "upload";
let PLPic2Status;
let mainactionPLPremisePic = "upload";
let PLPremisePicStatus;
let mainactionPLSelfDecl = "upload";
let PLSelfDeclStatus;
let mainactionPLTaxReceipt = "upload";
let PLTaxReceiptStatus;
let mainactionPLCI = "upload";
let PLCIStatus;
let mainactionPLMOA = "upload";
let PLMOAStatus;
let mainactionPLAOA = "upload";
let PLAOAStatus;
let mainactionPLRentAgreement = "upload";
let PLRentAgreementStatus;
let mainactionPLOwnerDeed = "upload";
let PLOwnerDeedStatus;
let PLStatusArray = [
  {
    doc: "PLPic1Status",
    status: PLPic1Status,
  },
  {
    doc: "PLPic2Status",
    status: PLPic2Status,
  },
  {
    doc: "PLAadhar1Status",
    status: PLAadhar1Status,
  },
  {
    doc: "PLAadhar2Status",
    status: PLAadhar2Status,
  },
  {
    doc: "PLSelfDeclStatus",
    status: PLSelfDeclStatus,
  },
  {
    doc: "PLCIStatus",
    status: PLCIStatus,
  },
  {
    doc: "PLMOAStatus",
    status: PLMOAStatus,
  },
  {
    doc: "PLAOAStatus",
    status: PLAOAStatus,
  },
  {
    doc: "PLTaxReceiptStatus",
    status: PLTaxReceiptStatus,
  },
  {
    doc: "PLPremisePicStatus",
    status: PLPremisePicStatus,
  },
];
let PLOptStatusArray = [
  {
    doc: "PLOwnerDeedStatus",
    status: PLOwnerDeedStatus,
  },
  {
    doc: "PLRentAgreementStatus",
    status: PLRentAgreementStatus,
  },
];
let PLadditionalReupload = false;
let PLadditionalFilesIndex;
let PLadditionalReuploadIndex;
let PLadditionalReuploadStatus;
let PLadditionalFilesNames = [];
let PLproceednowforword;
let AnyReuploadOccuredPL = "No";

// HUF
let mainactionHUFAadhar = "upload";
let HUFAadharStatus;
let mainactionHUFPic = "upload";
let HUFPicStatus;
let mainactionHUFDeed = "upload";
let HUFDeedStatus;
let mainactionHUFPremisePic = "upload";
let HUFPremisePicStatus;
let mainactionHUFSelfDecl = "upload";
let HUFSelfDeclStatus;
let mainactionHUFTaxReceipt = "upload";
let HUFTaxReceiptStatus;
let mainactionHUFCopyOfRegistry = "upload";
let HUFCopyOfRegistryStatus;
let mainactionHUFRentAgreement = "upload";
let HUFRentAgreementStatus;
let HUFStatusArray = [
  {
    doc: "HUFAadharStatus",
    status: HUFAadharStatus,
  },
  {
    doc: "HUFPicStatus",
    status: HUFPicStatus,
  },
  {
    doc: "HUFDeedStatus",
    status: HUFDeedStatus,
  },
  {
    doc: "HUFSelfDeclStatus",
    status: HUFSelfDeclStatus,
  },
  {
    doc: "HUFTaxReceiptStatus",
    status: HUFTaxReceiptStatus,
  },
  {
    doc: "HUFPremisePicStatus",
    status: HUFPremisePicStatus,
  },
];
let HUFOptStatusArray = [
  {
    doc: "HUFCopyOfRegistryStatus",
    status: HUFCopyOfRegistryStatus,
  },
  {
    doc: "HUFRentAgreementStatus",
    status: HUFRentAgreementStatus,
  },
];
let HUFadditionalReupload = false;
let HUFadditionalFilesIndex;
let HUFadditionalReuploadIndex;
let HUFadditionalReuploadStatus;
let HUFadditionalFilesNames = [];
let HUFproceednowforword;
let AnyReuploadOccuredHUF = "No";

// ST
let mainactionSTAadhar1 = "upload";
let STAadhar1Status;
let mainactionSTAadhar2 = "upload";
let STAadhar2Status;
let mainactionSTPic1 = "upload";
let STPic1Status;
let mainactionSTPic2 = "upload";
let STPic2Status;
let mainactionSTSelfDecl = "upload";
let STSelfDeclStatus;
let mainactionSTTaxReceipt = "upload";
let mainactionSTPan3 = "upload";
let STPremisePicStatus;
let STTaxReceiptStatus;
let mainactionSTCR = "upload";
let STCRStatus;
let mainactionSTCopyOfRegistry = "upload";
let STCopyOfRegistryStatus;
let mainactionSTRentAgreement = "upload";
let STRentAgreementStatus;
let STStatusArray = [
  {
    doc: "STPic1Status",
    status: STPic1Status,
  },
  {
    doc: "STPic2Status",
    status: STPic2Status,
  },
  {
    doc: "STAadhar1Status",
    status: STAadhar1Status,
  },
  {
    doc: "STAadhar2Status",
    status: STAadhar2Status,
  },
  {
    doc: "STSelfDeclStatus",
    status: STSelfDeclStatus,
  },
  {
    doc: "STCRStatus",
    status: STCRStatus,
  },
  {
    doc: "STTaxReceiptStatus",
    status: STTaxReceiptStatus,
  },
  {
    doc: "STPremisePicStatus",
    status: STPremisePicStatus,
  },
];
let STOptStatusArray = [
  {
    doc: "STCopyOfRegistryStatus",
    status: STCopyOfRegistryStatus,
  },
  {
    doc: "STRentAgreementStatus",
    status: STRentAgreementStatus,
  },
];
let STadditionalReupload = false;
let STadditionalFilesIndex;
let STadditionalReuploadIndex;
let STadditionalReuploadStatus;
let STadditionalFilesNames = [];
let STproceednowforword;
let AnyReuploadOccuredST = "No";

export class ActiveTradeLicence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // general
      dropDownValue: "",
      serviceType: "",
      fileName: "File Name",
      fileBase64: "",
      documentType: "",
      documentName: "",
      comment: "",
      commentHistroyarray: [],
      // S
      SAadharDocLink: "#",
      SSelfDeclDocLink: "#",
      STaxReceiptDocLink: "#",
      SPicDocLink: "#",
      SBPDocLink: "#",
      SAddressProofType_radio: "OwnershipDeed",
      SCopyOfRegistryDocLink: "#",
      SRentAgreementDocLink: "#",
      SbusinessNature: "",
      SPRDocLink: "#",
      IsSAddFileDisabled: true,
      SadditionalFiles: [],
      api_SadditionalFiles: "No",
      SAdd_DocName: "",
      // P
      PAadhar1DocLink: "#",
      PAadhar2DocLink: "#",
      PSelfDeclDocLink: "#",
      PTaxReceiptDocLink: "#",
      PPDeedDocLink: "#",
      PPic1DocLink: "#",
      PPic2DocLink: "#",
      PBPDocLink: "#",
      PPbusinessNature: "",
      PAddressProofType_radio: "OwnershipDeed",
      PRentAgreementDocLink: "#",
      POwnerDeedDocLink: "#",
      IsPAddFileDisabled: true,
      PadditionalFiles: [],
      api_PadditionalFiles: "No",
      PAdd_DocName: "",
      // PL
      PLAadhar1DocLink: "#",
      PLAadhar2DocLink: "#",
      PLPic1DocLink: "#",
      PLPic2DocLink: "#",
      PLSelfDeclDocLink: "#",
      PLTaxReceiptDocLink: "#",
      PLCIDocLink: "#",
      PLMOADocLink: "#",
      PLAOADocLink: "#",
      PLbusinessNature: "",
      PLAddressProofType_radio: "OwnershipDeed",
      PLRentAgreementDocLink: "#",
      PLOwnerDeedDocLink: "#",
      IsPLAddFileDisabled: true,
      PLadditionalFiles: [],
      api_PLadditionalFiles: "No",
      PLAdd_DocName: "",
      // HUF
      HUFAadharDocLink: "#",
      HUFPan1DocLink: "#",
      HUFPicDocLink: "#",
      HUFPan2DocLink: "#",
      HUFDeedDocLink: "#",
      HUFSelfDeclDocLink: "#",
      HUFTaxReceiptDocLink: "#",
      HUFbusinessNature: "",
      HUFAddressProofType_radio: "OwnershipDeed",
      HUFCopyOfRegistryDocLink: "#",
      HUFRentAgreementDocLink: "#",
      IsHUFAddFileDisabled: true,
      HUFadditionalFiles: [],
      api_HUFadditionalFiles: "No",
      HUFAdd_DocName: "",
      // ST
      STAadhar1DocLink: "#",
      STAadhar2DocLink: "#",
      STPic1DocLink: "#",
      STPic2DocLink: "#",
      STSelfDeclDocLink: "#",
      STTaxReceiptDocLink: "#",
      STCRDocLink: "#",
      STbusinessNature: "",
      STAddressProofType_radio: "OwnershipDeed",
      STCopyOfRegistryDocLink: "#",
      STRentAgreementDocLink: "#",
      IsSTAddFileDisabled: true,
      STadditionalFiles: [],
      api_STadditionalFiles: "No",
      STAdd_DocName: "",
      Name: "",
      Contact: "",
      Timeline: [],
      Timelinedata: false,
      timelcount: 2,
      filtertimel: [],
      supportName: "",
      supportContact: "",
      supportEmail: "",
      deliveredDocuments: [],
      referralPartner: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    });

    if(this.state.referralPartner == true){
      const script = document.createElement("script");
      script.src = "/js/main.js";
      script.async = true;
      document.body.appendChild(script);
    }
    // ----------------------------temporary -----------------------------------------

    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj");
    let Dectemptoken = encryptor.decrypt(temptoken);
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary";
    } else {
      temp = "general";
    }

    // ----------------------------temporary -----------------------------------------
    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }
    $("#trade_SolePI").hide();
    $("#trade_P_LLP").hide();
    $("#trade_PL").hide();
    $("#trade_HUF").hide();
    $("#trade_Society").hide();
    this.getserviceInformationApi();
    let str = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = this.props.location.pathname.replaceAll("/", " > "));
    let mainstr = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = str.replace(" > ", " "));
    $(".myservicesortbyfilterbystatus").html(mainstr);

    this.getnameanumber();
  }

  getnameanumber = () => {
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
        {
          agentId: Decagentid,
          serviceName: Decservicename,
          serviceId: Decserviceid,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      });
  };

  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=serviceInformation",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == "200" && res.data.status == "success") {

          this.setState({
            deliveredDocuments: res.data.deliveredDocuments,
          });
          
          HideProceedBtn = "No";
          $(".proceednowbtn").show();
          if (res.data.documents.length == 0) {
            $(".proceednowbtn").attr("disabled", true);
          }
          if (
            res.data.inputFields.length == 0 ||
            res.data.inputFields.AddressProofType == null
          ) {
            this.setState(
              {
                SAddressProofType_radio: "OwnershipDeed",
                PAddressProofType_radio: "OwnershipDeed",
                PLAddressProofType_radio: "OwnershipDeed",
                HUFAddressProofType_radio: "OwnershipDeed",
                STAddressProofType_radio: "OwnershipDeed",
              },
              () => {
                $(".CopyOfRegistry").css("display", "flex");
                $(".RentAgreement").hide();
                $(".ResidentialCopyOfRegistry").css("display", "flex");
                $(".ResidentialRentAgreement").hide();
              }
            );
          }
          if (res.data.serviceType == null) {
            this.setState(
              {
                dropDownValue: "volvo",
                SAdd_DocName: "",
                SadditionalFiles: [1],
                api_SadditionalFiles: "No",
                PAdd_DocName: "",
                PadditionalFiles: [1],
                api_PadditionalFiles: "No",
                PLAdd_DocName: "",
                PLadditionalFiles: [1],
                api_PLadditionalFiles: "No",
                HUFAdd_DocName: "",
                HUFadditionalFiles: [1],
                api_HUFadditionalFiles: "No",
                STAdd_DocName: "",
                STadditionalFiles: [1],
                api_STadditionalFiles: "No",
              },
              () => {
                if (this.state.dropDownValue == "volvo") {
                  $("#trade_default_Img").show();
                  $("#trade_SolePI").hide();
                  $("#trade_P_LLP").hide();
                  $("#trade_PL").hide();
                  $("#trade_HUF").hide();
                  $("#trade_Society").hide();
                  SStatusArray.map((a) => (a.status = "open"));
                  SOptStatusArray.map((a) => (a.status = "open"));
                  PStatusArray.map((a) => (a.status = "open"));
                  POptStatusArray.map((a) => (a.status = "open"));
                  PLStatusArray.map((a) => (a.status = "open"));
                  PLOptStatusArray.map((a) => (a.status = "open"));
                  HUFStatusArray.map((a) => (a.status = "open"));
                  HUFOptStatusArray.map((a) => (a.status = "open"));
                  STStatusArray.map((a) => (a.status = "open"));
                  STOptStatusArray.map((a) => (a.status = "open"));
                }
              }
            );
          } else if (res.data.serviceType == "TL_SolePI_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "TL_SolePI_Documents",
                serviceType: "TL_SolePI_Documents",
              },
              () => {
                if (this.state.dropDownValue == "TL_SolePI_Documents") {
                  $("#trade_default_Img").hide();
                  $("#trade_SolePI").show();
                  $("#trade_P_LLP").hide();
                  $("#trade_PL").hide();
                  $("#trade_HUF").hide();
                  $("#trade_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#SadditionalDocumentDiv").hide();
              } else {
                $("#SAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  SadditionalFiles: [...res.data.addDocInformation],
                  SAdd_DocName: "File Name",
                  api_SadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                SAdd_DocName: "",
                SadditionalFiles: [1],
                api_SadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionSPic = "upload";
                SPicStatus = "remove";
                SStatusArray[1].status = SPicStatus;
                this.setState(
                  {
                    SPicDocLink: doc[0].ApplicantPhoto,
                  },
                  () => {
                    $("#viewDocLink_SPic").css("display", "block");
                    $("#choose_SPic").hide();
                    $("#X-btn_SPic").show();
                    $("#done-btn_SPic").hide();
                    $("#review-btn_SPic").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionSPic = "upload";
                SPicStatus = "review";
                SStatusArray[1].status = SPicStatus;
                this.setState(
                  {
                    SPicDocLink: doc[0].ApplicantPhoto,
                  },
                  () => {
                    $("#viewDocLink_SPic").css("display", "block");
                    $("#choose_SPic").hide();
                    $("#X-btn_SPic").hide();
                    $("#done-btn_SPic").hide();
                    $("#review-btn_SPic").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionSPic = "reupload";
                SPicStatus = "reupload";
                SStatusArray[1].status = SPicStatus;
                $("#choose_SPic").show();
                $("#viewDocLink_SPic").hide();
                $("#X-btn_SPic").hide();
                $("#done-btn_SPic").hide();
                $("#review-btn_SPic").hide();
              } else if (doc[0].status == "done") {
                SPicStatus = "done";
                mainactionSPic = "upload";
                SStatusArray[1].status = SPicStatus;
                $("#choose_SPic").hide();
                $("#X-btn_SPic").hide();
                $("#done-btn_SPic").show();
                $("#review-btn_SPic").hide();
              } else {
                SPicStatus = "open";
                SStatusArray[1].status = SPicStatus;
              }
              if (doc[1].status == "remove") {
                mainactionSAadhar = "upload";
                SAadharStatus = "remove";
                SStatusArray[0].status = SAadharStatus;
                this.setState(
                  {
                    SAadharDocLink: doc[1].IDProofApplicant,
                  },
                  () => {
                    $("#viewDocLink_SAadhar").css("display", "block");
                    $("#choose_SAadhar").hide();
                    $("#X-btn_SAadhar").show();
                    $("#done-btn_SAadhar").hide();
                    $("#review-btn_SAadhar").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionSAadhar = "upload";
                SAadharStatus = "review";
                SStatusArray[0].status = SAadharStatus;
                this.setState(
                  {
                    SAadharDocLink: doc[1].IDProofApplicant,
                  },
                  () => {
                    $("#viewDocLink_SAadhar").css("display", "block");
                    $("#choose_SAadhar").hide();
                    $("#X-btn_SAadhar").hide();
                    $("#done-btn_SAadhar").hide();
                    $("#review-btn_SAadhar").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionSAadhar = "reupload";
                SAadharStatus = "reupload";
                SStatusArray[0].status = SAadharStatus;
                $("#choose_SAadhar").show();
                $("#viewDocLink_SAadhar").hide();
                $("#X-btn_SAadhar").hide();
                $("#done-btn_SAadhar").hide();
                $("#review-btn_SAadhar").hide();
              } else if (doc[1].status == "done") {
                SAadharStatus = "done";
                mainactionSAadhar = "upload";
                SStatusArray[0].status = SAadharStatus;
                $("#choose_SAadhar").hide();
                $("#X-btn_SAadhar").hide();
                $("#done-btn_SAadhar").show();
                $("#review-btn_SAadhar").hide();
              } else {
                SAadharStatus = "open";
                SStatusArray[0].status = SAadharStatus;
              }
              if (doc[2].status == "remove") {
                mainactionSSelfDecl = "upload";
                SSelfDeclStatus = "remove";
                SStatusArray[2].status = SSelfDeclStatus;
                this.setState(
                  {
                    SSelfDeclDocLink: doc[2].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_SSelfDecl").css("display", "block");
                    $("#choose_SSelfDecl").hide();
                    $("#X-btn_SSelfDecl").show();
                    $("#done-btn_SSelfDecl").hide();
                    $("#review-btn_SSelfDecl").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionSSelfDecl = "upload";
                SSelfDeclStatus = "review";
                SStatusArray[2].status = SSelfDeclStatus;
                this.setState(
                  {
                    SSelfDeclDocLink: doc[2].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_SSelfDecl").css("display", "block");
                    $("#choose_SSelfDecl").hide();
                    $("#X-btn_SSelfDecl").hide();
                    $("#done-btn_SSelfDecl").hide();
                    $("#review-btn_SSelfDecl").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionSSelfDecl = "reupload";
                SSelfDeclStatus = "reupload";
                SStatusArray[2].status = SSelfDeclStatus;
                $("#choose_SSelfDecl").show();
                $("#viewDocLink_SSelfDecl").hide();
                $("#X-btn_SSelfDecl").hide();
                $("#done-btn_SSelfDecl").hide();
                $("#review-btn_SSelfDecl").hide();
              } else if (doc[2].status == "done") {
                SSelfDeclStatus = "done";
                mainactionSSelfDecl = "upload";
                SStatusArray[2].status = SSelfDeclStatus;
                $("#choose_SSelfDecl").hide();
                $("#X-btn_SSelfDecl").hide();
                $("#done-btn_SSelfDecl").show();
                $("#review-btn_SSelfDecl").hide();
              } else {
                SSelfDeclStatus = "open";
                SStatusArray[2].status = SSelfDeclStatus;
              }
              if (doc[4].status == "remove") {
                mainactionSRentAgreement = "upload";
                SRentAgreementStatus = "remove";
                SOptStatusArray[1].status = SRentAgreementStatus;
                this.setState(
                  {
                    SRentAgreementDocLink: doc[4].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_SRentAgreement").css("display", "block");
                    $("#choose_SRentAgreement").hide();
                    $("#X-btn_SRentAgreement").show();
                    $("#done-btn_SRentAgreement").hide();
                    $("#review-btn_SRentAgreement").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionSRentAgreement = "upload";
                SRentAgreementStatus = "review";
                SOptStatusArray[1].status = SRentAgreementStatus;
                this.setState(
                  {
                    SRentAgreementDocLink: doc[4].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_SRentAgreement").css("display", "block");
                    $("#choose_SRentAgreement").hide();
                    $("#X-btn_SRentAgreement").hide();
                    $("#done-btn_SRentAgreement").hide();
                    $("#review-btn_SRentAgreement").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionSRentAgreement = "reupload";
                SRentAgreementStatus = "reupload";
                SOptStatusArray[1].status = SRentAgreementStatus;
                $("#choose_SRentAgreement").show();
                $("#viewDocLink_SRentAgreement").hide();
                $("#X-btn_SRentAgreement").hide();
                $("#done-btn_SRentAgreement").hide();
                $("#review-btn_SRentAgreement").hide();
              } else if (doc[4].status == "done") {
                SRentAgreementStatus = "done";
                mainactionSRentAgreement = "upload";
                SOptStatusArray[1].status = SRentAgreementStatus;
                $("#choose_SRentAgreement").hide();
                $("#X-btn_SRentAgreement").hide();
                $("#done-btn_SRentAgreement").show();
                $("#review-btn_SRentAgreement").hide();
              } else {
                SRentAgreementStatus = "open";
                SOptStatusArray[1].status = SRentAgreementStatus;
              }
              if (doc[3].status == "remove") {
                mainactionSCopyOfRegistry = "upload";
                SCopyOfRegistryStatus = "remove";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                this.setState(
                  {
                    SCopyOfRegistryDocLink: doc[3].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_SCopyOfRegistry").css("display", "block");
                    $("#choose_SCopyOfRegistry").hide();
                    $("#X-btn_SCopyOfRegistry").show();
                    $("#done-btn_SCopyOfRegistry").hide();
                    $("#review-btn_SCopyOfRegistry").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionSCopyOfRegistry = "upload";
                SCopyOfRegistryStatus = "review";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                this.setState(
                  {
                    SCopyOfRegistryDocLink: doc[3].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_SCopyOfRegistry").css("display", "block");
                    $("#choose_SCopyOfRegistry").hide();
                    $("#X-btn_SCopyOfRegistry").hide();
                    $("#done-btn_SCopyOfRegistry").hide();
                    $("#review-btn_SCopyOfRegistry").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionSCopyOfRegistry = "reupload";
                SCopyOfRegistryStatus = "reupload";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                $("#choose_SCopyOfRegistry").show();
                $("#viewDocLink_SCopyOfRegistry").hide();
                $("#X-btn_SCopyOfRegistry").hide();
                $("#done-btn_SCopyOfRegistry").hide();
                $("#review-btn_SCopyOfRegistry").hide();
              } else if (doc[3].status == "done") {
                SCopyOfRegistryStatus = "done";
                mainactionSCopyOfRegistry = "upload";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                $("#choose_SCopyOfRegistry").hide();
                $("#X-btn_SCopyOfRegistry").hide();
                $("#done-btn_SCopyOfRegistry").show();
                $("#review-btn_SCopyOfRegistry").hide();
              } else {
                SCopyOfRegistryStatus = "open";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
              }
              if (doc[5].status == "remove") {
                mainactionSTaxReceipt = "upload";
                STaxReceiptStatus = "remove";
                SStatusArray[3].status = STaxReceiptStatus;
                this.setState(
                  {
                    STaxReceiptDocLink: doc[5].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_STaxReceipt").css("display", "block");
                    $("#choose_STaxReceipt").hide();
                    $("#X-btn_STaxReceipt").show();
                    $("#done-btn_STaxReceipt").hide();
                    $("#review-btn_STaxReceipt").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionSTaxReceipt = "upload";
                STaxReceiptStatus = "review";
                SStatusArray[3].status = STaxReceiptStatus;
                this.setState(
                  {
                    STaxReceiptDocLink: doc[5].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_STaxReceipt").css("display", "block");
                    $("#choose_STaxReceipt").hide();
                    $("#X-btn_STaxReceipt").hide();
                    $("#done-btn_STaxReceipt").hide();
                    $("#review-btn_STaxReceipt").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionSTaxReceipt = "reupload";
                STaxReceiptStatus = "reupload";
                SStatusArray[3].status = STaxReceiptStatus;
                $("#choose_STaxReceipt").show();
                $("#viewDocLink_STaxReceipt").hide();
                $("#X-btn_STaxReceipt").hide();
                $("#done-btn_STaxReceipt").hide();
                $("#review-btn_STaxReceipt").hide();
              } else if (doc[5].status == "done") {
                STaxReceiptStatus = "done";
                mainactionSTaxReceipt = "upload";
                SStatusArray[3].status = STaxReceiptStatus;
                $("#choose_STaxReceipt").hide();
                $("#X-btn_STaxReceipt").hide();
                $("#done-btn_STaxReceipt").show();
                $("#review-btn_STaxReceipt").hide();
              } else {
                STaxReceiptStatus = "open";
                SStatusArray[3].status = STaxReceiptStatus;
              }

              if (doc[6].status == "remove") {
                mainactionSPremisepic = "upload";
                SPremisepicStatus = "remove";
                SStatusArray[4].status = SPremisepicStatus;
                this.setState(
                  {
                    SPremisepicDocLink: doc[6].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_SPremisepic").css("display", "block");
                    $("#choose_SPremisepic").hide();
                    $("#X-btn_SPremisepic").show();
                    $("#done-btn_SPremisepic").hide();
                    $("#review-btn_SPremisepic").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionSPremisepic = "upload";
                SPremisepicStatus = "review";
                SStatusArray[4].status = SPremisepicStatus;
                this.setState(
                  {
                    SPremisepicDocLink: doc[6].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_SPremisepic").css("display", "block");
                    $("#choose_SPremisepic").hide();
                    $("#X-btn_SPremisepic").hide();
                    $("#done-btn_SPremisepic").hide();
                    $("#review-btn_SPremisepic").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionSPremisepic = "reupload";
                SPremisepicStatus = "reupload";
                SStatusArray[4].status = SPremisepicStatus;
                $("#choose_SPremisepic").show();
                $("#viewDocLink_SPremisepic").hide();
                $("#X-btn_SPremisepic").hide();
                $("#done-btn_SPremisepic").hide();
                $("#review-btn_SPremisepic").hide();
              } else if (doc[6].status == "done") {
                SPremisepicStatus = "done";
                mainactionSPremisepic = "upload";
                SStatusArray[4].status = SPremisepicStatus;
                $("#choose_SPremisepic").hide();
                $("#X-btn_SPremisepic").hide();
                $("#done-btn_SPremisepic").show();
                $("#review-btn_SPremisepic").hide();
              } else {
                SPremisepicStatus = "open";
                SStatusArray[4].status = SPremisepicStatus;
              }
            }
            if (
              res.data.inputFields[0].status == "review" ||
              res.data.inputFields[0].status == "done"
            ) {
              this.setState({
                SbusinessNature: res.data.inputFields[0].Nature,
              });
              $(".uploadtextarea").attr("disabled", true);
            }
            if (res.data.inputFields[1].AddressProofType == "OwnershipDeed") {
              this.setState(
                {
                  SAddressProofType_radio: "OwnershipDeed",
                },
                () => {
                  $(".CopyOfRegistry").css("display", "flex");
                  $(".RentAgreement").hide();
                }
              );
            } else if (
              res.data.inputFields[1].AddressProofType == "RentAgreement"
            ) {
              this.setState(
                {
                  SAddressProofType_radio: "RentAgreement",
                },
                () => {
                  $(".RentAgreement").css("display", "flex");
                  $(".CopyOfRegistry").hide();
                }
              );
            }

            this.ScheckRemainingReuploads();
            this.checkhandleSProceedBtn();
          } else if (res.data.serviceType == "TL_P_LLP_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "TL_P_LLP_Documents",
                serviceType: "TL_P_LLP_Documents",
              },
              () => {
                if (this.state.dropDownValue == "TL_P_LLP_Documents") {
                  $("#trade_default_Img").hide();
                  $("#trade_SolePI").hide();
                  $("#trade_P_LLP").show();
                  $("#trade_PL").hide();
                  $("#trade_HUF").hide();
                  $("#trade_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#PadditionalDocumentDiv").hide();
              } else {
                $("#PAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  PadditionalFiles: [...res.data.addDocInformation],
                  PAdd_DocName: "File Name",
                  api_PadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                PAdd_DocName: "",
                PadditionalFiles: [1],
                api_PadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionPPic1 = "upload";
                PPic1Status = "remove";
                PStatusArray[0].status = PPic1Status;
                this.setState(
                  {
                    PPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PPic1").css("display", "block");
                    $("#choose_PPic1").hide();
                    $("#X-btn_PPic1").show();
                    $("#done-btn_PPic1").hide();
                    $("#review-btn_PPic1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionPPic1 = "upload";
                PPic1Status = "review";
                PStatusArray[0].status = PPic1Status;
                this.setState(
                  {
                    PPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PPic1").css("display", "block");
                    $("#choose_PPic1").hide();
                    $("#X-btn_PPic1").hide();
                    $("#done-btn_PPic1").hide();
                    $("#review-btn_PPic1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionPPic1 = "reupload";
                PPic1Status = "reupload";
                PStatusArray[0].status = PPic1Status;
                $("#choose_PPic1").show();
                $("#viewDocLink_PPic1").hide();
                $("#X-btn_PPic1").hide();
                $("#done-btn_PPic1").hide();
                $("#review-btn_PPic1").hide();
              } else if (doc[0].status == "done") {
                PPic1Status = "done";
                mainactionPPic1 = "upload";
                PStatusArray[0].status = PPic1Status;
                $("#choose_PPic1").hide();
                $("#X-btn_PPic1").hide();
                $("#done-btn_PPic1").show();
                $("#review-btn_PPic1").hide();
              } else {
                PPic1Status = "open";
                PStatusArray[0].status = PPic1Status;
              }
              if (doc[1].status == "remove") {
                mainactionPPic2 = "upload";
                PPic2Status = "remove";
                PStatusArray[1].status = PPic2Status;
                this.setState(
                  {
                    PPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PPic2").css("display", "block");
                    $("#choose_PPic2").hide();
                    $("#X-btn_PPic2").show();
                    $("#done-btn_PPic2").hide();
                    $("#review-btn_PPic2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionPPic2 = "upload";
                PPic2Status = "review";
                PStatusArray[1].status = PPic2Status;
                this.setState(
                  {
                    PPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PPic2").css("display", "block");
                    $("#choose_PPic2").hide();
                    $("#X-btn_PPic2").hide();
                    $("#done-btn_PPic2").hide();
                    $("#review-btn_PPic2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionPPic2 = "reupload";
                PPic2Status = "reupload";
                PStatusArray[1].status = PPic2Status;
                $("#choose_PPic2").show();
                $("#viewDocLink_PPic2").hide();
                $("#X-btn_PPic2").hide();
                $("#done-btn_PPic2").hide();
                $("#review-btn_PPic2").hide();
              } else if (doc[1].status == "done") {
                PPic2Status = "done";
                mainactionPPic2 = "upload";
                PStatusArray[1].status = PPic2Status;
                $("#choose_PPic2").hide();
                $("#X-btn_PPic2").hide();
                $("#done-btn_PPic2").show();
                $("#review-btn_PPic2").hide();
              } else {
                PPic2Status = "open";
                PStatusArray[1].status = PPic2Status;
              }
              if (doc[2].status == "remove") {
                mainactionPAadhar1 = "upload";
                PAadhar1Status = "remove";
                PStatusArray[2].status = PAadhar1Status;
                this.setState(
                  {
                    PAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PAadhar1").css("display", "block");
                    $("#choose_PAadhar1").hide();
                    $("#X-btn_PAadhar1").show();
                    $("#done-btn_PAadhar1").hide();
                    $("#review-btn_PAadhar1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionPAadhar1 = "upload";
                PAadhar1Status = "review";
                PStatusArray[2].status = PAadhar1Status;
                this.setState(
                  {
                    PAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PAadhar1").css("display", "block");
                    $("#choose_PAadhar1").hide();
                    $("#X-btn_PAadhar1").hide();
                    $("#done-btn_PAadhar1").hide();
                    $("#review-btn_PAadhar1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPAadhar1 = "reupload";
                PAadhar1Status = "reupload";
                PStatusArray[2].status = PAadhar1Status;
                $("#choose_PAadhar1").show();
                $("#viewDocLink_PAadhar1").hide();
                $("#X-btn_PAadhar1").hide();
                $("#done-btn_PAadhar1").hide();
                $("#review-btn_PAadhar1").hide();
              } else if (doc[2].status == "done") {
                PAadhar1Status = "done";
                mainactionPAadhar1 = "upload";
                PStatusArray[2].status = PAadhar1Status;
                $("#choose_PAadhar1").hide();
                $("#X-btn_PAadhar1").hide();
                $("#done-btn_PAadhar1").show();
                $("#review-btn_PAadhar1").hide();
              } else {
                PAadhar1Status = "open";
                PStatusArray[2].status = PAadhar1Status;
              }
              if (doc[3].status == "remove") {
                mainactionPAadhar2 = "upload";
                PAadhar2Status = "remove";
                PStatusArray[3].status = PAadhar2Status;
                this.setState(
                  {
                    PAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PAadhar2").css("display", "block");
                    $("#choose_PAadhar2").hide();
                    $("#X-btn_PAadhar2").show();
                    $("#done-btn_PAadhar2").hide();
                    $("#review-btn_PAadhar2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionPAadhar2 = "upload";
                PAadhar2Status = "review";
                PStatusArray[3].status = PAadhar2Status;
                this.setState(
                  {
                    PAadhar2DocLink: doc[3].AadhaarCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PAadhar2").css("display", "block");
                    $("#choose_PAadhar2").hide();
                    $("#X-btn_PAadhar2").hide();
                    $("#done-btn_PAadhar2").hide();
                    $("#review-btn_PAadhar2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionPAadhar2 = "reupload";
                PAadhar2Status = "reupload";
                PStatusArray[3].status = PAadhar2Status;
                $("#choose_PAadhar2").show();
                $("#viewDocLink_PAadhar2").hide();
                $("#X-btn_PAadhar2").hide();
                $("#done-btn_PAadhar2").hide();
                $("#review-btn_PAadhar2").hide();
              } else if (doc[3].status == "done") {
                PAadhar2Status = "done";
                mainactionPAadhar2 = "upload";
                PStatusArray[3].status = PAadhar2Status;
                $("#choose_PAadhar2").hide();
                $("#X-btn_PAadhar2").hide();
                $("#done-btn_PAadhar2").show();
                $("#review-btn_PAadhar2").hide();
              } else {
                PAadhar2Status = "open";
                PStatusArray[3].status = PAadhar2Status;
              }
              if (doc[4].status == "remove") {
                mainactionPPDeed = "upload";
                PPDeedStatus = "remove";
                PStatusArray[4].status = PPDeedStatus;
                this.setState(
                  {
                    PPDeedDocLink: doc[4].PartnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PPDeed").css("display", "block");
                    $("#choose_PPDeed").hide();
                    $("#X-btn_PPDeed").show();
                    $("#done-btn_PPDeed").hide();
                    $("#review-btn_PPDeed").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionPPDeed = "upload";
                PPDeedStatus = "review";
                PStatusArray[4].status = PPDeedStatus;
                this.setState(
                  {
                    PPDeedDocLink: doc[4].PartnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PPDeed").css("display", "block");
                    $("#choose_PPDeed").hide();
                    $("#X-btn_PPDeed").hide();
                    $("#done-btn_PPDeed").hide();
                    $("#review-btn_PPDeed").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionPPDeed = "reupload";
                PPDeedStatus = "reupload";
                PStatusArray[4].status = PPDeedStatus;
                $("#choose_PPDeed").show();
                $("#viewDocLink_PPDeed").hide();
                $("#X-btn_PPDeed").hide();
                $("#done-btn_PPDeed").hide();
                $("#review-btn_PPDeed").hide();
              } else if (doc[4].status == "done") {
                PPDeedStatus = "done";
                mainactionPPDeed = "upload";
                PStatusArray[4].status = PPDeedStatus;
                $("#choose_PPDeed").hide();
                $("#X-btn_PPDeed").hide();
                $("#done-btn_PPDeed").show();
                $("#review-btn_PPDeed").hide();
              } else {
                PPDeedStatus = "open";
                PStatusArray[4].status = PPDeedStatus;
              }
              if (doc[5].status == "remove") {
                mainactionPSelfDecl = "upload";
                PSelfDeclStatus = "remove";
                PStatusArray[5].status = PSelfDeclStatus;
                this.setState(
                  {
                    PSelfDeclDocLink: doc[5].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_PSelfDecl").css("display", "block");
                    $("#choose_PSelfDecl").hide();
                    $("#X-btn_PSelfDecl").show();
                    $("#done-btn_PSelfDecl").hide();
                    $("#review-btn_PSelfDecl").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionPSelfDecl = "upload";
                PSelfDeclStatus = "review";
                PStatusArray[5].status = PSelfDeclStatus;
                this.setState(
                  {
                    PSelfDeclDocLink: doc[5].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_PSelfDecl").css("display", "block");
                    $("#choose_PSelfDecl").hide();
                    $("#X-btn_PSelfDecl").hide();
                    $("#done-btn_PSelfDecl").hide();
                    $("#review-btn_PSelfDecl").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionPSelfDecl = "reupload";
                PSelfDeclStatus = "reupload";
                PStatusArray[5].status = PSelfDeclStatus;
                $("#choose_PSelfDecl").show();
                $("#viewDocLink_PSelfDecl").hide();
                $("#X-btn_PSelfDecl").hide();
                $("#done-btn_PSelfDecl").hide();
                $("#review-btn_PSelfDecl").hide();
              } else if (doc[5].status == "done") {
                PSelfDeclStatus = "done";
                mainactionPSelfDecl = "upload";
                PStatusArray[5].status = PSelfDeclStatus;
                $("#choose_PSelfDecl").hide();
                $("#X-btn_PSelfDecl").hide();
                $("#done-btn_PSelfDecl").show();
                $("#review-btn_PSelfDecl").hide();
              } else {
                PSelfDeclStatus = "open";
                PStatusArray[5].status = PSelfDeclStatus;
              }

              if (doc[6].status == "remove") {
                mainactionPRentAgreement = "upload";
                PRentAgreementStatus = "remove";
                POptStatusArray[0].status = PRentAgreementStatus;
                this.setState(
                  {
                    PRentAgreementDocLink: doc[6].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PRentAgreement").css("display", "block");
                    $("#choose_PRentAgreement").hide();
                    $("#X-btn_PRentAgreement").show();
                    $("#done-btn_PRentAgreement").hide();
                    $("#review-btn_PRentAgreement").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionPRentAgreement = "upload";
                PRentAgreementStatus = "review";
                POptStatusArray[0].status = PRentAgreementStatus;
                this.setState(
                  {
                    PRentAgreementDocLink: doc[6].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PRentAgreement").css("display", "block");
                    $("#choose_PRentAgreement").hide();
                    $("#X-btn_PRentAgreement").hide();
                    $("#done-btn_PRentAgreement").hide();
                    $("#review-btn_PRentAgreement").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionPRentAgreement = "reupload";
                PRentAgreementStatus = "reupload";
                POptStatusArray[0].status = PRentAgreementStatus;
                $("#choose_PRentAgreement").show();
                $("#viewDocLink_PRentAgreement").hide();
                $("#X-btn_PRentAgreement").hide();
                $("#done-btn_PRentAgreement").hide();
                $("#review-btn_PRentAgreement").hide();
              } else if (doc[6].status == "done") {
                PRentAgreementStatus = "done";
                mainactionPRentAgreement = "upload";
                POptStatusArray[0].status = PRentAgreementStatus;
                $("#choose_PRentAgreement").hide();
                $("#X-btn_PRentAgreement").hide();
                $("#done-btn_PRentAgreement").show();
                $("#review-btn_PRentAgreement").hide();
              } else {
                PRentAgreementStatus = "open";
                POptStatusArray[0].status = PRentAgreementStatus;
              }
              if (doc[7].status == "remove") {
                mainactionPOwnerDeed = "upload";
                POwnerDeedStatus = "remove";
                POptStatusArray[1].status = POwnerDeedStatus;
                this.setState(
                  {
                    POwnerDeedDocLink: doc[7].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_POwnerDeed").css("display", "block");
                    $("#choose_POwnerDeed").hide();
                    $("#X-btn_POwnerDeed").show();
                    $("#done-btn_POwnerDeed").hide();
                    $("#review-btn_POwnerDeed").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionPOwnerDeed = "upload";
                POwnerDeedStatus = "review";
                POptStatusArray[1].status = POwnerDeedStatus;
                this.setState(
                  {
                    POwnerDeedDocLink: doc[7].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_POwnerDeed").css("display", "block");
                    $("#choose_POwnerDeed").hide();
                    $("#X-btn_POwnerDeed").hide();
                    $("#done-btn_POwnerDeed").hide();
                    $("#review-btn_POwnerDeed").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionPOwnerDeed = "reupload";
                POwnerDeedStatus = "reupload";
                POptStatusArray[1].status = POwnerDeedStatus;
                $("#choose_POwnerDeed").show();
                $("#viewDocLink_POwnerDeed").hide();
                $("#X-btn_POwnerDeed").hide();
                $("#done-btn_POwnerDeed").hide();
                $("#review-btn_POwnerDeed").hide();
              } else if (doc[7].status == "done") {
                POwnerDeedStatus = "done";
                mainactionPOwnerDeed = "upload";
                POptStatusArray[1].status = POwnerDeedStatus;
                $("#choose_POwnerDeed").hide();
                $("#X-btn_POwnerDeed").hide();
                $("#done-btn_POwnerDeed").show();
                $("#review-btn_POwnerDeed").hide();
              } else {
                POwnerDeedStatus = "open";
                POptStatusArray[1].status = POwnerDeedStatus;
              }
              if (doc[8].status == "remove") {
                mainactionPTaxReceipt = "upload";
                PTaxReceiptStatus = "remove";
                PStatusArray[6].status = PTaxReceiptStatus;
                this.setState(
                  {
                    PTaxReceiptDocLink: doc[5].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_PTaxReceipt").css("display", "block");
                    $("#choose_PTaxReceipt").hide();
                    $("#X-btn_PTaxReceipt").show();
                    $("#done-btn_PTaxReceipt").hide();
                    $("#review-btn_PTaxReceipt").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionPTaxReceipt = "upload";
                PTaxReceiptStatus = "review";
                PStatusArray[6].status = PTaxReceiptStatus;
                this.setState(
                  {
                    PTaxReceiptDocLink: doc[8].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_PTaxReceipt").css("display", "block");
                    $("#choose_PTaxReceipt").hide();
                    $("#X-btn_PTaxReceipt").hide();
                    $("#done-btn_PTaxReceipt").hide();
                    $("#review-btn_PTaxReceipt").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionPTaxReceipt = "reupload";
                PTaxReceiptStatus = "reupload";
                PStatusArray[6].status = PTaxReceiptStatus;
                $("#choose_PTaxReceipt").show();
                $("#viewDocLink_PTaxReceipt").hide();
                $("#X-btn_PTaxReceipt").hide();
                $("#done-btn_PTaxReceipt").hide();
                $("#review-btn_PTaxReceipt").hide();
              } else if (doc[8].status == "done") {
                PTaxReceiptStatus = "done";
                mainactionPTaxReceipt = "upload";
                PStatusArray[6].status = PTaxReceiptStatus;
                $("#choose_PTaxReceipt").hide();
                $("#X-btn_PTaxReceipt").hide();
                $("#done-btn_PTaxReceipt").show();
                $("#review-btn_PTaxReceipt").hide();
              } else {
                PTaxReceiptStatus = "open";
                PStatusArray[6].status = PTaxReceiptStatus;
              }

              if (doc[9].status == "remove") {
                mainactionPPremisepic = "upload";
                PPremisepicStatus = "remove";
                PStatusArray[7].status = PPremisepicStatus;
                this.setState(
                  {
                    PPremisepicDocLink: doc[9].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_PPremisepic").css("display", "block");
                    $("#choose_PPremisepic").hide();
                    $("#X-btn_PPremisepic").show();
                    $("#done-btn_PPremisepic").hide();
                    $("#review-btn_PPremisepic").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionPPremisepic = "upload";
                PPremisepicStatus = "review";
                PStatusArray[7].status = PPremisepicStatus;
                this.setState(
                  {
                    PPremisepicDocLink: doc[9].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_PPremisepic").css("display", "block");
                    $("#choose_PPremisepic").hide();
                    $("#X-btn_PPremisepic").hide();
                    $("#done-btn_PPremisepic").hide();
                    $("#review-btn_PPremisepic").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionPPremisepic = "reupload";
                PPremisepicStatus = "reupload";
                PStatusArray[7].status = PPremisepicStatus;
                $("#choose_PPremisepic").show();
                $("#viewDocLink_PPremisepic").hide();
                $("#X-btn_PPremisepic").hide();
                $("#done-btn_PPremisepic").hide();
                $("#review-btn_PPremisepic").hide();
              } else if (doc[9].status == "done") {
                PPremisepicStatus = "done";
                mainactionPPremisepic = "upload";
                PStatusArray[7].status = PPremisepicStatus;
                $("#choose_PPremisepic").hide();
                $("#X-btn_PPremisepic").hide();
                $("#done-btn_PPremisepic").show();
                $("#review-btn_PPremisepic").hide();
              } else {
                PPremisepicStatus = "open";
                PStatusArray[7].status = PPremisepicStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  PPbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
              if (res.data.inputFields[1].AddressProofType == "OwnershipDeed") {
                this.setState(
                  {
                    PAddressProofType_radio: "OwnershipDeed",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    PAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            this.PcheckRemainingReuploads();
            this.checkhandlePProceedBtn();
          } else if (res.data.serviceType == "TL_PRL_PL_OP_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "TL_PRL_PL_OP_Documents",
                serviceType: "TL_PRL_PL_OP_Documents",
              },
              () => {
                if (this.state.dropDownValue == "TL_PRL_PL_OP_Documents") {
                  $("#trade_default_Img").hide();
                  $("#trade_SolePI").hide();
                  $("#trade_P_LLP").hide();
                  $("#trade_PL").show();
                  $("#trade_HUF").hide();
                  $("#trade_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#PLadditionalDocumentDiv").hide();
              } else {
                $("#PLAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  PLadditionalFiles: [...res.data.addDocInformation],
                  PLAdd_DocName: "File Name",
                  api_PLadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                PLAdd_DocName: "",
                PLadditionalFiles: [1],
                api_PLadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionPLPic1 = "upload";
                PLPic1Status = "remove";
                PLStatusArray[0].status = PLPic1Status;
                this.setState(
                  {
                    PLPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PLPic1").css("display", "block");
                    $("#choose_PLPic1").hide();
                    $("#X-btn_PLPic1").show();
                    $("#done-btn_PLPic1").hide();
                    $("#review-btn_PLPic1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionPLPic1 = "upload";
                PLPic1Status = "review";
                PLStatusArray[0].status = PLPic1Status;
                this.setState(
                  {
                    PLPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PLPic1").css("display", "block");
                    $("#choose_PLPic1").hide();
                    $("#X-btn_PLPic1").hide();
                    $("#done-btn_PLPic1").hide();
                    $("#review-btn_PLPic1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionPLPic1 = "reupload";
                PLPic1Status = "reupload";
                PLStatusArray[0].status = PLPic1Status;
                $("#choose_PLPic1").show();
                $("#viewDocLink_PLPic1").hide();
                $("#X-btn_PLPic1").hide();
                $("#done-btn_PLPic1").hide();
                $("#review-btn_PLPic1").hide();
              } else if (doc[0].status == "done") {
                PLPic1Status = "done";
                mainactionPLPic1 = "upload";
                PLStatusArray[0].status = PLPic1Status;
                $("#choose_PLPic1").hide();
                $("#X-btn_PLPic1").hide();
                $("#done-btn_PLPic1").show();
                $("#review-btn_PLPic1").hide();
              } else {
                PLPic1Status = "open";
                PLStatusArray[0].status = PLPic1Status;
              }
              if (doc[1].status == "remove") {
                mainactionPLPic2 = "upload";
                PLPic2Status = "remove";
                PLStatusArray[1].status = PLPic2Status;
                this.setState(
                  {
                    PLPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PLPic2").css("display", "block");
                    $("#choose_PLPic2").hide();
                    $("#X-btn_PLPic2").show();
                    $("#done-btn_PLPic2").hide();
                    $("#review-btn_PLPic2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionPLPic2 = "upload";
                PLPic2Status = "review";
                PLStatusArray[1].status = PLPic2Status;
                this.setState(
                  {
                    PLPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PLPic2").css("display", "block");
                    $("#choose_PLPic2").hide();
                    $("#X-btn_PLPic2").hide();
                    $("#done-btn_PLPic2").hide();
                    $("#review-btn_PLPic2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionPLPic2 = "reupload";
                PLPic2Status = "reupload";
                PLStatusArray[1].status = PLPic2Status;
                $("#choose_PLPic2").show();
                $("#viewDocLink_PLPic2").hide();
                $("#X-btn_PLPic2").hide();
                $("#done-btn_PLPic2").hide();
                $("#review-btn_PLPic2").hide();
              } else if (doc[1].status == "done") {
                PLPic2Status = "done";
                mainactionPLPic2 = "upload";
                PLStatusArray[1].status = PLPic2Status;
                $("#choose_PLPic2").hide();
                $("#X-btn_PLPic2").hide();
                $("#done-btn_PLPic2").show();
                $("#review-btn_PLPic2").hide();
              } else {
                PLPic2Status = "open";
                PLStatusArray[1].status = PLPic2Status;
              }
              if (doc[2].status == "remove") {
                mainactionPLAadhar1 = "upload";
                PLAadhar1Status = "remove";
                PLStatusArray[2].status = PLAadhar1Status;
                this.setState(
                  {
                    PLAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar1").css("display", "block");
                    $("#choose_PLAadhar1").hide();
                    $("#X-btn_PLAadhar1").show();
                    $("#done-btn_PLAadhar1").hide();
                    $("#review-btn_PLAadhar1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionPLAadhar1 = "upload";
                PLAadhar1Status = "review";
                PLStatusArray[2].status = PLAadhar1Status;
                this.setState(
                  {
                    PLAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar1").css("display", "block");
                    $("#choose_PLAadhar1").hide();
                    $("#X-btn_PLAadhar1").hide();
                    $("#done-btn_PLAadhar1").hide();
                    $("#review-btn_PLAadhar1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPLAadhar1 = "reupload";
                PLAadhar1Status = "reupload";
                PLStatusArray[2].status = PLAadhar1Status;
                $("#choose_PLAadhar1").show();
                $("#viewDocLink_PLAadhar1").hide();
                $("#X-btn_PLAadhar1").hide();
                $("#done-btn_PLAadhar1").hide();
                $("#review-btn_PLAadhar1").hide();
              } else if (doc[2].status == "done") {
                PLAadhar1Status = "done";
                mainactionPLAadhar1 = "upload";
                PLStatusArray[2].status = PLAadhar1Status;
                $("#choose_PLAadhar1").hide();
                $("#X-btn_PLAadhar1").hide();
                $("#done-btn_PLAadhar1").show();
                $("#review-btn_PLAadhar1").hide();
              } else {
                PLAadhar1Status = "open";
                PLStatusArray[2].status = PLAadhar1Status;
              }
              if (doc[3].status == "remove") {
                mainactionPLAadhar2 = "upload";
                PLAadhar2Status = "remove";
                PLStatusArray[3].status = PLAadhar2Status;
                this.setState(
                  {
                    PLAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar2").css("display", "block");
                    $("#choose_PLAadhar2").hide();
                    $("#X-btn_PLAadhar2").show();
                    $("#done-btn_PLAadhar2").hide();
                    $("#review-btn_PLAadhar2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionPLAadhar2 = "upload";
                PLAadhar2Status = "review";
                PLStatusArray[3].status = PLAadhar2Status;
                this.setState(
                  {
                    PLAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar2").css("display", "block");
                    $("#choose_PLAadhar2").hide();
                    $("#X-btn_PLAadhar2").hide();
                    $("#done-btn_PLAadhar2").hide();
                    $("#review-btn_PLAadhar2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionPLAadhar2 = "reupload";
                PLAadhar2Status = "reupload";
                PLStatusArray[3].status = PLAadhar2Status;
                $("#choose_PLAadhar2").show();
                $("#viewDocLink_PLAadhar2").hide();
                $("#X-btn_PLAadhar2").hide();
                $("#done-btn_PLAadhar2").hide();
                $("#review-btn_PLAadhar2").hide();
              } else if (doc[3].status == "done") {
                PLAadhar2Status = "done";
                mainactionPLAadhar2 = "upload";
                PLStatusArray[3].status = PLAadhar2Status;
                $("#choose_PLAadhar2").hide();
                $("#X-btn_PLAadhar2").hide();
                $("#done-btn_PLAadhar2").show();
                $("#review-btn_PLAadhar2").hide();
              } else {
                PLAadhar2Status = "open";
                PLStatusArray[3].status = PLAadhar2Status;
              }
              if (doc[4].status == "remove") {
                mainactionPLCI = "upload";
                PLCIStatus = "remove";
                PLStatusArray[5].status = PLCIStatus;
                this.setState(
                  {
                    PLCIDocLink: doc[4].CertificateOfIncorporation,
                  },
                  () => {
                    $("#viewDocLink_PLCI").css("display", "block");
                    $("#choose_PLCI").hide();
                    $("#X-btn_PLCI").show();
                    $("#done-btn_PLCI").hide();
                    $("#review-btn_PLCI").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionPLCI = "upload";
                PLCIStatus = "review";
                PLStatusArray[5].status = PLCIStatus;
                this.setState(
                  {
                    PLCIDocLink: doc[4].CertificateOfIncorporation,
                  },
                  () => {
                    $("#viewDocLink_PLCI").css("display", "block");
                    $("#choose_PLCI").hide();
                    $("#X-btn_PLCI").hide();
                    $("#done-btn_PLCI").hide();
                    $("#review-btn_PLCI").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionPLCI = "reupload";
                PLCIStatus = "reupload";
                PLStatusArray[5].status = PLCIStatus;
                $("#choose_PLCI").show();
                $("#viewDocLink_PLCI").hide();
                $("#X-btn_PLCI").hide();
                $("#done-btn_PLCI").hide();
                $("#review-btn_PLCI").hide();
              } else if (doc[4].status == "done") {
                PLCIStatus = "done";
                mainactionPLCI = "upload";
                PLStatusArray[5].status = PLCIStatus;
                $("#choose_PLCI").hide();
                $("#X-btn_PLCI").hide();
                $("#done-btn_PLCI").show();
                $("#review-btn_PLCI").hide();
              } else {
                PLCIStatus = "open";
                PLStatusArray[5].status = PLCIStatus;
              }
              if (doc[5].status == "remove") {
                mainactionPLAOA = "upload";
                PLAOAStatus = "remove";
                PLStatusArray[7].status = PLAOAStatus;
                this.setState(
                  {
                    PLAOADocLink: doc[5].AOA,
                  },
                  () => {
                    $("#viewDocLink_PLAOA").css("display", "block");
                    $("#choose_PLAOA").hide();
                    $("#X-btn_PLAOA").show();
                    $("#done-btn_PLAOA").hide();
                    $("#review-btn_PLAOA").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionPLAOA = "upload";
                PLAOAStatus = "review";
                PLStatusArray[7].status = PLAOAStatus;
                this.setState(
                  {
                    PLAOADocLink: doc[5].AOA,
                  },
                  () => {
                    $("#viewDocLink_PLAOA").css("display", "block");
                    $("#choose_PLAOA").hide();
                    $("#X-btn_PLAOA").hide();
                    $("#done-btn_PLAOA").hide();
                    $("#review-btn_PLAOA").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionPLAOA = "reupload";
                PLAOAStatus = "reupload";
                PLStatusArray[7].status = PLAOAStatus;
                $("#choose_PLAOA").show();
                $("#viewDocLink_PLAOA").hide();
                $("#X-btn_PLAOA").hide();
                $("#done-btn_PLAOA").hide();
                $("#review-btn_PLAOA").hide();
              } else if (doc[5].status == "done") {
                PLAOAStatus = "done";
                mainactionPLAOA = "upload";
                PLStatusArray[7].status = PLAOAStatus;
                $("#choose_PLAOA").hide();
                $("#X-btn_PLAOA").hide();
                $("#done-btn_PLAOA").show();
                $("#review-btn_PLAOA").hide();
              } else {
                PLAOAStatus = "open";
                PLStatusArray[7].status = PLAOAStatus;
              }
              if (doc[6].status == "remove") {
                mainactionPLMOA = "upload";
                PLMOAStatus = "remove";
                PLStatusArray[6].status = PLMOAStatus;
                this.setState(
                  {
                    PLMOADocLink: doc[6].MOA,
                  },
                  () => {
                    $("#viewDocLink_PLMOA").css("display", "block");
                    $("#choose_PLMOA").hide();
                    $("#X-btn_PLMOA").show();
                    $("#done-btn_PLMOA").hide();
                    $("#review-btn_PLMOA").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionPLMOA = "upload";
                PLMOAStatus = "review";
                PLStatusArray[6].status = PLMOAStatus;
                this.setState(
                  {
                    PLMOADocLink: doc[6].MOA,
                  },
                  () => {
                    $("#viewDocLink_PLMOA").css("display", "block");
                    $("#choose_PLMOA").hide();
                    $("#X-btn_PLMOA").hide();
                    $("#done-btn_PLMOA").hide();
                    $("#review-btn_PLMOA").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionPLMOA = "reupload";
                PLMOAStatus = "reupload";
                PLStatusArray[6].status = PLMOAStatus;
                $("#choose_PLMOA").show();
                $("#viewDocLink_PLMOA").hide();
                $("#X-btn_PLMOA").hide();
                $("#done-btn_PLMOA").hide();
                $("#review-btn_PLMOA").hide();
              } else if (doc[6].status == "done") {
                PLMOAStatus = "done";
                mainactionPLMOA = "upload";
                PLStatusArray[6].status = PLMOAStatus;
                $("#choose_PLMOA").hide();
                $("#X-btn_PLMOA").hide();
                $("#done-btn_PLMOA").show();
                $("#review-btn_PLMOA").hide();
              } else {
                PLMOAStatus = "open";
                PLStatusArray[6].status = PLMOAStatus;
              }
              if (doc[7].status == "remove") {
                mainactionPLSelfDecl = "upload";
                PLSelfDeclStatus = "remove";
                PLStatusArray[4].status = PLSelfDeclStatus;
                this.setState(
                  {
                    PLSelfDeclDocLink: doc[7].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_PLSelfDecl").css("display", "block");
                    $("#choose_PLSelfDecl").hide();
                    $("#X-btn_PLSelfDecl").show();
                    $("#done-btn_PLSelfDecl").hide();
                    $("#review-btn_PLSelfDecl").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionPLSelfDecl = "upload";
                PLSelfDeclStatus = "review";
                PLStatusArray[4].status = PLSelfDeclStatus;
                this.setState(
                  {
                    PLSelfDeclDocLink: doc[7].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_PLSelfDecl").css("display", "block");
                    $("#choose_PLSelfDecl").hide();
                    $("#X-btn_PLSelfDecl").hide();
                    $("#done-btn_PLSelfDecl").hide();
                    $("#review-btn_PLSelfDecl").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionPLSelfDecl = "reupload";
                PLSelfDeclStatus = "reupload";
                PLStatusArray[4].status = PLSelfDeclStatus;
                $("#choose_PLSelfDecl").show();
                $("#viewDocLink_PLSelfDecl").hide();
                $("#X-btn_PLSelfDecl").hide();
                $("#done-btn_PLSelfDecl").hide();
                $("#review-btn_PLSelfDecl").hide();
              } else if (doc[7].status == "done") {
                PLSelfDeclStatus = "done";
                mainactionPLSelfDecl = "upload";
                PLStatusArray[4].status = PLSelfDeclStatus;
                $("#choose_PLSelfDecl").hide();
                $("#X-btn_PLSelfDecl").hide();
                $("#done-btn_PLSelfDecl").show();
                $("#review-btn_PLSelfDecl").hide();
              } else {
                PLSelfDeclStatus = "open";
                PLStatusArray[4].status = PLSelfDeclStatus;
              }

              if (doc[9].status == "remove") {
                mainactionPLRentAgreement = "upload";
                PLRentAgreementStatus = "remove";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                this.setState(
                  {
                    PLRentAgreementDocLink: doc[9].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PLRentAgreement").css("display", "block");
                    $("#choose_PLRentAgreement").hide();
                    $("#X-btn_PLRentAgreement").show();
                    $("#done-btn_PLRentAgreement").hide();
                    $("#review-btn_PLRentAgreement").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionPLRentAgreement = "upload";
                PLRentAgreementStatus = "review";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                this.setState(
                  {
                    PLRentAgreementDocLink: doc[9].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PLRentAgreement").css("display", "block");
                    $("#choose_PLRentAgreement").hide();
                    $("#X-btn_PLRentAgreement").hide();
                    $("#done-btn_PLRentAgreement").hide();
                    $("#review-btn_PLRentAgreement").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionPLRentAgreement = "reupload";
                PLRentAgreementStatus = "reupload";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                $("#choose_PLRentAgreement").show();
                $("#viewDocLink_PLRentAgreement").hide();
                $("#X-btn_PLRentAgreement").hide();
                $("#done-btn_PLRentAgreement").hide();
                $("#review-btn_PLRentAgreement").hide();
              } else if (doc[9].status == "done") {
                PLRentAgreementStatus = "done";
                mainactionPLRentAgreement = "upload";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                $("#choose_PLRentAgreement").hide();
                $("#X-btn_PLRentAgreement").hide();
                $("#done-btn_PLRentAgreement").show();
                $("#review-btn_PLRentAgreement").hide();
              } else {
                PLRentAgreementStatus = "open";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
              }
              if (doc[8].status == "remove") {
                mainactionPLOwnerDeed = "upload";
                PLOwnerDeedStatus = "remove";
                PLOptStatusArray[0].status = PLOwnerDeedStatus;
                this.setState(
                  {
                    PLOwnerDeedDocLink: doc[8].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PLOwnerDeed").css("display", "block");
                    $("#choose_PLOwnerDeed").hide();
                    $("#X-btn_PLOwnerDeed").show();
                    $("#done-btn_PLOwnerDeed").hide();
                    $("#review-btn_PLOwnerDeed").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionPLOwnerDeed = "upload";
                PLOwnerDeedStatus = "review";
                PLOptStatusArray[0].status = PLOwnerDeedStatus;
                this.setState(
                  {
                    PLOwnerDeedDocLink: doc[8].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PLOwnerDeed").css("display", "block");
                    $("#choose_PLOwnerDeed").hide();
                    $("#X-btn_PLOwnerDeed").hide();
                    $("#done-btn_PLOwnerDeed").hide();
                    $("#review-btn_PLOwnerDeed").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionPLOwnerDeed = "reupload";
                PLOwnerDeedStatus = "reupload";
                PLOptStatusArray[0].status = PLOwnerDeedStatus;
                $("#choose_PLOwnerDeed").show();
                $("#viewDocLink_PLOwnerDeed").hide();
                $("#X-btn_PLOwnerDeed").hide();
                $("#done-btn_PLOwnerDeed").hide();
                $("#review-btn_PLOwnerDeed").hide();
              } else if (doc[8].status == "done") {
                PLOwnerDeedStatus = "done";
                mainactionPLOwnerDeed = "upload";
                PLOptStatusArray[0].status = PLOwnerDeedStatus;
                $("#choose_PLOwnerDeed").hide();
                $("#X-btn_PLOwnerDeed").hide();
                $("#done-btn_PLOwnerDeed").show();
                $("#review-btn_PLOwnerDeed").hide();
              } else {
                PLOwnerDeedStatus = "open";
                PLOptStatusArray[0].status = PLOwnerDeedStatus;
              }
              if (doc[10].status == "remove") {
                mainactionPLTaxReceipt = "upload";
                PLTaxReceiptStatus = "remove";
                PLStatusArray[7].status = PLTaxReceiptStatus;
                this.setState(
                  {
                    PLTaxReceiptDocLink: doc[10].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_PLTaxReceipt").css("display", "block");
                    $("#choose_PLTaxReceipt").hide();
                    $("#X-btn_PLTaxReceipt").show();
                    $("#done-btn_PLTaxReceipt").hide();
                    $("#review-btn_PLTaxReceipt").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionPLTaxReceipt = "upload";
                PLTaxReceiptStatus = "review";
                PLStatusArray[7].status = PLTaxReceiptStatus;
                this.setState(
                  {
                    PLTaxReceiptDocLink: doc[10].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_PLTaxReceipt").css("display", "block");
                    $("#choose_PLTaxReceipt").hide();
                    $("#X-btn_PLTaxReceipt").hide();
                    $("#done-btn_PLTaxReceipt").hide();
                    $("#review-btn_PLTaxReceipt").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionPLTaxReceipt = "reupload";
                PLTaxReceiptStatus = "reupload";
                PLStatusArray[7].status = PLTaxReceiptStatus;
                $("#choose_PLTaxReceipt").show();
                $("#viewDocLink_PLTaxReceipt").hide();
                $("#X-btn_PLTaxReceipt").hide();
                $("#done-btn_PLTaxReceipt").hide();
                $("#review-btn_PLTaxReceipt").hide();
              } else if (doc[10].status == "done") {
                PLTaxReceiptStatus = "done";
                mainactionPLTaxReceipt = "upload";
                PLStatusArray[7].status = PLTaxReceiptStatus;
                $("#choose_PLTaxReceipt").hide();
                $("#X-btn_PLTaxReceipt").hide();
                $("#done-btn_PLTaxReceipt").show();
                $("#review-btn_PLTaxReceipt").hide();
              } else {
                PLTaxReceiptStatus = "open";
                PLStatusArray[7].status = PLTaxReceiptStatus;
              }

              if (doc[11].status == "remove") {
                mainactionPLPremisePic = "upload";
                PLPremisePicStatus = "remove";
                PLStatusArray[9].status = PLPremisePicStatus;
                this.setState(
                  {
                    PLPremisePicDocLink: doc[11].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_PLPremisePic").css("display", "block");
                    $("#choose_PLPremisePic").hide();
                    $("#X-btn_PLPremisePic").show();
                    $("#done-btn_PLPremisePic").hide();
                    $("#review-btn_PLPremisePic").hide();
                  }
                );
              } else if (doc[11].status == "review") {
                mainactionPLPremisePic = "upload";
                PLPremisePicStatus = "review";
                PLStatusArray[9].status = PLPremisePicStatus;
                this.setState(
                  {
                    PLPremisePicDocLink: doc[11].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_PLPremisePic").css("display", "block");
                    $("#choose_PLPremisePic").hide();
                    $("#X-btn_PLPremisePic").hide();
                    $("#done-btn_PLPremisePic").hide();
                    $("#review-btn_PLPremisePic").show();
                  }
                );
              } else if (doc[11].status == "reupload") {
                mainactionPLPremisePic = "reupload";
                PLPremisePicStatus = "reupload";
                PLStatusArray[9].status = PLPremisePicStatus;
                $("#choose_PLPremisePic").show();
                $("#viewDocLink_PLPremisePic").hide();
                $("#X-btn_PLPremisePic").hide();
                $("#done-btn_PLPremisePic").hide();
                $("#review-btn_PLPremisePic").hide();
              } else if (doc[11].status == "done") {
                PLPremisePicStatus = "done";
                mainactionPLPremisePic = "upload";
                PLStatusArray[9].status = PLPremisePicStatus;
                $("#choose_PLPremisePic").hide();
                $("#X-btn_PLPremisePic").hide();
                $("#done-btn_PLPremisePic").show();
                $("#review-btn_PLPremisePic").hide();
              } else {
                PLPremisePicStatus = "open";
                PLStatusArray[9].status = PLPremisePicStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  PLbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
              if (res.data.inputFields[1].AddressProofType == "OwnershipDeed") {
                this.setState(
                  {
                    PLAddressProofType_radio: "OwnershipDeed",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    PLAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            this.PLcheckRemainingReuploads();
            this.checkhandlePLProceedBtn();
          } else if (res.data.serviceType == "TL_HUF_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "TL_HUF_Documents",
                serviceType: "TL_HUF_Documents",
              },
              () => {
                if (this.state.dropDownValue == "TL_HUF_Documents") {
                  $("#trade_default_Img").hide();
                  $("#trade_SolePI").hide();
                  $("#trade_P_LLP").hide();
                  $("#trade_PL").hide();
                  $("#trade_HUF").show();
                  $("#trade_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#HUFadditionalDocumentDiv").hide();
              } else {
                $("#HUFAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  HUFadditionalFiles: [...res.data.addDocInformation],
                  HUFAdd_DocName: "File Name",
                  api_HUFadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                HUFAdd_DocName: "",
                HUFadditionalFiles: [1],
                api_HUFadditionalFiles: "No",
              });
            }
            if (
              res.data.inputFields[0].status == "review" ||
              res.data.inputFields[0].status == "done"
            ) {
              this.setState({
                HUFbusinessNature: res.data.inputFields[0].Nature,
              });
              $(".uploadtextarea").attr("disabled", true);
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[1].status == "remove") {
                mainactionHUFAadhar = "upload";
                HUFAadharStatus = "remove";
                HUFStatusArray[0].status = HUFAadharStatus;
                this.setState(
                  {
                    HUFAadharDocLink: doc[1].IDProofApplicant,
                  },
                  () => {
                    $("#viewDocLink_HUFAadhar").css("display", "block");
                    $("#choose_HUFAadhar").hide();
                    $("#X-btn_HUFAadhar").show();
                    $("#done-btn_HUFAadhar").hide();
                    $("#review-btn_HUFAadhar").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionHUFAadhar = "upload";
                HUFAadharStatus = "review";
                HUFStatusArray[0].status = HUFAadharStatus;
                this.setState(
                  {
                    HUFAadharDocLink: doc[1].IDProofApplicant,
                  },
                  () => {
                    $("#viewDocLink_HUFAadhar").css("display", "block");
                    $("#choose_HUFAadhar").hide();
                    $("#X-btn_HUFAadhar").hide();
                    $("#done-btn_HUFAadhar").hide();
                    $("#review-btn_HUFAadhar").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionHUFAadhar = "reupload";
                HUFAadharStatus = "reupload";
                HUFStatusArray[0].status = HUFAadharStatus;
                $("#choose_HUFAadhar").show();
                $("#viewDocLink_HUFAadhar").hide();
                $("#X-btn_HUFAadhar").hide();
                $("#done-btn_HUFAadhar").hide();
                $("#review-btn_HUFAadhar").hide();
              } else if (doc[1].status == "done") {
                HUFAadharStatus = "done";
                mainactionHUFAadhar = "upload";
                HUFStatusArray[0].status = HUFAadharStatus;
                $("#choose_HUFAadhar").hide();
                $("#X-btn_HUFAadhar").hide();
                $("#done-btn_HUFAadhar").show();
                $("#review-btn_HUFAadhar").hide();
              } else {
                HUFAadharStatus = "open";
                HUFStatusArray[0].status = HUFAadharStatus;
              }

              if (doc[0].status == "remove") {
                mainactionHUFPic = "upload";
                HUFPicStatus = "remove";
                HUFStatusArray[1].status = HUFPicStatus;
                this.setState(
                  {
                    HUFPicDocLink: doc[0].ApplicantPhoto,
                  },
                  () => {
                    $("#viewDocLink_HUFPic").css("display", "block");
                    $("#choose_HUFPic").hide();
                    $("#X-btn_HUFPic").show();
                    $("#done-btn_HUFPic").hide();
                    $("#review-btn_HUFPic").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionHUFPic = "upload";
                HUFPicStatus = "review";
                HUFStatusArray[1].status = HUFPicStatus;
                this.setState(
                  {
                    HUFPicDocLink: doc[0].ApplicantPhoto,
                  },
                  () => {
                    $("#viewDocLink_HUFPic").css("display", "block");
                    $("#choose_HUFPic").hide();
                    $("#X-btn_HUFPic").hide();
                    $("#done-btn_HUFPic").hide();
                    $("#review-btn_HUFPic").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionHUFPic = "reupload";
                HUFPicStatus = "reupload";
                HUFStatusArray[1].status = HUFPicStatus;
                $("#choose_HUFPic").show();
                $("#viewDocLink_HUFPic").hide();
                $("#X-btn_HUFPic").hide();
                $("#done-btn_HUFPic").hide();
                $("#review-btn_HUFPic").hide();
              } else if (doc[0].status == "done") {
                HUFPicStatus = "done";
                mainactionHUFPic = "upload";
                HUFStatusArray[1].status = HUFPicStatus;
                $("#choose_HUFPic").hide();
                $("#X-btn_HUFPic").hide();
                $("#done-btn_HUFPic").show();
                $("#review-btn_HUFPic").hide();
              } else {
                HUFPicStatus = "open";
                HUFStatusArray[1].status = HUFPicStatus;
              }
              if (doc[2].status == "remove") {
                mainactionHUFDeed = "upload";
                HUFDeedStatus = "remove";
                HUFStatusArray[2].status = HUFDeedStatus;
                this.setState(
                  {
                    HUFDeedDocLink: doc[2].HUFDeedDocument,
                  },
                  () => {
                    $("#viewDocLink_HUFDeed").css("display", "block");
                    $("#choose_HUFDeed").hide();
                    $("#X-btn_HUFDeed").show();
                    $("#done-btn_HUFDeed").hide();
                    $("#review-btn_HUFDeed").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionHUFDeed = "upload";
                HUFDeedStatus = "review";
                HUFStatusArray[2].status = HUFDeedStatus;
                this.setState(
                  {
                    HUFDeedDocLink: doc[2].HUFDeedDocument,
                  },
                  () => {
                    $("#viewDocLink_HUFDeed").css("display", "block");
                    $("#choose_HUFDeed").hide();
                    $("#X-btn_HUFDeed").hide();
                    $("#done-btn_HUFDeed").hide();
                    $("#review-btn_HUFDeed").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionHUFDeed = "reupload";
                HUFDeedStatus = "reupload";
                HUFStatusArray[2].status = HUFDeedStatus;
                $("#choose_HUFDeed").show();
                $("#viewDocLink_HUFDeed").hide();
                $("#X-btn_HUFDeed").hide();
                $("#done-btn_HUFDeed").hide();
                $("#review-btn_HUFDeed").hide();
              } else if (doc[2].status == "done") {
                HUFDeedStatus = "done";
                mainactionHUFDeed = "upload";
                HUFStatusArray[2].status = HUFDeedStatus;
                $("#choose_HUFDeed").hide();
                $("#X-btn_HUFDeed").hide();
                $("#done-btn_HUFDeed").show();
                $("#review-btn_HUFDeed").hide();
              } else {
                HUFDeedStatus = "open";
                HUFStatusArray[2].status = HUFDeedStatus;
              }
              if (doc[3].status == "remove") {
                mainactionHUFSelfDecl = "upload";
                HUFSelfDeclStatus = "remove";
                HUFStatusArray[3].status = HUFSelfDeclStatus;
                this.setState(
                  {
                    HUFSelfDeclDocLink: doc[3].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_HUFSelfDecl").css("display", "block");
                    $("#choose_HUFSelfDecl").hide();
                    $("#X-btn_HUFSelfDecl").show();
                    $("#done-btn_HUFSelfDecl").hide();
                    $("#review-btn_HUFSelfDecl").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionHUFSelfDecl = "upload";
                HUFSelfDeclStatus = "review";
                HUFStatusArray[3].status = HUFSelfDeclStatus;
                this.setState(
                  {
                    HUFSelfDeclDocLink: doc[3].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_HUFSelfDecl").css("display", "block");
                    $("#choose_HUFSelfDecl").hide();
                    $("#X-btn_HUFSelfDecl").hide();
                    $("#done-btn_HUFSelfDecl").hide();
                    $("#review-btn_HUFSelfDecl").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionHUFSelfDecl = "reupload";
                HUFSelfDeclStatus = "reupload";
                HUFStatusArray[3].status = HUFSelfDeclStatus;
                $("#choose_HUFSelfDecl").show();
                $("#viewDocLink_HUFSelfDecl").hide();
                $("#X-btn_HUFSelfDecl").hide();
                $("#done-btn_HUFSelfDecl").hide();
                $("#review-btn_HUFSelfDecl").hide();
              } else if (doc[3].status == "done") {
                HUFSelfDeclStatus = "done";
                mainactionHUFSelfDecl = "upload";
                HUFStatusArray[3].status = HUFSelfDeclStatus;
                $("#choose_HUFSelfDecl").hide();
                $("#X-btn_HUFSelfDecl").hide();
                $("#done-btn_HUFSelfDecl").show();
                $("#review-btn_HUFSelfDecl").hide();
              } else {
                HUFSelfDeclStatus = "open";
                HUFStatusArray[3].status = HUFSelfDeclStatus;
              }
              if (doc[5].status == "remove") {
                mainactionHUFCopyOfRegistry = "upload";
                HUFCopyOfRegistryStatus = "remove";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                this.setState(
                  {
                    HUFCopyOfRegistryDocLink: doc[5].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_HUFCopyOfRegistry").css("display", "block");
                    $("#choose_HUFCopyOfRegistry").hide();
                    $("#X-btn_HUFCopyOfRegistry").show();
                    $("#done-btn_HUFCopyOfRegistry").hide();
                    $("#review-btn_HUFCopyOfRegistry").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionHUFCopyOfRegistry = "upload";
                HUFCopyOfRegistryStatus = "review";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                this.setState(
                  {
                    HUFCopyOfRegistryDocLink: doc[5].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_HUFCopyOfRegistry").css("display", "block");
                    $("#choose_HUFCopyOfRegistry").hide();
                    $("#X-btn_HUFCopyOfRegistry").hide();
                    $("#done-btn_HUFCopyOfRegistry").hide();
                    $("#review-btn_HUFCopyOfRegistry").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionHUFCopyOfRegistry = "reupload";
                HUFCopyOfRegistryStatus = "reupload";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                $("#choose_HUFCopyOfRegistry").show();
                $("#viewDocLink_HUFCopyOfRegistry").hide();
                $("#X-btn_HUFCopyOfRegistry").hide();
                $("#done-btn_HUFCopyOfRegistry").hide();
                $("#review-btn_HUFCopyOfRegistry").hide();
              } else if (doc[5].status == "done") {
                HUFCopyOfRegistryStatus = "done";
                mainactionHUFCopyOfRegistry = "upload";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                $("#choose_HUFCopyOfRegistry").hide();
                $("#X-btn_HUFCopyOfRegistry").hide();
                $("#done-btn_HUFCopyOfRegistry").show();
                $("#review-btn_HUFCopyOfRegistry").hide();
              } else {
                HUFCopyOfRegistryStatus = "open";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
              }
              if (doc[4].status == "remove") {
                mainactionHUFRentAgreement = "upload";
                HUFRentAgreementStatus = "remove";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                this.setState(
                  {
                    HUFRentAgreementDocLink: doc[4].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_HUFRentAgreement").css("display", "block");
                    $("#choose_HUFRentAgreement").hide();
                    $("#X-btn_HUFRentAgreement").show();
                    $("#done-btn_HUFRentAgreement").hide();
                    $("#review-btn_HUFRentAgreement").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionHUFRentAgreement = "upload";
                HUFRentAgreementStatus = "review";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                this.setState(
                  {
                    HUFRentAgreementDocLink: doc[4].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_HUFRentAgreement").css("display", "block");
                    $("#choose_HUFRentAgreement").hide();
                    $("#X-btn_HUFRentAgreement").hide();
                    $("#done-btn_HUFRentAgreement").hide();
                    $("#review-btn_HUFRentAgreement").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionHUFRentAgreement = "reupload";
                HUFRentAgreementStatus = "reupload";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                $("#choose_HUFRentAgreement").show();
                $("#viewDocLink_HUFRentAgreement").hide();
                $("#X-btn_HUFRentAgreement").hide();
                $("#done-btn_HUFRentAgreement").hide();
                $("#review-btn_HUFRentAgreement").hide();
              } else if (doc[4].status == "done") {
                HUFRentAgreementStatus = "done";
                mainactionHUFRentAgreement = "upload";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                $("#choose_HUFRentAgreement").hide();
                $("#X-btn_HUFRentAgreement").hide();
                $("#done-btn_HUFRentAgreement").show();
                $("#review-btn_HUFRentAgreement").hide();
              } else {
                HUFRentAgreementStatus = "open";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
              }
              if (doc[6].status == "remove") {
                mainactionHUFTaxReceipt = "upload";
                HUFTaxReceiptStatus = "remove";
                HUFStatusArray[4].status = HUFTaxReceiptStatus;
                this.setState(
                  {
                    HUFTaxReceiptDocLink: doc[6].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_HUFTaxReceipt").css("display", "block");
                    $("#choose_HUFTaxReceipt").hide();
                    $("#X-btn_HUFTaxReceipt").show();
                    $("#done-btn_HUFTaxReceipt").hide();
                    $("#review-btn_HUFTaxReceipt").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionHUFTaxReceipt = "upload";
                HUFTaxReceiptStatus = "review";
                HUFStatusArray[4].status = HUFTaxReceiptStatus;
                this.setState(
                  {
                    HUFTaxReceiptDocLink: doc[6].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_HUFTaxReceipt").css("display", "block");
                    $("#choose_HUFTaxReceipt").hide();
                    $("#X-btn_HUFTaxReceipt").hide();
                    $("#done-btn_HUFTaxReceipt").hide();
                    $("#review-btn_HUFTaxReceipt").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionHUFTaxReceipt = "reupload";
                HUFTaxReceiptStatus = "reupload";
                HUFStatusArray[4].status = HUFTaxReceiptStatus;
                $("#choose_HUFTaxReceipt").show();
                $("#viewDocLink_HUFTaxReceipt").hide();
                $("#X-btn_HUFTaxReceipt").hide();
                $("#done-btn_HUFTaxReceipt").hide();
                $("#review-btn_HUFTaxReceipt").hide();
              } else if (doc[6].status == "done") {
                HUFTaxReceiptStatus = "done";
                mainactionHUFTaxReceipt = "upload";
                HUFStatusArray[4].status = HUFTaxReceiptStatus;
                $("#choose_HUFTaxReceipt").hide();
                $("#X-btn_HUFTaxReceipt").hide();
                $("#done-btn_HUFTaxReceipt").show();
                $("#review-btn_HUFTaxReceipt").hide();
              } else {
                HUFTaxReceiptStatus = "open";
                HUFStatusArray[4].status = HUFTaxReceiptStatus;
              }
              if (doc[7].status == "remove") {
                mainactionHUFPremisePic = "upload";
                HUFPremisePicStatus = "remove";
                HUFStatusArray[5].status = HUFPremisePicStatus;
                this.setState(
                  {
                    HUFPremisePicDocLink: doc[7].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_HUFPremisePic").css("display", "block");
                    $("#choose_HUFPremisePic").hide();
                    $("#X-btn_HUFPremisePic").show();
                    $("#done-btn_HUFPremisePic").hide();
                    $("#review-btn_HUFPremisePic").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionHUFPremisePic = "upload";
                HUFPremisePicStatus = "review";
                HUFStatusArray[5].status = HUFPremisePicStatus;
                this.setState(
                  {
                    HUFPremisePicDocLink: doc[7].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_HUFPremisePic").css("display", "block");
                    $("#choose_HUFPremisePic").hide();
                    $("#X-btn_HUFPremisePic").hide();
                    $("#done-btn_HUFPremisePic").hide();
                    $("#review-btn_HUFPremisePic").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionHUFPremisePic = "reupload";
                HUFPremisePicStatus = "reupload";
                HUFStatusArray[5].status = HUFPremisePicStatus;
                $("#choose_HUFPremisePic").show();
                $("#viewDocLink_HUFPremisePic").hide();
                $("#X-btn_HUFPremisePic").hide();
                $("#done-btn_HUFPremisePic").hide();
                $("#review-btn_HUFPremisePic").hide();
              } else if (doc[7].status == "done") {
                HUFPremisePicStatus = "done";
                mainactionHUFPremisePic = "upload";
                HUFStatusArray[5].status = HUFPremisePicStatus;
                $("#choose_HUFPremisePic").hide();
                $("#X-btn_HUFPremisePic").hide();
                $("#done-btn_HUFPremisePic").show();
                $("#review-btn_HUFPremisePic").hide();
              } else {
                HUFPremisePicStatus = "open";
                HUFStatusArray[5].status = HUFPremisePicStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  HUFbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
              if (res.data.inputFields[1].AddressProofType == "OwnershipDeed") {
                this.setState(
                  {
                    HUFAddressProofType_radio: "OwnershipDeed",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    HUFAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            this.HUFcheckRemainingReuploads();
            this.checkhandleHUFProceedBtn();
          } else if (res.data.serviceType == "TL_STC_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "TL_STC_Documents",
                serviceType: "TL_STC_Documents",
              },
              () => {
                if (this.state.dropDownValue == "TL_STC_Documents") {
                  $("#trade_default_Img").hide();
                  $("#trade_SolePI").hide();
                  $("#trade_P_LLP").hide();
                  $("#trade_PL").hide();
                  $("#trade_HUF").hide();
                  $("#trade_Society").show();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#STadditionalDocumentDiv").hide();
              } else {
                $("#STAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  STadditionalFiles: [...res.data.addDocInformation],
                  STAdd_DocName: "File Name",
                  api_STadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                STAdd_DocName: "",
                STadditionalFiles: [1],
                api_STadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionSTPic1 = "upload";
                STPic1Status = "remove";
                STStatusArray[0].status = STPic1Status;
                this.setState(
                  {
                    STPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_STPic1").css("display", "block");
                    $("#choose_STPic1").hide();
                    $("#X-btn_STPic1").show();
                    $("#done-btn_STPic1").hide();
                    $("#review-btn_STPic1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionSTPic1 = "upload";
                STPic1Status = "review";
                STStatusArray[0].status = STPic1Status;
                this.setState(
                  {
                    STPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_STPic1").css("display", "block");
                    $("#choose_STPic1").hide();
                    $("#X-btn_STPic1").hide();
                    $("#done-btn_STPic1").hide();
                    $("#review-btn_STPic1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionSTPic1 = "reupload";
                STPic1Status = "reupload";
                STStatusArray[0].status = STPic1Status;
                $("#choose_STPic1").show();
                $("#viewDocLink_STPic1").hide();
                $("#X-btn_STPic1").hide();
                $("#done-btn_STPic1").hide();
                $("#review-btn_STPic1").hide();
              } else if (doc[0].status == "done") {
                STPic1Status = "done";
                mainactionSTPic1 = "upload";
                STStatusArray[0].status = STPic1Status;
                $("#choose_STPic1").hide();
                $("#X-btn_STPic1").hide();
                $("#done-btn_STPic1").show();
                $("#review-btn_STPic1").hide();
              } else {
                STPic1Status = "open";
                STStatusArray[0].status = STPic1Status;
              }
              if (doc[1].status == "remove") {
                mainactionSTPic2 = "upload";
                STPic2Status = "remove";
                STStatusArray[1].status = STPic2Status;
                this.setState(
                  {
                    STPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_STPic2").css("display", "block");
                    $("#choose_STPic2").hide();
                    $("#X-btn_STPic2").show();
                    $("#done-btn_STPic2").hide();
                    $("#review-btn_STPic2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionSTPic2 = "upload";
                STPic2Status = "review";
                STStatusArray[1].status = STPic2Status;
                this.setState(
                  {
                    STPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_STPic2").css("display", "block");
                    $("#choose_STPic2").hide();
                    $("#X-btn_STPic2").hide();
                    $("#done-btn_STPic2").hide();
                    $("#review-btn_STPic2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionSTPic2 = "reupload";
                STPic2Status = "reupload";
                STStatusArray[1].status = STPic2Status;
                $("#choose_STPic2").show();
                $("#viewDocLink_STPic2").hide();
                $("#X-btn_STPic2").hide();
                $("#done-btn_STPic2").hide();
                $("#review-btn_STPic2").hide();
              } else if (doc[1].status == "done") {
                STPic2Status = "done";
                mainactionSTPic2 = "upload";
                STStatusArray[1].status = STPic2Status;
                $("#choose_STPic2").hide();
                $("#X-btn_STPic2").hide();
                $("#done-btn_STPic2").show();
                $("#review-btn_STPic2").hide();
              } else {
                STPic2Status = "open";
                STStatusArray[1].status = STPic2Status;
              }
              if (doc[2].status == "remove") {
                mainactionSTAadhar1 = "upload";
                STAadhar1Status = "remove";
                STStatusArray[2].status = STAadhar1Status;
                this.setState(
                  {
                    STAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_STAadhar1").css("display", "block");
                    $("#choose_STAadhar1").hide();
                    $("#X-btn_STAadhar1").show();
                    $("#done-btn_STAadhar1").hide();
                    $("#review-btn_STAadhar1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionSTAadhar1 = "upload";
                STAadhar1Status = "review";
                STStatusArray[2].status = STAadhar1Status;
                this.setState(
                  {
                    STAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_STAadhar1").css("display", "block");
                    $("#choose_STAadhar1").hide();
                    $("#X-btn_STAadhar1").hide();
                    $("#done-btn_STAadhar1").hide();
                    $("#review-btn_STAadhar1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionSTAadhar1 = "reupload";
                STAadhar1Status = "reupload";
                STStatusArray[2].status = STAadhar1Status;
                $("#choose_STAadhar1").show();
                $("#viewDocLink_STAadhar1").hide();
                $("#X-btn_STAadhar1").hide();
                $("#done-btn_STAadhar1").hide();
                $("#review-btn_STAadhar1").hide();
              } else if (doc[2].status == "done") {
                STAadhar1Status = "done";
                mainactionSTAadhar1 = "upload";
                STStatusArray[2].status = STAadhar1Status;
                $("#choose_STAadhar1").hide();
                $("#X-btn_STAadhar1").hide();
                $("#done-btn_STAadhar1").show();
                $("#review-btn_STAadhar1").hide();
              } else {
                STAadhar1Status = "open";
                STStatusArray[2].status = STAadhar1Status;
              }
              if (doc[3].status == "remove") {
                mainactionSTAadhar2 = "upload";
                STAadhar2Status = "remove";
                STStatusArray[3].status = STAadhar2Status;
                this.setState(
                  {
                    STAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_STAadhar2").css("display", "block");
                    $("#choose_STAadhar2").hide();
                    $("#X-btn_STAadhar2").show();
                    $("#done-btn_STAadhar2").hide();
                    $("#review-btn_STAadhar2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionSTAadhar2 = "upload";
                STAadhar2Status = "review";
                STStatusArray[3].status = STAadhar2Status;
                this.setState(
                  {
                    STAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_STAadhar2").css("display", "block");
                    $("#choose_STAadhar2").hide();
                    $("#X-btn_STAadhar2").hide();
                    $("#done-btn_STAadhar2").hide();
                    $("#review-btn_STAadhar2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionSTAadhar2 = "reupload";
                STAadhar2Status = "reupload";
                STStatusArray[3].status = STAadhar2Status;
                $("#choose_STAadhar2").show();
                $("#viewDocLink_STAadhar2").hide();
                $("#X-btn_STAadhar2").hide();
                $("#done-btn_STAadhar2").hide();
                $("#review-btn_STAadhar2").hide();
              } else if (doc[3].status == "done") {
                STAadhar2Status = "done";
                mainactionSTAadhar2 = "upload";
                STStatusArray[3].status = STAadhar2Status;
                $("#choose_STAadhar2").hide();
                $("#X-btn_STAadhar2").hide();
                $("#done-btn_STAadhar2").show();
                $("#review-btn_STAadhar2").hide();
              } else {
                STAadhar2Status = "open";
                STStatusArray[3].status = STAadhar2Status;
              }
              if (doc[4].status == "remove") {
                mainactionSTCR = "upload";
                STCRStatus = "remove";
                STStatusArray[5].status = STCRStatus;
                this.setState(
                  {
                    STCRDocLink: doc[4].CertificateOfRegistration,
                  },
                  () => {
                    $("#viewDocLink_STCR").css("display", "block");
                    $("#choose_STCR").hide();
                    $("#X-btn_STCR").show();
                    $("#done-btn_STCR").hide();
                    $("#review-btn_STCR").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionSTCR = "upload";
                STCRStatus = "review";
                STStatusArray[5].status = STCRStatus;
                this.setState(
                  {
                    STCRDocLink: doc[4].CertificateOfRegistration,
                  },
                  () => {
                    $("#viewDocLink_STCR").css("display", "block");
                    $("#choose_STCR").hide();
                    $("#X-btn_STCR").hide();
                    $("#done-btn_STCR").hide();
                    $("#review-btn_STCR").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionSTCR = "reupload";
                STCRStatus = "reupload";
                STStatusArray[5].status = STCRStatus;
                $("#choose_STCR").show();
                $("#viewDocLink_STCR").hide();
                $("#X-btn_STCR").hide();
                $("#done-btn_STCR").hide();
                $("#review-btn_STCR").hide();
              } else if (doc[4].status == "done") {
                STCRStatus = "done";
                mainactionSTCR = "upload";
                STStatusArray[5].status = STCRStatus;
                $("#choose_STCR").hide();
                $("#X-btn_STCR").hide();
                $("#done-btn_STCR").show();
                $("#review-btn_STCR").hide();
              } else {
                STCRStatus = "open";
                STStatusArray[5].status = STCRStatus;
              }
              if (doc[5].status == "remove") {
                mainactionSTSelfDecl = "upload";
                STSelfDeclStatus = "remove";
                STStatusArray[4].status = STSelfDeclStatus;
                this.setState(
                  {
                    STSelfDeclDocLink: doc[5].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_STSelfDecl").css("display", "block");
                    $("#choose_STSelfDecl").hide();
                    $("#X-btn_STSelfDecl").show();
                    $("#done-btn_STSelfDecl").hide();
                    $("#review-btn_STSelfDecl").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionSTSelfDecl = "upload";
                STSelfDeclStatus = "review";
                STStatusArray[4].status = STSelfDeclStatus;
                this.setState(
                  {
                    STSelfDeclDocLink: doc[5].SelfDeclaration,
                  },
                  () => {
                    $("#viewDocLink_STSelfDecl").css("display", "block");
                    $("#choose_STSelfDecl").hide();
                    $("#X-btn_STSelfDecl").hide();
                    $("#done-btn_STSelfDecl").hide();
                    $("#review-btn_STSelfDecl").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionSTSelfDecl = "reupload";
                STSelfDeclStatus = "reupload";
                STStatusArray[4].status = STSelfDeclStatus;
                $("#choose_STSelfDecl").show();
                $("#viewDocLink_STSelfDecl").hide();
                $("#X-btn_STSelfDecl").hide();
                $("#done-btn_STSelfDecl").hide();
                $("#review-btn_STSelfDecl").hide();
              } else if (doc[5].status == "done") {
                STSelfDeclStatus = "done";
                mainactionSTSelfDecl = "upload";
                STStatusArray[4].status = STSelfDeclStatus;
                $("#choose_STSelfDecl").hide();
                $("#X-btn_STSelfDecl").hide();
                $("#done-btn_STSelfDecl").show();
                $("#review-btn_STSelfDecl").hide();
              } else {
                STSelfDeclStatus = "open";
                STStatusArray[4].status = STSelfDeclStatus;
              }
              if (doc[7].status == "remove") {
                mainactionSTRentAgreement = "upload";
                STRentAgreementStatus = "remove";
                STOptStatusArray[1].status = STRentAgreementStatus;
                this.setState(
                  {
                    STRentAgreementDocLink: doc[7].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_STRentAgreement").css("display", "block");
                    $("#choose_STRentAgreement").hide();
                    $("#X-btn_STRentAgreement").show();
                    $("#done-btn_STRentAgreement").hide();
                    $("#review-btn_STRentAgreement").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionSTRentAgreement = "upload";
                STRentAgreementStatus = "review";
                STOptStatusArray[1].status = STRentAgreementStatus;
                this.setState(
                  {
                    STRentAgreementDocLink: doc[7].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_STRentAgreement").css("display", "block");
                    $("#choose_STRentAgreement").hide();
                    $("#X-btn_STRentAgreement").hide();
                    $("#done-btn_STRentAgreement").hide();
                    $("#review-btn_STRentAgreement").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionSTRentAgreement = "reupload";
                STRentAgreementStatus = "reupload";
                STOptStatusArray[1].status = STRentAgreementStatus;
                $("#choose_STRentAgreement").show();
                $("#viewDocLink_STRentAgreement").hide();
                $("#X-btn_STRentAgreement").hide();
                $("#done-btn_STRentAgreement").hide();
                $("#review-btn_STRentAgreement").hide();
              } else if (doc[7].status == "done") {
                STRentAgreementStatus = "done";
                mainactionSTRentAgreement = "upload";
                STOptStatusArray[1].status = STRentAgreementStatus;
                $("#choose_STRentAgreement").hide();
                $("#X-btn_STRentAgreement").hide();
                $("#done-btn_STRentAgreement").show();
                $("#review-btn_STRentAgreement").hide();
              } else {
                STRentAgreementStatus = "open";
                STOptStatusArray[1].status = STRentAgreementStatus;
              }
              if (doc[6].status == "remove") {
                mainactionSTCopyOfRegistry = "upload";
                STCopyOfRegistryStatus = "remove";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                this.setState(
                  {
                    STCopyOfRegistryDocLink: doc[6].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_STCopyOfRegistry").css("display", "block");
                    $("#choose_STCopyOfRegistry").hide();
                    $("#X-btn_STCopyOfRegistry").show();
                    $("#done-btn_STCopyOfRegistry").hide();
                    $("#review-btn_STCopyOfRegistry").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionSTCopyOfRegistry = "upload";
                STCopyOfRegistryStatus = "review";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                this.setState(
                  {
                    STCopyOfRegistryDocLink: doc[6].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_STCopyOfRegistry").css("display", "block");
                    $("#choose_STCopyOfRegistry").hide();
                    $("#X-btn_STCopyOfRegistry").hide();
                    $("#done-btn_STCopyOfRegistry").hide();
                    $("#review-btn_STCopyOfRegistry").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionSTCopyOfRegistry = "reupload";
                STCopyOfRegistryStatus = "reupload";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                $("#choose_STCopyOfRegistry").show();
                $("#viewDocLink_STCopyOfRegistry").hide();
                $("#X-btn_STCopyOfRegistry").hide();
                $("#done-btn_STCopyOfRegistry").hide();
                $("#review-btn_STCopyOfRegistry").hide();
              } else if (doc[6].status == "done") {
                STCopyOfRegistryStatus = "done";
                mainactionSTCopyOfRegistry = "upload";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                $("#choose_STCopyOfRegistry").hide();
                $("#X-btn_STCopyOfRegistry").hide();
                $("#done-btn_STCopyOfRegistry").show();
                $("#review-btn_STCopyOfRegistry").hide();
              } else {
                STCopyOfRegistryStatus = "open";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
              }
              if (doc[8].status == "remove") {
                mainactionSTTaxReceipt = "upload";
                STTaxReceiptStatus = "remove";
                STStatusArray[6].status = STTaxReceiptStatus;
                this.setState(
                  {
                    STTaxReceiptDocLink: doc[8].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_STTaxReceipt").css("display", "block");
                    $("#choose_STTaxReceipt").hide();
                    $("#X-btn_STTaxReceipt").show();
                    $("#done-btn_STSemainactionSTTaxReceipt").hide();
                    $("#review-btn_STTaxReceipt").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionSTTaxReceipt = "upload";
                STTaxReceiptStatus = "review";
                STStatusArray[6].status = STTaxReceiptStatus;
                this.setState(
                  {
                    STTaxReceiptDocLink: doc[8].TaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_STTaxReceipt").css("display", "block");
                    $("#choose_STTaxReceipt").hide();
                    $("#X-btn_STTaxReceipt").hide();
                    $("#done-btn_STSemainactionSTTaxReceipt").hide();
                    $("#review-btn_STTaxReceipt").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionSTTaxReceipt = "reupload";
                STTaxReceiptStatus = "reupload";
                STStatusArray[6].status = STTaxReceiptStatus;
                $("#choose_STTaxReceipt").show();
                $("#viewDocLink_STTaxReceipt").hide();
                $("#X-btn_STTaxReceipt").hide();
                $("#done-btn_STSemainactionSTTaxReceipt").hide();
                $("#review-btn_STTaxReceipt").hide();
              } else if (doc[8].status == "done") {
                STTaxReceiptStatus = "done";
                mainactionSTTaxReceipt = "upload";
                STStatusArray[6].status = STTaxReceiptStatus;
                $("#choose_STTaxReceipt").hide();
                $("#X-btn_STTaxReceipt").hide();
                $("#done-btn_STSemainactionSTTaxReceipt").show();
                $("#review-btn_STTaxReceipt").hide();
              } else {
                STTaxReceiptStatus = "open";
                STStatusArray[6].status = STTaxReceiptStatus;
              }

              if (doc[9].status == "remove") {
                mainactionSTPan3 = "upload";
                STPremisePicStatus = "remove";
                STStatusArray[7].status = STPremisePicStatus;
                this.setState(
                  {
                    STPremisePicDocLink: doc[9].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_STPremisePic").css("display", "block");
                    $("#choose_STPremisePic").hide();
                    $("#X-btn_STPremisePic").show();
                    $("#done-btn_STPan3").hide();
                    $("#review-btn_STPremisePic").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionSTPan3 = "upload";
                STPremisePicStatus = "review";
                STStatusArray[7].status = STPremisePicStatus;
                this.setState(
                  {
                    STPremisePicDocLink: doc[9].PremisePhoto,
                  },
                  () => {
                    $("#viewDocLink_STPremisePic").css("display", "block");
                    $("#choose_STPremisePic").hide();
                    $("#X-btn_STPremisePic").hide();
                    $("#done-btn_STPan3").hide();
                    $("#review-btn_STPremisePic").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionSTPan3 = "reupload";
                STPremisePicStatus = "reupload";
                STStatusArray[7].status = STPremisePicStatus;
                $("#choose_STPremisePic").show();
                $("#viewDocLink_STPremisePic").hide();
                $("#X-btn_STPremisePic").hide();
                $("#done-btn_STPan3").hide();
                $("#review-btn_STPremisePic").hide();
              } else if (doc[9].status == "done") {
                STPremisePicStatus = "done";
                mainactionSTPan3 = "upload";
                STStatusArray[7].status = STPremisePicStatus;
                $("#choose_STPremisePic").hide();
                $("#X-btn_STPremisePic").hide();
                $("#done-btn_STPan3").show();
                $("#review-btn_STPremisePic").hide();
              } else {
                STPremisePicStatus = "open";
                STStatusArray[7].status = STPremisePicStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  STbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }

              if (res.data.inputFields[1].AddressProofType == "OwnershipDeed") {
                this.setState(
                  {
                    STAddressProofType_radio: "OwnershipDeed",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    STAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            this.STcheckRemainingReuploads();
            this.checkhandleSTProceedBtn();
          } else {
            this.showNotification("Something went wrong");
          }

          if (
            res.data.informationStatus == "lock" &&
            res.data.mainstatus == "false"
          ) {
            HideProceedBtn = "Yes";
            $(".proceednowbtn").hide();
            $("#test11-11").attr("disabled", true);
            $("#test12-22").attr("disabled", true);
            $("#test22-11").attr("disabled", true);
            $("#test22-22").attr("disabled", true);
            $("#test3-1").attr("disabled", true);
            $("#test3-2").attr("disabled", true);
            $("#test44-14").attr("disabled", true);
            $("#test44-24").attr("disabled", true);
            $("#test55-15").attr("disabled", true);
            $("#test55-25").attr("disabled", true);
          } else if (
            res.data.informationStatus == "lock" &&
            res.data.mainstatus == "true"
          ) {
            $("#test11-11").attr("disabled", true);
            $("#test12-22").attr("disabled", true);
            $("#test22-11").attr("disabled", true);
            $("#test22-22").attr("disabled", true);
            $("#test3-1").attr("disabled", true);
            $("#test3-2").attr("disabled", true);
            $("#test44-14").attr("disabled", true);
            $("#test44-24").attr("disabled", true);
            $("#test55-15").attr("disabled", true);
            $("#test55-25").attr("disabled", true);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "docuploadnotallowed") {
          this.setState({
            referralPartner:false,
          })
          this.showNotification(res.data.message);
        }  else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  checkDropdownValue = () => {};

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("relleIsugad");
    this.props.history.push({
      pathname: `/`,
    });
  };
  handledropDownValueChange = (e) => {
    $(".proceednowbtn").attr("disabled", true);
    $("#volvo").attr("disabled", true);
    this.setState(
      {
        dropDownValue: e.target.value,
      },
      () => {
        if (this.state.dropDownValue == "TL_SolePI_Documents") {
          this.setState(
            {
              dropDownValue: "TL_SolePI_Documents",
              serviceType: "TL_SolePI_Documents",
            },
            () => {
              if (this.state.dropDownValue == "TL_SolePI_Documents") {
                $("#trade_default_Img").hide();
                $("#trade_SolePI").show();
                $("#trade_P_LLP").hide();
                $("#trade_PL").hide();
                $("#trade_HUF").hide();
                $("#trade_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "TL_P_LLP_Documents") {
          this.setState(
            {
              dropDownValue: "TL_P_LLP_Documents",
              serviceType: "TL_P_LLP_Documents",
            },
            () => {
              if (this.state.dropDownValue == "TL_P_LLP_Documents") {
                $("#trade_default_Img").hide();
                $("#trade_SolePI").hide();
                $("#trade_P_LLP").show();
                $("#trade_PL").hide();
                $("#trade_HUF").hide();
                $("#trade_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "TL_PRL_PL_OP_Documents") {
          this.setState(
            {
              dropDownValue: "TL_PRL_PL_OP_Documents",
              serviceType: "TL_PRL_PL_OP_Documents",
            },
            () => {
              if (this.state.dropDownValue == "TL_PRL_PL_OP_Documents") {
                $("#trade_default_Img").hide();
                $("#trade_SolePI").hide();
                $("#trade_P_LLP").hide();
                $("#trade_PL").show();
                $("#trade_HUF").hide();
                $("#trade_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "TL_HUF_Documents") {
          this.setState(
            {
              dropDownValue: "TL_HUF_Documents",
              serviceType: "TL_HUF_Documents",
            },
            () => {
              if (this.state.dropDownValue == "TL_HUF_Documents") {
                $("#trade_default_Img").hide();
                $("#trade_SolePI").hide();
                $("#trade_P_LLP").hide();
                $("#trade_PL").hide();
                $("#trade_HUF").show();
                $("#trade_Society").hide();
                this.checkhandleHUFProceedBtn();
              }
            }
          );
        } else if (this.state.dropDownValue == "TL_STC_Documents") {
          this.setState(
            {
              dropDownValue: "TL_STC_Documents",
              serviceType: "TL_STC_Documents",
            },
            () => {
              if (this.state.dropDownValue == "TL_STC_Documents") {
                $("#trade_default_Img").hide();
                $("#trade_SolePI").hide();
                $("#trade_P_LLP").hide();
                $("#trade_PL").hide();
                $("#trade_HUF").hide();
                $("#trade_Society").show();
              }
            }
          );
        }
      }
    );
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  statusCheckFunction = (status, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        return i;
      }
    }
  };

  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }

  // Sole P/I

  // SAadhar
  handleSAadharChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant",
          },
          () => {
            $("#SAadharModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant",
            });
          }
        });
      $("#SAadharModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSAadhar = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SAadhar").attr("disabled", true);
      $("#upload_btn20_SAadhar").hide();
      $("#upload_btnloader_SAadhar").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )

        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SAadharDocLink: res.data.data.AadhaarCard,
                },
                () => $("#viewDocLink_SAadhar").css("display", "block")
              );
              SAadharStatus = "remove";
              SStatusArray[0].status = SAadharStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SAadharModal").modal("hide");
              $("#cancelbtn_SAadhar").attr("disabled", false);
              $("#upload_btn20_SAadhar").show();
              $("#upload_btnloader_SAadhar").hide();
              $("#choose_SAadhar").hide();
              $("#X-btn_SAadhar").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SAadhar").attr("disabled", false);
            $("#upload_btn20_SAadhar").show();
            $("#upload_btnloader_SAadhar").hide();
            $("#SAadharModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SAadhar").attr("disabled", false);
            $("#upload_btn20_SAadhar").show();
            $("#upload_btnloader_SAadhar").hide();
            $("#SAadharModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SAadhar").attr("disabled", false);
          $("#upload_btn20_SAadhar").show();
          $("#upload_btnloader_SAadhar").hide();
          $("#SAadharModal").modal("hide");

          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSAadhar = () => {
    $("#X-btn_SAadhar").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SAadharStatus = res.data.data.status;
          SStatusArray[0].status = SAadharStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SAadhar").show();
          $("#X-btn_SAadhar").hide();
          $("#X-btn_SAadhar").attr("disabled", false);
          $("#viewDocLink_SAadhar").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SAadhar").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SAadhar").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SAadharseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SAadharseecommentsbtn").hide();
          $("#SAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSAadharhidecommentsbtn = () => {
    $("#SAadharseecommentsbtn").show();
    $("#SAadharseecommentsbtn").attr("disabled", false);
    $("#SAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPremisepic
  handleSPremisepicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PremisePhoto",
          },
          () => {
            $("#SPremisepicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PremisePhoto",
          });
        }
      });
      $("#SPremisepicModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPremisepic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPremisepic").attr("disabled", true);
      $("#upload_btn20_SPremisepic").hide();
      $("#upload_btnloader_SPremisepic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPremisepicDocLink: res.data.data.PANCard,
                },
                () => $("#viewDocLink_SPremisepic").css("display", "block")
              );
              SPremisepicStatus = "remove";
              SStatusArray[2].status = SPremisepicStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPremisepicModal").modal("hide");
              $("#cancelbtn_SPremisepic").attr("disabled", false);
              $("#upload_btn20_SPremisepic").show();
              $("#upload_btnloader_SPremisepic").hide();
              $("#choose_SPremisepic").hide();
              $("#X-btn_SPremisepic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPremisepic").attr("disabled", false);
            $("#upload_btn20_SPremisepic").show();
            $("#upload_btnloader_SPremisepic").hide();
            $("#SPremisepicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPremisepic").attr("disabled", false);
            $("#upload_btn20_SPremisepic").show();
            $("#upload_btnloader_SPremisepic").hide();
            $("#SPremisepicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPremisepic").attr("disabled", false);
          $("#upload_btn20_SPremisepic").show();
          $("#upload_btnloader_SPremisepic").hide();
          $("#SPremisepicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPremisepic = () => {
    $("#X-btn_SPremisepic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PremisePhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SPremisepicStatus = res.data.data.status;
          SStatusArray[2].status = SPremisepicStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SPremisepic").show();
          $("#X-btn_SPremisepic").hide();
          $("#X-btn_SPremisepic").attr("disabled", false);
          $("#viewDocLink_SPremisepic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SPremisepic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPremisepic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSPremisepicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPremisepicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PremisePhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPremisepicseecommentsbtn").hide();
          $("#SPremisepichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPremisepichidecommentsbtn = () => {
    $("#SPremisepicseecommentsbtn").show();
    $("#SPremisepicseecommentsbtn").attr("disabled", false);
    $("#SPremisepichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SSelfDecl
  handleSSelfDeclChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "SelfDeclaration",
          },
          () => {
            $("#SSelfDeclModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "SelfDeclaration",
            });
          }
        });
      $("#SSelfDeclModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSSelfDecl = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SSelfDecl").attr("disabled", true);
      $("#upload_btn20_SSelfDecl").hide();
      $("#upload_btnloader_SSelfDecl").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )

        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SSelfDeclDocLink: res.data.data.PANCard,
                },
                () => $("#viewDocLink_SSelfDecl").css("display", "block")
              );
              SSelfDeclStatus = "remove";
              SStatusArray[2].status = SSelfDeclStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SSelfDeclModal").modal("hide");
              $("#cancelbtn_SSelfDecl").attr("disabled", false);
              $("#upload_btn20_SSelfDecl").show();
              $("#upload_btnloader_SSelfDecl").hide();
              $("#choose_SSelfDecl").hide();
              $("#X-btn_SSelfDecl").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SSelfDecl").attr("disabled", false);
            $("#upload_btn20_SSelfDecl").show();
            $("#upload_btnloader_SSelfDecl").hide();
            $("#SSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SSelfDecl").attr("disabled", false);
            $("#upload_btn20_SSelfDecl").show();
            $("#upload_btnloader_SSelfDecl").hide();
            $("#SSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SSelfDecl").attr("disabled", false);
          $("#upload_btn20_SSelfDecl").show();
          $("#upload_btnloader_SSelfDecl").hide();
          $("#SSelfDeclModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSSelfDecl = () => {
    $("#X-btn_SSelfDecl").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "SelfDeclaration",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SSelfDeclStatus = res.data.data.status;
          SStatusArray[2].status = SSelfDeclStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SSelfDecl").show();
          $("#X-btn_SSelfDecl").hide();
          $("#X-btn_SSelfDecl").attr("disabled", false);
          $("#viewDocLink_SSelfDecl").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SSelfDecl").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SSelfDecl").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSSelfDeclseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SSelfDeclseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SelfDeclaration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SSelfDeclseecommentsbtn").hide();
          $("#SSelfDeclhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSSelfDeclhidecommentsbtn = () => {
    $("#SSelfDeclseecommentsbtn").show();
    $("#SSelfDeclseecommentsbtn").attr("disabled", false);
    $("#SSelfDeclhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STaxReceipt
  handleSTaxReceiptChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TaxReceipt",
          },
          () => {
            $("#STaxReceiptModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "TaxReceipt",
            });
          }
        });
      $("#STaxReceiptModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTaxReceipt = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STaxReceipt").attr("disabled", true);
      $("#upload_btn20_STaxReceipt").hide();
      $("#upload_btnloader_STaxReceipt").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )

        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STaxReceiptDocLink: res.data.data.PANCard,
                },
                () => $("#viewDocLink_STaxReceipt").css("display", "block")
              );
              STaxReceiptStatus = "remove";
              SStatusArray[3].status = STaxReceiptStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#STaxReceiptModal").modal("hide");
              $("#cancelbtn_STaxReceipt").attr("disabled", false);
              $("#upload_btn20_STaxReceipt").show();
              $("#upload_btnloader_STaxReceipt").hide();
              $("#choose_STaxReceipt").hide();
              $("#X-btn_STaxReceipt").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STaxReceipt").attr("disabled", false);
            $("#upload_btn20_STaxReceipt").show();
            $("#upload_btnloader_STaxReceipt").hide();
            $("#STaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STaxReceipt").attr("disabled", false);
            $("#upload_btn20_STaxReceipt").show();
            $("#upload_btnloader_STaxReceipt").hide();
            $("#STaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STaxReceipt").attr("disabled", false);
          $("#upload_btn20_STaxReceipt").show();
          $("#upload_btnloader_STaxReceipt").hide();
          $("#STaxReceiptModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTaxReceipt = () => {
    $("#X-btn_STaxReceipt").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STaxReceiptStatus = res.data.data.status;
          SStatusArray[3].status = STaxReceiptStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_STaxReceipt").show();
          $("#X-btn_STaxReceipt").hide();
          $("#X-btn_STaxReceipt").attr("disabled", false);
          $("#viewDocLink_STaxReceipt").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STaxReceipt").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STaxReceipt").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTaxReceiptseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STaxReceiptseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STaxReceiptseecommentsbtn").hide();
          $("#STaxReceipthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTaxReceipthidecommentsbtn = () => {
    $("#STaxReceiptseecommentsbtn").show();
    $("#STaxReceiptseecommentsbtn").attr("disabled", false);
    $("#STaxReceipthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPic
  handleSPicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto",
          },
          () => {
            $("#SPicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto",
            });
          }
        });
      $("#SPicModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPic").attr("disabled", true);
      $("#upload_btn20_SPic").hide();
      $("#upload_btnloader_SPic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPicDocLink: res.data.data.SolePhoto,
                },
                () => $("#viewDocLink_SPic").css("display", "block")
              );
              SPicStatus = "remove";
              SStatusArray[1].status = SPicStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPicModal").modal("hide");
              $("#cancelbtn_SPic").attr("disabled", false);
              $("#upload_btn20_SPic").show();
              $("#upload_btnloader_SPic").hide();
              $("#choose_SPic").hide();
              $("#X-btn_SPic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPic").attr("disabled", false);
            $("#upload_btn20_SPic").show();
            $("#upload_btnloader_SPic").hide();
            $("#SPicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPic").attr("disabled", false);
            $("#upload_btn20_SPic").show();
            $("#upload_btnloader_SPic").hide();
            $("#SPicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPic").attr("disabled", false);
          $("#upload_btn20_SPic").show();
          $("#upload_btnloader_SPic").hide();
          $("#SPicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPic = () => {
    $("#X-btn_SPic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SPicStatus = res.data.data.status;
          SStatusArray[1].status = SPicStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SPic").show();
          $("#X-btn_SPic").hide();
          $("#X-btn_SPic").attr("disabled", false);
          $("#viewDocLink_SPic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SPic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSPicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPicseecommentsbtn").hide();
          $("#SPichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPichidecommentsbtn = () => {
    $("#SPicseecommentsbtn").show();
    $("#SPicseecommentsbtn").attr("disabled", false);
    $("#SPichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SBP

  // SGumasta

  // STradeLicence
  //SNature
  handleSbusinessNatureChange = (e) => {
    this.setState({
      SbusinessNature: e.target.value,
    });
  };

  // SAddressProofType
  SAddressProofType_radioChange = (e) => {
    this.setState(
      {
        SAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.SAddressProofType_radio != "OwnershipDeed") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.SAddressProofType_radio == "OwnershipDeed") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleSProceedBtn();
      }
    );
  };

  // SCopyOfRegistry
  handleSCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#SCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#SCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_SCopyOfRegistry").hide();
      $("#upload_btnloader_SCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SCopyOfRegistryDocLink: res.data.data.OwnershipDeed,
                },
                () => $("#viewDocLink_SCopyOfRegistry").css("display", "block")
              );
              SCopyOfRegistryStatus = "remove";
              SOptStatusArray[0].status = SCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_SCopyOfRegistry").show();
              $("#upload_btnloader_SCopyOfRegistry").hide();
              $("#choose_SCopyOfRegistry").hide();
              $("#X-btn_SCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_SCopyOfRegistry").show();
            $("#upload_btnloader_SCopyOfRegistry").hide();
            $("#SCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_SCopyOfRegistry").show();
            $("#upload_btnloader_SCopyOfRegistry").hide();
            $("#SCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_SCopyOfRegistry").show();
          $("#upload_btnloader_SCopyOfRegistry").hide();
          $("#SCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSCopyOfRegistry = () => {
    $("#X-btn_SCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SCopyOfRegistryStatus = res.data.data.status;
          SOptStatusArray[0].status = SCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SCopyOfRegistry").show();
          $("#X-btn_SCopyOfRegistry").hide();
          $("#X-btn_SCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_SCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "OwnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SCopyOfRegistryseecommentsbtn").hide();
          $("#SCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  completerender() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }

  handleSCopyOfRegistryhidecommentsbtn = () => {
    $("#SCopyOfRegistryseecommentsbtn").show();
    $("#SCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#SCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SRentAgreement
  handleSRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#SRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#SRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SRentAgreement").attr("disabled", true);
      $("#upload_btn20_SRentAgreement").hide();
      $("#upload_btnloader_SRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_SRentAgreement").css("display", "block")
              );
              SRentAgreementStatus = "remove";
              SOptStatusArray[1].status = SRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SRentAgreementModal").modal("hide");
              $("#cancelbtn_SRentAgreement").attr("disabled", false);
              $("#upload_btn20_SRentAgreement").show();
              $("#upload_btnloader_SRentAgreement").hide();
              $("#choose_SRentAgreement").hide();
              $("#X-btn_SRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SRentAgreement").attr("disabled", false);
            $("#upload_btn20_SRentAgreement").show();
            $("#upload_btnloader_SRentAgreement").hide();
            $("#SRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SRentAgreement").attr("disabled", false);
            $("#upload_btn20_SRentAgreement").show();
            $("#upload_btnloader_SRentAgreement").hide();
            $("#SRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SRentAgreement").attr("disabled", false);
          $("#upload_btn20_SRentAgreement").show();
          $("#upload_btnloader_SRentAgreement").hide();
          $("#SRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSRentAgreement = () => {
    $("#X-btn_SRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SRentAgreementStatus = res.data.data.status;
          SOptStatusArray[1].status = SRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SRentAgreement").show();
          $("#X-btn_SRentAgreement").hide();
          $("#X-btn_SRentAgreement").attr("disabled", false);
          $("#viewDocLink_SRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SRentAgreementseecommentsbtn").hide();
          $("#SRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSRentAgreementhidecommentsbtn = () => {
    $("#SRentAgreementseecommentsbtn").show();
    $("#SRentAgreementseecommentsbtn").attr("disabled", false);
    $("#SRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SResidentialCopyOfRegistry

  // SResidentialRentAgreement

  // SAdditional
  handleSAddFileChange = (e, index, status) => {
    SadditionalReuploadIndex = index;
    SadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!SadditionalFilesNames.includes(this.state.SAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.SAdd_DocName,
            },
            () => {
              $(".SAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.SAdd_DocName,
              });
            }
          });
        $(".SAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadSFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.SadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".SAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".SAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}SFileseecommentsbtn`).attr("disabled", false);
            if (SadditionalReupload == false) {
              SadditionalFilesNames.push(this.state.SAdd_DocName);
              this.state.SadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  SadditionalFiles: [
                    ...this.state.SadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_SadditionalFiles: "Yes",
                    },
                    () => {
                      this.SadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleSProceedBtn();
                    }
                  );

                  $(".SadditionalInput").attr("disabled", true);
                }
              );
            } else if (SadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.SadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_SadditionalFiles: "Yes",
                  SadditionalFiles: array,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".SAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".SAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.SadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.SadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                SadditionalFiles: array,
              },
              () => {
                this.SadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleSProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  SadditionalFiles: array,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  SadditionalFiles: [],
                  SAdd_DocName: "",
                  api_SadditionalFiles: "No",
                  IsSAddFileDisabled: true,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}SFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.SadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}SFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}SFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}SFileseecommentsbtn`).show();
    $(`#${index}SFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}SFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentSFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn SFileseecommentsbtn"
            id={`${FileIndex}SFileseecommentsbtn`}
            disabled
            onClick={() => this.handleSFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SFilehidecommentsbtn"
            id={`${FileIndex}SFilehidecommentsbtn`}
            onClick={() => this.handleSFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn SFileseecommentsbtn"
            id={`${FileIndex}SFileseecommentsbtn`}
            onClick={() => this.handleSFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SFilehidecommentsbtn"
            id={`${FileIndex}SFilehidecommentsbtn`}
            onClick={() => this.handleSFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusSAddFiles = (status, link, index) => {
    SadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        SadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_SAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_SAdd${index}`}
              // disabled={this.state.IsSAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeSFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        SadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_SAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_SAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalSFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleSAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalSFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleSAdd_DocNameChange(e)}
      />
    );
  };

  SadditionalFilesArea = () => {
    SadditionalFilesNames = [];
    if (this.state.api_SadditionalFiles == "Yes") {
      return this.state.SadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        SadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalSFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalSFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentSFiles(index, commentDisabled)}
                    {this.checkStatusSAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_SadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.SAdd_DocName}
                  onChange={(e) => this.handleSAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentSFiles(0, true)}
                  {this.checkStatusSAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleSAdd_DocNameChange = (e) => {
    this.setState(
      {
        SAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.SAdd_DocName.trim().length > 0) {
          this.setState({
            IsSAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsSAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddSFileBtn = (FileIndex) => {
    const { SAdd_DocName, SadditionalFiles } = this.state;

    let prevFile = SadditionalFiles[FileIndex];

    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (SAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          SadditionalFiles: [...this.state.SadditionalFiles, ""],
          SAdd_DocName: "",
          IsSAddFileDisabled: true,
        },
        () => {
          $(".SadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // // Proceed Checks
  ScheckRemainingReuploads = () => {
    if (
      SAadharStatus != "reupload" &&
      SSelfDeclStatus != "reupload" &&
      SPremisepicStatus != "reupload" &&
      STaxReceiptStatus != "reupload" &&
      SPicStatus != "reupload" &&
      SCopyOfRegistryStatus != "reupload" &&
      SRentAgreementStatus != "reupload"
    ) {
      AnyReuploadOccuredS = "No";
    } else {
      $(".proceednowbtn1").show();
      AnyReuploadOccuredS = "Yes";
    }
  };

  checkhandleSProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      SStatusArray
    );
    let SadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.SadditionalFiles
    );
    let goodtogo = "No";

    if (this.state.SAddressProofType_radio == "OwnershipDeed") {
      if (
        SOptStatusArray[0].status != "reupload" &&
        SOptStatusArray[0].status != "upload" &&
        SOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.SAddressProofType_radio == "RentAgreement") {
      if (
        SOptStatusArray[1].status != "reupload" &&
        SOptStatusArray[1].status != "upload" &&
        SOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredS == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      SadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else if (
      SAadharStatus == "remove" &&
      SPremisepicStatus == "remove" &&
      SSelfDeclStatus == "remove" &&
      STaxReceiptStatus == "remove" &&
      SPicStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else {
      $(".proceednowbtn1").attr("disabled", true);
      Sproceednowforword = "fail";
    }
  };

  handleSProceedBtn = () => {
    this.checkhandleSProceedBtn();
    if (this.state.SbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.SbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (Sproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.SAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.SbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  // Partnership

  // PAadhar1
  handlePAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant1",
          },
          () => {
            $("#PAadhar1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant1",
            });
          }
        });
      $("#PAadhar1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PAadhar1").attr("disabled", true);
      $("#upload_btn20_PAadhar1").hide();
      $("#upload_btnloader_PAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PAadhar1DocLink: res.data.data.AadhaarCardApplicant1,
                },
                () => $("#viewDocLink_PAadhar1").css("display", "block")
              );
              PAadhar1Status = "remove";
              PStatusArray[2].status = PAadhar1Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PAadhar1Modal").modal("hide");
              $("#cancelbtn_PAadhar1").attr("disabled", false);
              $("#upload_btn20_PAadhar1").show();
              $("#upload_btnloader_PAadhar1").hide();
              $("#choose_PAadhar1").hide();
              $("#X-btn_PAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PAadhar1").attr("disabled", false);
            $("#upload_btn20_PAadhar1").show();
            $("#upload_btnloader_PAadhar1").hide();
            $("#PAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PAadhar1").attr("disabled", false);
            $("#upload_btn20_PAadhar1").show();
            $("#upload_btnloader_PAadhar1").hide();
            $("#PAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PAadhar1").attr("disabled", false);
          $("#upload_btn20_PAadhar1").show();
          $("#upload_btnloader_PAadhar1").hide();
          $("#PAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePAadhar1 = () => {
    $("#X-btn_PAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PAadhar1Status = res.data.data.status;
          PStatusArray[2].status = PAadhar1Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PAadhar1").show();
          $("#X-btn_PAadhar1").hide();
          $("#X-btn_PAadhar1").attr("disabled", false);
          $("#viewDocLink_PAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PAadhar1seecommentsbtn").hide();
          $("#PAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar1hidecommentsbtn = () => {
    $("#PAadhar1seecommentsbtn").show();
    $("#PAadhar1seecommentsbtn").attr("disabled", false);
    $("#PAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PAadhar2
  handlePAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant2",
          },
          () => {
            $("#PAadhar2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant2",
            });
          }
        });
      $("#PAadhar2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PAadhar2").attr("disabled", true);
      $("#upload_btn20_PAadhar2").hide();
      $("#upload_btnloader_PAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PAadhar2DocLink: res.data.data.AadhaarCardApplicant2,
                },
                () => $("#viewDocLink_PAadhar2").css("display", "block")
              );
              PAadhar2Status = "remove";
              PStatusArray[3].status = PAadhar2Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PAadhar2Modal").modal("hide");
              $("#cancelbtn_PAadhar2").attr("disabled", false);
              $("#upload_btn20_PAadhar2").show();
              $("#upload_btnloader_PAadhar2").hide();
              $("#choose_PAadhar2").hide();
              $("#X-btn_PAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PAadhar2").attr("disabled", false);
            $("#upload_btn20_PAadhar2").show();
            $("#upload_btnloader_PAadhar2").hide();
            $("#PAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PAadhar2").attr("disabled", false);
            $("#upload_btn20_PAadhar2").show();
            $("#upload_btnloader_PAadhar2").hide();
            $("#PAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PAadhar2").attr("disabled", false);
          $("#upload_btn20_PAadhar2").show();
          $("#upload_btnloader_PAadhar2").hide();
          $("#PAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePAadhar2 = () => {
    $("#X-btn_PAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PAadhar2Status = res.data.data.status;
          PStatusArray[3].status = PAadhar2Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PAadhar2").show();
          $("#X-btn_PAadhar2").hide();
          $("#X-btn_PAadhar2").attr("disabled", false);
          $("#viewDocLink_PAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PAadhar2seecommentsbtn").hide();
          $("#PAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar2hidecommentsbtn = () => {
    $("#PAadhar2seecommentsbtn").show();
    $("#PAadhar2seecommentsbtn").attr("disabled", false);
    $("#PAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PSelfDecl
  handlePSelfDeclChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "SelfDeclaration",
          },
          () => {
            $("#PSelfDeclModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "SelfDeclaration",
            });
          }
        });
      $("#PSelfDeclModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPSelfDecl = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PSelfDecl").attr("disabled", true);
      $("#upload_btn20_PSelfDecl").hide();
      $("#upload_btnloader_PSelfDecl").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PSelfDeclDocLink: res.data.data.PANCardApplicant1,
                },
                () => $("#viewDocLink_PSelfDecl").css("display", "block")
              );
              PSelfDeclStatus = "remove";
              PStatusArray[5].status = PSelfDeclStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PSelfDeclModal").modal("hide");
              $("#cancelbtn_PSelfDecl").attr("disabled", false);
              $("#upload_btn20_PSelfDecl").show();
              $("#upload_btnloader_PSelfDecl").hide();
              $("#choose_PSelfDecl").hide();
              $("#X-btn_PSelfDecl").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PSelfDecl").attr("disabled", false);
            $("#upload_btn20_PSelfDecl").show();
            $("#upload_btnloader_PSelfDecl").hide();
            $("#PSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PSelfDecl").attr("disabled", false);
            $("#upload_btn20_PSelfDecl").show();
            $("#upload_btnloader_PSelfDecl").hide();
            $("#PSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PSelfDecl").attr("disabled", false);
          $("#upload_btn20_PSelfDecl").show();
          $("#upload_btnloader_PSelfDecl").hide();
          $("#PSelfDeclModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePSelfDecl = () => {
    $("#X-btn_PSelfDecl").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "SelfDeclaration",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PSelfDeclStatus = res.data.data.status;
          PStatusArray[5].status = PSelfDeclStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PSelfDecl").show();
          $("#X-btn_PSelfDecl").hide();
          $("#X-btn_PSelfDecl").attr("disabled", false);
          $("#viewDocLink_PSelfDecl").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PSelfDecl").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PSelfDecl").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePSelfDeclseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PSelfDeclseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SelfDeclaration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PSelfDeclseecommentsbtn").hide();
          $("#PSelfDeclhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePSelfDeclhidecommentsbtn = () => {
    $("#PSelfDeclseecommentsbtn").show();
    $("#PSelfDeclseecommentsbtn").attr("disabled", false);
    $("#PSelfDeclhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PTaxReceipt
  handlePTaxReceiptChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TaxReceipt",
          },
          () => {
            $("#PTaxReceiptModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "TaxReceipt",
            });
          }
        });
      $("#PTaxReceiptModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPTaxReceipt = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PTaxReceipt").attr("disabled", true);
      $("#upload_btn20_PTaxReceipt").hide();
      $("#upload_btnloader_PTaxReceipt").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PTaxReceiptDocLink: res.data.data.PANCardApplicant1,
                },
                () => $("#viewDocLink_PTaxReceipt").css("display", "block")
              );
              PTaxReceiptStatus = "remove";
              PStatusArray[6].status = PTaxReceiptStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PTaxReceiptModal").modal("hide");
              $("#cancelbtn_PTaxReceipt").attr("disabled", false);
              $("#upload_btn20_PTaxReceipt").show();
              $("#upload_btnloader_PTaxReceipt").hide();
              $("#choose_PTaxReceipt").hide();
              $("#X-btn_PTaxReceipt").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PTaxReceipt").attr("disabled", false);
            $("#upload_btn20_PTaxReceipt").show();
            $("#upload_btnloader_PTaxReceipt").hide();
            $("#PTaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PTaxReceipt").attr("disabled", false);
            $("#upload_btn20_PTaxReceipt").show();
            $("#upload_btnloader_PTaxReceipt").hide();
            $("#PTaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PTaxReceipt").attr("disabled", false);
          $("#upload_btn20_PTaxReceipt").show();
          $("#upload_btnloader_PTaxReceipt").hide();
          $("#PTaxReceiptModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePTaxReceipt = () => {
    $("#X-btn_PTaxReceipt").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PTaxReceiptStatus = res.data.data.status;
          PStatusArray[6].status = PTaxReceiptStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PTaxReceipt").show();
          $("#X-btn_PTaxReceipt").hide();
          $("#X-btn_PTaxReceipt").attr("disabled", false);
          $("#viewDocLink_PTaxReceipt").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PTaxReceipt").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PTaxReceipt").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePTaxReceiptseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PTaxReceiptseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PTaxReceiptseecommentsbtn").hide();
          $("#PTaxReceipthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePTaxReceipthidecommentsbtn = () => {
    $("#PTaxReceiptseecommentsbtn").show();
    $("#PTaxReceiptseecommentsbtn").attr("disabled", false);
    $("#PTaxReceipthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPDeed
  handlePPDeedChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PartnershipDeed",
          },
          () => {
            $("#PPDeedModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "PartnershipDeed",
            });
          }
        });
      $("#PPDeedModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  // PPremisepic
  handlePPremisepicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PremisePhoto",
          },
          () => {
            $("#PPremisepicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PremisePhoto",
          });
        }
      });
      $("#PPremisepicModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPremisepic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPremisepic").attr("disabled", true);
      $("#upload_btn20_PPremisepic").hide();
      $("#upload_btnloader_PPremisepic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPremisepicDocLink: res.data.data.PANCardApplicant1,
                },
                () => $("#viewDocLink_PPremisepic").css("display", "block")
              );
              PPremisepicStatus = "remove";
              PStatusArray[5].status = PPremisepicStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPremisepicModal").modal("hide");
              $("#cancelbtn_PPremisepic").attr("disabled", false);
              $("#upload_btn20_PPremisepic").show();
              $("#upload_btnloader_PPremisepic").hide();
              $("#choose_PPremisepic").hide();
              $("#X-btn_PPremisepic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPremisepic").attr("disabled", false);
            $("#upload_btn20_PPremisepic").show();
            $("#upload_btnloader_PPremisepic").hide();
            $("#PPremisepicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPremisepic").attr("disabled", false);
            $("#upload_btn20_PPremisepic").show();
            $("#upload_btnloader_PPremisepic").hide();
            $("#PPremisepicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPremisepic").attr("disabled", false);
          $("#upload_btn20_PPremisepic").show();
          $("#upload_btnloader_PPremisepic").hide();
          $("#PPremisepicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPremisepic = () => {
    $("#X-btn_PPremisepic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PremisePhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPremisepicStatus = res.data.data.status;
          PStatusArray[5].status = PPremisepicStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPremisepic").show();
          $("#X-btn_PPremisepic").hide();
          $("#X-btn_PPremisepic").attr("disabled", false);
          $("#viewDocLink_PPremisepic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPremisepic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPremisepic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPremisepicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPremisepicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PremisePhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPremisepicseecommentsbtn").hide();
          $("#PPremisepichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPremisepichidecommentsbtn = () => {
    $("#PPremisepicseecommentsbtn").show();
    $("#PPremisepicseecommentsbtn").attr("disabled", false);
    $("#PPremisepichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  uploadPPDeed = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPDeed").attr("disabled", true);
      $("#upload_btn20_PPDeed").hide();
      $("#upload_btnloader_PPDeed").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPDeedDocLink: res.data.data.PANCardApplicant2,
                },
                () => $("#viewDocLink_PPDeed").css("display", "block")
              );
              PPDeedStatus = "remove";
              PStatusArray[4].status = PPDeedStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPDeedModal").modal("hide");
              $("#cancelbtn_PPDeed").attr("disabled", false);
              $("#upload_btn20_PPDeed").show();
              $("#upload_btnloader_PPDeed").hide();
              $("#choose_PPDeed").hide();
              $("#X-btn_PPDeed").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPDeed").attr("disabled", false);
            $("#upload_btn20_PPDeed").show();
            $("#upload_btnloader_PPDeed").hide();
            $("#PPDeedModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPDeed").attr("disabled", false);
            $("#upload_btn20_PPDeed").show();
            $("#upload_btnloader_PPDeed").hide();
            $("#PPDeedModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPDeed").attr("disabled", false);
          $("#upload_btn20_PPDeed").show();
          $("#upload_btnloader_PPDeed").hide();
          $("#PPDeedModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPDeed = () => {
    $("#X-btn_PPDeed").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PartnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPDeedStatus = res.data.data.status;
          PStatusArray[4].status = PPDeedStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPDeed").show();
          $("#X-btn_PPDeed").hide();
          $("#X-btn_PPDeed").attr("disabled", false);
          $("#viewDocLink_PPDeed").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPDeed").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPDeed").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPDeedseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PartnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPDeedseecommentsbtn").hide();
          $("#PPDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPDeedhidecommentsbtn = () => {
    $("#PPDeedseecommentsbtn").show();
    $("#PPDeedseecommentsbtn").attr("disabled", false);
    $("#PPDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPic1
  handlePPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#PPic1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto1",
            });
          }
        });
      $("#PPic1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPic1").attr("disabled", true);
      $("#upload_btn20_PPic1").hide();
      $("#upload_btnloader_PPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_PPic1").css("display", "block")
              );
              PPic1Status = "remove";
              PStatusArray[0].status = PPic1Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPic1Modal").modal("hide");
              $("#cancelbtn_PPic1").attr("disabled", false);
              $("#upload_btn20_PPic1").show();
              $("#upload_btnloader_PPic1").hide();
              $("#choose_PPic1").hide();
              $("#X-btn_PPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPic1").attr("disabled", false);
            $("#upload_btn20_PPic1").show();
            $("#upload_btnloader_PPic1").hide();
            $("#PPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPic1").attr("disabled", false);
            $("#upload_btn20_PPic1").show();
            $("#upload_btnloader_PPic1").hide();
            $("#PPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPic1").attr("disabled", false);
          $("#upload_btn20_PPic1").show();
          $("#upload_btnloader_PPic1").hide();
          $("#PPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPic1 = () => {
    $("#X-btn_PPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPic1Status = res.data.data.status;
          PStatusArray[0].status = PPic1Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPic1").show();
          $("#X-btn_PPic1").hide();
          $("#X-btn_PPic1").attr("disabled", false);
          $("#viewDocLink_PPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPic1seecommentsbtn").hide();
          $("#PPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPic1hidecommentsbtn = () => {
    $("#PPic1seecommentsbtn").show();
    $("#PPic1seecommentsbtn").attr("disabled", false);
    $("#PPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPic2
  handlePPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#PPic2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto2",
            });
          }
        });
      $("#PPic2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPic2").attr("disabled", true);
      $("#upload_btn20_PPic2").hide();
      $("#upload_btnloader_PPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_PPic2").css("display", "block")
              );
              PPic2Status = "remove";
              PStatusArray[1].status = PPic2Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPic2Modal").modal("hide");
              $("#cancelbtn_PPic2").attr("disabled", false);
              $("#upload_btn20_PPic2").show();
              $("#upload_btnloader_PPic2").hide();
              $("#choose_PPic2").hide();
              $("#X-btn_PPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPic2").attr("disabled", false);
            $("#upload_btn20_PPic2").show();
            $("#upload_btnloader_PPic2").hide();
            $("#PPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPic2").attr("disabled", false);
            $("#upload_btn20_PPic2").show();
            $("#upload_btnloader_PPic2").hide();
            $("#PPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPic2").attr("disabled", false);
          $("#upload_btn20_PPic2").show();
          $("#upload_btnloader_PPic2").hide();
          $("#PPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPic2 = () => {
    $("#X-btn_PPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPic2Status = res.data.data.status;
          PStatusArray[1].status = PPic2Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPic2").show();
          $("#X-btn_PPic2").hide();
          $("#X-btn_PPic2").attr("disabled", false);
          $("#viewDocLink_PPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPic2seecommentsbtn").hide();
          $("#PPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPic2hidecommentsbtn = () => {
    $("#PPic2seecommentsbtn").show();
    $("#PPic2seecommentsbtn").attr("disabled", false);
    $("#PPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PGumasta

  // PTradeLicence
  handlePPbusinessNatureChange = (e) => {
    this.setState({
      PPbusinessNature: e.target.value,
    });
  };
  // PAddressProofType
  PAddressProofType_radioChange = (e) => {
    this.setState(
      {
        PAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.PAddressProofType_radio != "OwnershipDeed") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.PAddressProofType_radio == "OwnershipDeed") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandlePProceedBtn();
      }
    );
  };

  // PCopyOfRegistry

  // PResidentialRentAgreement

  // PRentAgreement
  handlePRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#PRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#PRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PRentAgreement").attr("disabled", true);
      $("#upload_btn20_PRentAgreement").hide();
      $("#upload_btnloader_PRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_PRentAgreement").css("display", "block")
              );
              PRentAgreementStatus = "remove";
              POptStatusArray[0].status = PRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PRentAgreementModal").modal("hide");
              $("#cancelbtn_PRentAgreement").attr("disabled", false);
              $("#upload_btn20_PRentAgreement").show();
              $("#upload_btnloader_PRentAgreement").hide();
              $("#choose_PRentAgreement").hide();
              $("#X-btn_PRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PRentAgreement").attr("disabled", false);
            $("#upload_btn20_PRentAgreement").show();
            $("#upload_btnloader_PRentAgreement").hide();
            $("#PRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PRentAgreement").attr("disabled", false);
            $("#upload_btn20_PRentAgreement").show();
            $("#upload_btnloader_PRentAgreement").hide();
            $("#PRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PRentAgreement").attr("disabled", false);
          $("#upload_btn20_PRentAgreement").show();
          $("#upload_btnloader_PRentAgreement").hide();
          $("#PRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePRentAgreement = () => {
    $("#X-btn_PRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PRentAgreementStatus = res.data.data.status;
          POptStatusArray[0].status = PRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PRentAgreement").show();
          $("#X-btn_PRentAgreement").hide();
          $("#X-btn_PRentAgreement").attr("disabled", false);
          $("#viewDocLink_PRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PRentAgreementseecommentsbtn").hide();
          $("#PRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePRentAgreementhidecommentsbtn = () => {
    $("#PRentAgreementseecommentsbtn").show();
    $("#PRentAgreementseecommentsbtn").attr("disabled", false);
    $("#PRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // POwnerDeed
  handlePOwnerDeedChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#POwnerDeedModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#POwnerDeedModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPOwnerDeed = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_POwnerDeed").attr("disabled", true);
      $("#upload_btn20_POwnerDeed").hide();
      $("#upload_btnloader_POwnerDeed").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  POwnerDeedDocLink: res.data.data.OwnershipDeed,
                },
                () => $("#viewDocLink_POwnerDeed").css("display", "block")
              );
              POwnerDeedStatus = "remove";
              POptStatusArray[1].status = POwnerDeedStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#POwnerDeedModal").modal("hide");
              $("#cancelbtn_POwnerDeed").attr("disabled", false);
              $("#upload_btn20_POwnerDeed").show();
              $("#upload_btnloader_POwnerDeed").hide();
              $("#choose_POwnerDeed").hide();
              $("#X-btn_POwnerDeed").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_POwnerDeed").attr("disabled", false);
            $("#upload_btn20_POwnerDeed").show();
            $("#upload_btnloader_POwnerDeed").hide();
            $("#POwnerDeedModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_POwnerDeed").attr("disabled", false);
            $("#upload_btn20_POwnerDeed").show();
            $("#upload_btnloader_POwnerDeed").hide();
            $("#POwnerDeedModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_POwnerDeed").attr("disabled", false);
          $("#upload_btn20_POwnerDeed").show();
          $("#upload_btnloader_POwnerDeed").hide();
          $("#POwnerDeedModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePOwnerDeed = () => {
    $("#X-btn_POwnerDeed").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          POwnerDeedStatus = res.data.data.status;
          POptStatusArray[1].status = POwnerDeedStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_POwnerDeed").show();
          $("#X-btn_POwnerDeed").hide();
          $("#X-btn_POwnerDeed").attr("disabled", false);
          $("#viewDocLink_POwnerDeed").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_POwnerDeed").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_POwnerDeed").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePOwnerDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#POwnerDeedseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "OwnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#POwnerDeedseecommentsbtn").hide();
          $("#POwnerDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePOwnerDeedhidecommentsbtn = () => {
    $("#POwnerDeedseecommentsbtn").show();
    $("#POwnerDeedseecommentsbtn").attr("disabled", false);
    $("#POwnerDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PAdditional
  handlePAddFileChange = (e, index, status) => {
    PadditionalReuploadIndex = index;
    PadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!PadditionalFilesNames.includes(this.state.PAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.PAdd_DocName,
            },
            () => {
              $(".PAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.PAdd_DocName,
              });
            }
          });
        $(".PAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".PAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}PFileseecommentsbtn`).attr("disabled", false);
            if (PadditionalReupload == false) {
              PadditionalFilesNames.push(this.state.PAdd_DocName);
              this.state.PadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PadditionalFiles: [
                    ...this.state.PadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PadditionalFiles: "Yes",
                    },
                    () => {
                      this.PadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePProceedBtn();
                    }
                  );

                  $(".PadditionalInput").attr("disabled", true);
                }
              );
            } else if (PadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PadditionalFiles: "Yes",
                  PadditionalFiles: array,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.PadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PadditionalFiles: array,
              },
              () => {
                this.PadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PadditionalFiles: array,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PadditionalFiles: [],
                  PAdd_DocName: "",
                  api_PadditionalFiles: "No",
                  IsPAddFileDisabled: true,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PFileseecommentsbtn`).show();
    $(`#${index}PFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn PFileseecommentsbtn"
            id={`${FileIndex}PFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFilehidecommentsbtn"
            id={`${FileIndex}PFilehidecommentsbtn`}
            onClick={() => this.handlePFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PFileseecommentsbtn"
            id={`${FileIndex}PFileseecommentsbtn`}
            onClick={() => this.handlePFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFilehidecommentsbtn"
            id={`${FileIndex}PFilehidecommentsbtn`}
            onClick={() => this.handlePFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPAddFiles = (status, link, index) => {
    PadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PAdd${index}`}
              // disabled={this.state.IsPAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removePFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalPFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePAdd_DocNameChange(e)}
      />
    );
  };

  PadditionalFilesArea = () => {
    PadditionalFilesNames = [];
    if (this.state.api_PadditionalFiles == "Yes") {
      return this.state.PadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPFiles(index, commentDisabled)}
                    {this.checkStatusPAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PAdd_DocName}
                  onChange={(e) => this.handlePAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPFiles(0, true)}
                  {this.checkStatusPAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePAdd_DocNameChange = (e) => {
    this.setState(
      {
        PAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PAdd_DocName.trim().length > 0) {
          this.setState({
            IsPAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddPFileBtn = (FileIndex) => {
    const { PAdd_DocName, PadditionalFiles } = this.state;

    let prevFile = PadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PadditionalFiles: [...this.state.PadditionalFiles, ""],
          PAdd_DocName: "",
          IsPAddFileDisabled: true,
        },
        () => {
          $(".PadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  PcheckRemainingReuploads = () => {
    if (
      PAadhar1Status != "reupload" &&
      PAadhar2Status != "reupload" &&
      PSelfDeclStatus != "reupload" &&
      PTaxReceiptStatus != "reupload" &&
      PPremisepicStatus != "reupload" &&
      PPDeedStatus != "reupload" &&
      PPic1Status != "reupload" &&
      PPic2Status != "reupload" &&
      PRentAgreementStatus != "reupload" &&
      POwnerDeedStatus != "reupload"
    ) {
      AnyReuploadOccuredP = "No";
    } else {
      $(".proceednowbtn2").show();
      AnyReuploadOccuredP = "Yes";
    }
  };

  checkhandlePProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      PStatusArray
    );
    let PadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.PAddressProofType_radio == "OwnershipDeed") {
      if (
        POptStatusArray[1].status != "reupload" &&
        POptStatusArray[1].status != "upload" &&
        POptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.PAddressProofType_radio == "OwnershipDeed") {
      if (
        POptStatusArray[0].status != "reupload" &&
        POptStatusArray[0].status != "upload" &&
        POptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredP == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      PadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else if (
      PAadhar1Status == "remove" &&
      PAadhar2Status == "remove" &&
      PSelfDeclStatus == "remove" &&
      PTaxReceiptStatus == "remove" &&
      PPremisepicStatus == "remove" &&
      PPDeedStatus == "remove" &&
      PPic1Status == "remove" &&
      PPic2Status == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else {
      $(".proceednowbtn2").attr("disabled", true);
      Pproceednowforword = "fail";
    }
  };

  handlePProceedBtn = () => {
    this.checkhandlePProceedBtn();
    if (this.state.PPbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.PPbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (Pproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.PAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.PPbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  // Private Limited

  // PLAadhar
  handlePLAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant1",
          },
          () => {
            $("#PLAadhar1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant1",
            });
          }
        });
      $("#PLAadhar1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAadhar1").attr("disabled", true);
      $("#upload_btn20_PLAadhar1").hide();
      $("#upload_btnloader_PLAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAadhar1DocLink: res.data.data.AadhaarCardApplicant1,
                },
                () => $("#viewDocLink_PLAadhar1").css("display", "block")
              );
              PLAadhar1Status = "remove";
              PLStatusArray[2].status = PLAadhar1Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAadhar1Modal").modal("hide");
              $("#cancelbtn_PLAadhar1").attr("disabled", false);
              $("#upload_btn20_PLAadhar1").show();
              $("#upload_btnloader_PLAadhar1").hide();
              $("#choose_PLAadhar1").hide();
              $("#X-btn_PLAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAadhar1").attr("disabled", false);
            $("#upload_btn20_PLAadhar1").show();
            $("#upload_btnloader_PLAadhar1").hide();
            $("#PLAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAadhar1").attr("disabled", false);
            $("#upload_btn20_PLAadhar1").show();
            $("#upload_btnloader_PLAadhar1").hide();
            $("#PLAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAadhar1").attr("disabled", false);
          $("#upload_btn20_PLAadhar1").show();
          $("#upload_btnloader_PLAadhar1").hide();
          $("#PLAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAadhar1 = () => {
    $("#X-btn_PLAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAadhar1Status = res.data.data.status;
          PLStatusArray[2].status = PLAadhar1Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAadhar1").show();
          $("#X-btn_PLAadhar1").hide();
          $("#X-btn_PLAadhar1").attr("disabled", false);
          $("#viewDocLink_PLAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAadhar1seecommentsbtn").hide();
          $("#PLAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAadhar1hidecommentsbtn = () => {
    $("#PLAadhar1seecommentsbtn").show();
    $("#PLAadhar1seecommentsbtn").attr("disabled", false);
    $("#PLAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAadhar2
  handlePLAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant2",
          },
          () => {
            $("#PLAadhar2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant2",
            });
          }
        });
      $("#PLAadhar2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAadhar2").attr("disabled", true);
      $("#upload_btn20_PLAadhar2").hide();
      $("#upload_btnloader_PLAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAadhar2DocLink: res.data.data.AadhaarCardApplicant2,
                },
                () => $("#viewDocLink_PLAadhar2").css("display", "block")
              );
              PLAadhar2Status = "remove";
              PLStatusArray[3].status = PLAadhar2Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAadhar2Modal").modal("hide");
              $("#cancelbtn_PLAadhar2").attr("disabled", false);
              $("#upload_btn20_PLAadhar2").show();
              $("#upload_btnloader_PLAadhar2").hide();
              $("#choose_PLAadhar2").hide();
              $("#X-btn_PLAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAadhar2").attr("disabled", false);
            $("#upload_btn20_PLAadhar2").show();
            $("#upload_btnloader_PLAadhar2").hide();
            $("#PLAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAadhar2").attr("disabled", false);
            $("#upload_btn20_PLAadhar2").show();
            $("#upload_btnloader_PLAadhar2").hide();
            $("#PLAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAadhar2").attr("disabled", false);
          $("#upload_btn20_PLAadhar2").show();
          $("#upload_btnloader_PLAadhar2").hide();
          $("#PLAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAadhar2 = () => {
    $("#X-btn_PLAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAadhar2Status = res.data.data.status;
          PLStatusArray[3].status = PLAadhar2Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAadhar2").show();
          $("#X-btn_PLAadhar2").hide();
          $("#X-btn_PLAadhar2").attr("disabled", false);
          $("#viewDocLink_PLAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAadhar2seecommentsbtn").hide();
          $("#PLAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAadhar2hidecommentsbtn = () => {
    $("#PLAadhar2seecommentsbtn").show();
    $("#PLAadhar2seecommentsbtn").attr("disabled", false);
    $("#PLAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPan1

  // PLPan2

  // PLPic1
  handlePLPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#PLPic1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto1",
            });
          }
        });
      $("#PLPic1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPic1").attr("disabled", true);
      $("#upload_btn20_PLPic1").hide();
      $("#upload_btnloader_PLPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_PLPic1").css("display", "block")
              );
              PLPic1Status = "remove";
              PLStatusArray[0].status = PLPic1Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPic1Modal").modal("hide");
              $("#cancelbtn_PLPic1").attr("disabled", false);
              $("#upload_btn20_PLPic1").show();
              $("#upload_btnloader_PLPic1").hide();
              $("#choose_PLPic1").hide();
              $("#X-btn_PLPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPic1").attr("disabled", false);
            $("#upload_btn20_PLPic1").show();
            $("#upload_btnloader_PLPic1").hide();
            $("#PLPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPic1").attr("disabled", false);
            $("#upload_btn20_PLPic1").show();
            $("#upload_btnloader_PLPic1").hide();
            $("#PLPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPic1").attr("disabled", false);
          $("#upload_btn20_PLPic1").show();
          $("#upload_btnloader_PLPic1").hide();
          $("#PLPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPic1 = () => {
    $("#X-btn_PLPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPic1Status = res.data.data.status;
          PLStatusArray[0].status = PLPic1Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPic1").show();
          $("#X-btn_PLPic1").hide();
          $("#X-btn_PLPic1").attr("disabled", false);
          $("#viewDocLink_PLPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPic1seecommentsbtn").hide();
          $("#PLPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPic1hidecommentsbtn = () => {
    $("#PLPic1seecommentsbtn").show();
    $("#PLPic1seecommentsbtn").attr("disabled", false);
    $("#PLPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPic2
  handlePLPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#PLPic2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto2",
            });
          }
        });
      $("#PLPic2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPic2").attr("disabled", true);
      $("#upload_btn20_PLPic2").hide();
      $("#upload_btnloader_PLPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_PLPic2").css("display", "block")
              );
              PLPic2Status = "remove";
              PLStatusArray[1].status = PLPic2Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPic2Modal").modal("hide");
              $("#cancelbtn_PLPic2").attr("disabled", false);
              $("#upload_btn20_PLPic2").show();
              $("#upload_btnloader_PLPic2").hide();
              $("#choose_PLPic2").hide();
              $("#X-btn_PLPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPic2").attr("disabled", false);
            $("#upload_btn20_PLPic2").show();
            $("#upload_btnloader_PLPic2").hide();
            $("#PLPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPic2").attr("disabled", false);
            $("#upload_btn20_PLPic2").show();
            $("#upload_btnloader_PLPic2").hide();
            $("#PLPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPic2").attr("disabled", false);
          $("#upload_btn20_PLPic2").show();
          $("#upload_btnloader_PLPic2").hide();
          $("#PLPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPic2 = () => {
    $("#X-btn_PLPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPic2Status = res.data.data.status;
          PLStatusArray[1].status = PLPic2Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPic2").show();
          $("#X-btn_PLPic2").hide();
          $("#X-btn_PLPic2").attr("disabled", false);
          $("#viewDocLink_PLPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPic2seecommentsbtn").hide();
          $("#PLPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPic2hidecommentsbtn = () => {
    $("#PLPic2seecommentsbtn").show();
    $("#PLPic2seecommentsbtn").attr("disabled", false);
    $("#PLPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLSelfDecl
  handlePLSelfDeclChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "SelfDeclaration",
          },
          () => {
            $("#PLSelfDeclModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "SelfDeclaration",
            });
          }
        });
      $("#PLSelfDeclModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLSelfDecl = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLSelfDecl").attr("disabled", true);
      $("#upload_btn20_PLSelfDecl").hide();
      $("#upload_btnloader_PLSelfDecl").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLSelfDeclDocLink: res.data.data.SelfDeclaration,
                },
                () => $("#viewDocLink_PLSelfDecl").css("display", "block")
              );
              PLSelfDeclStatus = "remove";
              PLStatusArray[4].status = PLSelfDeclStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLSelfDeclModal").modal("hide");
              $("#cancelbtn_PLSelfDecl").attr("disabled", false);
              $("#upload_btn20_PLSelfDecl").show();
              $("#upload_btnloader_PLSelfDecl").hide();
              $("#choose_PLSelfDecl").hide();
              $("#X-btn_PLSelfDecl").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLSelfDecl").attr("disabled", false);
            $("#upload_btn20_PLSelfDecl").show();
            $("#upload_btnloader_PLSelfDecl").hide();
            $("#PLSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLSelfDecl").attr("disabled", false);
            $("#upload_btn20_PLSelfDecl").show();
            $("#upload_btnloader_PLSelfDecl").hide();
            $("#PLSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLSelfDecl").attr("disabled", false);
          $("#upload_btn20_PLSelfDecl").show();
          $("#upload_btnloader_PLSelfDecl").hide();
          $("#PLSelfDeclModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLSelfDecl = () => {
    $("#X-btn_PLSelfDecl").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "SelfDeclaration",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLSelfDeclStatus = res.data.data.status;
          PLStatusArray[4].status = PLSelfDeclStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLSelfDecl").show();
          $("#X-btn_PLSelfDecl").hide();
          $("#X-btn_PLSelfDecl").attr("disabled", false);
          $("#viewDocLink_PLSelfDecl").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLSelfDecl").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLSelfDecl").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLSelfDeclseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLSelfDeclseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SelfDeclaration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLSelfDeclseecommentsbtn").hide();
          $("#PLSelfDeclhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLSelfDeclhidecommentsbtn = () => {
    $("#PLSelfDeclseecommentsbtn").show();
    $("#PLSelfDeclseecommentsbtn").attr("disabled", false);
    $("#PLSelfDeclhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLTaxReceipt
  handlePLTaxReceiptChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TaxReceipt",
          },
          () => {
            $("#PLTaxReceiptModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "TaxReceipt",
            });
          }
        });
      $("#PLTaxReceiptModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLTaxReceipt = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLTaxReceipt").attr("disabled", true);
      $("#upload_btn20_PLTaxReceipt").hide();
      $("#upload_btnloader_PLTaxReceipt").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLTaxReceiptDocLink: res.data.data.TaxReceipt,
                },
                () => $("#viewDocLink_PLTaxReceipt").css("display", "block")
              );
              PLTaxReceiptStatus = "remove";
              PLStatusArray[8].status = PLTaxReceiptStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLTaxReceiptModal").modal("hide");
              $("#cancelbtn_PLTaxReceipt").attr("disabled", false);
              $("#upload_btn20_PLTaxReceipt").show();
              $("#upload_btnloader_PLTaxReceipt").hide();
              $("#choose_PLTaxReceipt").hide();
              $("#X-btn_PLTaxReceipt").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLTaxReceipt").attr("disabled", false);
            $("#upload_btn20_PLTaxReceipt").show();
            $("#upload_btnloader_PLTaxReceipt").hide();
            $("#PLTaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLTaxReceipt").attr("disabled", false);
            $("#upload_btn20_PLTaxReceipt").show();
            $("#upload_btnloader_PLTaxReceipt").hide();
            $("#PLTaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLTaxReceipt").attr("disabled", false);
          $("#upload_btn20_PLTaxReceipt").show();
          $("#upload_btnloader_PLTaxReceipt").hide();
          $("#PLTaxReceiptModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLTaxReceipt = () => {
    $("#X-btn_PLTaxReceipt").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLTaxReceiptStatus = res.data.data.status;
          PLStatusArray[8].status = PLTaxReceiptStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLTaxReceipt").show();
          $("#X-btn_PLTaxReceipt").hide();
          $("#X-btn_PLTaxReceipt").attr("disabled", false);
          $("#viewDocLink_PLTaxReceipt").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLTaxReceipt").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLTaxReceipt").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLTaxReceiptseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLTaxReceiptseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLTaxReceiptseecommentsbtn").hide();
          $("#PLTaxReceipthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLTaxReceipthidecommentsbtn = () => {
    $("#PLTaxReceiptseecommentsbtn").show();
    $("#PLTaxReceiptseecommentsbtn").attr("disabled", false);
    $("#PLTaxReceipthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPremisePic
  handlePLPremisePicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PremisePhoto",
          },
          () => {
            $("#PLPremisePicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PremisePhoto",
          });
        }
      });
      $("#PLPremisePicModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPremisePic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPremisePic").attr("disabled", true);
      $("#upload_btn20_PLPremisePic").hide();
      $("#upload_btnloader_PLPremisePic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPremisePicDocLink: res.data.data.PremisePhoto,
                },
                () => $("#viewDocLink_PLPremisePic").css("display", "block")
              );
              PLPremisePicStatus = "remove";
              PLStatusArray[4].status = PLPremisePicStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPremisePicModal").modal("hide");
              $("#cancelbtn_PLPremisePic").attr("disabled", false);
              $("#upload_btn20_PLPremisePic").show();
              $("#upload_btnloader_PLPremisePic").hide();
              $("#choose_PLPremisePic").hide();
              $("#X-btn_PLPremisePic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPremisePic").attr("disabled", false);
            $("#upload_btn20_PLPremisePic").show();
            $("#upload_btnloader_PLPremisePic").hide();
            $("#PLPremisePicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPremisePic").attr("disabled", false);
            $("#upload_btn20_PLPremisePic").show();
            $("#upload_btnloader_PLPremisePic").hide();
            $("#PLPremisePicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPremisePic").attr("disabled", false);
          $("#upload_btn20_PLPremisePic").show();
          $("#upload_btnloader_PLPremisePic").hide();
          $("#PLPremisePicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPremisePic = () => {
    $("#X-btn_PLPremisePic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PremisePhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPremisePicStatus = res.data.data.status;
          PLStatusArray[4].status = PLPremisePicStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPremisePic").show();
          $("#X-btn_PLPremisePic").hide();
          $("#X-btn_PLPremisePic").attr("disabled", false);
          $("#viewDocLink_PLPremisePic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPremisePic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPremisePic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPremisePicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPremisePicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PremisePhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPremisePicseecommentsbtn").hide();
          $("#PLPremisePichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPremisePichidecommentsbtn = () => {
    $("#PLPremisePicseecommentsbtn").show();
    $("#PLPremisePicseecommentsbtn").attr("disabled", false);
    $("#PLPremisePichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLCI
  handlePLCIChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CertificateOfIncorporation",
          },
          () => {
            $("#PLCIModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "CertificateOfIncorporation",
            });
          }
        });
      $("#PLCIModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLCI = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLCI").attr("disabled", true);
      $("#upload_btn20_PLCI").hide();
      $("#upload_btnloader_PLCI").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLCIDocLink: res.data.data.CertificateOfIncorporation,
                },
                () => $("#viewDocLink_PLCI").css("display", "block")
              );
              PLCIStatus = "remove";
              PLStatusArray[5].status = PLCIStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLCIModal").modal("hide");
              $("#cancelbtn_PLCI").attr("disabled", false);
              $("#upload_btn20_PLCI").show();
              $("#upload_btnloader_PLCI").hide();
              $("#choose_PLCI").hide();
              $("#X-btn_PLCI").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLCI").attr("disabled", false);
            $("#upload_btn20_PLCI").show();
            $("#upload_btnloader_PLCI").hide();
            $("#PLCIModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLCI").attr("disabled", false);
            $("#upload_btn20_PLCI").show();
            $("#upload_btnloader_PLCI").hide();
            $("#PLCIModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLCI").attr("disabled", false);
          $("#upload_btn20_PLCI").show();
          $("#upload_btnloader_PLCI").hide();
          $("#PLCIModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLCI = () => {
    $("#X-btn_PLCI").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CertificateOfIncorporation",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLCIStatus = res.data.data.status;
          PLStatusArray[5].status = PLCIStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLCI").show();
          $("#X-btn_PLCI").hide();
          $("#X-btn_PLCI").attr("disabled", false);
          $("#viewDocLink_PLCI").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLCI").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLCI").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLCIseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLCIseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CertificateOfIncorporation",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLCIseecommentsbtn").hide();
          $("#PLCIhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLCIhidecommentsbtn = () => {
    $("#PLCIseecommentsbtn").show();
    $("#PLCIseecommentsbtn").attr("disabled", false);
    $("#PLCIhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLMOA
  handlePLMOAChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "MOA",
          },
          () => {
            $("#PLMOAModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "MOA",
            });
          }
        });
      $("#PLMOAModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLMOA = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLMOA").attr("disabled", true);
      $("#upload_btn20_PLMOA").hide();
      $("#upload_btnloader_PLMOA").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLMOADocLink: res.data.data.MOA,
                },
                () => $("#viewDocLink_PLMOA").css("display", "block")
              );
              PLMOAStatus = "remove";
              PLStatusArray[6].status = PLMOAStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLMOAModal").modal("hide");
              $("#cancelbtn_PLMOA").attr("disabled", false);
              $("#upload_btn20_PLMOA").show();
              $("#upload_btnloader_PLMOA").hide();
              $("#choose_PLMOA").hide();
              $("#X-btn_PLMOA").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLMOA").attr("disabled", false);
            $("#upload_btn20_PLMOA").show();
            $("#upload_btnloader_PLMOA").hide();
            $("#PLMOAModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLMOA").attr("disabled", false);
            $("#upload_btn20_PLMOA").show();
            $("#upload_btnloader_PLMOA").hide();
            $("#PLMOAModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLMOA").attr("disabled", false);
          $("#upload_btn20_PLMOA").show();
          $("#upload_btnloader_PLMOA").hide();
          $("#PLMOAModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLMOA = () => {
    $("#X-btn_PLMOA").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "MOA",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLMOAStatus = res.data.data.status;
          PLStatusArray[6].status = PLMOAStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLMOA").show();
          $("#X-btn_PLMOA").hide();
          $("#X-btn_PLMOA").attr("disabled", false);
          $("#viewDocLink_PLMOA").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLMOA").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLMOA").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLMOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLMOAseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "MOA",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLMOAseecommentsbtn").hide();
          $("#PLMOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLMOAhidecommentsbtn = () => {
    $("#PLMOAseecommentsbtn").show();
    $("#PLMOAseecommentsbtn").attr("disabled", false);
    $("#PLMOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAOA
  handlePLAOAChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AOA",
          },
          () => {
            $("#PLAOAModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "AOA",
            });
          }
        });
      $("#PLAOAModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAOA = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAOA").attr("disabled", true);
      $("#upload_btn20_PLAOA").hide();
      $("#upload_btnloader_PLAOA").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAOADocLink: res.data.data.AOA,
                },
                () => $("#viewDocLink_PLAOA").css("display", "block")
              );
              PLAOAStatus = "remove";
              PLStatusArray[7].status = PLAOAStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAOAModal").modal("hide");
              $("#cancelbtn_PLAOA").attr("disabled", false);
              $("#upload_btn20_PLAOA").show();
              $("#upload_btnloader_PLAOA").hide();
              $("#choose_PLAOA").hide();
              $("#X-btn_PLAOA").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAOA").attr("disabled", false);
            $("#upload_btn20_PLAOA").show();
            $("#upload_btnloader_PLAOA").hide();
            $("#PLAOAModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAOA").attr("disabled", false);
            $("#upload_btn20_PLAOA").show();
            $("#upload_btnloader_PLAOA").hide();
            $("#PLAOAModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAOA").attr("disabled", false);
          $("#upload_btn20_PLAOA").show();
          $("#upload_btnloader_PLAOA").hide();
          $("#PLAOAModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAOA = () => {
    $("#X-btn_PLAOA").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AOA",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAOAStatus = res.data.data.status;
          PLStatusArray[7].status = PLAOAStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAOA").show();
          $("#X-btn_PLAOA").hide();
          $("#X-btn_PLAOA").attr("disabled", false);
          $("#viewDocLink_PLAOA").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAOA").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAOA").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAOAseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AOA",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAOAseecommentsbtn").hide();
          $("#PLAOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAOAhidecommentsbtn = () => {
    $("#PLAOAseecommentsbtn").show();
    $("#PLAOAseecommentsbtn").attr("disabled", false);
    $("#PLAOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLGumasta

  // PLTradeLicence
  handlePLbusinessNatureChange = (e) => {
    this.setState({
      PLbusinessNature: e.target.value,
    });
  };

  // PLAddressProofType
  PLAddressProofType_radioChange = (e) => {
    this.setState(
      {
        PLAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.PLAddressProofType_radio != "OwnershipDeed") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.PLAddressProofType_radio == "OwnershipDeed") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandlePLProceedBtn();
      }
    );
  };

  // PLCopyOfRegistry

  // PLResidentialRentAgreement

  // PLRentAgreement
  handlePLRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#PLRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#PLRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLRentAgreement").attr("disabled", true);
      $("#upload_btn20_PLRentAgreement").hide();
      $("#upload_btnloader_PLRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_PLRentAgreement").css("display", "block")
              );
              PLRentAgreementStatus = "remove";
              PLOptStatusArray[1].status = PLRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLRentAgreementModal").modal("hide");
              $("#cancelbtn_PLRentAgreement").attr("disabled", false);
              $("#upload_btn20_PLRentAgreement").show();
              $("#upload_btnloader_PLRentAgreement").hide();
              $("#choose_PLRentAgreement").hide();
              $("#X-btn_PLRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLRentAgreement").attr("disabled", false);
            $("#upload_btn20_PLRentAgreement").show();
            $("#upload_btnloader_PLRentAgreement").hide();
            $("#PLRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLRentAgreement").attr("disabled", false);
            $("#upload_btn20_PLRentAgreement").show();
            $("#upload_btnloader_PLRentAgreement").hide();
            $("#PLRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLRentAgreement").attr("disabled", false);
          $("#upload_btn20_PLRentAgreement").show();
          $("#upload_btnloader_PLRentAgreement").hide();
          $("#PLRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLRentAgreement = () => {
    $("#X-btn_PLRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLRentAgreementStatus = res.data.data.status;
          PLOptStatusArray[1].status = PLRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLRentAgreement").show();
          $("#X-btn_PLRentAgreement").hide();
          $("#X-btn_PLRentAgreement").attr("disabled", false);
          $("#viewDocLink_PLRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLRentAgreementseecommentsbtn").hide();
          $("#PLRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLRentAgreementhidecommentsbtn = () => {
    $("#PLRentAgreementseecommentsbtn").show();
    $("#PLRentAgreementseecommentsbtn").attr("disabled", false);
    $("#PLRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLOwnerDeed
  handlePLOwnerDeedChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#PLOwnerDeedModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#PLOwnerDeedModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLOwnerDeed = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLOwnerDeed").attr("disabled", true);
      $("#upload_btn20_PLOwnerDeed").hide();
      $("#upload_btnloader_PLOwnerDeed").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLOwnerDeedDocLink: res.data.data.OwnershipDeed,
                },
                () => $("#viewDocLink_PLOwnerDeed").css("display", "block")
              );
              PLOwnerDeedStatus = "remove";
              PLOptStatusArray[0].status = PLOwnerDeedStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLOwnerDeedModal").modal("hide");
              $("#cancelbtn_PLOwnerDeed").attr("disabled", false);
              $("#upload_btn20_PLOwnerDeed").show();
              $("#upload_btnloader_PLOwnerDeed").hide();
              $("#choose_PLOwnerDeed").hide();
              $("#X-btn_PLOwnerDeed").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLOwnerDeed").attr("disabled", false);
            $("#upload_btn20_PLOwnerDeed").show();
            $("#upload_btnloader_PLOwnerDeed").hide();
            $("#PLOwnerDeedModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLOwnerDeed").attr("disabled", false);
            $("#upload_btn20_PLOwnerDeed").show();
            $("#upload_btnloader_PLOwnerDeed").hide();
            $("#PLOwnerDeedModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLOwnerDeed").attr("disabled", false);
          $("#upload_btn20_PLOwnerDeed").show();
          $("#upload_btnloader_PLOwnerDeed").hide();
          $("#PLOwnerDeedModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLOwnerDeed = () => {
    $("#X-btn_PLOwnerDeed").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLOwnerDeedStatus = res.data.data.status;
          PLOptStatusArray[0].status = PLOwnerDeedStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLOwnerDeed").show();
          $("#X-btn_PLOwnerDeed").hide();
          $("#X-btn_PLOwnerDeed").attr("disabled", false);
          $("#viewDocLink_PLOwnerDeed").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLOwnerDeed").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLOwnerDeed").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLOwnerDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLOwnerDeedseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "OwnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLOwnerDeedseecommentsbtn").hide();
          $("#PLOwnerDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLOwnerDeedhidecommentsbtn = () => {
    $("#PLOwnerDeedseecommentsbtn").show();
    $("#PLOwnerDeedseecommentsbtn").attr("disabled", false);
    $("#PLOwnerDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAdditional
  handlePLAddFileChange = (e, index, status) => {
    PLadditionalReuploadIndex = index;
    PLadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!PLadditionalFilesNames.includes(this.state.PLAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.PLAdd_DocName,
            },
            () => {
              $(".PLAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.PLAdd_DocName,
              });
            }
          });
        $(".PLAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPLFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PLadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".PLAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PLAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}PLFileseecommentsbtn`).attr("disabled", false);
            if (PLadditionalReupload == false) {
              PLadditionalFilesNames.push(this.state.PLAdd_DocName);
              this.state.PLadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PLadditionalFiles: [
                    ...this.state.PLadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PLadditionalFiles: "Yes",
                    },
                    () => {
                      this.PLadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePLProceedBtn();
                    }
                  );

                  $(".PLadditionalInput").attr("disabled", true);
                }
              );
            } else if (PLadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PLadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PLadditionalFiles: "Yes",
                  PLadditionalFiles: array,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PLAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PLAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.PLadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PLadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PLadditionalFiles: array,
              },
              () => {
                this.PLadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePLProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PLadditionalFiles: array,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PLadditionalFiles: [],
                  PLAdd_DocName: "",
                  api_PLadditionalFiles: "No",
                  IsPLAddFileDisabled: true,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PLFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PLadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PLFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PLFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PLFileseecommentsbtn`).show();
    $(`#${index}PLFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PLFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPLFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn PLFileseecommentsbtn"
            id={`${FileIndex}PLFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePLFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PLFilehidecommentsbtn"
            id={`${FileIndex}PLFilehidecommentsbtn`}
            onClick={() => this.handlePLFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PLFileseecommentsbtn"
            id={`${FileIndex}PLFileseecommentsbtn`}
            onClick={() => this.handlePLFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PLFilehidecommentsbtn"
            id={`${FileIndex}PLFilehidecommentsbtn`}
            onClick={() => this.handlePLFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPLAddFiles = (status, link, index) => {
    PLadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PLadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PLAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PLAdd${index}`}
              // disabled={this.state.IsPLAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePLAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removePLFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PLadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PLAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PLAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePLAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalPLFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PLadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePLAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPLFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PLadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePLAdd_DocNameChange(e)}
      />
    );
  };

  PLadditionalFilesArea = () => {
    PLadditionalFilesNames = [];
    if (this.state.api_PLadditionalFiles == "Yes") {
      return this.state.PLadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PLadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPLFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPLFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPLFiles(index, commentDisabled)}
                    {this.checkStatusPLAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PLadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PLAdd_DocName}
                  onChange={(e) => this.handlePLAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPLFiles(0, true)}
                  {this.checkStatusPLAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePLAdd_DocNameChange = (e) => {
    this.setState(
      {
        PLAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PLAdd_DocName.trim().length > 0) {
          this.setState({
            IsPLAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPLAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddPLFileBtn = (FileIndex) => {
    const { PLAdd_DocName, PLadditionalFiles } = this.state;

    let prevFile = PLadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PLAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PLadditionalFiles: [...this.state.PLadditionalFiles, ""],
          PLAdd_DocName: "",
          IsPLAddFileDisabled: true,
        },
        () => {
          $(".PLadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  PLcheckRemainingReuploads = () => {
    if (
      PLAadhar1Status != "reupload" &&
      PLAadhar2Status != "reupload" &&
      PLPic1Status != "reupload" &&
      PLPic2Status != "reupload" &&
      PLSelfDeclStatus != "reupload" &&
      PLTaxReceiptStatus != "reupload" &&
      PLCIStatus != "reupload" &&
      PLMOAStatus != "reupload" &&
      PLAOAStatus != "reupload" &&
      PLPremisePicStatus != "reupload" &&
      PLRentAgreementStatus != "reupload" &&
      PLOwnerDeedStatus != "reupload"
    ) {
      AnyReuploadOccuredPL = "No";
    } else {
      $(".proceednowbtn3").show();
      AnyReuploadOccuredPL = "Yes";
    }
  };

  checkhandlePLProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      PLStatusArray
    );
    let PLadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PLadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.PLAddressProofType_radio == "OwnershipDeed") {
      if (
        PLOptStatusArray[0].status != "reupload" &&
        PLOptStatusArray[0].status != "upload" &&
        PLOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.PLAddressProofType_radio == "RentAgreement") {
      if (
        PLOptStatusArray[1].status != "reupload" &&
        PLOptStatusArray[1].status != "upload" &&
        PLOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredPL == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      PLadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PLproceednowforword = "success";
    } else if (
      PLAadhar1Status == "remove" &&
      PLAadhar2Status == "remove" &&
      PLPic1Status == "remove" &&
      PLPic2Status == "remove" &&
      PLSelfDeclStatus == "remove" &&
      PLTaxReceiptStatus == "remove" &&
      PLPremisePicStatus == "remove" &&
      PLCIStatus == "remove" &&
      PLMOAStatus == "remove" &&
      PLAOAStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PLproceednowforword = "success";
    } else {
      $(".proceednowbtn3").attr("disabled", true);
      PLproceednowforword = "fail";
    }
  };

  handlePLProceedBtn = () => {
    this.checkhandlePLProceedBtn();
    if (this.state.PLbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.PLbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (PLproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.PLAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.PLbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  // HUF

  // HUFAadhar
  handleHUFAadharChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant",
          },
          () => {
            $("#HUFAadharModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant",
            });
          }
        });
      $("#HUFAadharModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFAadhar = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFAadhar").attr("disabled", true);
      $("#upload_btn20_HUFAadhar").hide();
      $("#upload_btnloader_HUFAadhar").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFAadharDocLink: res.data.data.IDProofApplicant,
                },
                () => $("#viewDocLink_HUFAadhar").css("display", "block")
              );
              HUFAadharStatus = "remove";
              HUFStatusArray[0].status = HUFAadharStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFAadharModal").modal("hide");
              $("#cancelbtn_HUFAadhar").attr("disabled", false);
              $("#upload_btn20_HUFAadhar").show();
              $("#upload_btnloader_HUFAadhar").hide();
              $("#choose_HUFAadhar").hide();
              $("#X-btn_HUFAadhar").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFAadhar").attr("disabled", false);
            $("#upload_btn20_HUFAadhar").show();
            $("#upload_btnloader_HUFAadhar").hide();
            $("#HUFAadharModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFAadhar").attr("disabled", false);
            $("#upload_btn20_HUFAadhar").show();
            $("#upload_btnloader_HUFAadhar").hide();
            $("#HUFAadharModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFAadhar").attr("disabled", false);
          $("#upload_btn20_HUFAadhar").show();
          $("#upload_btnloader_HUFAadhar").hide();
          $("#HUFAadharModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFAadhar = () => {
    $("#X-btn_HUFAadhar").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFAadharStatus = res.data.data.status;
          HUFStatusArray[0].status = HUFAadharStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFAadhar").show();
          $("#X-btn_HUFAadhar").hide();
          $("#X-btn_HUFAadhar").attr("disabled", false);
          $("#viewDocLink_HUFAadhar").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFAadhar").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFAadhar").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFAadharseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFAadharseecommentsbtn").hide();
          $("#HUFAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFAadharhidecommentsbtn = () => {
    $("#HUFAadharseecommentsbtn").show();
    $("#HUFAadharseecommentsbtn").attr("disabled", false);
    $("#HUFAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPan1

  // HUFPremisePic
  handleHUFPremisePicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PremisePhoto",
          },
          () => {
            $("#HUFPremisePicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PremisePhoto",
          });
        }
      });
      $("#HUFPremisePicModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFPremisePic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFPremisePic").attr("disabled", true);
      $("#upload_btn20_HUFPremisePic").hide();
      $("#upload_btnloader_HUFPremisePic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFPremisePicDocLink: res.data.data.PremisePhoto,
                },
                () => $("#viewDocLink_HUFPremisePic").css("display", "block")
              );
              HUFPremisePicStatus = "remove";
              HUFStatusArray[3].status = HUFPremisePicStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFPremisePicModal").modal("hide");
              $("#cancelbtn_HUFPremisePic").attr("disabled", false);
              $("#upload_btn20_HUFPremisePic").show();
              $("#upload_btnloader_HUFPremisePic").hide();
              $("#choose_HUFPremisePic").hide();
              $("#X-btn_HUFPremisePic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFPremisePic").attr("disabled", false);
            $("#upload_btn20_HUFPremisePic").show();
            $("#upload_btnloader_HUFPremisePic").hide();
            $("#HUFPremisePicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFPremisePic").attr("disabled", false);
            $("#upload_btn20_HUFPremisePic").show();
            $("#upload_btnloader_HUFPremisePic").hide();
            $("#HUFPremisePicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFPremisePic").attr("disabled", false);
          $("#upload_btn20_HUFPremisePic").show();
          $("#upload_btnloader_HUFPremisePic").hide();
          $("#HUFPremisePicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFPremisePic = () => {
    $("#X-btn_HUFPremisePic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PremisePhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFPremisePicStatus = res.data.data.status;
          HUFStatusArray[3].status = HUFPremisePicStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFPremisePic").show();
          $("#X-btn_HUFPremisePic").hide();
          $("#X-btn_HUFPremisePic").attr("disabled", false);
          $("#viewDocLink_HUFPremisePic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFPremisePic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFPremisePic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFPremisePicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPremisePicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFPremisePicseecommentsbtn").hide();
          $("#HUFPremisePichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPremisePichidecommentsbtn = () => {
    $("#HUFPremisePicseecommentsbtn").show();
    $("#HUFPremisePicseecommentsbtn").attr("disabled", false);
    $("#HUFPremisePichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPic
  handleHUFPicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto",
          },
          () => {
            $("#HUFPicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto",
            });
          }
        });
      $("#HUFPicModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFPic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFPic").attr("disabled", true);
      $("#upload_btn20_HUFPic").hide();
      $("#upload_btnloader_HUFPic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFPicDocLink: res.data.data.ApplicantPhoto,
                },
                () => $("#viewDocLink_HUFPic").css("display", "block")
              );
              HUFPicStatus = "remove";
              HUFStatusArray[1].status = HUFPicStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFPicModal").modal("hide");
              $("#cancelbtn_HUFPic").attr("disabled", false);
              $("#upload_btn20_HUFPic").show();
              $("#upload_btnloader_HUFPic").hide();
              $("#choose_HUFPic").hide();
              $("#X-btn_HUFPic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFPic").attr("disabled", false);
            $("#upload_btn20_HUFPic").show();
            $("#upload_btnloader_HUFPic").hide();
            $("#HUFPicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFPic").attr("disabled", false);
            $("#upload_btn20_HUFPic").show();
            $("#upload_btnloader_HUFPic").hide();
            $("#HUFPicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFPic").attr("disabled", false);
          $("#upload_btn20_HUFPic").show();
          $("#upload_btnloader_HUFPic").hide();
          $("#HUFPicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFPic = () => {
    $("#X-btn_HUFPic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFPicStatus = res.data.data.status;
          HUFStatusArray[1].status = HUFPicStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFPic").show();
          $("#X-btn_HUFPic").hide();
          $("#X-btn_HUFPic").attr("disabled", false);
          $("#viewDocLink_HUFPic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFPic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFPic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFPicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFPicseecommentsbtn").hide();
          $("#HUFPichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPichidecommentsbtn = () => {
    $("#HUFPicseecommentsbtn").show();
    $("#HUFPicseecommentsbtn").attr("disabled", false);
    $("#HUFPichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPan2

  // HUFDeed
  handleHUFDeedChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "HUFDeedDocument",
          },
          () => {
            $("#HUFDeedModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "HUFDeedDocument",
            });
          }
        });
      $("#HUFDeedModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFDeed = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFDeed").attr("disabled", true);
      $("#upload_btn20_HUFDeed").hide();
      $("#upload_btnloader_HUFDeed").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFDeedDocLink: res.data.data.HUFDeedDocument,
                },
                () => $("#viewDocLink_HUFDeed").css("display", "block")
              );
              HUFDeedStatus = "remove";
              HUFStatusArray[2].status = HUFDeedStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFDeedModal").modal("hide");
              $("#cancelbtn_HUFDeed").attr("disabled", false);
              $("#upload_btn20_HUFDeed").show();
              $("#upload_btnloader_HUFDeed").hide();
              $("#choose_HUFDeed").hide();
              $("#X-btn_HUFDeed").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFDeed").attr("disabled", false);
            $("#upload_btn20_HUFDeed").show();
            $("#upload_btnloader_HUFDeed").hide();
            $("#HUFDeedModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFDeed").attr("disabled", false);
            $("#upload_btn20_HUFDeed").show();
            $("#upload_btnloader_HUFDeed").hide();
            $("#HUFDeedModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFDeed").attr("disabled", false);
          $("#upload_btn20_HUFDeed").show();
          $("#upload_btnloader_HUFDeed").hide();
          $("#HUFDeedModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFDeed = () => {
    $("#X-btn_HUFDeed").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "HUFDeedDocument",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFDeedStatus = res.data.data.status;
          HUFStatusArray[2].status = HUFDeedStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFDeed").show();
          $("#X-btn_HUFDeed").hide();
          $("#X-btn_HUFDeed").attr("disabled", false);
          $("#viewDocLink_HUFDeed").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFDeed").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFDeed").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFDeedseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "HUFDeedDocument",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFDeedseecommentsbtn").hide();
          $("#HUFDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFDeedhidecommentsbtn = () => {
    $("#HUFDeedseecommentsbtn").show();
    $("#HUFDeedseecommentsbtn").attr("disabled", false);
    $("#HUFDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFSelfDecl
  handleHUFSelfDeclChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "SelfDeclaration",
          },
          () => {
            $("#HUFSelfDeclModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "SelfDeclaration",
            });
          }
        });
      $("#HUFSelfDeclModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFSelfDecl = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFSelfDecl").attr("disabled", true);
      $("#upload_btn20_HUFSelfDecl").hide();
      $("#upload_btnloader_HUFSelfDecl").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFSelfDeclDocLink: res.data.data.SelfDeclaration,
                },
                () => $("#viewDocLink_HUFSelfDecl").css("display", "block")
              );
              HUFSelfDeclStatus = "remove";
              HUFStatusArray[3].status = HUFSelfDeclStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFSelfDeclModal").modal("hide");
              $("#cancelbtn_HUFSelfDecl").attr("disabled", false);
              $("#upload_btn20_HUFSelfDecl").show();
              $("#upload_btnloader_HUFSelfDecl").hide();
              $("#choose_HUFSelfDecl").hide();
              $("#X-btn_HUFSelfDecl").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFSelfDecl").attr("disabled", false);
            $("#upload_btn20_HUFSelfDecl").show();
            $("#upload_btnloader_HUFSelfDecl").hide();
            $("#HUFSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFSelfDecl").attr("disabled", false);
            $("#upload_btn20_HUFSelfDecl").show();
            $("#upload_btnloader_HUFSelfDecl").hide();
            $("#HUFSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFSelfDecl").attr("disabled", false);
          $("#upload_btn20_HUFSelfDecl").show();
          $("#upload_btnloader_HUFSelfDecl").hide();
          $("#HUFSelfDeclModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFSelfDecl = () => {
    $("#X-btn_HUFSelfDecl").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "SelfDeclaration",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFSelfDeclStatus = res.data.data.status;
          HUFStatusArray[3].status = HUFSelfDeclStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFSelfDecl").show();
          $("#X-btn_HUFSelfDecl").hide();
          $("#X-btn_HUFSelfDecl").attr("disabled", false);
          $("#viewDocLink_HUFSelfDecl").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFSelfDecl").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFSelfDecl").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFSelfDeclseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFSelfDeclseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SelfDeclaration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFSelfDeclseecommentsbtn").hide();
          $("#HUFSelfDeclhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFSelfDeclhidecommentsbtn = () => {
    $("#HUFSelfDeclseecommentsbtn").show();
    $("#HUFSelfDeclseecommentsbtn").attr("disabled", false);
    $("#HUFSelfDeclhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFTaxReceipt
  handleHUFTaxReceiptChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TaxReceipt",
          },
          () => {
            $("#HUFTaxReceiptModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "TaxReceipt",
            });
          }
        });
      $("#HUFTaxReceiptModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFTaxReceipt = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFTaxReceipt").attr("disabled", true);
      $("#upload_btn20_HUFTaxReceipt").hide();
      $("#upload_btnloader_HUFTaxReceipt").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFTaxReceiptDocLink: res.data.data.TaxReceipt,
                },
                () => $("#viewDocLink_HUFTaxReceipt").css("display", "block")
              );
              HUFTaxReceiptStatus = "remove";
              HUFStatusArray[4].status = HUFTaxReceiptStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFTaxReceiptModal").modal("hide");
              $("#cancelbtn_HUFTaxReceipt").attr("disabled", false);
              $("#upload_btn20_HUFTaxReceipt").show();
              $("#upload_btnloader_HUFTaxReceipt").hide();
              $("#choose_HUFTaxReceipt").hide();
              $("#X-btn_HUFTaxReceipt").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFTaxReceipt").attr("disabled", false);
            $("#upload_btn20_HUFTaxReceipt").show();
            $("#upload_btnloader_HUFTaxReceipt").hide();
            $("#HUFTaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFTaxReceipt").attr("disabled", false);
            $("#upload_btn20_HUFTaxReceipt").show();
            $("#upload_btnloader_HUFTaxReceipt").hide();
            $("#HUFTaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFTaxReceipt").attr("disabled", false);
          $("#upload_btn20_HUFTaxReceipt").show();
          $("#upload_btnloader_HUFTaxReceipt").hide();
          $("#HUFTaxReceiptModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFTaxReceipt = () => {
    $("#X-btn_HUFTaxReceipt").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFTaxReceiptStatus = res.data.data.status;
          HUFStatusArray[4].status = HUFTaxReceiptStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFTaxReceipt").show();
          $("#X-btn_HUFTaxReceipt").hide();
          $("#X-btn_HUFTaxReceipt").attr("disabled", false);
          $("#viewDocLink_HUFTaxReceipt").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFTaxReceipt").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFTaxReceipt").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFTaxReceiptseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFTaxReceiptseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFTaxReceiptseecommentsbtn").hide();
          $("#HUFTaxReceipthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFTaxReceipthidecommentsbtn = () => {
    $("#HUFTaxReceiptseecommentsbtn").show();
    $("#HUFTaxReceiptseecommentsbtn").attr("disabled", false);
    $("#HUFTaxReceipthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFGumasta

  // HUFTradeLicence
  handleHUFbusinessNatureChange = (e) => {
    this.setState({
      HUFbusinessNature: e.target.value,
    });
  };

  // HUFAddressProofType
  HUFAddressProofType_radioChange = (e) => {
    this.setState(
      {
        HUFAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.HUFAddressProofType_radio != "OwnershipDeed") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.HUFAddressProofType_radio == "OwnershipDeed") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleHUFProceedBtn();
      }
    );
  };

  // HUFResidentialCopyOfRegistry

  // HUFCopyOfRegistry
  handleHUFCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#HUFCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#HUFCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_HUFCopyOfRegistry").hide();
      $("#upload_btnloader_HUFCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFCopyOfRegistryDocLink: res.data.data.OwnershipDeed,
                },
                () =>
                  $("#viewDocLink_HUFCopyOfRegistry").css("display", "block")
              );
              HUFCopyOfRegistryStatus = "remove";
              HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_HUFCopyOfRegistry").show();
              $("#upload_btnloader_HUFCopyOfRegistry").hide();
              $("#choose_HUFCopyOfRegistry").hide();
              $("#X-btn_HUFCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_HUFCopyOfRegistry").show();
            $("#upload_btnloader_HUFCopyOfRegistry").hide();
            $("#HUFCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_HUFCopyOfRegistry").show();
            $("#upload_btnloader_HUFCopyOfRegistry").hide();
            $("#HUFCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_HUFCopyOfRegistry").show();
          $("#upload_btnloader_HUFCopyOfRegistry").hide();
          $("#HUFCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFCopyOfRegistry = () => {
    $("#X-btn_HUFCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFCopyOfRegistryStatus = res.data.data.status;
          HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFCopyOfRegistry").show();
          $("#X-btn_HUFCopyOfRegistry").hide();
          $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_HUFCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "OwnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFCopyOfRegistryseecommentsbtn").hide();
          $("#HUFCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFCopyOfRegistryhidecommentsbtn = () => {
    $("#HUFCopyOfRegistryseecommentsbtn").show();
    $("#HUFCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#HUFCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFResidentialRentAgreement

  // HUFRentAgreement
  handleHUFRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#HUFRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#HUFRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFRentAgreement").attr("disabled", true);
      $("#upload_btn20_HUFRentAgreement").hide();
      $("#upload_btnloader_HUFRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_HUFRentAgreement").css("display", "block")
              );
              HUFRentAgreementStatus = "remove";
              HUFOptStatusArray[1].status = HUFRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFRentAgreementModal").modal("hide");
              $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
              $("#upload_btn20_HUFRentAgreement").show();
              $("#upload_btnloader_HUFRentAgreement").hide();
              $("#choose_HUFRentAgreement").hide();
              $("#X-btn_HUFRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
            $("#upload_btn20_HUFRentAgreement").show();
            $("#upload_btnloader_HUFRentAgreement").hide();
            $("#HUFRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
            $("#upload_btn20_HUFRentAgreement").show();
            $("#upload_btnloader_HUFRentAgreement").hide();
            $("#HUFRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
          $("#upload_btn20_HUFRentAgreement").show();
          $("#upload_btnloader_HUFRentAgreement").hide();
          $("#HUFRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFRentAgreement = () => {
    $("#X-btn_HUFRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFRentAgreementStatus = res.data.data.status;
          HUFOptStatusArray[1].status = HUFRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFRentAgreement").show();
          $("#X-btn_HUFRentAgreement").hide();
          $("#X-btn_HUFRentAgreement").attr("disabled", false);
          $("#viewDocLink_HUFRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $("#X-btn_HUFRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFRentAgreementseecommentsbtn").hide();
          $("#HUFRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFRentAgreementhidecommentsbtn = () => {
    $("#HUFRentAgreementseecommentsbtn").show();
    $("#HUFRentAgreementseecommentsbtn").attr("disabled", false);
    $("#HUFRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFAdditional
  handleHUFAddFileChange = (e, index, status) => {
    HUFadditionalReuploadIndex = index;
    HUFadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!HUFadditionalFilesNames.includes(this.state.HUFAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.HUFAdd_DocName,
            },
            () => {
              $(".HUFAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.HUFAdd_DocName,
              });
            }
          });
        $(".HUFAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadHUFFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.HUFadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".HUFAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".HUFAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}HUFFileseecommentsbtn`).attr("disabled", false);
            if (HUFadditionalReupload == false) {
              HUFadditionalFilesNames.push(this.state.HUFAdd_DocName);
              this.state.HUFadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  HUFadditionalFiles: [
                    ...this.state.HUFadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_HUFadditionalFiles: "Yes",
                    },
                    () => {
                      this.HUFadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleHUFProceedBtn();
                    }
                  );

                  $(".HUFadditionalInput").attr("disabled", true);
                }
              );
            } else if (HUFadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.HUFadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_HUFadditionalFiles: "Yes",
                  HUFadditionalFiles: array,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".HUFAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".HUFAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.HUFadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.HUFadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                HUFadditionalFiles: array,
              },
              () => {
                this.HUFadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleHUFProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  HUFadditionalFiles: array,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  HUFadditionalFiles: [],
                  HUFAdd_DocName: "",
                  api_HUFadditionalFiles: "No",
                  IsHUFAddFileDisabled: true,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}HUFFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.HUFadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}HUFFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}HUFFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}HUFFileseecommentsbtn`).show();
    $(`#${index}HUFFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}HUFFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentHUFFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn HUFFileseecommentsbtn"
            id={`${FileIndex}HUFFileseecommentsbtn`}
            disabled
            onClick={() => this.handleHUFFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn HUFFilehidecommentsbtn"
            id={`${FileIndex}HUFFilehidecommentsbtn`}
            onClick={() => this.handleHUFFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn HUFFileseecommentsbtn"
            id={`${FileIndex}HUFFileseecommentsbtn`}
            onClick={() => this.handleHUFFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn HUFFilehidecommentsbtn"
            id={`${FileIndex}HUFFilehidecommentsbtn`}
            onClick={() => this.handleHUFFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusHUFAddFiles = (status, link, index) => {
    HUFadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        HUFadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_HUFAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_HUFAdd${index}`}
              // disabled={this.state.IsHUFAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleHUFAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeHUFFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        HUFadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_HUFAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_HUFAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleHUFAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalHUFFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero HUFadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalHUFFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero HUFadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
      />
    );
  };

  HUFadditionalFilesArea = () => {
    HUFadditionalFilesNames = [];
    if (this.state.api_HUFadditionalFiles == "Yes") {
      return this.state.HUFadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        HUFadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalHUFFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalHUFFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2" key={index}>
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentHUFFiles(index, commentDisabled)}
                    {this.checkStatusHUFAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_HUFadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.HUFAdd_DocName}
                  onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentHUFFiles(0, true)}
                  {this.checkStatusHUFAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleHUFAdd_DocNameChange = (e) => {
    this.setState(
      {
        HUFAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.HUFAdd_DocName.trim().length > 0) {
          this.setState({
            IsHUFAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsHUFAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddHUFFileBtn = (FileIndex) => {
    const { HUFAdd_DocName, HUFadditionalFiles } = this.state;

    let prevFile = HUFadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (HUFAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          HUFadditionalFiles: [...this.state.HUFadditionalFiles, ""],
          HUFAdd_DocName: "",
          IsHUFAddFileDisabled: true,
        },
        () => {
          $(".HUFadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  HUFcheckRemainingReuploads = () => {
    if (
      HUFAadharStatus != "reupload" &&
      HUFPicStatus != "reupload" &&
      HUFDeedStatus != "reupload" &&
      HUFSelfDeclStatus != "reupload" &&
      HUFPremisePicStatus != "reupload" &&
      HUFTaxReceiptStatus != "reupload" &&
      HUFCopyOfRegistryStatus != "reupload" &&
      HUFRentAgreementStatus != "reupload"
    ) {
      AnyReuploadOccuredHUF = "No";
    } else {
      $(".proceednowbtn4").show();
      AnyReuploadOccuredHUF = "Yes";
    }
  };

  checkhandleHUFProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      HUFStatusArray
    );
    let HUFadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.HUFadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.HUFAddressProofType_radio == "OwnershipDeed") {
      if (
        HUFOptStatusArray[0].status != "reupload" &&
        HUFOptStatusArray[0].status != "upload" &&
        HUFOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.HUFAddressProofType_radio == "RentAgreement") {
      if (
        HUFOptStatusArray[1].status != "reupload" &&
        HUFOptStatusArray[1].status != "upload" &&
        HUFOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredHUF == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      HUFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      HUFproceednowforword = "success";
    } else if (
      HUFAadharStatus == "remove" &&
      HUFPicStatus == "remove" &&
      HUFDeedStatus == "remove" &&
      HUFSelfDeclStatus == "remove" &&
      HUFPremisePicStatus == "remove" &&
      HUFTaxReceiptStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      HUFproceednowforword = "success";
    } else {
      $(".proceednowbtn4").attr("disabled", true);
      HUFproceednowforword = "fail";
    }
  };

  handleHUFProceedBtn = () => {
    this.checkhandleHUFProceedBtn();
    if (this.state.HUFbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.HUFbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (HUFproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.HUFAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.HUFbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  // ST

  // STAadhar1
  handleSTAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant1",
          },
          () => {
            $("#STAadhar1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant1",
            });
          }
        });
      $("#STAadhar1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STAadhar1").attr("disabled", true);
      $("#upload_btn20_STAadhar1").hide();
      $("#upload_btnloader_STAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STAadhar1DocLink: res.data.data.AadhaarCardApplicant1,
                },
                () => $("#viewDocLink_STAadhar1").css("display", "block")
              );
              STAadhar1Status = "remove";
              STStatusArray[2].status = STAadhar1Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STAadhar1Modal").modal("hide");
              $("#cancelbtn_STAadhar1").attr("disabled", false);
              $("#upload_btn20_STAadhar1").show();
              $("#upload_btnloader_STAadhar1").hide();
              $("#choose_STAadhar1").hide();
              $("#X-btn_STAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STAadhar1").attr("disabled", false);
            $("#upload_btn20_STAadhar1").show();
            $("#upload_btnloader_STAadhar1").hide();
            $("#STAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STAadhar1").attr("disabled", false);
            $("#upload_btn20_STAadhar1").show();
            $("#upload_btnloader_STAadhar1").hide();
            $("#STAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STAadhar1").attr("disabled", false);
          $("#upload_btn20_STAadhar1").show();
          $("#upload_btnloader_STAadhar1").hide();
          $("#STAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTAadhar1 = () => {
    $("#X-btn_STAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STAadhar1Status = res.data.data.status;
          STStatusArray[2].status = STAadhar1Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STAadhar1").show();
          $("#X-btn_STAadhar1").hide();
          $("#X-btn_STAadhar1").attr("disabled", false);
          $("#viewDocLink_STAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STAadhar1seecommentsbtn").hide();
          $("#STAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTAadhar1hidecommentsbtn = () => {
    $("#STAadhar1seecommentsbtn").show();
    $("#STAadhar1seecommentsbtn").attr("disabled", false);
    $("#STAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STAadhar2
  handleSTAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant2",
          },
          () => {
            $("#STAadhar2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant2",
            });
          }
        });
      $("#STAadhar2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STAadhar2").attr("disabled", true);
      $("#upload_btn20_STAadhar2").hide();
      $("#upload_btnloader_STAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STAadhar2DocLink: res.data.data.AadhaarCardApplicant2,
                },
                () => $("#viewDocLink_STAadhar2").css("display", "block")
              );
              STAadhar2Status = "remove";
              STStatusArray[3].status = STAadhar2Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STAadhar2Modal").modal("hide");
              $("#cancelbtn_STAadhar2").attr("disabled", false);
              $("#upload_btn20_STAadhar2").show();
              $("#upload_btnloader_STAadhar2").hide();
              $("#choose_STAadhar2").hide();
              $("#X-btn_STAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STAadhar2").attr("disabled", false);
            $("#upload_btn20_STAadhar2").show();
            $("#upload_btnloader_STAadhar2").hide();
            $("#STAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STAadhar2").attr("disabled", false);
            $("#upload_btn20_STAadhar2").show();
            $("#upload_btnloader_STAadhar2").hide();
            $("#STAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STAadhar2").attr("disabled", false);
          $("#upload_btn20_STAadhar2").show();
          $("#upload_btnloader_STAadhar2").hide();
          $("#STAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTAadhar2 = () => {
    $("#X-btn_STAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STAadhar2Status = res.data.data.status;
          STStatusArray[3].status = STAadhar2Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STAadhar2").show();
          $("#X-btn_STAadhar2").hide();
          $("#X-btn_STAadhar2").attr("disabled", false);
          $("#viewDocLink_STAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STAadhar2seecommentsbtn").hide();
          $("#STAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTAadhar2hidecommentsbtn = () => {
    $("#STAadhar2seecommentsbtn").show();
    $("#STAadhar2seecommentsbtn").attr("disabled", false);
    $("#STAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPan1

  // STPan2

  // STPic1
  handleSTPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#STPic1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto1",
            });
          }
        });
      $("#STPic1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPic1").attr("disabled", true);
      $("#upload_btn20_STPic1").hide();
      $("#upload_btnloader_STPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_STPic1").css("display", "block")
              );
              STPic1Status = "remove";
              STStatusArray[0].status = STPic1Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPic1Modal").modal("hide");
              $("#cancelbtn_STPic1").attr("disabled", false);
              $("#upload_btn20_STPic1").show();
              $("#upload_btnloader_STPic1").hide();
              $("#choose_STPic1").hide();
              $("#X-btn_STPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPic1").attr("disabled", false);
            $("#upload_btn20_STPic1").show();
            $("#upload_btnloader_STPic1").hide();
            $("#STPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPic1").attr("disabled", false);
            $("#upload_btn20_STPic1").show();
            $("#upload_btnloader_STPic1").hide();
            $("#STPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPic1").attr("disabled", false);
          $("#upload_btn20_STPic1").show();
          $("#upload_btnloader_STPic1").hide();
          $("#STPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPic1 = () => {
    $("#X-btn_STPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPic1Status = res.data.data.status;
          STStatusArray[0].status = STPic1Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPic1").show();
          $("#X-btn_STPic1").hide();
          $("#X-btn_STPic1").attr("disabled", false);
          $("#viewDocLink_STPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPic1seecommentsbtn").hide();
          $("#STPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPic1hidecommentsbtn = () => {
    $("#STPic1seecommentsbtn").show();
    $("#STPic1seecommentsbtn").attr("disabled", false);
    $("#STPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPic2
  handleSTPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#STPic2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto2",
            });
          }
        });
      $("#STPic2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPic2").attr("disabled", true);
      $("#upload_btn20_STPic2").hide();
      $("#upload_btnloader_STPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_STPic2").css("display", "block")
              );
              STPic2Status = "remove";
              STStatusArray[1].status = STPic2Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPic2Modal").modal("hide");
              $("#cancelbtn_STPic2").attr("disabled", false);
              $("#upload_btn20_STPic2").show();
              $("#upload_btnloader_STPic2").hide();
              $("#choose_STPic2").hide();
              $("#X-btn_STPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPic2").attr("disabled", false);
            $("#upload_btn20_STPic2").show();
            $("#upload_btnloader_STPic2").hide();
            $("#STPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPic2").attr("disabled", false);
            $("#upload_btn20_STPic2").show();
            $("#upload_btnloader_STPic2").hide();
            $("#STPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPic2").attr("disabled", false);
          $("#upload_btn20_STPic2").show();
          $("#upload_btnloader_STPic2").hide();
          $("#STPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPic2 = () => {
    $("#X-btn_STPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPic2Status = res.data.data.status;
          STStatusArray[1].status = STPic2Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPic2").show();
          $("#X-btn_STPic2").hide();
          $("#X-btn_STPic2").attr("disabled", false);
          $("#viewDocLink_STPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPic2seecommentsbtn").hide();
          $("#STPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPic2hidecommentsbtn = () => {
    $("#STPic2seecommentsbtn").show();
    $("#STPic2seecommentsbtn").attr("disabled", false);
    $("#STPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STSelfDecl
  handleSTSelfDeclChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "SelfDeclaration",
          },
          () => {
            $("#STSelfDeclModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "SelfDeclaration",
            });
          }
        });
      $("#STSelfDeclModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTSelfDecl = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STSelfDecl").attr("disabled", true);
      $("#upload_btn20_STSelfDecl").hide();
      $("#upload_btnloader_STSelfDecl").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STSelfDeclDocLink: res.data.data.PANCardSTC,
                },
                () => $("#viewDocLink_STSelfDecl").css("display", "block")
              );
              STSelfDeclStatus = "remove";
              STStatusArray[4].status = STSelfDeclStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STSelfDeclModal").modal("hide");
              $("#cancelbtn_STSelfDecl").attr("disabled", false);
              $("#upload_btn20_STSelfDecl").show();
              $("#upload_btnloader_STSelfDecl").hide();
              $("#choose_STSelfDecl").hide();
              $("#X-btn_STSelfDecl").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STSelfDecl").attr("disabled", false);
            $("#upload_btn20_STSelfDecl").show();
            $("#upload_btnloader_STSelfDecl").hide();
            $("#STSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STSelfDecl").attr("disabled", false);
            $("#upload_btn20_STSelfDecl").show();
            $("#upload_btnloader_STSelfDecl").hide();
            $("#STSelfDeclModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STSelfDecl").attr("disabled", false);
          $("#upload_btn20_STSelfDecl").show();
          $("#upload_btnloader_STSelfDecl").hide();
          $("#STSelfDeclModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTSelfDecl = () => {
    $("#X-btn_STSelfDecl").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "SelfDeclaration",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STSelfDeclStatus = res.data.data.status;
          STStatusArray[4].status = STSelfDeclStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STSelfDecl").show();
          $("#X-btn_STSelfDecl").hide();
          $("#X-btn_STSelfDecl").attr("disabled", false);
          $("#viewDocLink_STSelfDecl").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STSelfDecl").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STSelfDecl").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTSelfDeclseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STSelfDeclseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SelfDeclaration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STSelfDeclseecommentsbtn").hide();
          $("#STSelfDeclhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTSelfDeclhidecommentsbtn = () => {
    $("#STSelfDeclseecommentsbtn").show();
    $("#STSelfDeclseecommentsbtn").attr("disabled", false);
    $("#STSelfDeclhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STTaxReceipt
  handleSTTaxReceiptChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TaxReceipt",
          },
          () => {
            $("#STTaxReceiptModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "TaxReceipt",
            });
          }
        });
      $("#STTaxReceiptModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTTaxReceipt = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STTaxReceipt").attr("disabled", true);
      $("#upload_btn20_STTaxReceipt").hide();
      $("#upload_btnloader_STTaxReceipt").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STTaxReceiptDocLink: res.data.data.PANCardSTC,
                },
                () => $("#viewDocLink_STTaxReceipt").css("display", "block")
              );
              STTaxReceiptStatus = "remove";
              STStatusArray[6].status = STTaxReceiptStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STTaxReceiptModal").modal("hide");
              $("#cancelbtn_STTaxReceipt").attr("disabled", false);
              $("#upload_btn20_STTaxReceipt").show();
              $("#upload_btnloader_STTaxReceipt").hide();
              $("#choose_STTaxReceipt").hide();
              $("#X-btn_STTaxReceipt").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STTaxReceipt").attr("disabled", false);
            $("#upload_btn20_STTaxReceipt").show();
            $("#upload_btnloader_STTaxReceipt").hide();
            $("#STTaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STTaxReceipt").attr("disabled", false);
            $("#upload_btn20_STTaxReceipt").show();
            $("#upload_btnloader_STTaxReceipt").hide();
            $("#STTaxReceiptModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STTaxReceipt").attr("disabled", false);
          $("#upload_btn20_STTaxReceipt").show();
          $("#upload_btnloader_STTaxReceipt").hide();
          $("#STTaxReceiptModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTTaxReceipt = () => {
    $("#X-btn_STTaxReceipt").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STTaxReceiptStatus = res.data.data.status;
          STStatusArray[6].status = STTaxReceiptStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STTaxReceipt").show();
          $("#X-btn_STTaxReceipt").hide();
          $("#X-btn_STTaxReceipt").attr("disabled", false);
          $("#viewDocLink_STTaxReceipt").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STTaxReceipt").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STTaxReceipt").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTTaxReceiptseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STTaxReceiptseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STTaxReceiptseecommentsbtn").hide();
          $("#STTaxReceipthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTTaxReceipthidecommentsbtn = () => {
    $("#STTaxReceiptseecommentsbtn").show();
    $("#STTaxReceiptseecommentsbtn").attr("disabled", false);
    $("#STTaxReceipthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPan3
  handleSTPremisePicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PremisePhoto",
          },
          () => {
            $("#STPremisePicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PremisePhoto",
          });
        }
      });
      $("#STPremisePicModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPremisePic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPremisePic").attr("disabled", true);
      $("#upload_btn20_STPremisePic").hide();
      $("#upload_btnloader_STPremisePic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPremisePicDocLink: res.data.data.PANCardSTC,
                },
                () => $("#viewDocLink_STPremisePic").css("display", "block")
              );
              STPremisePicStatus = "remove";
              STStatusArray[4].status = STPremisePicStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPremisePicModal").modal("hide");
              $("#cancelbtn_STPremisePic").attr("disabled", false);
              $("#upload_btn20_STPremisePic").show();
              $("#upload_btnloader_STPremisePic").hide();
              $("#choose_STPremisePic").hide();
              $("#X-btn_STPremisePic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPremisePic").attr("disabled", false);
            $("#upload_btn20_STPremisePic").show();
            $("#upload_btnloader_STPremisePic").hide();
            $("#STPremisePicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPremisePic").attr("disabled", false);
            $("#upload_btn20_STPremisePic").show();
            $("#upload_btnloader_STPremisePic").hide();
            $("#STPremisePicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPremisePic").attr("disabled", false);
          $("#upload_btn20_STPremisePic").show();
          $("#upload_btnloader_STPremisePic").hide();
          $("#STPremisePicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPremisePic = () => {
    $("#X-btn_STPremisePic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PremisePhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPremisePicStatus = res.data.data.status;
          STStatusArray[4].status = STPremisePicStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPremisePic").show();
          $("#X-btn_STPremisePic").hide();
          $("#X-btn_STPremisePic").attr("disabled", false);
          $("#viewDocLink_STPremisePic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPremisePic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPremisePic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPremisePicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPremisePicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PremisePhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPremisePicseecommentsbtn").hide();
          $("#STPremisePichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPremisePichidecommentsbtn = () => {
    $("#STPremisePicseecommentsbtn").show();
    $("#STPremisePicseecommentsbtn").attr("disabled", false);
    $("#STPremisePichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STCR
  handleSTCRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CertificateOfRegistration",
          },
          () => {
            $("#STCRModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "CertificateOfRegistration",
            });
          }
        });
      $("#STCRModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTCR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STCR").attr("disabled", true);
      $("#upload_btn20_STCR").hide();
      $("#upload_btnloader_STCR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STCRDocLink: res.data.data.CertificateOfRegistration,
                },
                () => $("#viewDocLink_STCR").css("display", "block")
              );
              STCRStatus = "remove";
              STStatusArray[5].status = STCRStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STCRModal").modal("hide");
              $("#cancelbtn_STCR").attr("disabled", false);
              $("#upload_btn20_STCR").show();
              $("#upload_btnloader_STCR").hide();
              $("#choose_STCR").hide();
              $("#X-btn_STCR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STCR").attr("disabled", false);
            $("#upload_btn20_STCR").show();
            $("#upload_btnloader_STCR").hide();
            $("#STCRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STCR").attr("disabled", false);
            $("#upload_btn20_STCR").show();
            $("#upload_btnloader_STCR").hide();
            $("#STCRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STCR").attr("disabled", false);
          $("#upload_btn20_STCR").show();
          $("#upload_btnloader_STCR").hide();
          $("#STCRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTCR = () => {
    $("#X-btn_STCR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CertificateOfRegistration",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STCRStatus = res.data.data.status;
          STStatusArray[5].status = STCRStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STCR").show();
          $("#X-btn_STCR").hide();
          $("#X-btn_STCR").attr("disabled", false);
          $("#viewDocLink_STCR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STCR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STCR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTCRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STCRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CertificateOfRegistration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STCRseecommentsbtn").hide();
          $("#STCRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTCRhidecommentsbtn = () => {
    $("#STCRseecommentsbtn").show();
    $("#STCRseecommentsbtn").attr("disabled", false);
    $("#STCRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STBP

  // STGumasta

  // STTradeLicence

  handleSTbusinessNatureChange = (e) => {
    this.setState({
      STbusinessNature: e.target.value,
    });
  };

  // STAddressProofType
  STAddressProofType_radioChange = (e) => {
    this.setState(
      {
        STAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.STAddressProofType_radio != "OwnershipDeed") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.STAddressProofType_radio == "OwnershipDeed") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleSTProceedBtn();
      }
    );
  };

  // STCopyOfRegistry
  handleSTCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#STCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#STCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_STCopyOfRegistry").hide();
      $("#upload_btnloader_STCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STCopyOfRegistryDocLink: res.data.data.OwnershipDeed,
                },
                () => $("#viewDocLink_STCopyOfRegistry").css("display", "block")
              );
              STCopyOfRegistryStatus = "remove";
              STOptStatusArray[0].status = STCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_STCopyOfRegistry").show();
              $("#upload_btnloader_STCopyOfRegistry").hide();
              $("#choose_STCopyOfRegistry").hide();
              $("#X-btn_STCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_STCopyOfRegistry").show();
            $("#upload_btnloader_STCopyOfRegistry").hide();
            $("#STCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_STCopyOfRegistry").show();
            $("#upload_btnloader_STCopyOfRegistry").hide();
            $("#STCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_STCopyOfRegistry").show();
          $("#upload_btnloader_STCopyOfRegistry").hide();
          $("#STCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTCopyOfRegistry = () => {
    $("#X-btn_STCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STCopyOfRegistryStatus = res.data.data.status;
          STOptStatusArray[0].status = STCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STCopyOfRegistry").show();
          $("#X-btn_STCopyOfRegistry").hide();
          $("#X-btn_STCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_STCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "OwnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STCopyOfRegistryseecommentsbtn").hide();
          $("#STCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTCopyOfRegistryhidecommentsbtn = () => {
    $("#STCopyOfRegistryseecommentsbtn").show();
    $("#STCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#STCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STResidentialCopyOfRegistry

  // STRentAgreement
  handleSTRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#STRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#STRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STRentAgreement").attr("disabled", true);
      $("#upload_btn20_STRentAgreement").hide();
      $("#upload_btnloader_STRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_STRentAgreement").css("display", "block")
              );
              STRentAgreementStatus = "remove";
              STOptStatusArray[1].status = STRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STRentAgreementModal").modal("hide");
              $("#cancelbtn_STRentAgreement").attr("disabled", false);
              $("#upload_btn20_STRentAgreement").show();
              $("#upload_btnloader_STRentAgreement").hide();
              $("#choose_STRentAgreement").hide();
              $("#X-btn_STRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STRentAgreement").attr("disabled", false);
            $("#upload_btn20_STRentAgreement").show();
            $("#upload_btnloader_STRentAgreement").hide();
            $("#STRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STRentAgreement").attr("disabled", false);
            $("#upload_btn20_STRentAgreement").show();
            $("#upload_btnloader_STRentAgreement").hide();
            $("#STRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STRentAgreement").attr("disabled", false);
          $("#upload_btn20_STRentAgreement").show();
          $("#upload_btnloader_STRentAgreement").hide();
          $("#STRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTRentAgreement = () => {
    $("#X-btn_STRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STRentAgreementStatus = res.data.data.status;
          STOptStatusArray[1].status = STRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STRentAgreement").show();
          $("#X-btn_STRentAgreement").hide();
          $("#X-btn_STRentAgreement").attr("disabled", false);
          $("#viewDocLink_STRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STRentAgreementseecommentsbtn").hide();
          $("#STRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTRentAgreementhidecommentsbtn = () => {
    $("#STRentAgreementseecommentsbtn").show();
    $("#STRentAgreementseecommentsbtn").attr("disabled", false);
    $("#STRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STResidentialRentAgreement

  // STAdditional
  handleSTAddFileChange = (e, index, status) => {
    STadditionalReuploadIndex = index;
    STadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!STadditionalFilesNames.includes(this.state.STAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.STAdd_DocName,
            },
            () => {
              $(".STAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.STAdd_DocName,
              });
            }
          });
        $(".STAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadSTFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.STadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".STAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".STAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}STFileseecommentsbtn`).attr("disabled", false);
            if (STadditionalReupload == false) {
              STadditionalFilesNames.push(this.state.STAdd_DocName);
              this.state.STadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  STadditionalFiles: [
                    ...this.state.STadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_STadditionalFiles: "Yes",
                    },
                    () => {
                      this.STadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleSTProceedBtn();
                    }
                  );

                  $(".STadditionalInput").attr("disabled", true);
                }
              );
            } else if (STadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.STadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_STadditionalFiles: "Yes",
                  STadditionalFiles: array,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".STAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".STAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.STadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.STadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                STadditionalFiles: array,
              },
              () => {
                this.STadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleSTProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  STadditionalFiles: array,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  STadditionalFiles: [],
                  STAdd_DocName: "",
                  api_STadditionalFiles: "No",
                  IsSTAddFileDisabled: true,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}STFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.STadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}STFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}STFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}STFileseecommentsbtn`).show();
    $(`#${index}STFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}STFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentSTFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn STFileseecommentsbtn"
            id={`${FileIndex}STFileseecommentsbtn`}
            disabled
            onClick={() => this.handleSTFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn STFilehidecommentsbtn"
            id={`${FileIndex}STFilehidecommentsbtn`}
            onClick={() => this.handleSTFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn STFileseecommentsbtn"
            id={`${FileIndex}STFileseecommentsbtn`}
            onClick={() => this.handleSTFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn STFilehidecommentsbtn"
            id={`${FileIndex}STFilehidecommentsbtn`}
            onClick={() => this.handleSTFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusSTAddFiles = (status, link, index) => {
    STadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        STadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_STAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_STAdd${index}`}
              // disabled={this.state.IsSTAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSTAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeSTFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        STadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_STAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_STAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSTAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalSTFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero STadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleSTAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalSTFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero STadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleSTAdd_DocNameChange(e)}
      />
    );
  };

  STadditionalFilesArea = () => {
    STadditionalFilesNames = [];
    if (this.state.api_STadditionalFiles == "Yes") {
      return this.state.STadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        STadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalSTFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalSTFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentSTFiles(index, commentDisabled)}
                    {this.checkStatusSTAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_STadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.STAdd_DocName}
                  onChange={(e) => this.handleSTAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentSTFiles(0, true)}
                  {this.checkStatusSTAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleSTAdd_DocNameChange = (e) => {
    this.setState(
      {
        STAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.STAdd_DocName.trim().length > 0) {
          this.setState({
            IsSTAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsSTAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddSTFileBtn = (FileIndex) => {
    const { STAdd_DocName, STadditionalFiles } = this.state;

    let prevFile = STadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (STAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          STadditionalFiles: [...this.state.STadditionalFiles, ""],
          STAdd_DocName: "",
          IsSTAddFileDisabled: true,
        },
        () => {
          $(".STadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  STcheckRemainingReuploads = () => {
    if (
      STAadhar1Status != "reupload" &&
      STAadhar2Status != "reupload" &&
      STPic1Status != "reupload" &&
      STPic2Status != "reupload" &&
      STSelfDeclStatus != "reupload" &&
      STTaxReceiptStatus != "reupload" &&
      STCRStatus != "reupload" &&
      STPremisePicStatus != "reupload" &&
      STCopyOfRegistryStatus != "reupload" &&
      STRentAgreementStatus != "reupload"
    ) {
      AnyReuploadOccuredST = "No";
    } else {
      $(".proceednowbtn5").show();
      AnyReuploadOccuredST = "Yes";
    }
  };

  checkhandleSTProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      STStatusArray
    );
    let STadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.STadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.STAddressProofType_radio == "OwnershipDeed") {
      if (
        STOptStatusArray[0].status != "reupload" &&
        STOptStatusArray[0].status != "upload" &&
        STOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.STAddressProofType_radio == "RentAgreement") {
      if (
        STOptStatusArray[1].status != "reupload" &&
        STOptStatusArray[1].status != "upload" &&
        STOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }
    if (
      AnyReuploadOccuredST == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      STadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn5").attr("disabled", false);
      STproceednowforword = "success";
    } else if (
      STAadhar1Status == "remove" &&
      STAadhar2Status == "remove" &&
      STPic1Status == "remove" &&
      STPic2Status == "remove" &&
      STPremisePicStatus == "remove" &&
      STSelfDeclStatus == "remove" &&
      STTaxReceiptStatus == "remove" &&
      STCRStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn5").attr("disabled", false);
      STproceednowforword = "success";
    } else {
      $(".proceednowbtn5").attr("disabled", true);
      STproceednowforword = "fail";
    }
  };

  handleSTProceedBtn = () => {
    this.checkhandleSTProceedBtn();
    if (this.state.STbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.STbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (STproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.STAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.STbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };

  handletimeline = () => {
    $("#timeline_btnloader").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=timeLine",
        {
          agentId: Decagentid,
          userId: Decuserid,
          serviceId: Decserviceid,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          if (res.data.timeLine.length > 0) {
            let length = res.data.timeLine.length;
            let ftimeline = res.data.timeLine.filter((items, index) => {
              return index <= this.state.timelcount;
            });
            this.setState({
              filtertimel: ftimeline,
              timelcount: this.state.timelcount + 3,
              Timeline: res.data.timeLine,
              Timelinedata: true,
            });
            this.setState({});
            // .splice(this.state.timelcount,length)
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      });
  };

  handlemoretimeline = () => {
    let ftimeline = this.state.Timeline.filter((items, index) => {
      return index <= this.state.timelcount;
    });
    this.setState({
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3,
    });
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            onClick={this.backbutton}
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/paymentinvoice">Payments Invoices</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/documentrecords">Documents Record</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/addservice">Add service</Link>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
            {this.state.referralPartner ? 
              <div className="uploaddocu">
                <div className="row">
                  {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div> */}
                  <div className="col-lg-9 mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        {/* <p className="uploadtext">Upload Information :-</p> */}
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 selectcateselectpadding">
                        <select
                          className="selectcateselect"
                          value={this.state.dropDownValue}
                          onChange={this.handledropDownValueChange}
                        >
                          <option
                            value="volvo"
                            id="volvo"
                            className="selectcateselectoption2"
                          >
                            Select category...
                          </option>
                          <option
                            value="TL_SolePI_Documents"
                            className="selectcateselectoption"
                          >
                            Sole Proprietorship / Individual
                          </option>
                          <option
                            value="TL_P_LLP_Documents"
                            className="selectcateselectoption"
                          >
                            Partnership / LLP
                          </option>
                          <option
                            value="TL_PRL_PL_OP_Documents"
                            className="selectcateselectoption"
                          >
                            Private limited / Public limited / One person
                            company
                          </option>
                          <option
                            value="TL_HUF_Documents"
                            className="selectcateselectoption"
                          >
                            HUF
                          </option>
                          <option
                            value="TL_STC_Documents"
                            className="selectcateselectoption"
                          >
                            Society / Trust / Club
                          </option>
                        </select>
                      </div>
                    </div>
                    </div>
                    </div>
                    
                    <div className="tabs-content">
                  <div id="tab1" className="tab-content">
                    <div className="row">
                      {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div> */}
                      <div className="col-lg-9 mt-3">
                    {/* None */}
                    <center id="trade_default_Img">
                      <img
                        src={process.env.PUBLIC_URL + "/img/Group_3582.svg"}
                        className="selectcategoryimg"
                      />
                    </center>

                    {/* Sole P/I */}
                    <div0 id="trade_SolePI">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* SPic */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph of the proprietor
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SPicseecommentsbtn"
                                      onClick={this.handleSPicseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SPichidecommentsbtn"
                                      onClick={this.handleSPichidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_3"
                                      className="choose_btn"
                                      id="choose_SPic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_3"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSPicChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SPic"
                                      onClick={(e) => this.removeSPic(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SPic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SPic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SPicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SPic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* SAadhar */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhar Card/Voter ID/ Passport/Pan Card/
                                    Driving License
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr"></p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SAadharseecommentsbtn"
                                      onClick={this.handleSAadharseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SAadharhidecommentsbtn"
                                      onClick={
                                        this.handleSAadharhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_1"
                                      className="choose_btn"
                                      id="choose_SAadhar"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_1"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSAadharChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SAadhar"
                                      onClick={(e) => this.removeSAadhar(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SAadharDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SAadhar"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SSelfDecl */}

                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Self Declaration
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/SelfDeclaration.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SSelfDeclseecommentsbtn"
                                      onClick={
                                        this.handleSSelfDeclseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SSelfDeclhidecommentsbtn"
                                      onClick={
                                        this.handleSSelfDeclhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_2"
                                      className="choose_btn"
                                      id="choose_SSelfDecl"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_2"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSSelfDeclChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SSelfDecl"
                                      onClick={(e) => this.removeSSelfDecl(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SSelfDeclDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SSelfDecl"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Nature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                maxLength={200}
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.SbusinessNature}
                                onChange={this.handleSbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>
                          {/* SBP */}

                          {/* SGumasta */}

                          {/* STradeLicence */}

                          {/* SAddressProofType */}

                          {/* SCopyOfRegistry */}

                          {/* SResidentialRentAgreement */}

                          {/* SResidentialCopyOfRegistry */}

                          {/* STaxReceipt */}

                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STaxReceiptseecommentsbtn"
                                      onClick={
                                        this.handleSTaxReceiptseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STaxReceipthidecommentsbtn"
                                      onClick={
                                        this.handleSTaxReceipthidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_201"
                                      className="choose_btn"
                                      id="choose_STaxReceipt"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_201"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTaxReceiptChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STaxReceipt"
                                      onClick={(e) => this.removeSTaxReceipt(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STaxReceiptDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STaxReceipt"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>

                              <input
                                type="radio"
                                id="test11-11"
                                name="radio-group11"
                                value="OwnershipDeed"
                                checked={
                                  this.state.SAddressProofType_radio ===
                                  "OwnershipDeed"
                                }
                                onChange={this.SAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test11-11"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test12-22"
                                name="radio-group11"
                                value="RentAgreement"
                                checked={
                                  this.state.SAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.SAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test12-22"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* SCopyOfRegistry */}

                          {/* SRentAgreement */}
                          <div className="displayflex RentAgreement ">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol borderHtao">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rental Agreement / NOC from Any Relative and
                                    Ownership Electricity bill
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SRentAgreementseecommentsbtn"
                                      onClick={
                                        this.handleSRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SRentAgreementhidecommentsbtn"
                                      onClick={
                                        this.handleSRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_8"
                                      className="choose_btn"
                                      id="choose_SRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_8"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SRentAgreement"
                                      onClick={(e) =>
                                        this.removeSRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SCopyOfRegistry */}
                          <div className="displayflex  CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Electricity bill/Telephone bill
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SCopyOfRegistryseecommentsbtn"
                                      onClick={
                                        this.handleSCopyOfRegistryseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SCopyOfRegistryhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleSCopyOfRegistryhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_9"
                                      className="choose_btn"
                                      id="choose_SCopyOfRegistry"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_9"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSCopyOfRegistryChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SCopyOfRegistry"
                                      onClick={(e) =>
                                        this.removeSCopyOfRegistry(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SCopyOfRegistryDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SCopyOfRegistry"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* SPremisepic */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Photograph of Shop with name of shop/office
                                    clearly visible
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PremisePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SPremisepicseecommentsbtn"
                                      onClick={
                                        this.handleSPremisepicseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SPremisepichidecommentsbtn"
                                      onClick={
                                        this.handleSPremisepichidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_p1"
                                      className="choose_btn"
                                      id="choose_SPremisepic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_p1"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSPremisepicChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SPremisepic"
                                      onClick={(e) => this.removeSPremisepic(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SPremisepic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SPremisepic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SPremisepicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SPremisepic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SAdditional */}
                          <div id="SadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.SadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="SAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddSFileBtn(
                                      SadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn1"
                          onClick={this.handleSProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div0>
                    {/* Partnership */}
                    <div id="trade_P_LLP">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* PPic1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph (Partner 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPic1seecommentsbtn"
                                      onClick={this.handlePPic1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPic1hidecommentsbtn"
                                      onClick={this.handlePPic1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_14"
                                      className="choose_btn"
                                      id="choose_PPic1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_14"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePPic1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPic1"
                                      onClick={(e) => this.removePPic1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPic1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPic1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PPic2 */}

                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph (Partner 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPic2seecommentsbtn"
                                      onClick={this.handlePPic2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPic2hidecommentsbtn"
                                      onClick={this.handlePPic2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_15"
                                      className="choose_btn"
                                      id="choose_PPic2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_15"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePPic2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPic2"
                                      onClick={(e) => this.removePPic2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPic2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPic2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PAadhar1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                    Driving License (Partner 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr"></p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PAadhar1seecommentsbtn"
                                      onClick={
                                        this.handlePAadhar1seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PAadhar1hidecommentsbtn"
                                      onClick={
                                        this.handlePAadhar1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_10"
                                      className="choose_btn"
                                      id="choose_PAadhar1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_10"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePAadhar1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PAadhar1"
                                      onClick={(e) => this.removePAadhar1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PAadhar1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PAadhar1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PAadhar2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                    Driving License (Partner 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr"></p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PAadhar2seecommentsbtn"
                                      onClick={
                                        this.handlePAadhar2seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PAadhar2hidecommentsbtn"
                                      onClick={
                                        this.handlePAadhar2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_11"
                                      className="choose_btn"
                                      id="choose_PAadhar2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_11"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePAadhar2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PAadhar2"
                                      onClick={(e) => this.removePAadhar2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PAadhar2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PAadhar2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PSelfDecl */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Self Declaration
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/SelfDeclaration.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PSelfDeclseecommentsbtn"
                                      onClick={
                                        this.handlePSelfDeclseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PSelfDeclhidecommentsbtn"
                                      onClick={
                                        this.handlePSelfDeclhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_12"
                                      className="choose_btn"
                                      id="choose_PSelfDecl"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_12"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePSelfDeclChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PSelfDecl"
                                      onClick={(e) => this.removePSelfDecl(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PSelfDeclDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PSelfDecl"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PPDeed */}

                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Partnership Deed
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PartnershipDeed.pdf "
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPDeedseecommentsbtn"
                                      onClick={this.handlePPDeedseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPDeedhidecommentsbtn"
                                      onClick={this.handlePPDeedhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_120"
                                      className="choose_btn"
                                      id="choose_PPDeed"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_120"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePPDeedChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPDeed"
                                      onClick={(e) => this.removePPDeed(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPDeedDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPDeed"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Nature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                maxLength={200}
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.PPbusinessNature}
                                onChange={this.handlePPbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>
                          {/* PAddressProofType */}

                          {/* PResidentialCopyOfRegistry */}

                          {/* PResidentialRentAgreement */}

                          {/* PCopyOfRegistry */}

                          {/* PPDeed */}

                          {/* PBP */}

                          {/* PTaxReceipt */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PTaxReceiptseecommentsbtn"
                                      onClick={
                                        this.handlePTaxReceiptseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PTaxReceipthidecommentsbtn"
                                      onClick={
                                        this.handlePTaxReceipthidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_1201"
                                      className="choose_btn"
                                      id="choose_PTaxReceipt"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_1201"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePTaxReceiptChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PTaxReceipt"
                                      onClick={(e) => this.removePTaxReceipt(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PTaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PTaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PTaxReceiptDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PTaxReceipt"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>

                              <input
                                type="radio"
                                id="test22-11"
                                name="radio-group12"
                                value="OwnershipDeed"
                                checked={
                                  this.state.PAddressProofType_radio ===
                                  "OwnershipDeed"
                                }
                                onChange={this.PAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test22-11"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test22-22"
                                name="radio-group12"
                                value="RentAgreement"
                                checked={
                                  this.state.PAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.PAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test22-22"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* PCopyOfRegistry */}

                          {/* PRentAgreement */}
                          <div className="displayflex  RentAgreement">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol borderHtao">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rental Agreement / NOC from Any Relative and
                                    Ownership Electricity bill
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PRentAgreementseecommentsbtn"
                                      onClick={
                                        this.handlePRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PRentAgreementhidecommentsbtn"
                                      onClick={
                                        this.handlePRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_200"
                                      className="choose_btn"
                                      id="choose_PRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_200"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PRentAgreement"
                                      onClick={(e) =>
                                        this.removePRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* POwnerDeed */}
                          <div className="displayflex  CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Electricity bill/Telephone bill/Ownership
                                    Deed in the name of Partnership firm*
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="POwnerDeedseecommentsbtn"
                                      onClick={
                                        this.handlePOwnerDeedseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="POwnerDeedhidecommentsbtn"
                                      onClick={
                                        this.handlePOwnerDeedhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_210"
                                      className="choose_btn"
                                      id="choose_POwnerDeed"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_210"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePOwnerDeedChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_POwnerDeed"
                                      onClick={(e) => this.removePOwnerDeed(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_POwnerDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__POwnerDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.POwnerDeedDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_POwnerDeed"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PPremisepic */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Photograph of Shop with name of shop/office
                                    clearly visible
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PremisePhoto.jpeg
"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPremisepicseecommentsbtn"
                                      onClick={
                                        this.handlePPremisepicseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPremisepichidecommentsbtn"
                                      onClick={
                                        this.handlePPremisepichidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_p2"
                                      className="choose_btn"
                                      id="choose_PPremisepic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_p2"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePPremisepicChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPremisepic"
                                      onClick={(e) => this.removePPremisepic(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPremisepic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPremisepic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPremisepicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPremisepic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PAdditional */}
                          <div id="PadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.PadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="PAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddPFileBtn(
                                      PadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn2"
                          onClick={this.handlePProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>

                    {/* Private limited */}
                    <div id="trade_PL">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* PLPic1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph (Director 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPic1seecommentsbtn"
                                      onClick={this.handlePLPic1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPic1hidecommentsbtn"
                                      onClick={this.handlePLPic1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_26"
                                      className="choose_btn"
                                      id="choose_PLPic1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_26"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLPic1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPic1"
                                      onClick={(e) => this.removePLPic1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPic1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPic1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLPic2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph (Director 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPic2seecommentsbtn"
                                      onClick={this.handlePLPic2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPic2hidecommentsbtn"
                                      onClick={this.handlePLPic2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_27"
                                      className="choose_btn"
                                      id="choose_PLPic2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_27"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLPic2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPic2"
                                      onClick={(e) => this.removePLPic2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPic2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPic2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PLAadhar1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                    Driving License (Director 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLAadhar1seecommentsbtn"
                                      onClick={
                                        this.handlePLAadhar1seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLAadhar1hidecommentsbtn"
                                      onClick={
                                        this.handlePLAadhar1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_22"
                                      className="choose_btn"
                                      id="choose_PLAadhar1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_22"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLAadhar1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLAadhar1"
                                      onClick={(e) => this.removePLAadhar1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLAadhar1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLAadhar1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLAadhar2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                    Driving License (Director 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLAadhar2seecommentsbtn"
                                      onClick={
                                        this.handlePLAadhar2seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLAadhar2hidecommentsbtn"
                                      onClick={
                                        this.handlePLAadhar2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_23"
                                      className="choose_btn"
                                      id="choose_PLAadhar2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_23"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLAadhar2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLAadhar2"
                                      onClick={(e) => this.removePLAadhar2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLAadhar2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLAadhar2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLSelfDecl */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Self Declaration
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr"></p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/SelfDeclaration.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLSelfDeclseecommentsbtn"
                                      onClick={
                                        this.handlePLSelfDeclseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLSelfDeclhidecommentsbtn"
                                      onClick={
                                        this.handlePLSelfDeclhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_28"
                                      className="choose_btn"
                                      id="choose_PLSelfDecl"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_28"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLSelfDeclChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLSelfDecl"
                                      onClick={(e) => this.removePLSelfDecl(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLSelfDeclDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLSelfDecl"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLCI */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Certificate of Incorporation
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CertificateOfIncorporation.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLCIseecommentsbtn"
                                      onClick={this.handlePLCIseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLCIhidecommentsbtn"
                                      onClick={this.handlePLCIhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_29"
                                      className="choose_btn"
                                      id="choose_PLCI"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_29"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePLCIChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLCI"
                                      onClick={(e) => this.removePLCI(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLCI"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLCI"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLCIDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLCI"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLMOA */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    MOA<sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/MOA.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLMOAseecommentsbtn"
                                      onClick={this.handlePLMOAseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLMOAhidecommentsbtn"
                                      onClick={this.handlePLMOAhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_30"
                                      className="choose_btn"
                                      id="choose_PLMOA"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_30"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLMOAChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLMOA"
                                      onClick={(e) => this.removePLMOA(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLMOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLMOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLMOADocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLMOA"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLAOA */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    AOA<sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AOA.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLAOAseecommentsbtn"
                                      onClick={this.handlePLAOAseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLAOAhidecommentsbtn"
                                      onClick={this.handlePLAOAhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_31"
                                      className="choose_btn"
                                      id="choose_PLAOA"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_31"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLAOAChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLAOA"
                                      onClick={(e) => this.removePLAOA(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLAOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLAOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLAOADocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLAOA"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PLNature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                maxLength={200}
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.PLbusinessNature}
                                onChange={this.handlePLbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>
                          {/* PLBR */}
                          {/* PLAddressProofType */}

                          {/* PLCopyOfRegistry */}

                          {/* PLResidentialRentAgreement */}

                          {/* PLCopyOfRegistry */}

                          {/* PLTaxReceipt */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLTaxReceiptseecommentsbtn"
                                      onClick={
                                        this.handlePLTaxReceiptseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLTaxReceipthidecommentsbtn"
                                      onClick={
                                        this.handlePLTaxReceipthidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_280"
                                      className="choose_btn"
                                      id="choose_PLTaxReceipt"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_280"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLTaxReceiptChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLTaxReceipt"
                                      onClick={(e) =>
                                        this.removePLTaxReceipt(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLTaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLTaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLTaxReceiptDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLTaxReceipt"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>

                              <input
                                type="radio"
                                id="test3-1"
                                name="radio-group3"
                                value="OwnershipDeed"
                                checked={
                                  this.state.PLAddressProofType_radio ===
                                  "OwnershipDeed"
                                }
                                onChange={this.PLAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test3-1"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test3-2"
                                name="radio-group3"
                                value="RentAgreement"
                                checked={
                                  this.state.PLAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.PLAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test3-2"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* PLCopyOfRegistry */}

                          {/* PLRentAgreement */}
                          <div className="displayflex  RentAgreement">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol borderHtao">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rental Agreement / NOC from Any Relative and
                                    Ownership Electricity bill*
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLRentAgreementseecommentsbtn"
                                      onClick={
                                        this.handlePLRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLRentAgreementhidecommentsbtn"
                                      onClick={
                                        this
                                          .handlePLRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_370"
                                      className="choose_btn"
                                      id="choose_PLRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_370"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLRentAgreement"
                                      onClick={(e) =>
                                        this.removePLRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLOwnerDeed */}
                          <div className="displayflex  CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Electricity bill/Telephone bill
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLOwnerDeedseecommentsbtn"
                                      onClick={
                                        this.handlePLOwnerDeedseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLOwnerDeedhidecommentsbtn"
                                      onClick={
                                        this.handlePLOwnerDeedhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_38"
                                      className="choose_btn"
                                      id="choose_PLOwnerDeed"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_38"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLOwnerDeedChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLOwnerDeed"
                                      onClick={(e) => this.removePLOwnerDeed(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLOwnerDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLOwnerDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLOwnerDeedDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLOwnerDeed"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLPremisePic */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Photograph of Shop with name of shop/office
                                    clearly visible
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr"></p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PremisePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPremisePicseecommentsbtn"
                                      onClick={
                                        this.handlePLPremisePicseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPremisePichidecommentsbtn"
                                      onClick={
                                        this.handlePLPremisePichidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_p3"
                                      className="choose_btn"
                                      id="choose_PLPremisePic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_p3"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLPremisePicChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPremisePic"
                                      onClick={(e) =>
                                        this.removePLPremisePic(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPremisePic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPremisePic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPremisePicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPremisePic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLAdditional */}
                          <div id="PLadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.PLadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="PLAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddPLFileBtn(
                                      PLadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn3"
                          onClick={this.handlePLProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>

                    {/* HUF */}
                    <div id="trade_HUF">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* HUFPic */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photo of Applicant
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFPicseecommentsbtn"
                                      onClick={this.handleHUFPicseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFPichidecommentsbtn"
                                      onClick={this.handleHUFPichidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_41"
                                      className="choose_btn"
                                      id="choose_HUFPic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_41"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFPicChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFPic"
                                      onClick={(e) => this.removeHUFPic(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFPic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFPic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFPicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFPic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFAadhar */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhar Card/Voter ID/ Passport/Pan Card/
                                    Driving License of karta
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFAadharseecommentsbtn"
                                      onClick={
                                        this.handleHUFAadharseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFAadharhidecommentsbtn"
                                      onClick={
                                        this.handleHUFAadharhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_39"
                                      className="choose_btn"
                                      id="choose_HUFAadhar"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_39"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFAadharChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFAadhar"
                                      onClick={(e) => this.removeHUFAadhar(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFAadharDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFAadhar"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFPan1 */}

                          {/* HUFPan2 */}

                          {/* HUFDeed */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    HUF Deed Document
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/HUFDeedDocument.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFDeedseecommentsbtn"
                                      onClick={this.handleHUFDeedseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFDeedhidecommentsbtn"
                                      onClick={
                                        this.handleHUFDeedhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_43"
                                      className="choose_btn"
                                      id="choose_HUFDeed"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_43"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFDeedChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFDeed"
                                      onClick={(e) => this.removeHUFDeed(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFDeedDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFDeed"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFSelfDecl */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Self Declaration
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr"></p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/SelfDeclaration.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFSelfDeclseecommentsbtn"
                                      onClick={
                                        this.handleHUFSelfDeclseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFSelfDeclhidecommentsbtn"
                                      onClick={
                                        this.handleHUFSelfDeclhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_44p"
                                      className="choose_btn"
                                      id="choose_HUFSelfDecl"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_44p"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFSelfDeclChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFSelfDecl"
                                      onClick={(e) => this.removeHUFSelfDecl(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFSelfDeclDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFSelfDecl"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Nature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                maxLength={200}
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.HUFbusinessNature}
                                onChange={this.handleHUFbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>
                          {/* HUFAddressProofType */}

                          {/* HUFResidentialCopyOfRegistry */}

                          {/* HUFResidentialRentAgreement */}

                          {/* HUFTaxReceipt */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFTaxReceiptseecommentsbtn"
                                      onClick={
                                        this.handleHUFTaxReceiptseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFTaxReceipthidecommentsbtn"
                                      onClick={
                                        this.handleHUFTaxReceipthidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_44p0"
                                      className="choose_btn"
                                      id="choose_HUFTaxReceipt"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_44p0"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFTaxReceiptChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFTaxReceipt"
                                      onClick={(e) =>
                                        this.removeHUFTaxReceipt(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFTaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFTaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFTaxReceiptDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFTaxReceipt"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>

                              <input
                                type="radio"
                                id="test44-14"
                                name="radio-group14"
                                value="OwnershipDeed"
                                checked={
                                  this.state.HUFAddressProofType_radio ===
                                  "OwnershipDeed"
                                }
                                onChange={this.HUFAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test44-14"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test44-24"
                                name="radio-group14"
                                value="RentAgreement"
                                checked={
                                  this.state.HUFAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.HUFAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test44-24"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* HUFCopyOfRegistry */}
                          <div className="displayflex CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Electricity bill/Telephone bill
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFCopyOfRegistryseecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFCopyOfRegistryseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFCopyOfRegistryhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFCopyOfRegistryhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_470"
                                      className="choose_btn"
                                      id="choose_HUFCopyOfRegistry"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_470"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFCopyOfRegistryChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFCopyOfRegistry"
                                      onClick={(e) =>
                                        this.removeHUFCopyOfRegistry(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFCopyOfRegistryDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFCopyOfRegistry"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFRentAgreement */}
                          <div className="displayflex RentAgreement">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rental Agreement / NOC from Any Relative and
                                    Ownership Electricity bill
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFRentAgreementseecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFRentAgreementhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_480"
                                      className="choose_btn"
                                      id="choose_HUFRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_480"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFRentAgreement"
                                      onClick={(e) =>
                                        this.removeHUFRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFPremisePic */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Photograph of Shop with name of shop/office
                                    clearly visible
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr"></p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PremisePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFPremisePicseecommentsbtn"
                                      onClick={
                                        this.handleHUFPremisePicseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFPremisePichidecommentsbtn"
                                      onClick={
                                        this.handleHUFPremisePichidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_p4"
                                      className="choose_btn"
                                      id="choose_HUFPremisePic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_p4"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFPremisePicChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFPremisePic"
                                      onClick={(e) =>
                                        this.removeHUFPremisePic(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFPremisePic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFPremisePic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFPremisePicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFPremisePic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFPR */}

                          {/* HUFAdditional */}
                          <div id="HUFadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.HUFadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="HUFAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddHUFFileBtn(
                                      HUFadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn4"
                          onClick={this.handleHUFProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>
                    {/* Society/ trust/ club */}
                    <div id="trade_Society">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* STPic1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photo (President or Secretary)
                                    (1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPic1seecommentsbtn"
                                      onClick={this.handleSTPic1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPic1hidecommentsbtn"
                                      onClick={this.handleSTPic1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_54"
                                      className="choose_btn"
                                      id="choose_STPic1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_54"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTPic1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPic1"
                                      onClick={(e) => this.removeSTPic1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPic1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPic1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPic2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photo (President or Secretary)
                                    (2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPic2seecommentsbtn"
                                      onClick={this.handleSTPic2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPic2hidecommentsbtn"
                                      onClick={this.handleSTPic2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_55"
                                      className="choose_btn"
                                      id="choose_STPic2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_55"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTPic2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPic2"
                                      onClick={(e) => this.removeSTPic2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPic2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPic2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* STAadhar1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                    Driving License (President or Secretary) (1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STAadhar1seecommentsbtn"
                                      onClick={
                                        this.handleSTAadhar1seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STAadhar1hidecommentsbtn"
                                      onClick={
                                        this.handleSTAadhar1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_50"
                                      className="choose_btn"
                                      id="choose_STAadhar1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_50"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTAadhar1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STAadhar1"
                                      onClick={(e) => this.removeSTAadhar1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STAadhar1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STAadhar1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STAadhar2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                    Driving License (President or Secretary) (2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STAadhar2seecommentsbtn"
                                      onClick={
                                        this.handleSTAadhar2seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STAadhar2hidecommentsbtn"
                                      onClick={
                                        this.handleSTAadhar2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_51"
                                      className="choose_btn"
                                      id="choose_STAadhar2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_51"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTAadhar2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STAadhar2"
                                      onClick={(e) => this.removeSTAadhar2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STAadhar2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STAadhar2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPan1 */}
                          {/* STPan2 */}

                          {/* STSelfDecl */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Self Declaration
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/SelfDeclaration.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STSelfDeclseecommentsbtn"
                                      onClick={
                                        this.handleSTSelfDeclseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STSelfDeclhidecommentsbtn"
                                      onClick={
                                        this.handleSTSelfDeclhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_56p"
                                      className="choose_btn"
                                      id="choose_STSelfDecl"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_56p"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTSelfDeclChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STSelfDecl"
                                      onClick={(e) => this.removeSTSelfDecl(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STSelfDecl"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STSelfDeclDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STSelfDecl"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STCR */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Society Registration Certificate
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CertificateOfRegistration.pdf "
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STCRseecommentsbtn"
                                      onClick={this.handleSTCRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STCRhidecommentsbtn"
                                      onClick={this.handleSTCRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_57"
                                      className="choose_btn"
                                      id="choose_STCR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_57"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSTCRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STCR"
                                      onClick={(e) => this.removeSTCR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STCR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STCR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STCRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STCR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Nature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                maxLength={200}
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.STbusinessNature}
                                onChange={this.handleSTbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>
                          {/* STBP */}

                          {/* STBR */}

                          {/* STGumasta */}

                          {/* STTradeLicence */}

                          {/* STAddressProofType */}

                          {/* STResidentialCopyOfRegistry */}

                          {/* STResidentialRentAgreement */}

                          {/* STTaxReceipt */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STTaxReceiptseecommentsbtn"
                                      onClick={
                                        this.handleSTTaxReceiptseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STTaxReceipthidecommentsbtn"
                                      onClick={
                                        this.handleSTTaxReceipthidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_56p0"
                                      className="choose_btn"
                                      id="choose_STTaxReceipt"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_56p0"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTTaxReceiptChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STTaxReceipt"
                                      onClick={(e) =>
                                        this.removeSTTaxReceipt(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STTaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STTaxReceipt"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STTaxReceiptDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STTaxReceipt"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <input
                                type="radio"
                                id="test55-15"
                                name="radio-group15"
                                value="OwnershipDeed"
                                checked={
                                  this.state.STAddressProofType_radio ===
                                  "OwnershipDeed"
                                }
                                onChange={this.STAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test55-15"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test55-25"
                                name="radio-group15"
                                value="RentAgreement"
                                checked={
                                  this.state.STAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.STAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test55-25"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* STCopyOfRegistry */}
                          <div className="displayflex CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Electricity bill/Telephone bill
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STCopyOfRegistryseecommentsbtn"
                                      onClick={
                                        this
                                          .handleSTCopyOfRegistryseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STCopyOfRegistryhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleSTCopyOfRegistryhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_62"
                                      className="choose_btn"
                                      id="choose_STCopyOfRegistry"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_62"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTCopyOfRegistryChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STCopyOfRegistry"
                                      onClick={(e) =>
                                        this.removeSTCopyOfRegistry(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STCopyOfRegistryDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STCopyOfRegistry"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STRentAgreement */}
                          <div className="displayflex RentAgreement ">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol borderHtao">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rental Agreement / NOC from Any Relative and
                                    Ownership Electricity bill
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STRentAgreementseecommentsbtn"
                                      onClick={
                                        this.handleSTRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STRentAgreementhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleSTRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_630"
                                      className="choose_btn"
                                      id="choose_STRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_630"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STRentAgreement"
                                      onClick={(e) =>
                                        this.removeSTRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Photograph of Shop with name of shop/office
                                    clearly visible
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PremisePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPremisePicseecommentsbtn"
                                      onClick={
                                        this.handleSTPremisePicseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPremisePichidecommentsbtn"
                                      onClick={
                                        this.handleSTPremisePichidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_p5"
                                      className="choose_btn"
                                      id="choose_STPremisePic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_p5"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTPremisePicChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPremisePic"
                                      onClick={(e) =>
                                        this.removeSTPremisePic(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPremisePic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPremisePic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPremisePicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPremisePic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPR */}

                          {/* STAdditional */}
                          <div id="STadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.STadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="STAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddSTFileBtn(
                                      STadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn5"
                          onClick={this.handleSTProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>
                  </div>
             

                  {/* comments */}
                  <div className="col-lg-3 colbtwborder">
                    <div className="commentimg-section">
                      <SupportDetails
                        name={this.state.Name}
                        role={"Relationship Manager"}
                        contact={this.state.Contact}
                      />

                      <div className="supportDetails">
                        <img
                          src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}
                          alt="image"
                        />
                        <div>
                          <h5>{supportName}</h5>
                          <p>Agent Support Executive</p>
                          <span>
                            <b>Contact: </b> +91 {supportContact}
                          </span>
                          <p className="mb-1">
                            <b>Working Hours: </b> 11:00 AM - 06:30 PM
                          </p>
                        </div>
                      </div>
                      <div className="mm">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                            data-target="#youtubeModal"
                          >
                            <img
                              src={process.env.PUBLIC_URL + "/img/play.jpg"}
                              id="play"
                              className="play_img play_img2"
                              alt="image"
                            />
                          </a>
                        </center>
                      </div>
                      <button
                        data-toggle="modal"
                        onClick={this.handletimeline}
                        data-src=""
                        data-target="#timeline"
                        className="dashBoardBtn mt-5 newbmar"
                      >
                        Timeline
                      </button>

                      <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                    </div>
                    <div className="TDRightSide">
                      <div className="convheader">
                        <div>
                          <img
                            src={process.env.PUBLIC_URL + "/img/person.png"}
                            className="converpersonimg"
                          />
                        </div>
                        <div className="convheadercontdiv">
                          <p className="convservicename">{this.state.Name}</p>
                          <p className="convpersonname">Relationship Manager</p>
                          <p className="convuniquename">
                            Contact:{" "}
                            <span style={{ color: "#2d2d2d" }}>
                              {this.state.Contact}
                            </span>
                          </p>
                          <p className="convuniquename">
                            Service ID :{" "}
                            <span className="convuniquenamespan">
                              {Decserviceid}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="convmaindiv">
                        {this.renderCommenthistory()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab2" className="tab-content">
                    <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.completerender()}
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          :<center>
            <img alt="notaccess" className="notuploaddoc" src={process.env.PUBLIC_URL + "/img/Warning-rafiki.svg"} />

            <h4 class="my-linkmodal-text youcant">You can't upload document because you're Referal Partner. Please contact support executive for any help and more information.</h4>
            </center>
          }
          </div>
          </div>
         
          <SupportDetailsMobile
            name={this.state.Name}
            role={"Relationship Manager"}
            contact={this.state.Contact}
          />

          <Footer />
          <div
            className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 close1tl closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ? (
                    this.state.Timeline.length > 0 ? (
                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button
                                    id="load-more"
                                    onClick={this.handlemoretimeline}
                                  >
                                    More..
                                  </button>
                                </div>
                                <div class="timeline-continue">
                                  {this.state.filtertimel.map((item, index) => {
                                    if (index % 2 == 0) {
                                      return (
                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date"></p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">
                                                  {item.time}
                                                </p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">
                                                  Date :{" "}
                                                  <span class="mode_span">
                                                    {" "}
                                                    {item.date}
                                                  </span>
                                                </p>
                                                <h5 className="tlremark">
                                                  {item.remark}
                                                </h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}
                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date"></p>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date"></p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">
                                                  {item.time}
                                                </p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">
                                                  Date :{" "}
                                                  <span class="mode_span">
                                                    {item.date}{" "}
                                                  </span>
                                                </p>
                                                <h5 className="tlremark">
                                                  {item.remark}
                                                </h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>
                    )
                  ) : null}
                  <center>
                    <img
                      className="timelineloder"
                      src={process.env.PUBLIC_URL + "/img/loader.gif"}
                      alt="loading"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="SAadharModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SAadhar"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SAadhar"
                        onClick={this.uploadSAadhar}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SAadhar"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SSelfDeclModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SSelfDecl"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SSelfDecl"
                        onClick={this.uploadSSelfDecl}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SSelfDecl"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STaxReceiptModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STaxReceipt"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STaxReceipt"
                        onClick={this.uploadSTaxReceipt}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STaxReceipt"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPic"
                        onClick={this.uploadSPic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SBP"
                        onClick={this.uploadSBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SGumasta"
                        onClick={this.uploadSGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STradeLicence"
                        onClick={this.uploadSTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SCopyOfRegistry"
                        onClick={this.uploadSCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SRentAgreement"
                        onClick={this.uploadSRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade SAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadSFile(
                            SadditionalReuploadIndex,
                            SadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Partnership */}
          <div className="modal fade" id="PAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PAadhar1"
                        onClick={this.uploadPAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PAadhar2"
                        onClick={this.uploadPAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PSelfDeclModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PSelfDecl"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PSelfDecl"
                        onClick={this.uploadPSelfDecl}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PSelfDecl"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PTaxReceiptModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PTaxReceipt"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PTaxReceipt"
                        onClick={this.uploadPTaxReceipt}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PTaxReceipt"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPDeedModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPDeed"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPDeed"
                        onClick={this.uploadPPDeed}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPDeed"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPic1"
                        onClick={this.uploadPPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPic2"
                        onClick={this.uploadPPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PBP"
                        onClick={this.uploadPBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PGumasta"
                        onClick={this.uploadPGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PTradeLicence"
                        onClick={this.uploadPTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PRentAgreement"
                        onClick={this.uploadPRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="POwnerDeedModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_POwnerDeed"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_POwnerDeed"
                        onClick={this.uploadPOwnerDeed}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_POwnerDeed"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadPFile(
                            PadditionalReuploadIndex,
                            PadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAadhar1"
                        onClick={this.uploadPLAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAadhar2"
                        onClick={this.uploadPLAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPan1"
                        onClick={this.uploadPLPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPan2"
                        onClick={this.uploadPLPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPic1"
                        onClick={this.uploadPLPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPic2"
                        onClick={this.uploadPLPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLSelfDeclModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLSelfDecl"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLSelfDecl"
                        onClick={this.uploadPLSelfDecl}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLSelfDecl"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLTaxReceiptModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLTaxReceipt"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLTaxReceipt"
                        onClick={this.uploadPLTaxReceipt}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLTaxReceipt"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLCIModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLCI"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLCI"
                        onClick={this.uploadPLCI}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLCI"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLMOAModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLMOA"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLMOA"
                        onClick={this.uploadPLMOA}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLMOA"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAOAModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAOA"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAOA"
                        onClick={this.uploadPLAOA}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAOA"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLBRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLBR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLBR"
                        onClick={this.uploadPLBR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLBR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLBP"
                        onClick={this.uploadPLBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLGumasta"
                        onClick={this.uploadPLGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLTradeLicence"
                        onClick={this.uploadPLTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="PLResidentialRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLResidentialRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLResidentialRentAgreement"
                        onClick={this.uploadPLResidentialRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLResidentialRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLCopyOfRegistry"
                        onClick={this.uploadPLCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLRentAgreement"
                        onClick={this.uploadPLRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLOwnerDeedModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLOwnerDeed"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLOwnerDeed"
                        onClick={this.uploadPLOwnerDeed}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLOwnerDeed"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PLAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadPLFile(
                            PLadditionalReuploadIndex,
                            PLadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFAadharModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFAadhar"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFAadhar"
                        onClick={this.uploadHUFAadhar}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFAadhar"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPan1"
                        onClick={this.uploadHUFPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPic"
                        onClick={this.uploadHUFPic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPan2"
                        onClick={this.uploadHUFPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFDeedModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFDeed"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFDeed"
                        onClick={this.uploadHUFDeed}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFDeed"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFSelfDeclModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFSelfDecl"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFSelfDecl"
                        onClick={this.uploadHUFSelfDecl}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFSelfDecl"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFTaxReceiptModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFTaxReceipt"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFTaxReceipt"
                        onClick={this.uploadHUFTaxReceipt}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFTaxReceipt"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="HUFTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFTradeLicence"
                        onClick={this.uploadHUFTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFCopyOfRegistry"
                        onClick={this.uploadHUFCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFRentAgreement"
                        onClick={this.uploadHUFRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPR"
                        onClick={this.uploadHUFPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade HUFAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadHUFFile(
                            HUFadditionalReuploadIndex,
                            HUFadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STAadhar1"
                        onClick={this.uploadSTAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STAadhar2"
                        onClick={this.uploadSTAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPan1"
                        onClick={this.uploadSTPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPan2"
                        onClick={this.uploadSTPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPic1"
                        onClick={this.uploadSTPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPic2"
                        onClick={this.uploadSTPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STSelfDeclModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STSelfDecl"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STSelfDecl"
                        onClick={this.uploadSTSelfDecl}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STSelfDecl"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STTaxReceiptModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STTaxReceipt"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STTaxReceipt"
                        onClick={this.uploadSTTaxReceipt}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STTaxReceipt"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STCRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STCR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STCR"
                        onClick={this.uploadSTCR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STCR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STBP"
                        onClick={this.uploadSTBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STBRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STBR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STBR"
                        onClick={this.uploadSTBR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STBR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STGumasta"
                        onClick={this.uploadSTGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STTradeLicence"
                        onClick={this.uploadSTTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STCopyOfRegistry"
                        onClick={this.uploadSTCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STRentAgreement"
                        onClick={this.uploadSTRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STResidentialCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STResidentialCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STResidentialCopyOfRegistry"
                        onClick={this.uploadSTResidentialCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STResidentialCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STResidentialRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STResidentialRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STResidentialRentAgreement"
                        onClick={this.uploadSTResidentialRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STResidentialRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade STAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadSTFile(
                            STadditionalReuploadIndex,
                            STadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPremisepicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPremisepic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPremisepic"
                        onClick={this.uploadSPremisepic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPremisepic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPremisepicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPremisepic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPremisepic"
                        onClick={this.uploadPPremisepic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPremisepic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPremisePicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPremisePic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPremisePic"
                        onClick={this.uploadPLPremisePic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPremisePic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPremisePicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPremisePic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPremisePic"
                        onClick={this.uploadHUFPremisePic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPremisePic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPremisePicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPremisePic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPremisePic"
                        onClick={this.uploadSTPremisePic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPremisePic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}
export default ActiveTradeLicence;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Select2 from "react-select2-wrapper";
import jsondata from "../states-and-districts.json"
import Sidebar from "./Sidebar";
let Doclink;
let Docname;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
var activeserviceGSTR = [];
var activeserviceGSTRF = [];
var activeserviceITRF = [];
var activeserviceTR = [];
var activeserviceOA = [];
var activeserviceGUMASTA = [];
var activeserviceTradeLicence = [];
var activeserviceFoodLicence = [];
var completeserviceGSTR = [];
var completeserviceGSTRF = [];
var completeserviceITRF = [];
var completeserviceTR = [];
var completeserviceOA = [];
var completeserviceGUMASTA = [];
var completeserviceTradeLicence = [];
var completeserviceFoodLicence = [];
let Decuserid;
let Decagentid;
let Decjwttoken;
let Deccontact;
let Decemail;
let temp = "general";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toCheckout: false,
      pay: true,
      data: jsondata,
      states: [],
      districts: [],
      workdistricts: [],
      file: "",
      firstName: "",
      lastName: "",
      contactNo: "",
      email: "",
      DOB: "",
      gender: "",
      applicationType: "",
      applicationOther: "",
      koid: "",
      state: "",
      city_district: "",
      landmark: "",
      address: "",
      pinCode: "",
      aadharCard: "",
      panCard: "",
      addressproof: "",
      cancelCheque: "",
      profile: "",
      profilephoto: "",
      partnerCode: "",
      bankAccountName: "",
      accountNo: "",
      IFSCcode: "",
      workstate: "",
      workcity_district: "",
      workaddress: "",
      worklandmark: "",
      workpinCode: "",
      workaddressproof: "",
      sameAddress: "",
      previousStatus: "",
      previousInsuranceStatus: "",
      panCardbase64: "",
      aadharCardbase64: "",
      cancelChequebase64: "",
      profilebase64: "",
      profilephotobase64: "",
      addressproofbase64: "",
      workaddressproofbase64: "",
      documents:[],
      aadharNumber:"",
      panNumber:"",
    };
  }

  componentDidMount() {
     
    
    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)
    
    window.scrollTo(0, 0);
    activeserviceGSTR = [];
    activeserviceGSTRF = [];
    activeserviceITRF = [];
    activeserviceTR = [];
    activeserviceOA = [];
    activeserviceGUMASTA = [];
    activeserviceTradeLicence = [];
    activeserviceFoodLicence = [];
    completeserviceGSTR = [];
    completeserviceGSTRF = [];
    completeserviceITRF = [];
    completeserviceTR = [];
    completeserviceOA = [];
    completeserviceGUMASTA = [];
    completeserviceTradeLicence = [];
    completeserviceFoodLicence = [];
    $(".sendUnlockOtpLoader").hide();
    $("#sendUnlockOtp").show();
    $("#submitunlockOtpbtn").show();

    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);

    // let mainemail = localStorage.getItem("email");
    // Decemail = encryptor.decrypt(mainemail);
    // let maincontact = localStorage.getItem("contact");
    // Deccontact = encryptor.decrypt(maincontact);
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
      $(".useropt__rn__addBtn").hide()
    } else {
      temp = "general"
      $(".useropt__rn__addBtn").show()
    }

    // ----------------------------temporary -----------------------------------------
    let paramsUserId = this.props.match.params.id2;
    // if (paramsUserId != Decuserid) {
    //   this.props.history.push("/dashboard");
    // } else {
      this.handleMyservicesapi();
    // }
    window.addEventListener("click", function (event) {
      if (event.target.className != "search-box") {
        $(".search-box").removeClass("search-focus");
      }
    });

    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);
  }
  countdown() {
    this.setState({ rtime: 90 });
    $("#mobileresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.rtime == 1) {
        clear(interval);
        $("#mobileresend-loader").hide();
      } else {
      }
      this.setState({ rtime: this.state.rtime - 1 });
    }, 1000);
  }
  handleSearchBox = (e) => {
    e.stopPropagation();
    $(".search-box").addClass("search-focus");
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };

  addServices = () => {
    this.props.history.push({
      pathname: `/addservice/${Decuserid}/addservice`, state: {name: this.state.username}
    });
  };

  handleMyservicesapi = () => {
    // $(".myseriveloaderdiv").show();
    // $(".header-btn").hide();
    axios({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + "p=getAgentDetails",
      data: {
        agentId: Decagentid,
      },
      headers: {
        Authkey: process.env.REACT_APP_API_KEY,
        "App-Token-Access": Decjwttoken,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then((resp) => {
       
        // $(".myseriveloaderdiv").hide();
        // $(".header-btn").show();
        if (resp.data.code == 200 && resp.data.status == "success") {
         this.setState({
          DOB: resp.data.details.DOB,
          IFSCcode: resp.data.details.IFSCCode,
          aadharCard: resp.data.details.aadhaarCard,
          accountNo: resp.data.details.accountNo,
          address:resp.data.details.address,
          addressproof: resp.data.details.addressProof,
          applicationOther: resp.data.details.applicationOther,
          applicationType: resp.data.details.applicationType,
          bankAccountName: resp.data.details.bankAccountName,
          cancelCheque: resp.data.details.cancelCheque,
          contactNo: resp.data.details.contact,
          email: resp.data.details.email,
          firstName: resp.data.details.firstName,
          gender: resp.data.details.gender,
          koid: resp.data.details.koid,
          landmark: resp.data.details.landmark,
          lastName:resp.data.details.lastName,
          panCard: resp.data.details.panCard,
          partnerCode: resp.data.details.partnerCode,
          pinCode: resp.data.details.pinCode,
          previousInsuranceStatus:resp.data.details.previousInsuranceStatus,
          previousStatus: resp.data.details.previousStatus,
          profile: resp.data.details.profile,
          profilephoto: resp.data.details.profilephoto,
          sameAddress: resp.data.details.sameAddress,
          state: resp.data.details.state,
          username: resp.data.details.username,
          workAddressproof: resp.data.details.workAddressProof,
          workaddress:resp.data.details.workaddress,
          worklandmark: resp.data.details.worklandmark,
          workpinCode: resp.data.details.workpinCode,
          workstate: resp.data.details.workstate,
          documents:resp.data.documents,
          aadharNumber:resp.data.details.aadharNumber? resp.data.details.aadharNumber.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'') : "",
          panNumber:resp.data.details.panNumber? resp.data.details.panNumber : ""
         }, () => {
           this.setState({
            workcity_district: resp.data.details.workcity_district,            
          city_district: resp.data.details.city_district,
           })
         })
        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        } else {
          this.showNotification(resp.data.message);
        }
      })
      .catch((error) => {
        $(".myseriveloaderdiv").hide();
      });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };


  filterDistricts = () => {
    const filtered = this.state.data.filter((item) => {
      if (item.state == this.state.state) {
        this.setState({ districts: item.districts });
      }
    });
 
  };
  filterDistricts2 = () => {
    const filtered = this.state.data.filter((item) => {
      if (item.state == this.state.workstate) {
        this.setState({ workdistricts: item.districts });
      }
    });
   
  };
  onRadioChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  editdetails=()=> {
    this.props.history.push({pathname:"/support", state: {type: "editdetails", } })
  }
  render() {
    const options = {
      placeholder: "Select State",
    };
    const options2 = {
      placeholder: "Select District",
    };
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      $("#resendUnlockOTP").addClass("text-muted");
      if (this.state.rtime == 0) {
        $("#resendUnlockOTP").removeClass("text-muted");
      }
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <Sidebar/>

          <div className="arrow-left11"></div>
          <div className="white_div">
            <div className="white_box">
              <div className="main-row">

                {/* <!-- header --> */}
                <div className="inner-header d-flex flex-md-row flex-column justify-content-between px-2">
                  {/* <!-- search --> */}


                  {/* <!-- nav-tabs -->      */}
                  <div className="navigation-tab">
                    <header className="tabs-nav position-relative">
                      <ul className="d-flex navigation-tab-ul spMargin1">
                        <li className="active navigation-tab-li" id="activeTab">
                          <a href="#tab1" className="px-2 py-1">
                            General Details
                          </a>
                        </li>
                        <li className="navigation-tab-li" id="completedTab">
                          <a href="#tab2" className="px-2 py-1">
                            Agreements & more
                          </a>
                        </li>
                        <hr className="navigation-tab-vertical-line" />
                        <div className="slider"></div>
                      </ul>
                    </header>
                  </div>

                  {/* <!-- dropdowns --> */}
                
                </div>

                {/* <!-- page-content --> */}
                <div className="tabs-content py-md-3 py-3 mt-md-4 mt-2">
                  {/* <!-- active --> */}
                  <div id="tab1" className="tab-content">
                  <button className="editdetailsbtn" onClick={this.editdetails}>Edit details</button>
                  <br/>
                    <div className="myservicescrolldiv ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="myseriveloaderdiv">
                            <div className="myseriveloaderinsidediv">
                              <center>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/img/loader.gif"
                                  }
                                  className="myseriveloader"
                                />
                              </center>
                            </div>
                          </div>
                       
                          <div className="agent-form m-0 mt-3">

            {/* Personal Details------------------------------------------------------------------------ */}
            <span className="type-head">
              <u>Personal details:</u>
            </span>
  

            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 appli lefttt">
                <select
                  name="application"
                  id="applicationtype"
                  className="agent-select  agent-left"
                  value={this.state.applicationType}
                  disabled
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ applicationType: e.target.value });
                    if (e.target.value == "other") {
                      $("#koidinput").hide();
                      $("#other-fieldset").fadeIn();
                    } else if (
                      e.target.value == "kiosk" ||
                      e.target.value == "NICT"
                    ) {
                      $("#other-fieldset").hide();
                      $("#koidinput").fadeIn();
                    } else {
                      $("#koidinput").fadeOut();
                      $("#other-fieldset").fadeOut();
                    }
                  }}
                >
                  <option value="a" disabled>
                    &nbsp;Application Type
                  </option>
                  <option value="individual">&nbsp;Individual</option>
                  <option value="kiosk">&nbsp;KIOSK</option>
                  <option value="gov">
                    &nbsp;MP Online / Other gov. online portals
                  </option>
                  <option value="NICT">&nbsp;NICT Agent</option>
                  <option value="cyber">&nbsp;Cyber Cafe</option>
                  <option value="other">&nbsp;Others</option>
                </select>
                <span className="  warn" id="applicationtype-warn">
                  Please select application type
                </span>
              </div>

              <div
                className="col-lg-6 appli col-12 righttt"
                id="other-fieldset"
              >
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Other</legend>
                  <input
                    className="agent-input"
                    disabled
                    id="other"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Please Specify"
                    value={this.state.applicationOther}
                    onChange={(e) =>
                      this.setState({ applicationOther: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn " id="other-warn">
                  Please specify application type.
                </span>
                <span className="  warn " id="other-text-warn">
                  Text too long (Max 50)
                </span>
              </div>

              <div className="col-lg-6 appli col-12 righttt" id="koidinput">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="koid">&nbsp;KIOSK ID &nbsp;</legend>
                  <input
                    className="agent-input"
                    id="kioid"
                    type="text"
                    maxLength="25"
                    autoComplete="off"
                    placeholder="Enter KIOSK ID"
                    value={this.state.koid}
                    disabled
                    onChange={(e) => this.setState({ koid: e.target.value })}
                  />
                </fieldset>
                {/* <span className="  warn " id="koid-warn">
                  This field can't be empty
                </span> */}
                <span className="  warn " id="koid-text-warn">
                  Text too long (Max 50)
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className="my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;First Name</legend>
                  <input
                    className="agent-input"
                    id="firstname"
                    value={this.state.firstName}
                    type="text"
                    maxLength="50"
                    disabled
                    autoComplete="off"
                    placeholder="Enter First Name"
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn " id="firstname-warn">
                  Please enter your First name.
                </span>
                <span className="  warn " id="firstname-invalidtext-warn">
                  Please enter a valid Firstname.
                </span>
                <span className="  warn " id="firstname-text-warn">
                  Text too long (Max 25)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Last Name</legend>
                  <input
                    className="agent-input"
                    id="lastname"
                    value={this.state.lastName}
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Enter Last Name"
                    disabled
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="lastname-invalidtext-warn">
                  Please enter a valid Lastname.
                </span>
                <span className="  warn" id="lastname-warn">
                  Please enter your Last name.
                </span>
                <span className="  warn " id="lastname-text-warn">
                  Text too long (Max 25)
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;Email</legend>
                  <input
                    className="agent-input-email"
                    id="email"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    value={this.state.email}
                    placeholder="Enter Your Email"
                    disabled
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </fieldset>
                <span className="  warn" id="email-warn">
                  Please enter a valid email
                </span>
                <span className="  warn" id="email-warn-empty">
                  Please enter your email.
                </span>
                <span className="  warn " id="email-text-warn">
                  Text too long (Max 100)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Mobile No.</legend>
                  <input
                    className="agent-input"
                    id="mobile"
                    type="tel"
                    autoComplete="off"
                    maxLength="10"
                    value={this.state.contactNo}
                    placeholder="Enter Your Mobile No."
                    disabled
                    onChange={(e) =>
                      this.setState({ contactNo: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="mobile-warn">
                  Please enter a valid Mobile No.
                </span>
                <span className="  warn" id="mobile-warn-empty">
                  Please enter your mobie no.
                </span>
                <span className="  warn " id="mobile-text-warn">
                  Mobile no. too long (Max 10)
                </span>
              </div>
            </div>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend ">&nbsp;D.O.B.</legend>
                  <input
                    className="agent-input dob-input"
                    id="dob"
                    value={this.state.DOB}
                    type="date"
                    disabled
                    onChange={(e) => this.setState({ DOB: e.target.value })}
                  />
                </fieldset>
                <span className="  warn" id="dob-warn">
                  Please provide D.O.B
                </span>
              </div>

              <div className="col-lg-6 col-12 righttt">
                <select
                  name="gender"
                  id="gender"
                  className=" agent-select agent-right"
                  value={this.state.gender}
                  disabled
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ gender: e.target.value });
                  }}
                >
                  <option value="c" disabled>
                    Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <span className="  warn" id="gender-warn">
                  Please select gender
                </span>
              </div>
            </div>

            {/* Bank details------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Bank details:</u>
            </span>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">
                    &nbsp;Account Holder Name
                  </legend>
                  <input
                    className="agent-input"
                    id="accountholdername"
                    type="text"
                    maxLength="100"
                    autoComplete="off"
                    placeholder="Enter Name"
                    disabled
                    value={this.state.bankAccountName}
                    onChange={(e) =>
                      this.setState({ bankAccountName: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="accountholdername-warn-empty">
                  Please enter Account holder's name.
                </span>
                <span className="  warn " id="accountholdername-text-warn">
                  Text too long (Max 100)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Account No.</legend>
                  <input
                    className="agent-input"
                    id="accountno"
                    type="tel"
                    maxLength="20"
                    autoComplete="off"
                    placeholder="Enter Your Bank Account No."
                    value={this.state.accountNo}
                    disabled
                    onChange={(e) =>
                      this.setState({ accountNo: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="accountno-warn">
                  Please enter a valid account no.
                </span>
                <span className="  warn" id="accountno-warn-empty">
                  Please enter your account no.
                </span>
                <span className="  warn " id="accountno-text-warn">
                  Account no. too long (Max 50)
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend ">&nbsp;IFSC Code</legend>
                  <input
                    className="agent-input agent-input-Uppercase"
                    id="ifsc"
                    type="text"
                    maxLength="20"
                    autoComplete="off"
                    placeholder="Enter IFSC Code"
                    value={this.state.IFSCcode}
                    disabled
                    onChange={(e) =>
                      this.setState({ IFSCcode: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="ifsc-warn">
                  Please enter the IFSC Code.
                </span>
                <span className="  warn " id="ifsc-text-warn">
                  Code too long (Max 20)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Bank Proof</legend>
                  <a
                  href={this.state.cancelCheque}
                  target="_blank"
                  className="btn view-btn mt-1 w-100"
                >
                  View bank proof
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                    alt=""
                  />
                </a>
                  {/* <input className="agent-input input-file " type="file" /> */}
                </fieldset>
               
                <span className=" warn" id="cheque-warn">
                  Please select a file with max-size of 10 mb
                </span>
                <span className="warn" id="cheque-warnsize">
                  File too big (max-size 10 mb)
                </span>
              </div>
            </div>

            {/* Address details------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Address details:</u>
            </span>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;Full Address</legend>
                  <input
                    className="agent-input"
                    id="address"
                    type="address"
                    maxLength="150"
                    autoComplete="off"
                    disabled
                    value={this.state.address}
                    placeholder="Enter Your Address"
                    onChange={(e) => this.setState({ address: e.target.value })}
                  />
                </fieldset>
                <span className="  warn" id="address-warn">
                  Please enter your address.
                </span>
                <span className="  warn " id="address-text-warn">
                  Address text too long (Max 150)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Landmark</legend>
                  <input
                    className="agent-input"
                    id="landmark"
                    type="text"
                    autoComplete="off"
                    maxLength="100"
                    value={this.state.landmark}
                    placeholder="Enter Landmark"
                    disabled
                    onChange={(e) =>
                      this.setState({ landmark: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="landmark-warn-empty">
                  Please enter a landmark.
                </span>
                <span className="  warn " id="landmark-text-warn">
                  Landmark text too long (Max 50)
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt state-select">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;State</legend>
                  <Select2
                    className="form-control"
                    id="state"
                    autoComplete="off"
                    value={this.state.state}
                    options={options}
                    disabled
                    onChange={(e) => {
                      this.setState({ state: e.target.value });
                      
                      this.filterDistricts();
                    }}
                    data={[
                      "Andaman and Nicobar Island (UT)",
                      "Andhra Pradesh",
                      "Arunachal Pradesh",
                      "Assam",
                      "Bihar",
                      "Chandigarh (UT)",
                      "Chhattisgarh",
                      "Dadra and Nagar Haveli (UT)",
                      "Daman and Diu (UT)",
                      "Delhi (NCT)",
                      "Goa",
                      "Gujarat",
                      "Haryana",
                      "Himachal Pradesh",
                      "Jammu and Kashmir(UT)",
                      "Jharkhand",
                      "Karnataka",
                      "Kerala",
                      "Ladakh(UT)",
                      "Lakshadweep (UT)",
                      "Madhya Pradesh",
                      "Maharashtra",
                      "Manipur",
                      "Meghalaya",
                      "Mizoram",
                      "Nagaland",
                      "Odisha",
                      "Puducherry (UT)",
                      "Punjab",
                      "Rajasthan",
                      "Sikkim",
                      "Tamil Nadu",
                      "Telangana",
                      "Tripura",
                      "Uttarakhand",
                      "Uttar Pradesh",
                      "West Bengal",
                    ]}
                  />
                </fieldset>
                <span className="  warn" id="state-warn">
                  Please select your state.
                </span>
                {/* <span className="  warn " id="state-text-warn">
                  State name too long (Max 20)
                </span> */}
              </div>
              <div className="col-lg-6 col-12 righttt district-select">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">
                    &nbsp;City / District
                  </legend>
                  <Select2
                    className="form-control"
                    id="city"
                    disabled
                    value={this.state.city_district}
                    options={options2}
                    onChange={(e) => {
                      this.setState({ city_district: e.target.value });
                    }}
                    data={this.state.districts}
                  />
                </fieldset>
                <span className="  warn" id="city-warn">
                  Please select your city / district.
                </span>
                {/* <span className="  warn " id="city-text-warn">
                  City name too long (Max 50)
                </span> */}
              </div>
            </div>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset">
                  <legend className="agent-legend">&nbsp;Pin Code</legend>
                  <input
                    className="agent-input"
                    id="pin"
                    type="tel"
                    disabled
                    maxLength="6"
                    autoComplete="off"
                    value={this.state.pinCode}
                    placeholder="Enter Pin Code"
                    onChange={(e) => this.setState({ pinCode: e.target.value })}
                  />
                </fieldset>
                <span className="  warn" id="pin-warn">
                  Please enter a valid pin code
                </span>
                <span className="  warn" id="pin-warn-empty">
                  Please enter your pin code
                </span>
                <span className="  warn " id="pin-text-warn">
                  Pincode must be of 6 digit
                </span>
              </div>
            </div>
            {/* Address proof------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Address Proof:</u>
            </span>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset">
                  <legend className="agent-legend">&nbsp;Address Proof</legend>
                  <a
                  href={this.state.addressproof}
                  target="_blank"
                  className="btn view-btn mt-1 w-100"
                >
                  View address proof
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                    alt=""
                  />
                </a>
                </fieldset>
                <br />
                <span className="  warn" id="addressproof-warn">
                  Please select a file with max-size of 10 mb
                </span>
                <span className="  warn" id="addressproof-warnsize">
                  file too big (max-size 10 mb)
                </span>
              </div>
            </div>

            {/* Work Address proof------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Work Address :</u>
            </span>

            <span className="text-orange same-as">
              Copy same address as above
            </span>
            <div className="d-flex agent-radio">
              <div className="d-flex ">
                <input
                  type="radio"
                  id="sameAddressy"
                  name="sameAddress"
                  value="Y"
                  disabled
                  checked={this.state.sameAddress === "Y"}
                  onChange={this.onRadioChange}
                />
                <label htmlFor="sameAddressy" className="uploadradiotext ">
                  YES
                </label>
                <br />
                <input
                  type="radio"
                  id="sameAddressn"
                  name="sameAddress"
                  value="N"
                  disabled
                  checked={this.state.sameAddress === "N"}
                  onChange={this.onRadioChange}
                />
                <label htmlFor="sameAddressn" className="uploadradiotext ml-3">
                  NO
                </label>
              </div>
            </div>
            {this.state.sameAddress == "N" ? (
              <>
                <div className=" row justify-content-between mb-2 mt-4">
                  <div className="col-lg-6 col-12 lefttt">
                    <fieldset className=" my-fieldset agent-left">
                      <legend className="agent-legend">
                        &nbsp;Full Address
                      </legend>
                      <input
                        className="agent-input"
                        id="workladdress"
                        type="text"
                        maxLength="150"
                        autoComplete="off"
                        disabled
                        placeholder="Enter Your Address"
                        value={this.state.workaddress}
                        onChange={(e) =>
                          this.setState({ workaddress: e.target.value })
                        }
                      />
                    </fieldset>
                    <span className="  warn" id="workaddress-warn">
                      Please enter your address.
                    </span>
                    <span className="  warn " id="workaddress-text-warn">
                      Address too long (Max 150)
                    </span>
                  </div>
                  <div className="col-lg-6 col-12 righttt">
                    <fieldset className=" my-fieldset agent-right">
                      <legend className="agent-legend">&nbsp;Landmark</legend>
                      <input
                        className="agent-input"
                        id="worklandmark"
                        type="text"
                        maxLength="100"
                        autoComplete="off"
                        placeholder="Enter Landmark"
                        disabled
                        value={this.state.worklandmark}
                        onChange={(e) =>
                          this.setState({ worklandmark: e.target.value })
                        }
                      />
                    </fieldset>
                    <span className="  warn" id="worklandmark-warn">
                      Please enter a landmark.
                    </span>
                    <span className="  warn " id="worklandmark-text-warn">
                      Landmark name too long (Max 50)
                    </span>
                  </div>
                </div>
                <div className="row justify-content-between my-2">
                  <div className="col-lg-6 col-12 lefttt state-select">
                    <fieldset className=" my-fieldset agent-left">
                      <legend className="agent-legend">&nbsp;State</legend>
                      <Select2
                        className="form-control "
                        id="workstate"
                        value={this.state.workstate}
                        options={options}
                        disabled
                        onChange={(e) => {
                          this.setState({ workstate: e.target.value });
                          this.filterDistricts2();
                        }}
                        data={[
                          "Andaman and Nicobar Island (UT)",
                          "Andhra Pradesh",
                          "Arunachal Pradesh",
                          "Assam",
                          "Bihar",
                          "Chandigarh (UT)",
                          "Chhattisgarh",
                          "Dadra and Nagar Haveli (UT)",
                          "Daman and Diu (UT)",
                          "Delhi (NCT)",
                          "Goa",
                          "Gujarat",
                          "Haryana",
                          "Himachal Pradesh",
                          "Jammu and Kashmir(UT)",
                          "Jharkhand",
                          "Karnataka",
                          "Kerala",
                          "Ladakh(UT)",
                          "Lakshadweep (UT)",
                          "Madhya Pradesh",
                          "Maharashtra",
                          "Manipur",
                          "Meghalaya",
                          "Mizoram",
                          "Nagaland",
                          "Odisha",
                          "Puducherry (UT)",
                          "Punjab",
                          "Rajasthan",
                          "Sikkim",
                          "Tamil Nadu",
                          "Telangana",
                          "Tripura",
                          "Uttarakhand",
                          "Uttar Pradesh",
                          "West Bengal",
                        ]}
                      />
                    </fieldset>
                    <span className="  warn" id="workstate-warn">
                      Please select your state.
                    </span>
                    <span className="  warn " id="workstate-text-warn">
                      State name too long (Max 20)
                    </span>
                  </div>
                  <div className="col-lg-6 col-12 righttt district-select">
                    <fieldset className=" my-fieldset agent-right">
                      <legend className="agent-legend">
                        &nbsp;City / District
                      </legend>
                      <Select2
                        className="form-control"
                        value={this.state.workcity_district}
                        options={options2}
                        id="workcity"
                        disabled
                        onChange={(e) => {
                          this.setState({ workcity_district: e.target.value });
                        }}
                        data={this.state.workdistricts}
                      />
                    </fieldset>
                    <span className="  warn" id="workcity-warn">
                      Please select your city / district.
                    </span>
                    {/* <span className="  warn " id="workcity-text-warn">
                      City name too long (Max 50)
                    </span> */}
                  </div>
                </div>
                <div className=" row justify-content-between my-2">
                  <div className="col-lg-6 col-12 lefttt">
                    <fieldset className=" my-fieldset">
                      <legend className="agent-legend">&nbsp;Pin Code</legend>
                      <input
                        className="agent-input"
                        id="workpin"
                        type="text"
                        maxLength="6"
                        autoComplete="off"
                        disabled
                        placeholder="Enter Pin Code"
                        value={this.state.workpinCode}
                        onChange={(e) =>
                          this.setState({ workpinCode: e.target.value })
                        }
                      />
                    </fieldset>
                    <span className="  warn" id="workpin-warn">
                      Please enter a valid pin code
                    </span>
                    <span className="  warn" id="workpin-warn-empty">
                      Please enter pin code
                    </span>
                    <span className="  warn " id="workpin-text-warn">
                      Pin code must be of 6 digit
                    </span>
                  </div>
                </div>
                <div className="row justify-content-between my-2">
                  <div className="col-lg-6 col-12 lefttt">
                    <fieldset className="my-fieldset">
                      <legend className="agent-legend">
                        &nbsp;Work Address Proof
                      </legend>
                      <a
                  href={this.state.workaddressproof}
                  target="_blank"
                  className="btn view-btn mt-1 w-100"
                >
                  View address proof
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                    alt=""
                  />
                </a>
                    </fieldset>
                    <span className="col-12 capt">
                      Aadhar/Electicity Bill/Notery Registry
                    </span>
                    <br />{" "}
                    <span className="warn" id="workaddressproof-warn">
                      Please select a file with max-size of 10 mb
                    </span>
                    <span className="  warn" id="workaddressproof-warnsize">
                      
                    </span>
                  </div>
                </div>
              </>
            ) : null}

            {/* Other Details------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Other Details:</u>
            </span>
            {this.state.aadharNumber.trim() != "" && this.state.panNumber.trim() != ""?
                        <div className="row justify-content-between my-2">
                        <div className="col-lg-6 col-12 lefttt">
                          <fieldset className="my-fieldset agent-left">
                            <legend className="agent-legend">&nbsp;Aadhar Number</legend>
                            <input
                              className="agent-input"
                              id="firstname"
                              value={this.state.aadharNumber}
                              type="text"
                              placeholder="Enter Aadhar Number"
                              disabled
                              autoComplete="off"
                            />
                          </fieldset>
          
                        </div>
                        <div className="col-lg-6 col-12 righttt">
                          <fieldset className=" my-fieldset agent-right">
                            <legend className="agent-legend">&nbsp;Pan Number</legend>
                            <input
                              className="agent-input"
                              id="lastname"
                              value={this.state.panNumber}
                              type="text"
                              autoComplete="off"
                              placeholder="Enter Pan Number"
                              disabled
                            />
                          </fieldset>
          
                        </div>
                      </div> : null
          }
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;Aadhar Card</legend>
                  <a
                  href={this.state.aadharCard}
                  target="_blank"
                  className="btn view-btn mt-1 w-100"
                >
                  View aadhar card
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                    alt=""
                  />
                </a>
                </fieldset>
                <span className="warn" id="aadhar-warn">
                  Please select a file with max-size of 10 mb
                </span>
                <span className="warn" id="aadhar-warnsize">
                  File too big (max-size 10 mb)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;PAN Card</legend>
                  <a
                  href={this.state.panCard}
                  target="_blank"
                  className="btn view-btn mt-1 w-100"
                >
                  View pan card
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                    alt=""
                  />
                </a>
                </fieldset>
                <span className="  warn" id="pan-warn">
                  Please select a file with a max-size of 10 mb
                </span>
                <span className="  warn" id="pan-warnsize">
                  File too big (max-size 10 mb)
                </span>
              </div>
            </div>
            {/* Application Details------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Application Details:</u>
            </span>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className="my-fieldset">
                  <legend className="agent-legend">
                    &nbsp;Business / User Profile
                  </legend>
                  <a
                  href={this.state.profile}
                  target="_blank"
                  className="btn view-btn mt-1 w-100"
                >
                  View profile form
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                    alt=""
                  />
                </a>
                </fieldset>
                <span className="  warn" id="profile-warn">
                  Please select a file with a max-size of 10 mb
                  <br />
                </span>
                <span className="  warn" id="profile-warnsize">
                  File too big (max-size 10 mb)
                  <br />
                </span>
                
              </div>

              <div className="col-lg-6 col-12 righttt">
                <fieldset className="my-fieldset">
                  <legend className="agent-legend">&nbsp; Profile Photo</legend>
                  <a
                  href={this.state.profilephoto}
                  target="_blank"
                  className="btn view-btn mt-1 w-100"
                >
                  View profile photo
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                    alt=""
                  />
                </a>
                </fieldset>
                <span className="  warn" id="profilephoto-warn">
                  Please select a file with a max-size of 10 mb
                  <br />
                </span>
                <span className="  warn" id="profilephoto-warnsize">
                  File too big (max-size 10 mb)
                  <br />
                </span>
              </div>
            </div>

            <div className="questions">
              <ul>
                <div className="ques">
                  <div className="d-flex">
                    <div className="ques-bullet">
                      {" "}
                      <img src="/img/Ellipse 2753.svg" alt="" />
                    </div>{" "}
                    <p className="mb-2">
                      {" "}
                      Are you included in or providing any partner or agent services already?
                      <span className=" ">
                        <sup className="sup__red">*</sup>
                      </span>
                    </p>
                  </div>
                  <div className="d-flex agent-radio mb-3">
                    <div className="d-flex ml-2">
                      <input
                        type="radio"
                        id="previousStatusy"
                        name="previousStatus"
                        value="Y"
                        disabled
                        checked={this.state.previousStatus === "Y"}
                        onChange={this.onRadioChange}
                      />
                      <label
                        htmlFor="previousStatusy"
                        className="uploadradiotext ml-2"
                      >
                        YES
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="previousStatusn"
                        name="previousStatus"
                        value="N"
                        disabled
                        checked={this.state.previousStatus === "N"}
                        onChange={this.onRadioChange}
                      />
                      <label
                        htmlFor="previousStatusn"
                        className="uploadradiotext ml-3"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ques">
                  <div className="d-flex">
                    <div className="ques-bullet">
                      {" "}
                      <img src="/img/Ellipse 2753.svg" alt="" />
                    </div>{" "}
                    <p className="mb-2">
                      {" "}
                      Are you a health insurance provider or any other policy agent?
                      <span className=" ">
                        <sup className="sup__red">*</sup>
                      </span>
                    </p>
                  </div>
                  <div className="d-flex agent-radio ">
                    <div className="d-flex ml-2">
                      <input
                        type="radio"
                        id="previousInsuranceStatusy"
                        name="previousInsuranceStatus"
                        value="Y"
                        disabled
                        checked={this.state.previousInsuranceStatus === "Y"}
                        onChange={this.onRadioChange}
                      />
                      <label
                        htmlFor="previousInsuranceStatusy"
                        className="uploadradiotext ml-2"
                      >
                        YES
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="previousInsuranceStatusn"
                        name="previousInsuranceStatus"
                        value="N"
                        disabled
                        checked={this.state.previousInsuranceStatus === "N"}
                        onChange={this.onRadioChange}
                      />
                      <label
                        htmlFor="previousInsuranceStatusn"
                        className="uploadradiotext ml-3"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset">
                  <legend className="koid">
                    &nbsp;Partner / Executive Code&nbsp;
                  </legend>
                  <input
                    className="agent-input"
                    id="partnercode"
                    type="text"
                    maxLength="10"
                    autoComplete="off"
                    disabled
                    placeholder="Enter Code"
                    value={this.state.partnerCode}
                    onChange={(e) => {
                      this.setState({ partnerCode: e.target.value });
                    }}
                  />
                </fieldset>
                <span className="  warn " id="firstname-text-warn">
                  Code too long (Max 10)
                </span>
              </div>
            </div>

          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- completed --> */}
                  <div id="tab2" className="tab-content">
                    <div className="myservicescrolldiv mt-4">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="myseriveloaderdiv">
                            <div className="myseriveloaderinsidediv">
                              <center>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/img/loader.gif"
                                  }
                                  className="myseriveloader"
                                />
                              </center>
                            </div>
                          </div>
                          <div className="vaultscrolldiv vaultscrolldiv2 w-100">
                          <div className="container-fluid">
                            {/* <p className="agent__overview__head pl-2">
                              Agent Kit Including :-
                            </p> */}
                            <div className="row">
                              {this.state.documents.map((item, index) => {
                                let name = Object.keys(item)[0];
                                let url = Object.values(item)[0];
                                return (
                                  <div key={index} className="col-xl-2 col-md-4 col-12 mb-4 specialpad">
                                  <div className="card position-relative border-0 ">
                                    <div className="card-body py-3 px-3 supportcardbody">
                                      <div
                                        className="d-flex flex-column justify-content-center align-items-center cursorpointer supportcardmargin"
                                        // onClick={this.handleCheckVaultDocs}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 247.svg"
                                          }
                                          className="vault_card_img supportimg"
                                        />
                                      </div>
                                      <p className="vault_card_docName mt-2">
                                        {name}
                                      </p>
                                      <div>
  
  
                                        <div className="d-flex mt-3 justify-content-center">
                                          <a
                                            href={url}
                                            className="btn-sm vault_card_btn w-100 py-1 donthoverme"
                                            id="download_btn"
                                            target="_blank"
                                          >
                                            Download{" "}
                                            <span>
                                              <img
                                                className="vault_card_btn_img"
                                                src="img/download_icon.svg"
                                                alt="download"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                )
                              })}
                             



                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <div
            className="modal fade"
            id="Unlock-Service"
            tabIndex="-1"
            role="dialog"
            data-backdrop="static"
            data-keyboard="false"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div className="modal-content back-modal " id="unlockotpmodal">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => this.setState({ rtime: 1 })}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text enterotp">Enter OTP</h4>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's mobile
                  </h5>
                  <input
                    className="Userinfo-input otp-input"
                    maxLength="6"
                    type="tel"
                    value={this.state.mobileotp}
                    onChange={(e) =>
                      this.setState({ mobileotp: e.target.value })
                    }
                    id="unlockmobileinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidunlockmobileotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidmobile"></span>
                  </p>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's email
                  </h5>
                  <input
                    className="Userinfo-input otp-input "
                    maxLength="6"
                    type="tel"
                    value={this.state.emailotp}
                    onChange={(e) =>
                      this.setState({ emailotp: e.target.value })
                    }
                    id="unlockemailinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidunlockemailotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                      <span className="invalidemail"></span>
                  </p>
                </div>

                <div className="d-flex justify-content-end  otpmodaloptions">
                  {/* <h6 onClick={this.changeuserinfo}>Change mobile/email</h6>{" "} */}
                  <h6
                    className="text-muted"
                    id="resendUnlockOTP"
                    onClick={this.resenduserOtp}
                  >
                    Resend OTP{" "}
                    {this.state.rtime != 0 ? `(${this.state.rtime} Sec)` : " "}
                  </h6>
                </div>
                <p className="mb-0" id="otpsentunlocksuccess">
                  OTP sent successfully{" "}
                </p>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendUnlockOtpLoader"
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={this.SubmitOTP}
                    className="btn  send-btn text-white"
                    id="submitunlockOtpbtn"
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="modal-content back-modal " id="unlockotp">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => this.setState({ rtime: 1 })}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="modal-body border-0 text-center pb-0">
                  <h4 className="my-warn-text unlock-text">
                    Send OTP to unlock user's information!
                  </h4>
                  <img
                    className="p-2"
                    src={process.env.PUBLIC_URL + "/img/Group 5572.svg"}
                    width="120px"
                  />
                  <p className="my-success-para m-0 contotp">
                    Continue to send OTP ?
                  </p>
                </div>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendUnlockOtpLoader"
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={() =>
                      this.serviceunlock(
                        this.state.serviceid,
                        this.state.servicename
                      )
                    }
                    className="btn  send-btn text-white  "
                    id="sendUnlockOtp"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Profile;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import $, { data } from "jquery";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import jsondata from "../states-and-districts.json"
var id;
const key = process.env.REACT_APP_ENCRYPTOR_KEY;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
var encryptor = require("simple-encryptor")(key);
export class IncompleteAgentregistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toCheckout: false,
      pay: true,
      data: jsondata,
      states: [],
      districts: [],
      workdistricts: [],
      applicationid: "",
      file: "",
      firstName: "",
      lastName: "",
      contactNo: "",
      email: "",
      DOB: "",
      gender: "c",
      applicationType: "a",
      applicationOther: "",
      koid: "",
      state: "",
      city_district: "",
      landmark: "",
      address: "",
      pinCode: "",
      aadharCard: "",
      aadharCardback:"",
      aadharCardbackbase64:"",
      panCard: "",
      addressproof: "",
      cancelCheque: "",
      profile: "",
      profilephoto: "",
      partnerCode: "",
      bankAccountName: "",
      accountNo: "",
      IFSCcode: "",
      workstate: "",
      workcity_district: "",
      workaddress: "",
      worklandmark: "",
      workpinCode: "",
      workaddressproof: "",
      sameAddress: "N",
      previousStatus: "N",
      previousInsuranceStatus: "N",
      panCardbase64: "",
      aadharCardbase64: "",
      cancelChequebase64: "",
      profilebase64: "",
      profilephotobase64: "",
      addressproofbase64: "",
      workaddressproofbase64: "",
      applicationTypeArray:[],
      panNumber:"",
      aadharNumber:"",
      orderid:"",
      idType:""
    };
  }

  showCommission = () =>{
      $("#viewCommission").modal("show")
  }
  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search);
     id = queryParams.get("partnerid");
    if (id != null) {
      this.getagentdetils();
    }
    else
    {
      this.props.history.push("/register");
    }



    if(this.props.location.state ){
      let  order_id = this.props.location.state.order_id; 
      this.setState({orderid: order_id})
    }
    else
    {
      this.setState({orderid: ""})
    }



    window.scrollTo(0, 0);
   
    $(document.body).css("background-color", "#fff");

    const apptype = new URLSearchParams(this.props.location.search).get(
      "applicationtype"
    );
   
    if (apptype == "nictagent") {
      this.setState({ applicationType: "NICT" });
      $("#koidinput").show();
    }
    axios.post(`${baseUrl}p=getApplicationType`,{ },
    {
      headers: {
        Authkey: process.env.REACT_APP_API_KEY,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    }
    ).then((res) => {
      if(res.data.code == 200 && res.data.status =="success"){
        this.setState({applicationTypeArray:res.data.Data})
      }else{
        this.showNotification("Something Went Wrong! Try Again Later")
      }
    }).catch(err => {
      this.showNotification("Something Went Wrong! Try Again Later")
    })
  }


  getagentdetils = () => {
    axios.post(`${baseUrl}p=getPreDetails`,{ 
      agentId:id
    },
    {
      headers: {
        Authkey: process.env.REACT_APP_API_KEY,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    }
    ).then((res) => {
      if(res.data.code == 200 && res.data.status =="success"){
        this.setState({
            firstName: res.data.details.firstName,
            lastName: res.data.details.lastName,
            contactNo: res.data.details.contact,
            email: res.data.details.email,
            idType: res.data.details.type,
            state: res.data.details.state,
        });

        this.state.data.filter((item) => {
          if (item.state ==  res.data.details.state) {
            this.setState({ districts: item.districts,
              city_district: res.data.details.city, });
          }
        });

      }else{
        this.props.history.push("/register");
      }
    }).catch(err => {
      this.showNotification("Something Went Wrong! Try Again Later")
    })
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      if (file) {
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      }
    });
  };

  handleFileInputChange1 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#cheque-warnsize").show();
      $("#cheque-warnsize").html("File too big!");
      $("#upload1").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#cheque-warnsize").show();
      $("#cheque-warnsize").html("File name limit exceed!");
      $("#upload1").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            cancelChequebase64: result,
            cancelCheque: file.name,
            file,
          });
          $("#upload1-label").hide();
          $("#remove-file1").show();
          $("#remove-file1").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleFileInputChange2 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#addressproof-warnsize").show();
      $("#addressproof-warnsize").html("File too big!");
      $("#upload2").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#addressproof-warnsize").show();
      $("#addressproof-warnsize").html("File name limit exceed!");
      $("#upload2").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            addressproofbase64: result,
            addressproof: file.name,
            file,
          });
          $("#upload2-label").hide();
          $("#remove-file2").show();
          $("#remove-file2").css("display", "flex");
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleFileInputChange3 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520 ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#workaddressproof-warnsize").show();
      $("#workaddressproof-warnsize").html("File too big!");
      $("#upload3").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#workaddressproof-warnsize").show();
      $("#workaddressproof-warnsize").html("File name limit exceed!");
      $("#upload3").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            workaddressproofbase64: result,
            workaddressproof: file.name,
            file,
          });
          $("#upload3-label").hide();
          $("#remove-file3").show();
          $("#remove-file3").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleFileInputChange4 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#aadhar-warnsize").show();
      $("#aadhar-warnsize").html("File name limit exceed!");
      $("#upload4").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#aadhar-warnsize").show();
      $("#aadhar-warnsize").html("File name limit exceed!");
      $("#upload4").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            aadharCardbase64: result,
            aadharCard: file.name,
            file,
          });
          $("#upload4-label").hide();
          $("#remove-file4").show();
          $("#remove-file4").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleFileInputChange5 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#pan-warnsize").show();
      $("#pan-warnsize").html("File too big!");
      $("#upload5").focus();
      this.setState({ file: "" });
    }if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#pan-warnsize").show();
      $("#pan-warnsize").html("File name limit exceed!");
      $("#upload5").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            panCardbase64: result,
            panCard: file.name,
            file,
          });
          $("#upload5-label").hide();
          $("#remove-file5").show();
          $("#remove-file5").css("display", "flex");
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };
  handleFileInputChange6 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];

    if (file.size > 20971520  || file.name.length > 100) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#profile-warnsize").show();
      $("#profile-warnsize").html("File too big!");
      $("#upload6").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#profile-warnsize").show();
      $("#profile-warnsize").html("File name limit exceed!");
      $("#upload6").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            profilebase64: result,
            profile: file.name,
            file,
          });
          $("#upload6-label").hide();
          $("#remove-file6").show();
          $("#remove-file6").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };
  handleFileInputChange7 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];

    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#profilephoto-warnsize").show();
      $("#profilephoto-warnsize").html("File too big!");
      $("#upload7").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#profilephoto-warnsize").show();
      $("#profilephoto-warnsize").html("File name limit exceed!");
      $("#upload7").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            profilephotobase64: result,
            profilephoto: file.name,
            file,
          });
          $("#upload7-label").hide();
          $("#remove-file7").show();
          $("#remove-file7").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };
  handleFileInputChange8 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];

    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#aadharCardback-warnsize").show();
      $("#aadharCardback-warnsize").html("File too big!");
      $("#upload8").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#aadharCardback-warnsize").show();
      $("#aadharCardback-warnsize").html("File name limit exceed!");
      $("#upload8").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            aadharCardbackbase64: result,
            aadharCardback: file.name,
            file,
          });
          $("#upload8-label").hide();
          $("#remove-file8").show();
          $("#remove-file8").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleSubmit = (e) => {
    const nameregex = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i;
    const pincoderegex = /^[1-9][0-9]{5}$/;
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    const aadharRegex = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
    const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    e.preventDefault();
    if (this.state.applicationType.trim() == "a") {
      $(".warn").hide();
      $("#applicationtype-warn").show();
      $("#applicationtype").focus();
    } else if (
      this.state.applicationType == "NICT" &&
      this.state.koid.length > 25
    ) {
      $(".warn").hide();
      $("#koid-text-warn").show();
      $("#koidinput").focus();
    } else if (
      this.state.applicationType == "kiosk" &&
      this.state.koid.length > 25
    ) {
      $(".warn").hide();
      $("#koid-text-warn").show();
      $("#koidinput").focus();
    } else if (
      this.state.applicationType == "other" &&
      this.state.applicationOther.trim() == ""
    ) {
      $(".warn").hide();
      $("#other-warn").show();
      $("#other").focus();
    } else if (this.state.applicationOther.length > 50) {
      $(".warn").hide();
      $("#other-text-warn").show();
      $("#other").focus();
    } else if (this.state.firstName.trim() == "") {
      $(".warn").hide();
      $("#firstname-warn").show();
      $("#firstname").focus();
    } else if (this.state.firstName.length > 50) {
      $(".warn").hide();
      $("#firstname-text-warn").show();
      $("#firstname").focus();
    } else if (!nameregex.test(this.state.firstName)) {
      $(".warn").hide();
      $("#firstname-invalidtext-warn").show();
      $("#firstname").focus();
    } else if (this.state.lastName.trim() == "") {
      $(".warn").hide();
      $("#lastname-warn").show();
      $("#lastname").focus();
    } else if (this.state.lastName.length > 50) {
      $(".warn").hide();
      $("#lastname-text-warn").show();
      $("#lastname").focus();
    } else if (!nameregex.test(this.state.lastName)) {
      $(".warn").hide();
      $("#lastname-invalidtext-warn").show();
      $("#lastname").focus();
    } else if (this.state.email.trim() == "") {
      $(".warn").hide();
      $("#email-warn-empty").show();
      $("#email").focus();
    } else if (this.state.email.length > 100) {
      $(".warn").hide();
      $("#email-text-warn").show();
      $("#email").focus();
    } else if (!emailregex.test(this.state.email)) {
      $(".warn").hide();
      $("#email-warn").show();
      $("#email").focus();
    } else if (this.state.contactNo.trim() == "") {
      $(".warn").hide();
      $("#mobile-warn-empty").show();
      $("#mobile").focus();
    } else if (this.state.contactNo.length > 10) {
      $(".warn").hide();
      $("#mobile-text-warn").show();
      $("#mobile").focus();
    } else if (!contactNoregex.test(this.state.contactNo)) {
      $(".warn").hide();
      $("#mobile-warn").show();
      $("#mobile").focus();
    } else if (this.state.DOB.trim() == "") {
      $(".warn").hide();
      $("#dob-warn").show();
      $("#dob").focus();
    } else if (this.state.gender.trim() == "c") {
      $(".warn").hide();
      $("#gender-warn").show();
      $("#gender").focus();
    } else if (this.state.bankAccountName.trim() == "") {
      $(".warn").hide();
      $("#accountholdername-warn-empty").show();
      $("#accountholdername").focus();
    } else if (this.state.bankAccountName.length > 100) {
      $(".warn").hide();
      $("#accountholdername-text-warn").show();
      $("#accountholdername").focus();
    } else if (
      this.state.accountNo.trim() == "" 
    ) {
      $(".warn").hide();
      $("#accountno-warn").show();
      $("#accountno-warn").html("Please enter account no.");

      $("#accountno").focus();
    } else if (isNaN(this.state.accountNo)) {
      $(".warn").hide();
      $("#accountno-warn").show();
      $("#accountno-warn").html("Please enter valid account no.");

      $("#accountno").focus();
    } else if (this.state.accountNo.length > 50) {
      $(".warn").hide();
      $("#accountno-text-warn").show();
      $("#accountno").focus();
    } else if (this.state.IFSCcode.trim() == "") {
      $(".warn").hide();
      $("#ifsc-warn").show();
      $("#ifsc").focus();
    } else if (this.state.IFSCcode.length > 50) {
      $(".warn").hide();
      $("#ifsc-text-warn").show();
      $("#ifsc").focus();
    } else if (this.state.cancelCheque.trim() == "") {
      $(".warn").hide();
      $("#cheque-warn").show();
      $("#cheque-warn").html( "Please select a file as Bank Proof");

      $("#upload1").focus();
      this.showNotification(
        "Please select a file as Bank Proof"
      );
    } else if (this.state.address.trim() == "") {
      $(".warn").hide();
      $("#address-warn").show();
      $("#address").focus();
    } else if (this.state.address.length > 150) {
      $(".warn").hide();
      $("#address-text-warn").show();
      $("#address").focus();
    } else if (this.state.landmark.trim() == "") {
      $(".warn").hide();
      $("#landmark-warn-empty").show();
      $("#landmark").focus();
    } else if (this.state.landmark.length > 100) {
      $(".warn").hide();
      $("#landmark-text-warn").show();
      $("#landmark").focus();
    } else if (this.state.state.trim() == "") {
      $(".warn").hide();
      $("#state-warn").show();
      $("#state").focus();
      this.showNotification("Please select a state");
    } else if (this.state.state.length > 40) {
      $(".warn").hide();
      $("#state-text-warn").show();
      $("#state").focus();
    } else if (this.state.city_district.trim() == "") {
      $(".warn").hide();
      $("#city-warn").show();
      $("#city").focus();
    } else if (this.state.city_district.length > 50) {
      $(".warn").hide();
      $("#city-text-warn").show();
      $("#city").focus();
      this.showNotification("Please select district");
    }else if(this.state.pinCode.trim() == ""){
      $(".warn").hide();
      $("#pin-warn-empty").show();
      $("#pin").focus();
    } else if (this.state.pinCode.length > 6 || this.state.pinCode.length < 6) {
      $(".warn").hide();
      $("#pin-warn").show();
      $("#pin").focus();
    } else if (
      !pincoderegex.test(this.state.pinCode) &&
      this.state.pinCode.length == 6
    ) {
      $(".warn").hide();
      $("#pin-warn").show();
      $("#pin").focus();
    } else if (this.state.addressproof.trim() == "") {
      $(".warn").hide();
      $("#addressproof-warn").show();
      $("#addressproof-warn").html("Please select a file as Address proof");
      $("#upload2").focus();
      this.showNotification("Please select a file as Address proof");
    } else if (
      this.state.sameAddress == "N" &&
      this.state.workaddress.trim() == ""
    ) {
      $(".warn").hide();
      $("#workaddress-warn").show();
      $("#workladdress").focus();
    } else if (
      this.state.sameAddress == "N" &&
      this.state.workaddress.length > 150
    ) {
      $(".warn").hide();
      $("#workaddress-text-warn").show();
      $("#workaddress").focus();
    } else if (
      this.state.sameAddress == "N" &&
      this.state.worklandmark.trim() == ""
    ) {
      $(".warn").hide();
      $("#worklandmark-warn").show();
      $("#worklandmark").focus();
    } else if (
      this.state.sameAddress == "N" &&
      this.state.worklandmark.length > 100
    ) {
      $(".warn").hide();
      $("#worklandmark-text-warn").show();
      $("#worklandmark").focus();
    } else if (
      this.state.sameAddress == "N" &&
      this.state.workstate.trim() == ""
    ) {
      $(".warn").hide();
      $("#workstate-warn").show();
      $("#workstate").focus();
      this.showNotification("Please select work state");
    } else if (
      this.state.sameAddress == "N" &&
      this.state.workstate.length > 40
    ) {
      $(".warn").hide();
      $("#workstate-text-warn").show();
      $("#workstate").focus();
    } else if (
      this.state.sameAddress == "N" &&
      this.state.workcity_district.trim() == ""
    ) {
      $(".warn").hide();
      $("#workcity-warn").show();
      $("#workcity").focus();
      this.showNotification("Please select work district");
    } else if (
      this.state.sameAddress == "N" &&
      this.state.workcity_district.length > 50
    ) {
      $(".warn").hide();
      $("#workcity-text-warn").show();
      $("#workcity").focus();
    } else if (
      this.state.sameAddress == "N" &&
      (this.state.workpinCode.trim() == "")
    ) {
      $(".warn").hide();
      $("#workpin-warn-empty").show();
      $("#workpin").focus();
    } else if (
      this.state.sameAddress == "N" &&
      (this.state.workpinCode.length > 6 || this.state.workpinCode.length < 6)
    ) {
      $(".warn").hide();
      $("#workpin-warn").show();
      $("#workpin").focus();
    } else if (
      this.state.sameAddress == "N" &&
      this.state.workpinCode.length < 6
    ) {
      $(".warn").hide();
      $("#workpin-warn").show();
      $("#workpinCode").focus();
    } else if (
      this.state.sameAddress == "N" &&
      !pincoderegex.test(this.state.workpinCode)
    ) {
      $(".warn").hide();
      $("#workpin-warn").show();
      $("#workpin").focus();
    } else if (
      this.state.sameAddress == "N" &&
      this.state.workaddressproof.trim() == ""
    ) {
      $(".warn").hide();
      $("#workaddressproof-warn").show();
      $("#workaddressproof-warn").html("Please select a file as work Address proof");

      $("#upload3").focus();
      this.showNotification(
        "Please select a valid file as work Address proof"
      );
    } else if (this.state.aadharNumber.trim() == "") {
      $(".warn").hide();
      $("#aadharNumber-warn").show();
      $("#aadharNumber").focus();
    } else if (!aadharRegex.test(this.state.aadharNumber)) {
      $(".warn").hide();
      $("#aadharNumber-invalidtext-warn").show();
      
      $("#aadharNumber").focus();
    } else if (this.state.panNumber.trim() == "") {
      $(".warn").hide();
      $("#panNumber-warn").show();
      $("#panNumber").focus();
    } else if (!panRegex.test(this.state.panNumber)) {
      $(".warn").hide();
      $("#panNumber-invalidtext-warn").show();
      
      $("#panNumber").focus();
    } else if (this.state.aadharCard.trim() == "") {
      $(".warn").hide();
      $("#aadhar-warn").show();
      $("#aadhar-warn").html("Please select a file for Aadhar card front");
      $("#upload4").focus();
      this.showNotification("Please select a file for Aadhar card front");
    } else if (this.state.aadharCardback.trim() == "") {
      $(".warn").hide();
      $("#aadharCardback-warn").show();
      $("#aadharCardback-warn").html("Please select a file for Aadhar card back");
      $("#upload8").focus();
      this.showNotification("Please select a file for Aadhar card back");
    } else if (this.state.panCard.trim() == "") {
      $(".warn").hide();
      $("#pan-warn").show();
      $("#pan-warn").html("Please select a file for Pan card");

      $("#upload5").focus();
      this.showNotification("Please select a file for Pan card");
    } else if (this.state.profile.trim() == "") {
      $(".warn").hide();
      $("#profile-warn").show();
      $("#upload6").focus();
    } else if (this.state.profilephoto.trim() == "") {
      $(".warn").hide();
      $("#profilephoto-warn").show();
      $("#upload7").focus();
    } else {
      const aadharNumberwithoutSpace = this.state.aadharNumber.replace(/ /g, ""); 
      var mySentence = this.state.firstName.trim();
      mySentence = mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      var mywords = words.join(" ");
      var mySentence2 = this.state.lastName.trim();
      mySentence2 = mySentence2.replace(/\s+/g, " ");
      const words2 = mySentence2.split(" ");

      for (let i = 0; i < words2.length; i++) {
        words2[i] = words2[i][0].toUpperCase() + words2[i].substr(1);
      }
      var mywords2 = words2.join(" ");

      var mySentence3 = this.state.bankAccountName.trim();
      mySentence3 = mySentence3.replace(/\s+/g, " ");
      const words3 = mySentence3.split(" ");

      for (let i = 0; i < words3.length; i++) {
        words3[i] = words3[i][0].toUpperCase() + words3[i].substr(1);
      }
      var mywords3 = words3.join(" ");
      $(".warn").hide();
      this.setState({ pay: false });
      axios
        .post(
          `${baseUrl}p=registerAfter`,
          {  agentId:id,
            DOB: this.state.DOB.trim(),
            gender: this.state.gender.trim(),
            applicationType: this.state.applicationType.trim(),
            applicationOther: this.state.applicationOther.trim(),
            koid: this.state.koid.trim(),
            address: this.state.address.trim(),
            landmark: this.state.landmark,
            pinCode: this.state.pinCode,
            aadharCard: this.state.aadharCard.trim(),
            aadharCardback: this.state.aadharCardback.trim(),
            panCard: this.state.panCard.trim(),
            addressproof: this.state.addressproof.trim(),
            cancelCheque: this.state.cancelCheque.trim(),
            profile: this.state.profile.trim(),
            profilephoto: this.state.profilephoto.trim(),
            partnerCode: this.state.partnerCode.trim(),
            previousStatus: this.state.previousStatus.trim(),
            previousInsuranceStatus: this.state.previousInsuranceStatus.trim(),
            bankAccountName: mywords3.trim(),
            accountNo: this.state.accountNo.trim(),
            IFSCcode: this.state.IFSCcode.trim(),
            sameAddress: this.state.sameAddress.trim(),
            workstate: this.state.workstate,
            workcity_district: this.state.workcity_district,
            workaddress: this.state.workaddress,
            worklandmark: this.state.worklandmark,
            workpinCode: this.state.workpinCode.trim(),
            workaddressproof: this.state.workaddressproof.trim(),
            workaddressproofbase64: this.state.workaddressproofbase64,
            panCardbase64: this.state.panCardbase64,
            aadharCardbase64: this.state.aadharCardbase64,
            aadharCardbackbase64: this.state.aadharCardbackbase64,
            cancelChequebase64: this.state.cancelChequebase64,
            profilebase64: this.state.profilebase64,
            addressproofbase64: this.state.addressproofbase64,
            profilephotobase64: this.state.profilephotobase64,
            aadharNumber:aadharNumberwithoutSpace,
            panNumber:this.state.panNumber
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            this.props.history.push(`/paymentsuccessfull`, {
              applicationid: id,
            });
          } else if (
            res.data.code == 201 &&
            res.data.status == "alreadyexist"
          ) {
            this.showNotification(res.data.message);
          } else if (res.data.code == 201) {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          this.setState({ pay: true });
        });
    }
  };
  onRadioChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  filterDistricts = () => {
    const filtered = this.state.data.filter((item) => {
      if (item.state == this.state.state) {
        this.setState({ districts: item.districts });
      }
    });
 
  };
  filterDistricts2 = () => {
    const filtered = this.state.data.filter((item) => {
      if (item.state == this.state.workstate) {
        this.setState({ workdistricts: item.districts });
      }
    });
   
  };

  showtutorial = () => {
    $("#youtubeModal").modal("show")
  }
  render() {
    const options = {
      placeholder: "Select State",
    };
    const options2 = {
      placeholder: "Select District",
    };

    return (
  

      <div className="row main-div p-0">
        <div id="notifContainer"></div>
        <div className="overlay"></div>
      
        <Helmet>
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/select2.css"}
          />
        </Helmet>
        <div className="left-form col-lg-12 col-xl-7 col-12 p-0">
          {/* Header------------------------------------------------------------------------------------- */}
          <header className="row justify-content-between px-2 py-2">
            <div className="fixed-top d-flex  agent-header justify-content-between">
              <a href="https://legal251.com" className="ml-2">
                <img className="logo" src="/img/Legal_logo_full.svg" alt="" />
              </a>
              <div className="d-flex align-items-center">
                <a   href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                    target="_blank" className="mx-1 app">
                  <img src="/img/Grou 215.svg" height="20px" alt="" />
                </a>
                <a href="tel:7772877729"
                    target="_blank" className="mx-1 app head-text">
                  <img src="/img/phone.svg" height="20px" alt="" />
                  <span className="ml-2">(+91) 77728 77729</span>
                </a>
                {/* <a href="#" className="mx-2 my-auto head-text app">
                 
                </a> */}
                <Link to="/" className="ml-4 mr-2 agent-login head-text">
                  Login
                </Link>
                <a className="mr-lg-2 mr-4 head-text point" onClick={() => this.showCommission()}>Commission</a>
              </div>
            </div>
          </header>
          {/* BODY-------------------------------------------------------------------------------------- */}

          <div className="agent-form">
            <div>
              <span className="agent-head">
                <h2 className="text-orange main-head">Partner Registration</h2>
              </span>
            </div>
            <br />

            {/* Personal Details------------------------------------------------------------------------ */}
            <span className="type-head">
              <u>Personal details:</u>
            </span>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 appli lefttt">
                <select
                  name="application"
                  id="applicationtype"
                  className="agent-select  agent-left"
                  value={this.state.applicationType}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ applicationType: e.target.value });
                    if (e.target.value == "other") {
                      $("#koidinput").hide();
                      $("#other-fieldset").fadeIn();
                    } else if (
                      e.target.value == "kiosk" ||
                      e.target.value == "NICT"
                    ) {
                      $("#other-fieldset").hide();
                      $("#koidinput").fadeIn();
                    } else {
                      $("#koidinput").fadeOut();
                      $("#other-fieldset").fadeOut();
                    }
                  }}
                >
                  <option value="a" disabled>
                    &nbsp;Application Type
                  </option>
                  {this.state.applicationTypeArray.map((item, index) => {
                    return <option  value={item.key}>&nbsp;{item.value}</option>
                  })}
                  
                </select>
                <span className="  warn" id="applicationtype-warn">
                  Please select application type
                </span>
              </div>

              <div
                className="col-lg-6 appli col-12 righttt"
                id="other-fieldset"
              >
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Other</legend>
                  <input
                    className="agent-input"
                    id="other"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Please Specify"
                    value={this.state.applicationOther}
                    onChange={(e) =>
                      this.setState({ applicationOther: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn " id="other-warn">
                  Please specify application type.
                </span>
                <span className="  warn " id="other-text-warn">
                  Text too long (Max 50)
                </span>
              </div>

              <div className="col-lg-6 appli col-12 righttt" id="koidinput">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="koid">&nbsp;KIOSK ID &nbsp;</legend>
                  <input
                    className="agent-input"
                    id="kioid"
                    type="text"
                    maxLength="25"
                    autoComplete="off"
                    placeholder="Enter KIOSK ID"
                    value={this.state.koid}
                    onChange={(e) => this.setState({ koid: e.target.value })}
                  />
                </fieldset>
                {/* <span className="  warn " id="koid-warn">
                  This field can't be empty
                </span> */}
                <span className="  warn " id="koid-text-warn">
                  Text too long (Max 50)
                </span>
              </div>
            </div>
               
            <div className="row justify-content-between my-2">
           
            <div className="col-lg-6 col-12 appli lefttt">
                <select
                  name="idtype"
                  id="idtypetype"
                  className="agent-select  agent-left"
                  value={this.state.idType}
                  disabled
                  onChange={(e) => {
                    this.setState({ idType: e.target.value });
                  }}
                >
                                    <option value="">Select Id Type</option>
                                    <option value="RP">Referral Partner  (Rs 1251/-) - 10% Commission</option>
                                    <option value="KP">KIOSK Partner ( Rs 3251/-) 20% Commission</option>
                                    <option value="PP">Professional Partner (Rs 5251/-) - 20% Commission</option>
                </select>
                <span className="  warn" id="idtypetype-warn">
                  Please select application type
                </span>
              </div>
              </div>
            <div className="row justify-content-between my-2">



              <div className="col-lg-6 col-12 lefttt">
                <fieldset className="my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;First Name</legend>
                  <input
                    className="agent-input"
                    id="firstname"
                    value={this.state.firstName}
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Enter First Name"
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    disabled
                  />
                </fieldset>
                <span className="  warn " id="firstname-warn">
                  Please enter your First name.
                </span>
                <span className="  warn " id="firstname-invalidtext-warn">
                  Please enter a valid Firstname.
                </span>
                <span className="  warn " id="firstname-text-warn">
                  Text too long (Max 25)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Last Name</legend>
                  <input
                    className="agent-input"
                    id="lastname"
                    value={this.state.lastName}
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Enter Last Name"
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    disabled
                  />
                </fieldset>
                <span className="  warn" id="lastname-invalidtext-warn">
                  Please enter a valid Lastname.
                </span>
                <span className="  warn" id="lastname-warn">
                  Please enter your Last name.
                </span>
                <span className="  warn " id="lastname-text-warn">
                  Text too long (Max 25)
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;Email</legend>
                  <input
                    className="agent-input-email"
                    id="email"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    value={this.state.email}
                    placeholder="Enter Your Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    disabled
                  />
                </fieldset>
                <span className="  warn" id="email-warn">
                  Please enter a valid email
                </span>
                <span className="  warn" id="email-warn-empty">
                  Please enter your email.
                </span>
                <span className="  warn " id="email-text-warn">
                  Text too long (Max 100)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Mobile No.</legend>
                  <input
                    className="agent-input"
                    id="mobile"
                    type="tel"
                    autoComplete="off"
                    maxLength="10"
                    value={this.state.contactNo}
                    placeholder="Enter Your Mobile No."
                    onChange={(e) =>
                      this.setState({ contactNo: e.target.value })
                    }
                    disabled
                  />
                </fieldset>
                <span className="  warn" id="mobile-warn">
                  Please enter a valid Mobile No.
                </span>
                <span className="  warn" id="mobile-warn-empty">
                  Please enter your mobie no.
                </span>
                <span className="  warn " id="mobile-text-warn">
                  Mobile no. too long (Max 10)
                </span>
              </div>
            </div>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend ">&nbsp;D.O.B.</legend>
                  <input
                    className="agent-input dob-input"
                    id="dob"
                    value={this.state.DOB}
                    type="date"
                    onChange={(e) => this.setState({ DOB: e.target.value })}
                  />
                </fieldset>
                <span className="  warn" id="dob-warn">
                  Please provide D.O.B
                </span>
              </div>

              <div className="col-lg-6 col-12 righttt">
                <select
                  name="gender"
                  id="gender"
                  className=" agent-select agent-right"
                  value={this.state.gender}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ gender: e.target.value });
                  }}
                >
                  <option value="c" disabled>
                    Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <span className="  warn" id="gender-warn">
                  Please select gender
                </span>
              </div>
            </div>

            {/* Bank details------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Bank details:</u>
            </span>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">
                    &nbsp;Account Holder Name
                  </legend>
                  <input
                    className="agent-input"
                    id="accountholdername"
                    type="text"
                    maxLength="100"
                    autoComplete="off"
                    placeholder="Enter Name"
                    value={this.state.bankAccountName}
                    onChange={(e) =>
                      this.setState({ bankAccountName: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="accountholdername-warn-empty">
                  Please enter Account holder's name.
                </span>
                <span className="  warn " id="accountholdername-text-warn">
                  Text too long (Max 100)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Account No.</legend>
                  <input
                    className="agent-input"
                    id="accountno"
                    type="tel"
                    maxLength="20"
                    autoComplete="off"
                    placeholder="Enter Your Bank Account No."
                    value={this.state.accountNo}
                    onChange={(e) =>
                      this.setState({ accountNo: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="accountno-warn">
                  Please enter a valid account no.
                </span>
                <span className="  warn" id="accountno-warn-empty">
                  Please enter your account no.
                </span>
                <span className="  warn " id="accountno-text-warn">
                  Account no. too long (Max 50)
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend ">&nbsp;IFSC Code</legend>
                  <input
                    className="agent-input agent-input-Uppercase"
                    id="ifsc"
                    type="text"
                    maxLength="20"
                    autoComplete="off"
                    placeholder="Enter IFSC Code"
                    value={this.state.IFSCcode}
                    onChange={(e) =>
                      this.setState({ IFSCcode: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="ifsc-warn">
                  Please enter the IFSC Code.
                </span>
                <span className="  warn " id="ifsc-text-warn">
                  Code too long (Max 20)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Bank Proof</legend>
                  <input
                    type="file"
                    id="upload1"
                    name="cancelChequebase64"
                    accept="image/jpg , image/jpeg , image/png, application/pdf"
                    hidden
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={this.handleFileInputChange1}
                  />
                  <label
                    htmlFor="upload1"
                    id="upload1-label"
                    className="   place-color  mb-0"
                  >
                    <span>
                      Choose File &nbsp;
                      <img src="/img/Path 20712.svg" height="15px" alt="" />
                    </span>
                    <img src="/img/Path20711.svg" height="20px" alt="" />
                  </label>
                  <div id="remove-file1" className=" place-color row  mb-0">
                    <div className=" text-success choose-text col-10">
                      {this.state.cancelCheque} &nbsp;
                    </div>
                    <div className="col-2 bg-white choose-btn">
                      <button
                        className="btn p-0"
                        onClick={(e) => {
                          this.setState({ cancelCheque: "" });
                          $("#remove-file1").hide();
                          $("#upload1-label").show();
                        }}
                      >
                        <img
                          className=""
                          src="/img/Group 4352.svg"
                          height="20px"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                  {/* <input className="agent-input input-file " type="file" /> */}
                </fieldset>
                <p className="col-12 capt">
                  Cancelled Cheque / Bank Statement / Passbook Front page
                </p>
                <span className=" warn" id="cheque-warn">
                  Please select a file with max-size of 10 mb
                </span>
                <span className="warn" id="cheque-warnsize">
                  File too big (max-size 10 mb)
                </span>
              </div>
            </div>

            {/* Address details------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Address details:</u>
            </span>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;Full Address</legend>
                  <input
                    className="agent-input"
                    id="address"
                    type="address"
                    maxLength="150"
                    autoComplete="off"
                    value={this.state.address}
                    placeholder="Enter Your Address"
                    onChange={(e) => this.setState({ address: e.target.value })}
                  />
                </fieldset>
                <span className="  warn" id="address-warn">
                  Please enter your address.
                </span>
                <span className="  warn " id="address-text-warn">
                  Address text too long (Max 150)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Landmark</legend>
                  <input
                    className="agent-input"
                    id="landmark"
                    type="text"
                    autoComplete="off"
                    maxLength="100"
                    value={this.state.landmark}
                    placeholder="Enter Landmark"
                    onChange={(e) =>
                      this.setState({ landmark: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="landmark-warn-empty">
                  Please enter a landmark.
                </span>
                <span className="  warn " id="landmark-text-warn">
                  Landmark text too long (Max 50)
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt state-select">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;State</legend>
                  <Select2
                    className="form-control"
                    id="state"
                    autoComplete="off"
                    value={this.state.state}
                    options={options}
                    onChange={(e) => {
                      this.setState({ state: e.target.value });
                      
                      this.filterDistricts();
                    }}
                    data={[
                      "Andaman and Nicobar Island (UT)",
                      "Andhra Pradesh",
                      "Arunachal Pradesh",
                      "Assam",
                      "Bihar",
                      "Chandigarh (UT)",
                      "Chhattisgarh",
                      "Dadra and Nagar Haveli (UT)",
                      "Daman and Diu (UT)",
                      "Delhi (NCT)",
                      "Goa",
                      "Gujarat",
                      "Haryana",
                      "Himachal Pradesh",
                      "Jammu and Kashmir(UT)",
                      "Jharkhand",
                      "Karnataka",
                      "Kerala",
                      "Ladakh(UT)",
                      "Lakshadweep (UT)",
                      "Madhya Pradesh",
                      "Maharashtra",
                      "Manipur",
                      "Meghalaya",
                      "Mizoram",
                      "Nagaland",
                      "Odisha",
                      "Puducherry (UT)",
                      "Punjab",
                      "Rajasthan",
                      "Sikkim",
                      "Tamil Nadu",
                      "Telangana",
                      "Tripura",
                      "Uttarakhand",
                      "Uttar Pradesh",
                      "West Bengal",
                    ]}
                    disabled
                  />
                </fieldset>
                <span className="  warn" id="state-warn">
                  Please select your state.
                </span>
                {/* <span className="  warn " id="state-text-warn">
                  State name too long (Max 20)
                </span> */}
              </div>
              <div className="col-lg-6 col-12 righttt district-select">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">
                    &nbsp;City / District
                  </legend>
                  <Select2
                    className="form-control"
                    id="city"
                    value={this.state.city_district}
                    options={options2}
                    disabled
                    onChange={(e) => {
                      this.setState({ city_district: e.target.value });
                    }}
                    data={this.state.districts}
                  />
                </fieldset>
                <span className="  warn" id="city-warn">
                  Please select your city / district.
                </span>
                {/* <span className="  warn " id="city-text-warn">
                  City name too long (Max 50)
                </span> */}
              </div>
            </div>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset">
                  <legend className="agent-legend">&nbsp;Pin Code</legend>
                  <input
                    className="agent-input"
                    id="pin"
                    type="tel"
                    maxLength="6"
                    autoComplete="off"
                    value={this.state.pinCode}
                    placeholder="Enter Pin Code"
                    onChange={(e) => this.setState({ pinCode: e.target.value })}
                  />
                </fieldset>
                <span className="  warn" id="pin-warn">
                  Please enter a valid pin code
                </span>
                <span className="  warn" id="pin-warn-empty">
                  Please enter your pin code
                </span>
                <span className="  warn " id="pin-text-warn">
                  Pincode must be of 6 digit
                </span>
              </div>
            </div>
            {/* Address proof------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Address Proof:</u>
            </span>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset">
                  <legend className="agent-legend">&nbsp;Address Proof</legend>
                  <input
                    type="file"
                    className="w-100"
                    id="upload2"
                    hidden
                    name="addressproofbase64"
                    accept="image/jpg , image/jpeg , image/png, application/pdf"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={this.handleFileInputChange2}
                  />
                  <label
                    htmlFor="upload2"
                    id="upload2-label"
                    className=" w-100 place-color  mb-0"
                  >
                    <span>
                      Choose File &nbsp;
                      <img src="/img/Path 20712.svg" height="15px" alt="" />
                    </span>
                    <img src="/img/Path20711.svg" height="20px" alt="" />
                  </label>
                  <div id="remove-file2" className=" place-color row  mb-0">
                    <div className="text-success col-10 choose-text">
                      {this.state.addressproof} &nbsp;
                    </div>
                    <div className="col-2 bg-white choose-btn">
                      <button
                        className="btn p-0 "
                        onClick={(e) => {
                          this.setState({ addressproof: "" });
                          $("#remove-file2").hide();
                          $("#upload2-label").show();
                        }}
                      >
                        <img
                          className=""
                          src="/img/Group 4352.svg"
                          height="20px"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </fieldset>
                <span className="col-12 capt">
                  Aadhar/Electicity Bill/Notary Registry
                </span>
                <br />
                <span className="  warn" id="addressproof-warn">
                  Please select a file with max-size of 10 mb
                </span>
                <span className="  warn" id="addressproof-warnsize">
                  file too big (max-size 10 mb)
                </span>
              </div>
            </div>

            {/* Work Address proof------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Work Address :</u>
            </span>

            <span className="text-orange same-as">
              Copy same address as above
            </span>
            <div className="d-flex agent-radio">
              <div className="d-flex ">
                <input
                  type="radio"
                  id="sameAddressy"
                  name="sameAddress"
                  value="Y"
                  checked={this.state.sameAddress === "Y"}
                  onChange={this.onRadioChange}
                />
                <label htmlFor="sameAddressy" className="uploadradiotext ">
                  YES
                </label>
                <br />
                <input
                  type="radio"
                  id="sameAddressn"
                  name="sameAddress"
                  value="N"
                  checked={this.state.sameAddress === "N"}
                  onChange={this.onRadioChange}
                />
                <label htmlFor="sameAddressn" className="uploadradiotext ml-3">
                  NO
                </label>
              </div>
            </div>
            {this.state.sameAddress == "N" ? (
              <>
                <div className=" row justify-content-between mb-2 mt-4">
                  <div className="col-lg-6 col-12 lefttt">
                    <fieldset className=" my-fieldset agent-left">
                      <legend className="agent-legend">
                        &nbsp;Full Address
                      </legend>
                      <input
                        className="agent-input"
                        id="workladdress"
                        type="text"
                        maxLength="150"
                        autoComplete="off"
                        placeholder="Enter Your Address"
                        value={this.state.workaddress}
                        onChange={(e) =>
                          this.setState({ workaddress: e.target.value })
                        }
                      />
                    </fieldset>
                    <span className="  warn" id="workaddress-warn">
                      Please enter your address.
                    </span>
                    <span className="  warn " id="workaddress-text-warn">
                      Address too long (Max 150)
                    </span>
                  </div>
                  <div className="col-lg-6 col-12 righttt">
                    <fieldset className=" my-fieldset agent-right">
                      <legend className="agent-legend">&nbsp;Landmark</legend>
                      <input
                        className="agent-input"
                        id="worklandmark"
                        type="text"
                        maxLength="100"
                        autoComplete="off"
                        placeholder="Enter Landmark"
                        value={this.state.worklandmark}
                        onChange={(e) =>
                          this.setState({ worklandmark: e.target.value })
                        }
                      />
                    </fieldset>
                    <span className="  warn" id="worklandmark-warn">
                      Please enter a landmark.
                    </span>
                    <span className="  warn " id="worklandmark-text-warn">
                      Landmark name too long (Max 50)
                    </span>
                  </div>
                </div>
                <div className="row justify-content-between my-2">
                  <div className="col-lg-6 col-12 lefttt state-select">
                    <fieldset className=" my-fieldset agent-left">
                      <legend className="agent-legend">&nbsp;State</legend>
                      <Select2
                        className="form-control "
                        id="workstate"
                        value={this.state.workstate}
                        options={options}
                        onChange={(e) => {
                          this.setState({ workstate: e.target.value });
                          this.filterDistricts2();
                        }}
                        data={[
                          "Andaman and Nicobar Island (UT)",
                          "Andhra Pradesh",
                          "Arunachal Pradesh",
                          "Assam",
                          "Bihar",
                          "Chandigarh (UT)",
                          "Chhattisgarh",
                          "Dadra and Nagar Haveli (UT)",
                          "Daman and Diu (UT)",
                          "Delhi (NCT)",
                          "Goa",
                          "Gujarat",
                          "Haryana",
                          "Himachal Pradesh",
                          "Jammu and Kashmir(UT)",
                          "Jharkhand",
                          "Karnataka",
                          "Kerala",
                          "Ladakh(UT)",
                          "Lakshadweep (UT)",
                          "Madhya Pradesh",
                          "Maharashtra",
                          "Manipur",
                          "Meghalaya",
                          "Mizoram",
                          "Nagaland",
                          "Odisha",
                          "Puducherry (UT)",
                          "Punjab",
                          "Rajasthan",
                          "Sikkim",
                          "Tamil Nadu",
                          "Telangana",
                          "Tripura",
                          "Uttarakhand",
                          "Uttar Pradesh",
                          "West Bengal",
                        ]}
                      />
                    </fieldset>
                    <span className="  warn" id="workstate-warn">
                      Please select your state.
                    </span>
                    <span className="  warn " id="workstate-text-warn">
                      State name too long (Max 20)
                    </span>
                  </div>
                  <div className="col-lg-6 col-12 righttt district-select">
                    <fieldset className=" my-fieldset agent-right">
                      <legend className="agent-legend">
                        &nbsp;City / District
                      </legend>
                      <Select2
                        className="form-control"
                        value={this.state.workcity_district}
                        options={options2}
                        id="workcity"
                        onChange={(e) => {
                          this.setState({ workcity_district: e.target.value });
                        }}
                        data={this.state.workdistricts}
                      />
                    </fieldset>
                    <span className="  warn" id="workcity-warn">
                      Please select your city / district.
                    </span>
                    {/* <span className="  warn " id="workcity-text-warn">
                      City name too long (Max 50)
                    </span> */}
                  </div>
                </div>
                <div className=" row justify-content-between my-2">
                  <div className="col-lg-6 col-12 lefttt">
                    <fieldset className=" my-fieldset">
                      <legend className="agent-legend">&nbsp;Pin Code</legend>
                      <input
                        className="agent-input"
                        id="workpin"
                        type="text"
                        maxLength="6"
                        autoComplete="off"
                        placeholder="Enter Pin Code"
                        value={this.state.workpinCode}
                        onChange={(e) =>
                          this.setState({ workpinCode: e.target.value })
                        }
                      />
                    </fieldset>
                    <span className="  warn" id="workpin-warn">
                      Please enter a valid pin code
                    </span>
                    <span className="  warn" id="workpin-warn-empty">
                      Please enter pin code
                    </span>
                    <span className="  warn " id="workpin-text-warn">
                      Pin code must be of 6 digit
                    </span>
                  </div>
                </div>
                <div className="row justify-content-between my-2">
                  <div className="col-lg-6 col-12 lefttt">
                    <fieldset className="my-fieldset">
                      <legend className="agent-legend">
                        &nbsp;Work Address Proof
                      </legend>
                      <input
                        type="file"
                        id="upload3"
                        hidden
                        name="workaddressproofbase64"
                        accept="image/jpg , image/jpeg , image/png, application/pdf"
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        onChange={this.handleFileInputChange3}
                      />
                      <label
                        htmlFor="upload3"
                        id="upload3-label"
                        className=" place-color  mb-0"
                      >
                        <span>
                          Choose File &nbsp;
                          <img src="/img/Path 20712.svg" height="15px" alt="" />
                        </span>{" "}
                        <img src="/img/Path20711.svg" height="20px" alt="" />
                      </label>
                      <div id="remove-file3" className=" row place-color  mb-0">
                        <div className="text-success col-10 choose-text">
                          {this.state.workaddressproof} &nbsp;
                        </div>
                        <div className="col-2 bg-white choose-btn">
                          <button
                            className="btn p-0 "
                            onClick={(e) => {
                              this.setState({ workaddressproof: "" });
                              $("#remove-file3").hide();
                              $("#upload3-label").show();
                            }}
                          >
                            <img
                              className=""
                              src="/img/Group 4352.svg"
                              height="20px"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </fieldset>
                    <span className="col-12 capt">
                      GUMASTA or Trade Licence/Electicity Bill/Notery or Registry
                    </span>
                    <br />{" "}
                    <span className="warn" id="workaddressproof-warn">
                      Please select a file with max-size of 10 mb
                    </span>
                    <span className="  warn" id="workaddressproof-warnsize">
                      
                    </span>
                  </div>
                </div>
              </>
            ) : null}

            {/* Other Details------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Other Details:</u>
            </span>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className="my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;Aadhar Card Number</legend>
                  <input
                    className="agent-input"
                    id="aadharNumber"
                    value={this.state.aadharNumber}
                    type="text"
                    maxLength="14"
                    autoComplete="off"
                    placeholder="Enter Aadhar Card Number"
                    onChange={(e) =>
                      this.setState({ aadharNumber: e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() })
                    }
                  />
                </fieldset>
                <span className="  warn " id="aadharNumber-warn">
                  Please enter your aadhar Number.
                </span>
                <span className="  warn " id="aadharNumber-invalidtext-warn">
                  Please enter a valid aadhar Number.
                </span>
                <span className="  warn " id="aadharNumber-text-warn">
                  Text too long (Max 25)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;PAN Card Number</legend>
                  <input
                    className="agent-input agent-input-Uppercase"
                    id="panNumber"
                    value={this.state.panNumber}
                    type="text"
                    maxLength="10"
                    autoComplete="off"
                    placeholder="Enter Pan Card number"
                    onChange={(e) =>
                      this.setState({ panNumber: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="panNumber-invalidtext-warn">
                  Please enter a valid pan number.
                </span>
                <span className="  warn" id="panNumber-warn">
                  Please enter your pan number.
                </span>
                <span className="  warn " id="panNumber-text-warn">
                  Text too long (Max 25)
                </span>
              </div>
            </div>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;Aadhar Card Front</legend>
                  <input
                    type="file"
                    id="upload4"
                    hidden
                    name="aadharCardbase64"
                    accept="image/jpg , image/jpeg , image/png, application/pdf"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={this.handleFileInputChange4}
                  />
                  <label
                    htmlFor="upload4"
                    id="upload4-label"
                    className=" place-color  mb-0"
                  >
                    <span>
                      Choose File &nbsp;
                      <img src="/img/Path 20712.svg" height="15px" alt="" />
                    </span>
                    <img src="/img/Path20711.svg" height="20px" alt="" />
                  </label>
                  <div id="remove-file4" className=" row place-color  mb-0">
                    <div className="text-success choose-text col-10">
                      {this.state.aadharCard} &nbsp;
                    </div>
                    <div className="col-2 bg-white choose-btn">
                      <button
                        className="btn p-0 "
                        onClick={(e) => {
                          this.setState({ aadharCard: "" });
                          $("#remove-file4").hide();
                          $("#upload4-label").show();
                        }}
                      >
                        <img
                          className=""
                          src="/img/Group 4352.svg"
                          height="20px"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </fieldset>
                <span className="warn" id="aadhar-warn">
                  Please select a file with max-size of 10 mb
                </span>
                <span className="warn" id="aadhar-warnsize">
                  File too big (max-size 10 mb)
                </span>
              </div>

              <div className="col-lg-6 col-12 righttt">
                <fieldset className="my-fieldset">
                  <legend className="agent-legend">&nbsp; Aadhar Card Back</legend>
                  <input
                    type="file"
                    id="upload8"
                    hidden
                    name="aadharCardbackbase64"
                    accept="image/jpg , image/jpeg , image/png, application/pdf"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={this.handleFileInputChange8}
                  />
                  <label
                    htmlFor="upload8"
                    id="upload8-label"
                    className=" place-color  mb-0"
                  >
                    <span>
                      Choose File &nbsp;
                      <img src="/img/Path 20712.svg" height="15px" alt="" />
                    </span>
                    <img src="/img/Path20711.svg" height="20px" alt="" />
                  </label>
                  <div id="remove-file8" className=" row place-color  mb-0">
                    <div className="text-success choose-text col-10">
                      {this.state.aadharCardback} &nbsp;
                    </div>
                    <div className="col-2 bg-white choose-btn">
                      <button
                        className="btn p-0 "
                        onClick={(e) => {
                          this.setState({ aadharCardback: "" });
                          $("#remove-file8").hide();
                          $("#upload8-label").show();
                        }}
                      >
                        <img
                          className=""
                          src="/img/Group 4352.svg"
                          height="20px"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </fieldset>
                <span className="  warn" id="aadharCardback-warn">
                  Please select a file with a max-size of 10 mb
                  <br />
                </span>
                <span className="  warn" id="aadharCardback-warnsize">
                  File too big (max-size 10 mb)
                  <br />
                </span>
              </div>

         
            </div>
            <div className=" row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;PAN Card</legend>
                  <input
                    type="file"
                    id="upload5"
                    hidden
                    name="panCardbase64"
                    accept="image/jpg , image/jpeg , image/png, application/pdf"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={this.handleFileInputChange5}
                  />
                  <label
                    htmlFor="upload5"
                    id="upload5-label"
                    className="place-color  mb-0"
                  >
                    <span>
                      Choose File &nbsp;
                      <img src="/img/Path 20712.svg" height="15px" alt="" />
                    </span>
                    <img src="/img/Path20711.svg" height="20px" alt="" />
                  </label>
                  <div id="remove-file5" className=" place-color row  mb-0">
                    <div className="text-success choose-text col-10">
                      {this.state.panCard} &nbsp;
                    </div>
                    <div className="col-2 bg-white choose-btn">
                      <button
                        className="btn p-0 "
                        onClick={(e) => {
                          this.setState({ panCard: "" });
                          $("#remove-file5").hide();
                          $("#upload5-label").show();
                        }}
                      >
                        <img
                          className=""
                          src="/img/Group 4352.svg"
                          height="20px"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </fieldset>
                <span className="  warn" id="pan-warn">
                  Please select a file with a max-size of 10 mb
                </span>
                <span className="  warn" id="pan-warnsize">
                  File too big (max-size 10 mb)
                </span>
              </div>
              </div>
            {/* Application Details------------------------------------------------------------------- */}
            <span className="mt-4 type-head">
              <u>Application Details:</u>
            </span>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className="my-fieldset">
                  <legend className="agent-legend">
                    &nbsp;Application Form
                  </legend>
                  <input
                    type="file"
                    id="upload6"
                    hidden
                    name="profilebase64"
                    accept="image/jpg , image/jpeg , image/png, application/pdf"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={this.handleFileInputChange6}
                  />
                  <label
                    htmlFor="upload6"
                    id="upload6-label"
                    className=" place-color  mb-0"
                  >
                    <span>
                      Choose File &nbsp;
                      <img src="/img/Path 20712.svg" height="15px" alt="" />
                    </span>
                    <img src="/img/Path20711.svg" height="20px" alt="" />
                  </label>
                  <div id="remove-file6" className=" row place-color  mb-0">
                    <div className="text-success choose-text col-10">
                      {this.state.profile} &nbsp;
                    </div>
                    <div className="col-2 bg-white choose-btn">
                      <button
                        className="btn p-0 "
                        onClick={(e) => {
                          this.setState({ profile: "" });
                          $("#remove-file6").hide();
                          $("#upload6-label").show();
                        }}
                      >
                        <img
                          className=""
                          src="/img/Group 4352.svg"
                          height="20px"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </fieldset>
                <div className="capt">Upload below form</div>
                <span className="  warn" id="profile-warn">
                  Please select a file with a max-size of 10 mb
                  <br />
                </span>
                <span className="  warn" id="profile-warnsize">
                  File too big (max-size 10 mb)
                  <br />
                </span>
                <a
                  href={
                    process.env.PUBLIC_URL + "/pdf/Shop_registration_form_1.pdf"
                  }
                  target="_blank"
                  className="btn view-btn mt-1"
                  download
                >
                  Download Application form
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                    alt=""
                  />
                </a>
              </div>

              <div className="col-lg-6 col-12 righttt">
                <fieldset className="my-fieldset">
                  <legend className="agent-legend">&nbsp; Profile Photo</legend>
                  <input
                    type="file"
                    id="upload7"
                    hidden
                    name="profilephotobase64"
                    accept="image/jpg , image/jpeg , image/png, application/pdf"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={this.handleFileInputChange7}
                  />
                  <label
                    htmlFor="upload7"
                    id="upload7-label"
                    className=" place-color  mb-0"
                  >
                    <span>
                      Choose File &nbsp;
                      <img src="/img/Path 20712.svg" height="15px" alt="" />
                    </span>
                    <img src="/img/Path20711.svg" height="20px" alt="" />
                  </label>
                  <div id="remove-file7" className=" row place-color  mb-0">
                    <div className="text-success choose-text col-10">
                      {this.state.profilephoto} &nbsp;
                    </div>
                    <div className="col-2 bg-white choose-btn">
                      <button
                        className="btn p-0 "
                        onClick={(e) => {
                          this.setState({ profilephoto: "" });
                          $("#remove-file7").hide();
                          $("#upload7-label").show();
                        }}
                      >
                        <img
                          className=""
                          src="/img/Group 4352.svg"
                          height="20px"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </fieldset>
                <div className="capt">Passport Size Photo</div>
                <span className="  warn" id="profilephoto-warn">
                  Please select a file with a max-size of 10 mb
                  <br />
                </span>
                <span className="  warn" id="profilephoto-warnsize">
                  File too big (max-size 10 mb)
                  <br />
                </span>
              </div>
            </div>

            <div className="questions">
              <ul>
                <div className="ques">
                  <div className="d-flex">
                    <div className="ques-bullet">
                      {" "}
                      <img src="/img/Ellipse 2753.svg" alt="" />
                    </div>{" "}
                    <p className="mb-2">
                      {" "}
                      Are you included in or providing any kind of partner or
                      agent services already?
                      <span className=" ">
                        <sup className="sup__red">*</sup>
                      </span>
                    </p>
                  </div>
                  <div className="d-flex agent-radio mb-3">
                    <div className="d-flex ml-2">
                      <input
                        type="radio"
                        id="previousStatusy"
                        name="previousStatus"
                        value="Y"
                        checked={this.state.previousStatus === "Y"}
                        onChange={this.onRadioChange}
                      />
                      <label
                        htmlFor="previousStatusy"
                        className="uploadradiotext ml-2"
                      >
                        YES
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="previousStatusn"
                        name="previousStatus"
                        value="N"
                        checked={this.state.previousStatus === "N"}
                        onChange={this.onRadioChange}
                      />
                      <label
                        htmlFor="previousStatusn"
                        className="uploadradiotext ml-3"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ques">
                  <div className="d-flex">
                    <div className="ques-bullet">
                      {" "}
                      <img src="/img/Ellipse 2753.svg" alt="" />
                    </div>{" "}
                    <p className="mb-2">
                      {" "}
                      Are you a health insurance provider or any other policies
                      agent ?
                      <span className=" ">
                        <sup className="sup__red">*</sup>
                      </span>
                    </p>
                  </div>
                  <div className="d-flex agent-radio ">
                    <div className="d-flex ml-2">
                      <input
                        type="radio"
                        id="previousInsuranceStatusy"
                        name="previousInsuranceStatus"
                        value="Y"
                        checked={this.state.previousInsuranceStatus === "Y"}
                        onChange={this.onRadioChange}
                      />
                      <label
                        htmlFor="previousInsuranceStatusy"
                        className="uploadradiotext ml-2"
                      >
                        YES
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="previousInsuranceStatusn"
                        name="previousInsuranceStatus"
                        value="N"
                        checked={this.state.previousInsuranceStatus === "N"}
                        onChange={this.onRadioChange}
                      />
                      <label
                        htmlFor="previousInsuranceStatusn"
                        className="uploadradiotext ml-3"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset">
                  <legend className="koid">
                    &nbsp;Partner / Executive Code&nbsp;
                  </legend>
                  <input
                    className="agent-input"
                    id="partnercode"
                    type="text"
                    maxLength="10"
                    autoComplete="off"
                    placeholder="Enter Code"
                    value={this.state.partnerCode}
                    onChange={(e) => {
                      this.setState({ partnerCode: e.target.value });
                    }}
                  />
                </fieldset>
                <span className="col-12 capt">Please enter if you have</span>
                <span className="  warn " id="firstname-text-warn">
                  Code too long (Max 10)
                </span>
              </div>
            </div>
            <div className="pay mb-5">
              <div className="pay-btn-div">
                {this.state.pay ? (
                  <button onClick={this.handleSubmit} className="pay-btn btn">
                    Submit Application
                  </button>
                ) : (
                  <img src="/img/loader.gif" height="50px" alt="" />
                )}
              </div>
              {this.state.toCheckout ? <Redirect  to={{
            pathname: '/paymentsuccessfull',
            state: { id: id }
        }}

              
              
              /> : null}
              
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-5 p-0 customfixedclass positionfix">
        <div className="container-fluid ">
                  <center>
                    <p className="success-heading2 success-heading3">
                      Payment successful
                      <img
                        className="tick-icon"
                        src={process.env.PUBLIC_URL + "/img/blue-tick.svg"}
                        alt=""
                      />{" "}
                    </p>
                    <p className="thankyoutext thankyoutext1">Thank you for choosing us!</p>
                    <p className="datetext">
                      {this.state.date},&nbsp; {this.state.time}
                      {/* 24 Nov, 2020, 02:20 PM  */}
                    </p>
                  </center>
                  <div className="row">
                    <div className="col-md-12">
                      <center>
                        <p className="ServiceIDheading2 ServiceIDheading5">
                          Application ID:-{" "}
                          <span className="ServiceIDheadingspan">
                            {id}
                          </span>
                        </p>
                     
                      
              
                        {this.state.orderid == "" ? null : (
                      <>
                        <hr className="paymenthr" />
                      <p className="Transactionheading2 Transactionheading5">
                        Transaction ID:-{" "}
                        <span className="ServiceIDheadingspan">
                        {this.state.orderid}
                        </span>
                      </p>
                     
                      <a
                        target="_blank"
                        href={`https://docs.legal251.com/invoices/partners/${id}_${this.state.orderid.substring(this.state.orderid.length - 3)}.pdf`}
                        className="DownloadInvoice2"
                      >
                        Download Invoice
                      </a>
                      </>
                      )}


                      
                       
                   
           
                      </center>
                    </div>
                    <div className="col-md-12">
                      <center>
                        <div className="custompadd">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/payment_sucessful.svg"
                            }
                            className="paymentsucessimg2 img-fluid"
                            alt=""
                          />
                        </div>
                      </center>
                    </div>
                  </div>
                </div>

        </div>
        <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/UG7VDgRJTcY"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div
          className="modal fade"
          id="viewCommission"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content back-modal comm-modal">
              <button
                type="button"
                className="border-0 bg-transparent ml-auto p-2 pr-3 text-right my-back-modal-close"
                data-dismiss="modal"
              >
                <img src={process.env.PUBLIC_URL + "/img/Page-1.svg"} />
              </button>
                  <h5 className="text-center">Commission</h5>
              <div className="modal-body border-0 text-center pb-0">
              <p className="notificationNote mt-3">
                     FOR Commission  Related Information Please CALL ON 77728-77729 AND DIAL 75.
                    <br/>कमीशन से संबंधित जानकारी के लिए कृपया 77728-77729 पर कॉल करें और 75 डायल करें
                  </p>
              </div>
              <div className="modal-footer border-0 justify-content-center mb-4">
             
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default IncompleteAgentregistration;

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Header from './Header'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import Footer from './Footer'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import customservices from "../customservices.json"
import AllServices from "../AllServices.json"
import $ from "jquery";
import axios from "axios";
import SupportDetails from "./SupportDetails";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
let Decjwttoken;
var encryptor = require("simple-encryptor")(key);
let Decagentid;
let supportName;
let supportContact;
let supportEmail;




const options = {
    loop: false,
    dots: false,
    nav: true,
    navText: [
        '<span class="arrow tback">‹</span>',
        '<span class="arrow tforward">›</span>',
    ],
    autoWidth: true,
    margin: 10,
    responsive: {
        0: {
            items: 1,
            // nav:false
        },
        600: {
            items: 3,
            // nav:false
        },
        1000: {
            items: 4,
            // nav:false
        },
        1200: {
            items: 5,
            // nav:false
        }

    }
}

const options1 = {
    items: 4,
    // margin : 30,
    // center: true,
    autoplay: true,
    autoplayTimeout: 1200,
    autoplayHoverPause: true,
    loop: true,
    nav: true,
    dots: true,
    navText: [
        '<span class="arrow back">‹</span>',
        '<span class="arrow forward">›</span>',
    ],
    dotsEach: 0,

    responsive: {
        0: {
            items: 1,
            // nav:false
        },
        300: {
            items: 1,
        },
        600: {
            items: 2,
            // nav:false
        },
        1000: {
            items: 3,
            // nav:false
        },
        1200: {
            items: 4,
            // nav:false
        }

    }

}
const options2 = {
    items: 1,
    // center: true,
    margin: 5,
    autoplay: true,
    autoplayTimeout: 1800,
    autoplayHoverPause: true,
    loop: true,
    nav: false,
    dots: false,
    dotsEach: 0,

}




export class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            count: 0,
            serviceName: [
                "GST Registration",
            ],
            search: "",
            NewService: [],
            notification: [],
            banner: [],
            supportname: "",
            supportcontact: "",
            supportemail: "",


        }
    }

    handler = (e) => {
        var lowerCase = e.target.value.toLowerCase();
        var mydata = this.state.serviceName.filter((el) => {
            //if no input the return the original
            if (this.state.search === '') {
                return null;
            }
            //return the item which contains the user input
            else {
                return el.toLowerCase().includes(this.state.search.toLowerCase())
            }
        })
        this.setState({
            search: lowerCase,
            NewService: mydata
        });
    }

    mains = (e) => {

        this.setState({
            count: e.target.id,
        });
        $(".history").removeClass("firstOwl")
        $(e.target).addClass("firstOwl")
    }


    getoverdueServicesApi = () => {
        $(".pageloader").show();
        $("#dataDiv").hide();
        axios
            .post(
                `${baseUrl}p=overdueServices`,
                {
                    agentId: Decagentid,
                },
                {
                    headers: {
                        Authkey: process.env.REACT_APP_API_KEY,
                        "App-Token-Access": Decjwttoken,
                    },
                    auth: {
                        username: process.env.REACT_APP_API_USERNAME,
                        password: process.env.REACT_APP_API_PASSWORD,
                    },
                }
            )
            .then((res) => {
                $(".pageloader").hide();
                if (res.data.code == 200 && res.data.status == "success") {

                    if (res.data.notification.length > 0) {
                        this.setState({ notification: res.data.notification }, () => {
                            $("#notificationModal").modal("show");
                        });
                    }

                    if (res.data.banner.length > 0) {

                        this.setState({ banner: res.data.banner });
                    }
                } else if (res.data.code == "201" && res.data.status == "invalidauth") {
                    this.logout();
                } else {
                    this.showNotification("Something went wrong ");
                }
            })
            .catch((err) => {

                this.showNotification("Something went wrong ");
            });
    };


    showNotification = (message) => {
        const notifContainer = document.getElementById("notifContainer");
        const notif = document.createElement("div");
        notif.classList.add("notifBox");
        notif.innerText = message;
        notifContainer.appendChild(notif);
        setTimeout(() => $(notif).addClass("pop"), 10);
        setTimeout(() => $(notif).removeClass("pop"), 3000);
        setTimeout(() => $(notif).remove(), 3100);
    };
    Value1 = (value) => {
        this.setState({
            search: value
        })
        this.selectservicehandle(value)
    }
    selectservicehandle = (value) => {

        if (value == "GST Return Filing") {
            this.props.history.push({
                pathname: `/addservice/gstrf`,
            });
        }
        else if (value == "GST Registration") {
            this.props.history.push({
                pathname: `/addservice/gstr`,
            });
        } else if (value == "ITR Filing") {

            this.props.history.push({
                pathname: `/addservice/itrf`,
            });
        } else if (value == "Trademark Registration") {
            this.props.history.push({
                pathname: `/addservice/tr`,
            });
        } else if (value == "Online Accounting") {
            this.props.history.push({
                pathname: `/addservice/oa`,
            });
        } else if (value == "Gumasta Licence (S&E)") {
            this.props.history.push({
                pathname: `/addservice/gumasta`,
            });
        } else if (value == "Trade Licence") {
            this.props.history.push({
                pathname: `/addservice/tl`,
            });
        } else if (value == "Food Licence") {
            this.props.history.push({
                pathname: `/addservice/fl`,
            });
        }
        else if (value == "Legal Notice Reply") {
            this.props.history.push({
                pathname: `/addservice/repl`,
            });
        } else if (value == "PF Return Filing") {
            this.props.history.push({
                pathname: `/addservice/pfrf`,
            });
        } else if (value == "ESIC Return Filing") {
            this.props.history.push({
                pathname: `/addservice/esicrf`,
            });
        }
        // else if (value == "Personal Loan") {
        //     this.props.history.push({
        //         pathname: `/addservice/personal-loan`,
        //     });
        // } else if (value == "Home Loan") {
        //     this.props.history.push({
        //         pathname: `/addservice/home-loan`,
        //     });
        // } else if (value == "Gold Loan") {
        //     this.props.history.push({
        //         pathname: `/addservice/gold-loan`,
        //     });
        // } else if (value == "Business Loan") {
        //     this.props.history.push({
        //         pathname: `/addservice/business-loan`,
        //     });
        // } else if (value == "Loan Against Property") {
        //     this.props.history.push({
        //         pathname: `/addservice/loan-against-property`,
        //     });
        // } else if (value == "Car Loan") {
        //     this.props.history.push({
        //         pathname: `/addservice/car-loan`,
        //     });
        // } else if (value == "Yes Bank Credit Card") {
        //     this.props.history.push({
        //         pathname: `/addservice/yes-bank-credit-card`,
        //     });
        // } else if (value == "Axis Bank Credit Card") {
        //     this.props.history.push({
        //         pathname: `/addservice/axis-bank-credit-card`,
        //     });
        // } else if (value == "Indusind Bank Credit Card") {
        //     this.props.history.push({
        //         pathname: `/addservice/indusind-bank-credit-card`,
        //     });
        // } else if (value == "Stashfin Bank Credit Card") {
        //     this.props.history.push({
        //         pathname: `/addservice/stashfin-bank-credit-card`,
        //     });
        // } else if (value == "State Bank of India Credit Card") {
        //     this.props.history.push({
        //         pathname: `/addservice/sbi-bank-credit-card`,
        //     });
        // } else if (value == "AU Bank Credit Card") {
        //     this.props.history.push({
        //         pathname: `/addservice/au-bank-credit-card`,
        //     });
        // }
        // else if (event.target.value == "Other") {
        //   this.props.history.push({
        //     pathname:  `/addservice/other`,
        //   });
        // }
        else {
            let name = value
            let valueindex = this.state.serviceName.indexOf(name);
            let mainshortCode = Object.keys(AllServices)[valueindex]
            let result = customservices[mainshortCode]
            this.props.history.push({
                pathname: `/addservice/${result.shortCode.toLowerCase()}`, state: result
            });
        }
    }
     componentDidMount() {
        window.scrollTo(0, 0);
        $(document.body).css("background-color", "#2d2d2d");
        this.setState({
            serviceName: Object.values(AllServices)
        })
        let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
        Decagentid = encryptor.decrypt(mainagentid);
        let mainjwttoken = localStorage.getItem("toeljgtkewlna");
        Decjwttoken = encryptor.decrypt(mainjwttoken);
        let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
        let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
        let mainplan = localStorage.getItem("ipacneglatvlael");
       
        this.getoverdueServicesApi();
         this.getservicename();
    }


    handlenotiprofilewapi = () => {
        axios({
          method: "post",
          url: process.env.REACT_APP_API_BASE_URL + "p=notificationProfile",
          data: {
            agentId: Decagentid,
          },
          headers: {
            AuthKey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        })
          .then((resp) => {
            if (resp.data.code == 200 && resp.data.status == "success") {
                supportName = resp.data.supportName
                supportContact = resp.data.supportContact
                supportEmail = resp.data.supportEmail
                this.setState({
                    supportname: supportName,
                    supportcontact: supportContact,
                    supportemail: supportEmail,
                })

            } else if (
              resp.data.code == "201" &&
              resp.data.status == "invalidauth"
            ) {
              this.logout();
            }
          })
          .catch((error) => { });
      };
    

    getservicename = () => {
        supportName =  localStorage.getItem("supportName");
        supportContact = localStorage.getItem("supportContact");
        supportEmail = localStorage.getItem("supportEmail");
        
        if (supportName != null && supportContact != null && supportEmail != null) {
            this.setState({
                supportname: supportName,
                supportcontact: supportContact,
                supportemail: supportEmail,
            })
        } 
        else {
            this.handlenotiprofilewapi()
        }
        
    }


    showPromotion = () => {
        this.props.history.push({ pathname: "/materials" })
    }

    TrainingMaterial = () => {
        this.props.history.push({ pathname: "/materials", state: { type: "Training", } })
    }

    render() {
        if (this.state.notification.length > 0) {

        }

        return (
            <div>

                <div className="cont">
                    <Helmet>
                        <link
                            rel="stylesheet"
                            href={process.env.PUBLIC_URL + "/css/select2.css"}
                        />
                    </Helmet>
                    <div id="notifContainer"></div>

                    <Header />

                    <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
                        className="sidebaropenicon"
                    />
                    <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                        className="sidebarbackicon"
                    />
                    <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
                        className="sidebarexpandicon"
                    />
                    <div className="overlay1"></div>
                    <Sidebar />

                    <div className="arrow-left"></div>
                    <div className="white_div">
                        <div className="white_box">
                            <div className="overviewrow">
                                <div className="row paddingmarginzero">
                                    <div className="col-lg-9 mainCol">
                                        <div className="row justify">
                                            <div className="paddingmarginzero">
                                                <p className="agent__overview__head heading">
                                                    Services Dashboard
                                                </p>
                                            </div>
                                            <div className=" agent__overview__header agent__overview__header1">
                                                <div>
                                                    <input
                                                        type="text"
                                                        onChange={this.handler}
                                                        value={this.state.search}
                                                        className="agent__overview__search_box 
                                                        agent__overview__search_box1 "
                                                        placeholder="Search by service name"
                                                        maxLength="100"
                                                    />

                                                </div>
                                                <div className='List_data' style={{ display: this.state.search ? 'block' : 'none' }}>
                                                    {
                                                        this.state.NewService == "" ? <>
                                                            <ul className='no_result_ul'>
                                                                <li className='no_result_li' >
                                                                    <a className='no_result_a'> No Result found </a>
                                                                </li>
                                                            </ul>
                                                        </> :
                                                            <ul className='map_ul' >
                                                                {
                                                                    this.state.NewService.map((items, index) => {
                                                                        return (
                                                                            <li className='map_li' key={index} >
                                                                                <a className='map_a' onClick={() => this.Value1(items)}> {items} </a>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ServiceDashboard">
                                            <OwlCarousel className='owl-theme owl-theme1 scroarr' loop {...options} >
                                                <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl '>
                                                            <span className=' history firstOwl'
                                                                onClick={this.mains} id="0"> All </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl'>
                                                            <span className=' history'
                                                                onClick={this.mains} id="1">For Business </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl'>
                                                            <span className=' history' onClick={this.mains} id="2"> Registrations </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl'>
                                                            <span className=' history' onClick={this.mains} id="3"> Filing and Accounting </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl'>
                                                            <span className=' history' onClick={this.mains} id="4"> Drafting  </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl'>
                                                            <span className=' history' onClick={this.mains} id="5"> Legal Issues </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl'>
                                                            <span className=' history' onClick={this.mains} id="6"> Loans </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl'>
                                                            <span className=' history' onClick={this.mains} id="7"> Credit Card </span>
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <div className='item '>
                                                    <div className="owl_data">

                                                        <p className='text-center owl_text_1 owl'>
                                                            <span className=' history' onClick={this.mains} id="8"> Other Services </span>
                                                        </p>
                                                    </div>
                                                </div>

                                            </OwlCarousel>

                                            {
                                                this.state.count == 0 ?
                                                    <>
                                                        <div className='MAIN_carousel onecaro '>
                                                            <h4 className='topService'>Top Services</h4>
                                                            <OwlCarousel className='owl-theme owl-theme2 '  {...options1} >
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("ITR Filing")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_3507_1_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> ITR <br />
                                                                                Filing
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Gumasta Licence (S&E)")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_5632_1_3.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Gumasta Licence <br />
                                                                                (S & E)
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("GST Return Filing")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_3506_1_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> GST Return <br />
                                                                                Filing
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("GST Registration")} >
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_3505_1_3 (1).svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> GST <br />
                                                                                Registration
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Online Accounting")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Layer_2_1_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Online <br />
                                                                                Accounting
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Trademark Registration")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_3625_2_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Trademark <br />
                                                                                Registration
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Trade Licence")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_5631_1_3.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Trade <br />
                                                                                Licence
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Food Licence")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_5645_1_3.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Food <br />
                                                                                Licence
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </OwlCarousel>
                                                        </div>

                                                        <div className="cardbox2">
                                                            <h6 className='h61'>For Business</h6>
                                                            <div className="row paddingright">
                                                                <div className="col-lg-2 col-md-3  col-sm-4  col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Partnership Deed")} >

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5916.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Partnership  <br />
                                                                            Deed
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3  col-sm-4 col-6 column">
                                                                    <div className='imgs'>
                                                                        <div className='img1'>
                                                                            <img className='Other_img margin' src=".\img\img\companyreg.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Company   <br />
                                                                            Registration
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className='subHeading'>Change in Business</p>
                                                            <div className="row paddingright">
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Dissolution of deeds")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5917.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Dissolution of <br />
                                                                            Deeds
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Registration & Licence</h6>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Import Export License (IEC)")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\NoPath - Copy (20).svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Import Export  <br />
                                                                            License(IEC)
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3  col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("ICE GATE Registration")}>

                                                                        <div className='img1' >
                                                                            <img className='Other_img' src=".\img\img\Group 5910.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>ICEGATE  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("MSME & Udhyam")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img margin' src=".\img\img\Group 5911.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>
                                                                            MSME <br />
                                                                            & Udyam
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Society Registration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img margin' src=".\img\img\Group 5912.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Society  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("PF Registration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5913.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>PF  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Professional Tax Registration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\proffessional.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Professional Tax  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Gumasta Licence (S&E)")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_5632_1_3.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Gumasta License  <br />
                                                                            (S&E)
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("GST Registration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_3505_1_3 (1).svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>GST  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Food Licence")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_5645_1_3.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Food  <br />
                                                                            Licence
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Trade Licence")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_5631_1_3.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Trade  <br />
                                                                            Licence
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Trademark Registration")}>

                                                                        <div className='img1' >
                                                                            <img className='Other_img' src=".\img\img\Group_3625_2_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Trade Mark  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs'>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Image_3.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>TAN  <br />
                                                                            Application
                                                                        </p>


                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("ESIC Registration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_6897.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>ESIC  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Accounting & Filing</h6>
                                                            <p id='color'>Filings</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6  column'>
                                                                    <div className='imgs'>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\professiontaxx.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>TDS Return <br />
                                                                            Filing
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' >
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\terms.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Professional Tax <br />
                                                                            Return Filing
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("PF Return Filing")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_6900.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>PF Return <br />
                                                                            Filing
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("ESIC Return Filing")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_6905.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>ESIC Return  <br />
                                                                            Filing
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("ITR Filing")} >

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_3507_1_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>ITR  <br />
                                                                            Filing
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("GST Return Filing")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_3506_1_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>GST Return  <br />
                                                                            Filing
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' >
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\ca.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Company Annual   <br />
                                                                            Return Compliances
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p id='color'>Accounting</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column '>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Online Accounting")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Layer_2_1_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Online <br />
                                                                            Accounting
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Drafting</h6>

                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Legal Drafting")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\movie-copyright_1_2.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Legal <br />
                                                                            Drafting
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Rent Agreement")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5918.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Rent <br />
                                                                            Agreement
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("NOC")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5919.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1 pt-1'>
                                                                            NOC
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Affidavit")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5920.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1 pt-1'>
                                                                            Affidavit
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Power of Attorney")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5921.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>
                                                                            Power of <br />
                                                                            Attorney
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Will")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\NoPath - Copy (17).svg" alt="" />
                                                                        </div>
                                                                        <p className='para1 pt-1'>
                                                                            Will
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Application Form")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5924.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>
                                                                            Application <br /> Form
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Legal Issue</h6>
                                                            <p id='color'>Legal Notice Reply</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Legal Notice Reply")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\legalreply.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Legal<br />Notice
                                                                            Reply
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p id='color'>Legal Notice</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Legal Notice Prepration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\help.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Legal Notice<br />
                                                                            Prepration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p id='color'>Legal Advice</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Legal Advice")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\baggage-claim.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Legal Advice
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="cardbox2">
                                                            <h6 className='h61'>Loans</h6>
                                                            <p id='color'>Loans</p>
                                                            <div className="row paddingright">

                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Personal Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\personalloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Personal Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Home Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\homeloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Home Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Gold Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\goldloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Gold Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Business Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\businessloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Business Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Loan Against Property")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\loanagainstprop.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Loan Against Property
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Car Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\carloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Car Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}


                                                        {/* <div className="cardbox2">
                                                            <h6 className='h61'>Credit Card</h6>
                                                            <p id='color'>credit Card</p>
                                                            <div className="row paddingright">
                                                               
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Indusind Bank Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Indusind Bank Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Stashfin Bank Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Stashfin Bank Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("State Bank of India Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> State Bank of India Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("AU Bank Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> AU Bank Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div> */}


                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Other</h6>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Balance Sheet Prepration & P&L")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5926.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Balance Sheet<br />
                                                                            Preparation & P&L
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Project Report Prepration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5927.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Project Report<br />
                                                                            Preparation
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("NDA Drafting")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\nda.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>NDA<br />
                                                                            Drafting
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Privacy Policy Drafting")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\user-privacy_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Privacy Policy<br />
                                                                            Drafting
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Terms and conditions Drafting")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\tnc.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Terms and<br />
                                                                            conditions Drafting
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""
                                            }

                                            {
                                                this.state.count == 1 ?
                                                    <>
                                                        <div className="cardbox2">
                                                            <h6 className='h61'>For Business</h6>
                                                            <div className="row paddingright">
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Partnership Deed")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5916.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Partnership  <br />
                                                                            Deed
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs'>
                                                                        <div className='img1'>
                                                                            <img className='Other_img margin' src=".\img\img\companyreg.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Company   <br />
                                                                            Registration
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className='subHeading'>Change in Business</p>
                                                            <div className="row paddingright">
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Dissolution of deeds")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5917.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Dissolution of <br />
                                                                            Deeds
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""
                                            }

                                            {
                                                this.state.count == 2 ?
                                                    <>
                                                        <div className='MAIN_carousel onecaro'>
                                                            <h4 className='topService'>Registration Services</h4>
                                                            <OwlCarousel className='owl-theme owl-theme2'  {...options1} >
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Import Export License (IEC)")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\NoPath - Copy (20).svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Import Export  <br />
                                                                                License(IEC)
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("ICE GATE Registration")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5910.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> ICEGATE  <br />
                                                                                Registration
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("MSME & Udhyam")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5911.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> MSME <br />
                                                                                & Udyam
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("GST Registration")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_3505_1_3 (1).svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> GST <br />
                                                                                Registration
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Gumasta Licence (S&E)")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_5632_1_3.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Gumasta License  <br />
                                                                                (S&E)
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Trademark Registration")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_3625_2_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Trademark <br />
                                                                                Registration
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Trade Licence")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_5631_1_3.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Trade <br />
                                                                                Licence
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Food Licence")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_5645_1_3.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Food <br />
                                                                                Licence
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </OwlCarousel>
                                                        </div>
                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Registration & Licence</h6>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Import Export License (IEC)")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\NoPath - Copy (20).svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Import Export  <br />
                                                                            License(IEC)
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3  col-sm-4  col-6  column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("ICE GATE Registration")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5910.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>ICEGATE  <br />
                                                                            Registration
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("MSME & Udhyam")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img margin' src=".\img\img\Group 5911.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>
                                                                            MSME <br />
                                                                            & Udyam
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Society Registration")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img margin' src=".\img\img\Group 5912.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Society  <br />
                                                                            Registration
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("PF Registration")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5913.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>PF  <br />
                                                                            Registration
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Professional Tax Registration")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\proffessional.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Professional Tax  <br />
                                                                            Registration
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Gumasta Licence (S&E)")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_5632_1_3.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Gumasta License  <br />
                                                                            (S&E)
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("GST Registration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_3505_1_3 (1).svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>GST  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Food Licence")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_5645_1_3.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Food  <br />
                                                                            Licence
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Trade Licence")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_5631_1_3.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Trade  <br />
                                                                            Licence
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Trademark Registration")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_3625_2_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Trade Mark  <br />
                                                                            Registration
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' >
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Image_3.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>TAN  <br />
                                                                            Application
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2 col-md-3 col-sm-4 col-6 column">
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("ESIC Registration")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_6897.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>ESIC  <br />
                                                                            Registration
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                    : ""
                                            }
                                            {
                                                this.state.count == 3 ?
                                                    <>
                                                        <div className='MAIN_carousel onecaro'>
                                                            <h4 className='topService'>Accounting & Filing</h4>
                                                            <OwlCarousel className='owl-theme owl-theme2'  {...options1} >
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1'>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\professiontaxx.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>TDS Return <br />
                                                                                Filing
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' >
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\terms.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Professional Tax <br />
                                                                                Return Filing
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1'>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_6900.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> PF Return <br />
                                                                                Filing
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1'>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_6905.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> ESIC Return  <br />
                                                                                Filing
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("ITR Filing")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_3507_1_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> ITR  <br />
                                                                                Filing
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("GST Return Filing")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group_3506_1_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> GST Return  <br />
                                                                                Filing
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' >
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\ca.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Company Annual   <br />
                                                                                Return Compliances
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Online Accounting")}>

                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Layer_2_1_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Online <br />
                                                                                Accounting
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </OwlCarousel>
                                                        </div>
                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Accounting & Filing</h6>
                                                            <p id='color'>Filings</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' >
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\professiontaxx.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>TDS Return <br />
                                                                            Filing
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs'>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\terms.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Professional Tax <br />
                                                                            Return Filing
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs'>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_6900.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> PF Return <br />
                                                                            Filing
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs'>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_6905.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> ESIC Return  <br />
                                                                            Filing
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("ITR Filing")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_3507_1_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> ITR  <br />
                                                                            Filing
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("GST Return Filing")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group_3506_1_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> GST Return  <br />
                                                                            Filing
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs'>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\ca.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Company Annual   <br />
                                                                            Return Compliances
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p id='color'>Accounting</p>

                                                            <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                <div className='imgs' onClick={() => this.selectservicehandle("Online Accounting")}>

                                                                    <div className='img1'>
                                                                        <img className='Other_img' src=".\img\img\Layer_2_1_1.svg" alt="" />
                                                                    </div>
                                                                    <p className='para1'>Online <br />
                                                                        Accounting
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                    : ""
                                            }
                                            {
                                                this.state.count == 4 ?
                                                    <>
                                                        <div className='MAIN_carousel onecaro'>
                                                            <h4 className='topService'>Drafting</h4>
                                                            <OwlCarousel className='owl-theme owl-theme2'  {...options1} >
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Legal Drafting")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\movie-copyright_1_2.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Legal <br />
                                                                                Drafting
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Rent Agreement")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5918.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>
                                                                                Rent <br /> Agreement

                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("NOC")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5919.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>  <br />
                                                                                NOC
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Affidavit")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5920.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>  <br />
                                                                                Affidavit
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Power of Attorney")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5921.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>
                                                                                Power of <br />
                                                                                Attorney
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Will")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\NoPath - Copy (17).svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>  <br />
                                                                                Will
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Application Form")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5924.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>
                                                                                Application <br /> Form
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Legal Drafting")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\movie-copyright_1_2.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Legal <br />
                                                                                Drafting
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </OwlCarousel>
                                                        </div>
                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Drafting</h6>

                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Legal Drafting")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\movie-copyright_1_2.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Legal <br />
                                                                            Drafting
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Rent Agreement")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5918.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Rent <br /> Agreement

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("NOC")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5919.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1 pt-1'>
                                                                            NOC
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Affidavit")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5920.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1 pt-1'>
                                                                            Affidavit
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Power of Attorney")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5921.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>
                                                                            Power of <br />
                                                                            Attorney
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Will")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\NoPath - Copy (17).svg" alt="" />
                                                                        </div>
                                                                        <p className='para1 pt-1'>
                                                                            Will
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Application Form")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5924.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>
                                                                            Application <br /> Form
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : ""
                                            }

                                            {
                                                this.state.count == 5 ?
                                                    <>

                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Legal Issue</h6>
                                                            <p id='color'>Legal Notice Reply</p>
                                                            <div className="row paddingright ">
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Legal Notice Reply")}>

                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\legalreply.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Legal<br />Notice
                                                                            Reply
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p id='color'>Legal Notice</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Legal Notice Prepration")}>
      
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\help.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Legal Notice<br />
                                                                            Preparation
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p id='color'>Legal Advice</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Legal Advice")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\baggage-claim.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Legal Advice
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </> : ""
                                            }

                                            {
                                                this.state.count == 6 ?
                                                    <>
                                                        <div className='MAIN_carousel onecaro'>
                                                            <h4 className='topService'>Loans</h4>
                                                            <OwlCarousel className='owl-theme owl-theme2'  {...options1} >
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Personal Loan")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\personalloan.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>Personal Loan
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Home Loan")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\homeloan.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Home Loan
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Gold Loan")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\goldloan.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Gold Loan
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Business Loan")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\businessloan.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Business Loan
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Loan Against Property")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\loanagainstprop.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>Loan Against Property
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Car Loan")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\carloan.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>Car Loan
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </OwlCarousel>
                                                        </div>
                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Loans</h6>
                                                            <p id='color'>Loans</p>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Personal Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\personalloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Personal Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Home Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\homeloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Home Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Gold Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\goldloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Gold Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Business Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\businessloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Business Loan
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Loan Against Property")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\loanagainstprop.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Loan Against Property
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Car Loan")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\carloan.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Car Loan
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </>
                                                    : ""
                                            }
                                            {
                                                this.state.count == 7 ?
                                                    <>
                                                        <div className='MAIN_carousel onecaro'>
                                                            <h4 className='topService'>Credit Card</h4>
                                                            <OwlCarousel className='owl-theme owl-theme2'  {...options1} >
                                                                {/* <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Yes Bank Credit Card")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\credit-card.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>Yes Bank Credit <br />Card
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Indusind Bank Credit Card")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\credit-card.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Indusind Bank Credit Card
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Axis Bank Credit Card")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\credit-card.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Axis Bank Credit Card
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Stashfin Bank Credit Card")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\credit-card.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Stashfin Bank Credit Card
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("State Bank of India Credit Card")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\credit-card.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>State Bank of India Credit Card
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("AU Bank Credit Card")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\credit-card.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>AU Bank Credit <br /> Card
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </OwlCarousel>
                                                        </div>
                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Credit Card</h6>
                                                            <p id='color'>credit Card</p>
                                                            <div className="row paddingright">
                                                                {/* <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Yes Bank Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Yes Bank Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div> */}
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Indusind Bank Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Indusind Bank Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Axis Bank Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Axis Bank Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div> */}
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Stashfin Bank Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Stashfin Bank Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("State Bank of India Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> State Bank of India Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("AU Bank Credit Card")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\credit-card.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'> AU Bank Credit Card
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                this.state.count == 8 ?
                                                    <>
                                                        <div className='MAIN_carousel onecaro'>
                                                            <h4 className='topService'>Other</h4>
                                                            <OwlCarousel className='owl-theme owl-theme2'  {...options1} >
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Balance Sheet Prepration & P&L")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5926.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>Balance Sheet<br />
                                                                                Preparation & P&L
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Privacy Policy Drafting")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5927.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Project Report<br />
                                                                                Preparation
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("NDA Drafting")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\nda.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> NDA<br />
                                                                                Drafting
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Privacy Policy Drafting")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\user-privacy_1.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Privacy Policy<br />
                                                                                Drafting
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Terms and conditions Drafting")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\tnc.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>Terms and<br />
                                                                                Conditions Drafting
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Balance Sheet Prepration & P&L")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5926.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'>Balance Sheet<br />
                                                                                Preparation & P&L
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("Privacy Policy Drafting")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\Group 5927.svg" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> Project Report<br />
                                                                                Preparation
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='item '>
                                                                    <div className="owl_data OwLData">
                                                                        <div className='new1' onClick={() => this.selectservicehandle("NDA Drafting")}>
                                                                            <div className='new2'>
                                                                                <img className='Owl_img' src=".\img\img\nda.png" alt="" />
                                                                            </div>
                                                                            <p className='Owl_Para'> NDA<br />
                                                                                Drafting
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </OwlCarousel>
                                                        </div>
                                                        <div className="cardbox2">
                                                            <h6 className='h61'>Other</h6>
                                                            <div className="row paddingright">
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Balance Sheet Prepration & P&L")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5926.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Balance Sheet<br />
                                                                            Preparation & P&L
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Project Report Prepration")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\Group 5927.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'> Project Report<br />
                                                                            Preparation
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("NDA Drafting")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\nda.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>NDA<br />
                                                                            Drafting
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4  col-6 column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Privacy Policy Drafting")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\user-privacy_1.svg" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Privacy Policy<br />
                                                                            Drafting
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-3 col-sm-4 col-6  column'>
                                                                    <div className='imgs' onClick={() => this.selectservicehandle("Terms and conditions Drafting")}>
                                                                        <div className='img1'>
                                                                            <img className='Other_img' src=".\img\img\tnc.png" alt="" />
                                                                        </div>
                                                                        <p className='para1'>Terms and<br />
                                                                            conditions Drafting
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""
                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-3 NewCol">
                                        <div className="mm2 mymm2new">
                                            {this.state.supportname != "" && this.state.supportcontact != ""?

                                                <div className='supportDetails supportDetailsnew'>
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                                                        alt="image"
                                                    />
                                                    <div>
                                                        <h5>{this.state.supportname}</h5>
                                                        <p>Agent Support Executive</p>
                                                        <span><b>Contact: </b> +91 {this.state.supportcontact}</span>
                                                        <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                                                    </div>
                                                </div>

                                                : null}
                                            <center>
                                                <button onClick={this.showPromotion} className=" dashBoardBtn newtmar">Share Promotional Material</button>
                                                <button onClick={this.TrainingMaterial} className="dashBoardBtn mt-4">Training Material</button>
                                                <button
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                                                    data-target="#youtubeModalmain"
                                                    className="dashBoardBtn mt-4 newbmar"
                                                >
                                                    Watch Tutorials
                                                </button>
                                            </center>
                                            <center>
                                                {this.state.banner.length > 0 ?
                                                    <OwlCarousel id="owl-demo" className='owl-theme owl-theme2  owll'  {...options2} >
                                                        {this.state.banner.map((ban, index) => {

                                                            return (
                                                                <div className='item' key={index}>
                                                                    <a href={ban.redirectUrl == "" ? "https://www.legal251.com/" : ban.redirectUrl} target="_blank">
                                                                        <div className="owl_data OwLData1">
                                                                            <div className=''>
                                                                                <img className='newImg1' src={ban.bannerUrl} alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            )
                                                        })}
                                                    </OwlCarousel>
                                                    : null}
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="notificationModal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content requiredDocumentModal">
                            <div className="modal-header border-bottom-0">
                                <h5 className="text-center w-100 ">Notice</h5>
                                <button
                                    type="button"
                                    onClick={() => $("#notificationModal").modal("hide")}
                                    className="close closeMain mr-2 closeNotification"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body uploadpopupbody">
                                {this.state.notification.map((noti, index) => {

                                    return (
                                        <div className="displayflex px-3" key={index}>
                                            <div className="uploadnocol"></div>
                                            <div className="uploaddatacol">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <p className="uploadnormaltext notificationHead">
                                                            {noti.type}
                                                        </p>
                                                        <p className="uploadnormaltext notificationMessage">
                                                            {noti.message}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        {noti.documents.map((doc, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className="uploadnormaltext notificationContent"
                                                                >
                                                                    {doc.DocumentName}
                                                                </li>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="d-flex flex-column px-4 justify-content-center align-items-center">
                                <p className="notificationNote mt-3">
                                    <b>Note:</b> FOR  SUPPORT AGENT CAN DIRECTLY CALL ON 77728-77729 AND DIAL 73.
                                    <br />सहायता के लिए एजेंट्स सीधे 77728-77729 पर कॉल कर 73 डायल करे.
                                </p>
                                <button
                                    className="btn X-btn d-block my-2 docModalsamplebtn"
                                    onClick={() => $("#notificationModal").modal("hide")}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="youtubeModal1"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered yt_modal "
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-body modal_b">
                                <button
                                    type="button"
                                    className="close close1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        id="video"
                                        allowscriptaccess="always"
                                        src="https://www.youtube.com/embed/nrRu4_aZb8g"
                                        allowFullScreen="allowfullscreen"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="youtubeModal2"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered yt_modal "
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-body modal_b">
                                <button
                                    type="button"
                                    className="close close1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        id="video"
                                        allowscriptaccess="always"
                                        src="https://www.youtube.com/embed/_D-Bh8KkQdw"
                                        allowFullScreen="allowfullscreen"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="youtubeModal3"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered yt_modal "
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-body modal_b">
                                <button
                                    type="button"
                                    className="close close1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        id="video"
                                        allowscriptaccess="always"
                                        src="https://www.youtube.com/embed/qPgrOl_p6xU"
                                        allowFullScreen="allowfullscreen"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="youtubeModal4"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered yt_modal "
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-body modal_b">
                                <button
                                    type="button"
                                    className="close close1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        id="video"
                                        allowscriptaccess="always"
                                        src="https://www.youtube.com/embed/RAOCth_jnIo"
                                        allowFullScreen="allowfullscreen"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="youtubeModal5"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered yt_modal "
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-body modal_b">
                                <button
                                    type="button"
                                    className="close close1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        id="video"
                                        allowscriptaccess="always"
                                        src="https://www.youtube.com/embed/PwBZR3dxP_Y"
                                        allowFullScreen="allowfullscreen"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="youtubeModal6"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered yt_modal "
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-body modal_b">
                                <button
                                    type="button"
                                    className="close close1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        id="video"
                                        allowscriptaccess="always"
                                        src="https://www.youtube.com/embed/PwB3nGW76BU"
                                        allowFullScreen="allowfullscreen"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="youtubeModal7"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered yt_modal "
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-body modal_b">
                                <button
                                    type="button"
                                    className="close close1"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        id="video"
                                        allowscriptaccess="always"
                                        src="https://www.youtube.com/embed/i2LAOION1cU"
                                        allowFullScreen="allowfullscreen"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="youtubeModalmain"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered yt_modal "
                        role="document"
                    >
                        <div className="modal-content videoModal">
                            <div className="modal-body modal_b mt-4">
                                <button
                                    type="button"
                                    className="close close1 closeMain mr-2"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="navigation-tab modalNavigation">
                                    <header className="tabs-nav position-relative">
                                        <ul className="d-flex p-0 ">
                                            <li className=" navigation-tab-li" id="activeTab">
                                                <a
                                                    href="#"
                                                    id="englishTab"
                                                    className="px-2 py-1 modalTab activemodalTab"
                                                    onClick={() => {
                                                        if (this.state.language != "English") {
                                                            this.setState({ language: "English" }, () => {
                                                                $("#englishTab").addClass("activemodalTab");
                                                                $("#hindiTab").removeClass("activemodalTab");
                                                            });
                                                        }
                                                    }}
                                                >
                                                    हिन्दी
                                                </a>
                                            </li>
                                            <li className="navigation-tab-li" id="completedTab">
                                                <a
                                                    href="#"
                                                    id="hindiTab"
                                                    className="px-2 py-1 modalTab "
                                                    onClick={() => {
                                                        if (this.state.language != "Hindi") {
                                                            this.setState({ language: "Hindi" }, () => {
                                                                $("#hindiTab").addClass("activemodalTab");
                                                                $("#englishTab").removeClass(
                                                                    "activemodalTab"
                                                                );
                                                            });
                                                        }
                                                    }}
                                                >
                                                    English
                                                </a>
                                            </li>
                                        </ul>
                                    </header>
                                </div>

                                {this.state.language == "English" ? (
                                    <div className="row justify-content-center m-0 spMargin">
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/nrRu4_aZb8g"
                                                    data-target="#youtubeModal1"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Legal251_Agent_Panel_Introduction.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">एजेंट पेनल की जानकारी</p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/_D-Bh8KkQdw"
                                                    data-target="#youtubeModal2"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Service_Purchase_for_New_Existing_and_Link_User_.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                नए और मौजूदा कस्टमर की सर्विस कैसे परचेस करे ?
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/qPgrOl_p6xU"
                                                    data-target="#youtubeModal3"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Help_Support__Complain_for_Users.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                एजेंट अपने ग्राहकों के लिए सहायता और कम्प्लेन दर्ज
                                                कैसे करे
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/RAOCth_jnIo"
                                                    data-target="#youtubeModal4"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Providing_Fast_Service_to_Walk_in_Customers.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                मौजूदा यूजर को फ़ास्ट सर्विसेस कैसे दे ?
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/PwBZR3dxP_Y"
                                                    data-target="#youtubeModal5"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Upload_Document_in_any_service.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                सर्विसेस में डाक्यूमेंट्स कैसे अपलोड करे ?
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/PwB3nGW76BU"
                                                    data-target="#youtubeModal6"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Help_Support__Complain_for_Agents.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                एजेंट अपनी सहायता , सपोर्ट और कम्प्लेन दर्ज करे
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/i2LAOION1cU"
                                                    data-target="#youtubeModal7"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Create_Password_New_Agent.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                नए एजेंट अपना पासवर्ड कैसे बनाये ?
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row justify-content-center m-0 spMargin">
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/nrRu4_aZb8g"
                                                    data-target="#youtubeModal1"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Legal251_Agent_Panel_Introduction.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                Legal251 Agent Panel Introduction
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/_D-Bh8KkQdw"
                                                    data-target="#youtubeModal2"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Service_Purchase_for_New_Existing_and_Link_User_.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                Service Purchase for New, Existing and Link User
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/qPgrOl_p6xU"
                                                    data-target="#youtubeModal3"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Help_Support__Complain_for_Users.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                Help, Support & Complain for Users
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/RAOCth_jnIo"
                                                    data-target="#youtubeModal4"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Providing_Fast_Service_to_Walk_in_Customers.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                Providing Fast Service to Walk in Customers
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/PwBZR3dxP_Y"
                                                    data-target="#youtubeModal5"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Upload_Document_in_any_service.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                Upload Document in any service
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/PwB3nGW76BU"
                                                    data-target="#youtubeModal6"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Help_Support__Complain_for_Agents.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                Help, Support & Complain for Agents
                                            </p>
                                        </div>
                                        <div className="mm videoDiv">
                                            <center>
                                                <a
                                                    className="video-btn"
                                                    data-toggle="modal"
                                                    data-src="https://www.youtube.com/embed/i2LAOION1cU"
                                                    data-target="#youtubeModal7"
                                                    onClick={() => {
                                                        $("#youtubeModalmain").modal("hide");
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/img/Create_Password_New_Agent.png"
                                                        }
                                                        id="play"
                                                        className="play_img playImg2"
                                                        alt="image"
                                                    />
                                                </a>
                                            </center>
                                            <p className="videoTitle mb-0">
                                                Create Password New Agent?
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}

export default Dashboard









import React, { Component } from "react";
import { Redirect } from "react-router";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import axios from "axios";
import ScriptTag from "react-script-tag";
import Select2 from "react-select2-wrapper";
import jsonData from "../State__and__dist.json";

var sha256 = require("js-sha256");
let Decuserid;
let DecemployeeId;
let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var fullname = "";
var citydistrict = "";
var servicename = "";
var servicestate = "";
var deldata = [];
var semail;
var scontact;
var hide;
var seedata;
var sessiondata;
var DecAdminType;
var Decemployeeid;
var servicearr = [];
let clrinterval = false;
var encryptor = require("simple-encryptor")(key);
var encryptor = require("simple-encryptor")(key);

export class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartdata: [],
      servicefullname: "",
      serviceselectvalue: "",
      servicerequestid: "",
      serviceprice: "",
      servicegovtfees: "",
      coupun: "",
      servicetotal: "",
      serviceshortname: "All",
      couponapplied: "",
      coupunpercentage: "",
      servicepriceaftercoupun: "",
      couponstatus: "false",
      checkserviceplan: "",
      gstno: "",
      servicegst: "",
      servicegstaftercoupun: "",
      flat: "",
      referral: "",
      citydistrictselectvalue: "",
      user__state: "",
      billing_name: "",
      sub_name_city: "",
      paymentLink: "",
      billingcontact: "",
      billingemail: "",
      update_discountType: "P",
      couponPercent: "",
      mtime: 60,
      etime: 60,
      contactOtp: "",
      emailOtp: "",
      sendotpsucess: false,
      sendotpsucess2: false,
      citydisabled:false,
      Servicesortcode:false,
      communicationMode:""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");

    semail = sessionStorage.getItem("newuseremail");
    scontact = sessionStorage.getItem("newusercontact");
    let mainemployeeid = localStorage.getItem("qazxswedcvfrtgb");
    Decemployeeid = encryptor.decrypt(mainemployeeid);
    DecemployeeId = encryptor.decrypt(mainagentid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let typeadmin = localStorage.getItem("werewrewrewrr");
    DecAdminType = encryptor.decrypt(typeadmin);
    let maincitydistrict = sessionStorage.getItem(
      "ylcvciretaireditdgisdtacesl"
    );

    var sessiondata;
    var localfees = 0;
    var localgst = 0;
    var localgovt = 0; 
    sessiondata = sessionStorage.getItem("data");
    seedata = JSON.parse(sessionStorage.getItem("data"));
    if (sessiondata == null || sessiondata == undefined || sessiondata == "") {
      sessiondata = [];
    } else {
      sessiondata = JSON.parse(sessionStorage.getItem("data"));
      if (sessiondata.length > 0) {
        var arraylen = sessiondata.length;
        for (let index = 0; index < arraylen; index++) {
          localfees = sessiondata[index].price + localfees;
          localgst = sessiondata[index].gstvalue + localgst;
          localgovt = sessiondata[index].govtfees + localgovt;
          servicearr.push(sessiondata[index].serviceName)
          
        }
        if (servicearr.includes("LLP") || servicearr.includes("OPC") || servicearr.includes("SECE") || servicearr.includes("PVTL")) {
          this.setState({
            Servicesortcode : true
          })
        }else{
          this.setState({
            Servicesortcode : false
          })
        }

        this.setState({
          serviceprice: Number(localfees.toFixed(2)),
          servicegst: localgst,
          servicegovtfees: localgovt,
          servicetotal: localfees + localgst + localgovt,
          user__state: sessiondata[0].state,
          billing_name: sessiondata[0].purchasename,
          sub_name_city: sessiondata[0].citydistrict,
        });
      }

      if (sessiondata[0].userId != "" && sessiondata[0].userId == undefined) {
        this.setState({
          sendotpsucess2: false,
        });
        hide = false;
      } else if (sessiondata[0].userId == "") {
        semail = sessionStorage.getItem("newuseremail");
        scontact = sessionStorage.getItem("newusercontact");
        this.setState({
          billingcontact: scontact,
          billingemail: semail,
          sendotpsucess2: true,
        });
        hide = true;
      } else {
        this.setState({
          sendotpsucess2: false,
        });
        hide = false;
      }
    }

    this.setState({
      cartdata: sessiondata,
    });
  }

  backbutton = () => {
    this.props.history.push({
      pathname: `/addservice`,
    });
  };


  Checkplan = (plan) => {
    if (plan == "S") {
      return <>(Starter)</>;
    } else if (plan == "B") {
      return <>(Basic)</>;
    } else if (plan == "P") {
      return <>(Pro)</>;
    } else if (plan == "PR") {
      return <>(Premium) </>;
    } else if (plan == "PP") {
      return <>(Pro+)</>;
    } else if (plan == null) {
      return <></>;
    } else {
      return <></>;
    }
  };



  Checkpackage = (plan,servicename) => {
   if(servicename=="ITRF")
   {
    if (plan == "S") {
      return <>  Salaried -</>;
    } else if (plan == "B") {
      return <>  Business -</>;
    }  else if (plan == null) {
      return <></>;
    } else {
      return <></>;
    }
   }
   
  };



  handledelete = (delindex) => {
    servicearr = [] 
    var data = this.state.cartdata;
    data.splice(delindex, 1);
    this.setState({
      cartdata: data,
    });

    if (data.length > 0) {
      var localfees = 0;
      var localgst = 0;
      var localgovt = 0;
      var arraylen = data.length;
      for (let index = 0; index < arraylen; index++) {
        localfees = data[index].price + localfees;
        localgst = data[index].gstvalue + localgst;
        localgovt = data[index].govtfees + localgovt;
        servicearr.push(data[index].serviceName)
      }
      if (servicearr.includes("LLP") || servicearr.includes("OPC") || servicearr.includes("SECE") || servicearr.includes("PVTL")) {
        this.setState({
          Servicesortcode : true
        })
      }else{
        this.setState({
          Servicesortcode : false
        })
      }
      this.setState({
        serviceprice: localfees,
        servicegst: localgst,
        servicegovtfees: localgovt,
        servicetotal: localfees + localgst + localgovt,
      });

      if (this.state.couponstatus == "true") {
        $(".copunappliedtext").hide();
        $(".delbtn").hide();
        $("#applybtnmain").show();
        $(".success").hide();
        $(".failure").hide();
        $(".copunorginalprice").removeClass("copunline");
        this.setState(
          {
            coupunpercentage: "",
            couponapplied: "",
            couponstatus: "false",
          },
          () => {
            this.applycoupun();
          }
        );
      }
    }
    sessionStorage.setItem("data", JSON.stringify(this.state.cartdata));
  };
  showservicetotal = (total) => {
    if (Number.isInteger(total)) {
      return total;
    } else {
      total = parseFloat(total).toFixed();
      return total;
    }
  };

  SendOTP = () => {
    clrinterval = false;
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.billing_name.trim() == "") {
      this.showNotification("Please enter Billing Name");
    }
    else if (this.state.billingemail.trim() == "") {
      this.showNotification("Please enter Billing Email");
    } else if (
      !emailregex.test(this.state.billingemail)
    ) {
      this.showNotification("Please enter Valid Email");
    } else if (this.state.billingcontact.trim() == "") {
      this.showNotification("Please enter Billing Contact");
    } else if (
      this.state.billingcontact.trim() == "" ||
      !contactNoregex.test(this.state.billingcontact)
    ) {
      this.showNotification("Please enter Valid Contact");
    }
    else if (this.state.sub_name_city.trim() == "") {
      this.showNotification("Please select Billing City/District");
    }
    else {
      $(".paymentloader").show();
      $(".sendcartotpbtn").hide();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=newusercartotp",
          {
            agentId: Decagentid,
            contact: this.state.billingcontact,
            email: this.state.billingemail,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          $(".paymentloader").hide();
          $(".sendcartotpbtn").show();
          if (res.data.code == 200 && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.setState({
              sendotpsucess: true,
              sendotpsucess2: false,
              citydisabled:true
            });
            $('.billingcommon').prop('disabled', true);
            this.countdown();
            this.countdownemail();
          } else if (res.data.code == 201) {
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
          $(".paymentloader").hide();
          $(".sendcartotpbtn").show();
        });
    }
  };

  createcartorder = () => {
    $("#invalidemailotpcheck").hide();
    $("#invalidmobileotpcheck").hide();
    $("#emailotpsentcheck").hide();
    $("#mobileotpsentcheck").hide();
    $("#choosecommunication").hide();
    let gstregex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    this.setState({ paynow: true });
    if ( this.state.communicationMode.trim() == "" ) {
      $("#choosecommunication").show();
    } else if (
      hide == true &&
      this.state.emailOtp.trim() == "" &&
      this.state.contactOtp.trim() == ""
    ) {
      $("#invalidemailotpcheck").show();
      $("#invalidmobileotpcheck").show();
      this.setState({ paynow: false });
    } else if (
      hide == true &&
      (this.state.contactOtp.trim() == "" || isNaN(this.state.contactOtp))
    ) {
      $("#invalidmobileotpcheck").show();
      this.setState({ paynow: false });
    } else if (
      hide == true &&
      (this.state.contactOtp.length > 6 || this.state.contactOtp < 6)
    ) {
      $("#invalidmobileotpcheck").show();
      this.setState({ paynow: false });
    } else if (hide == true && this.state.emailOtp.trim() == "") {
      $("#invalidemailotpcheck").show();
      this.setState({ paynow: false });
    } else if (
      hide == true &&
      (this.state.emailOtp.length > 6 || this.state.emailOtp < 6)
    ) {
      $("#invalidemailotpcheck").show();
      this.setState({ paynow: false });
    } else if (
      this.state.gstno.trim() != "" &&
      !gstregex.test(this.state.gstno)
    ) {
      this.showNotification("Please enter a valid Gst no");
      this.setState({ paynow: false });
    } else {
      var localemailotp = this.state.emailOtp;
      if (this.state.emailOtp == "NOTREC") {
        localemailotp = "0";
      }
      if (hide == true) {
        Decuserid = "";
      }

      $(".paynowbtn").hide();
      $(".paymentloader").show();

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=createCartOrder",
          {
            userId: Decuserid,
            agentId: Decagentid,
            services: this.state.cartdata,
            coupon: this.state.couponapplied,
            currency: "INR",
            gstno: this.state.gstno,
            govtfees: this.state.servicegovtfees,
            referral: this.state.referral,
            billingName: this.state.billing_name,
            billingCity: this.state.sub_name_city,
            billingState: this.state.user__state,
            emailOTP: localemailotp,
            contactOTP: this.state.contactOtp,
            contact: this.state.billingcontact,
            email: this.state.billingemail,
            CommunicationMode: this.state.communicationMode
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".paynowbtn").show();
            $(".paymentloader").hide();
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              amount: res.data.finalamount,
              currency: "INR",
              name: this.state.servicefullname,
              description: "Regular Service",
              image: "https://legal251.com/images/common/legal.svg",
              order_id: res.data.orderId,
              theme: {
                color: "#f8951d",
              },
              notes: {
                requestid: this.state.servicerequestid,
                from: "AGP",
                servicetype: "RL",
                agentid: Decagentid,
                userid: Decuserid,
                couponstatus: this.state.couponstatus,
                couponcode: this.state.couponapplied.toUpperCase(),
                discount: this.state.coupunpercentage,
                gstnumber: this.state.gstno,
                upgrade: "false",
                renew: "false",
                invoice: "bill",
              },
              handler: (response) => {
                var mysign = sha256.hmac(
                  process.env.REACT_APP_RAZORPAY_SECRETKEY,
                  res.data.orderId + "|" + response.razorpay_payment_id
                );
                if (
                  response.razorpay_payment_id != null &&
                  response.razorpay_payment_id != false &&
                  response.razorpay_payment_id != ""
                ) {
                  if (mysign == response.razorpay_signature) {
                    sessionStorage.clear();
                    this.props.history.push({
                      pathname: `/addservice/paymentsuccessfull`,
                      state: {
                        orderid: res.data.orderId,
                        servicerequestid: res.data.requestid,
                        serviceshortname: "CART",
                      },
                    });
                  } else {
                    sessionStorage.clear();
                    this.props.history.push({
                      pathname: `/addservice/paymentfailed`,
                      state: { Paymentfailed: true },
                    });
                  }
                } else {
                  sessionStorage.clear();
                  this.props.history.push({
                    pathname: `/addservice/paymentfailed`,
                    state: { Paymentfailed: true },
                  });
                }
              },
              prefill: {
                name: this.state.servicefullname,
                email: res.data.email,
                contact: res.data.contact,
              },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
            rzp1.on("payment.failed", function (response) {
              sessionStorage.clear();
              this.props.history.push({
                pathname: `/addservice/paymentfailed`,
                state: { Paymentfailed: true },
              });
            });
          } else if (res.data.code == "200" && res.data.status == "paid") {
            sessionStorage.clear();
            this.props.history.push({
              pathname: `/addservice/paymentsuccessfull`,
              state: {
                orderid: res.data.orderId,
                servicerequestid: res.data.requestid,
                serviceshortname: "CART",
              },
            });
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            $(".paynowbtn").show();
            $(".paymentloader").hide();
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  

  countdownemail() {
    $("#emailresend-loadercheck").hide();
    function clear() {
      clearInterval(interval);
      $("#emailresend-loadercheck").hide();
    }
    let interval = setInterval(() => {
      if (this.state.etime == 1) {
        clear(interval);
        $("#emailresend-loadercheck").hide();
      } else {
      }
      this.setState({ etime: this.state.etime - 1 });
    }, 1000);
  }

  countdown() {
    $("#mobileresend-loadercheck").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loadercheck").hide();
    }
    let interval = setInterval(() => {
      if (this.state.mtime == 1) {
        clear(interval);
        $("#mobileresend-loadercheck").hide();
      } else {
      }
      this.setState({ mtime: this.state.mtime - 1 });
    }, 1000);
  }

  handlemobileresend = () => {
    $("#mobileresendcheck").hide();
    $("#mobileresend-loadercheck").show();

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}p=CartResendContactOTP`,
        {
          agentId: Decagentid,
          contact: this.state.billingcontact,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          this.setState({ mtime: 60 });
          this.countdown();

          $("#mobileotpsentcheck").show();
          $("#invalidmobileotpcheck").hide();
          $("#mobileresendcheck").show();
          $("#mobileresend-loadercheck").hide();
        } else {
          this.showNotification(res.data.message);
          $("#mobileotpsentcheck").hide();
          $("#sendingmobile").show();
          $("#mobileresendcheck").show();
          $("#mobileresend-loadercheck").hide();
        }
      })
      .catch((err) => {
        $("#mobileotpsentcheck").hide();
        $("#mobileresendcheck").show();
        $("#mobileresend-loadercheck").hide();
        this.setState({ sendingmobile: false });
        this.showNotification("Something went wrong");
      });
  };

  handleemailotp = () => {
    $("#invalidemailotpcheck2").hide();
    $("#emailotpsentcheck2").show();
    $("#emailotpinput").prop("disabled", true);
    this.setState({ emailOtp: "NOTREC" });
  };

  handlemobileotp = () => {
    $("#invalidemailotpcheck2").show();
    $("#emailotpsentcheck2").hide();
    $("#emailotpinput").prop("disabled", false);
    this.setState({ emailOtp: "" });
  };

  handleemailresend = () => {
    $("#emailresendcheck").hide();
    $("#emailresend-loadercheck").show();
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}p=CartResendEmailOTP`,
        {
          agentId: Decagentid,
          email: this.state.billingemail,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          $("#emailotpsentsheck").show();
          $("#invalidemailotpcheck").hide();
          this.setState({ sendingemail: true });
          this.setState({ etime: 60 });
          $("#emailresendcheck").show();
          $("#emailresend-loadercheck").hide();
          this.countdownemail();
        } else {
          $("#emailresendcheck").show();
          $("#emailresend-loadercheck").hide();
          this.showNotification(res.data.message);
          $("#emailotpsentcheck").hide();
          this.setState({ sendingemail: false });
        }
      })
      .catch((err) => {
        $("#emailresendcheck").show();
        $("#emailresend-loadercheck").hide();
        $("#emailotpsentcheck").hide();
        this.showNotification("Something went wrong");
        this.setState({ sendingemail: false });
      });
  };

  handlecoupunChange = (e) => {
    this.setState({
      coupun: e.target.value,
    });
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  applycoupun = () => {
    if (this.state.coupun != "") {
      $(".couponapplyloader").show();
      $("#applybtnmain").hide();
      if (seedata[0].userId == "") {
        if (semail != null && scontact != null) {
          Decuserid = "";
        }
      }
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=couponVerifyAdToCart",
          {
            userId: Decuserid,
            serviceName: this.state.cartdata,
            coupon: this.state.coupun,
            totalPrice: this.state.serviceprice,
            agentId: Decagentid,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".copunappliedtext").show();
            $(".couponapplyloader").hide();
            $(".delbtn").show();
            $("#applybtnmain").hide();
            $(".success").show();
            $(".failure").hide();
            $(".copunorginalprice").addClass("copunline");
            $(".applycoupen").prop('disabled', true);
            this.setState({
              servicepriceaftercoupun: res.data.price,
              servicegstaftercoupun: this.state.servicegst,
              servicegst: res.data.gstvalue,
              servicetotal:
                parseFloat(res.data.price) +
                this.state.servicegovtfees +
                parseFloat(res.data.gstvalue),
              coupunpercentage: res.data.discount,
              couponapplied: this.state.coupun,
              couponstatus: "true",
              flat: res.data.type,
            });
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            $(".couponapplyloader").hide();
            $(".copunappliedtext").hide();
            $(".delbtn").hide();
            $("#applybtnmain").show();
            $(".success").hide();
            $(".failure").show();
            $(".copunorginalprice").removeClass("copunline");
            this.setState({
              servicepriceaftercoupun: "",
              servicetotal:
                this.state.serviceprice +
                this.state.servicegovtfees +
                this.state.servicegst,
              coupunpercentage: "",
              couponapplied: "",
              couponstatus: "false",
            });
          }
        })
        .catch((err) => {});
    }
  };

  delcoupun = () => {
    $(".copunappliedtext").hide();
    $(".delbtn").hide();
    $("#applybtnmain").show();
    $(".success").hide();
    $(".failure").hide();
    $(".copunorginalprice").removeClass("copunline");
    $(".applycoupen").prop('disabled', false);
    this.setState({
      servicegst: this.state.servicegstaftercoupun,
      servicetotal:
        this.state.serviceprice +
        this.state.servicegovtfees +
        this.state.servicegstaftercoupun,
      servicepriceaftercoupun: "",
      servicegstaftercoupun: "",
      coupunpercentage: "",
      couponapplied: "",
      couponstatus: "false",
      coupun: "",
    });
  };

  cardAddService = () => {
    this.props.history.push({
      pathname: "/addservice",
    });
  };

  linkUser = () => {};

  AddBillingAddrress = () => {
    $("#Query-Desc").modal("show");
  };
  createCoupon = () => {
    $("#invalidDiscount2").hide();
    $("#invalidDiscount").hide();
    if (this.state.couponPercent == "") {
      $("#invalidDiscount").show();
    } else if (
      this.state.update_discountType === "P" &&
      DecAdminType !== "ADMIN" &&
      (this.state.couponPercent > 20.1 || this.state.couponPercent < 1)
    ) {
      $("#invalidDiscount2").show();
    } else if (
      this.state.update_discountType === "P" &&
      DecAdminType === "ADMIN" &&
      (this.state.couponPercent > 100.1 || this.state.couponPercent < 1)
    ) {
      $("#invalidDiscount2").show();
    } else {
      $(".createCouponBtn").hide();
      $(".createCouponLoader").show();
    
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=createcheckoutCoupon",
          {
            discounttype: this.state.update_discountType,
            employeeId: Decemployeeid,
            serviceName: this.state.serviceshortname,
            coupon: this.state.couponPercent,
            requestid: this.state.servicerequestid,
            total: this.state.serviceprice,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          $(".createCouponBtn").show();
          $(".createCouponLoader").hide();
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({ coupun: res.data.couponCode }, () => {
              this.applycoupun();
            });
            $("#createCoupon").modal("hide");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            $("#createCoupon").modal("hide");
            this.logout();
          }
        })
        .catch((err) => {
          $(".createCouponBtn").show();
          $(".createCouponLoader").hide();
          $("#createCoupon").modal("hide");
          this.showNotification("Something Went Wrong");
        });
    }
  };

  citydistrictselecthandle = (event) => {
    this.setState({
      sub_name_city: event.target.value,
      user__state: jsonData[0][event.target.value],
    });
  };

  handlecartname = (event) => {
    this.setState({
      billing_name: event.target.value,
    });
  };



  handleemail = (e) => {
    this.setState({
      billingemail: e.target.value,
    });
  };

  handlecontact = (e) => {
    this.setState({
      billingcontact: e.target.value,
    });
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <>
          <div className="cont">
            <Helmet>
              <link
                rel="stylesheet"
                href={process.env.PUBLIC_URL + "/css/select2.css"}
              />
            </Helmet>

            <Header />
            <div id="notifContainer"></div>
            <div className="arrow-left2 visibilityhidden"></div>
            <img
              src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
              onClick={this.backbutton}
              className="sidebarbackicon"
            />
            <div className="white_div expandwhite_div">
              <div className="white_box">
                <div className="white_boxscrolldiv checkoutmain">
                  <p className="checkouthead">Checkout</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="revord">
                        <h4>Review Your Order</h4>

                        {this.state.cartdata.length == 0 ? (
                          <div className="empty_card_div">
                            <center>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Empty-pana (1).svg"
                                }
                              />
                              <p className="">
                                Your cart is empty right now add some services
                                to checkout
                              </p>
                              <button
                                className="cart_add_service_btn"
                                onClick={this.cardAddService}
                              >
                                Add Services
                              </button>
                            </center>
                          </div>
                        ) : (
                          this.state.cartdata.map((item, index) => {
                            return (
                              <div key={index} className="cartdiv">
                                <div className="d-flex">
                                  <div>
                                    <img
                                      className="serviceicon"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/NoPathCopy.png"
                                      }
                                    />
                                  </div>
                                  <div className="servicenandprice">
                                  <h5 className="cartservicename">
                                      {item.purchasename}
                                    </h5>
                                    <h5 className="cartservicename">
                                      {item.servicefullname}  {this.Checkpackage(item.package,item.serviceName)} {this.Checkplan(item.plan)} 
                                    </h5>
                                    <p className="cartserviceprice">
                                      &#x20b9; {item.price}
                                    </p>
                                  </div>
                                </div>
                                <div className="cartdel">
                                  <button
                                    onClick={() => this.handledelete(index)}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Iconmaterialdelete.svg"
                                      }
                                    />{" "}
                                    Delete
                                  </button>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <div className="white_div checkouth expandwhite_div chres">
                          {this.state.cartdata.length != 0 ? (
                            <>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 className="my-warn-text unlock-text1 cart_bill_n_text">
                                    Billing Name
                                  </h4>
                                  <input
                                    className=" cart_billingname billingcommon"
                                    placeholder="Enter billing name"
                                    maxLength="120"
                                    value={this.state.billing_name}
                                    onChange={this.handlecartname}
                                  />

                                  <div id="hidecont_email">
                                    {hide ? (
                                      <>
                                        <h4 className="my-warn-text unlock-text1 cart_bill_n_text">
                                          Billing Email
                                        </h4>
                                        <input
                                          className=" cart_billingname billingcommon"
                                          placeholder="Enter billing email"
                                          maxLength="100"
                                          value={this.state.billingemail}
                                          onChange={this.handleemail}
                                        />

                                        <h4 className="my-warn-text unlock-text1 cart_bill_n_text">
                                          Billing Contact
                                        </h4>
                                        <input
                                          className=" cart_billingname billingcommon"
                                          placeholder="Enter billing contact"
                                          maxLength="10"
                                          value={this.state.billingcontact}
                                          onChange={this.handlecontact}
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <h4 className="my-warn-text unlock-text1 mt-3 cart_bill_c_text">
                                    Billing City/District
                                  </h4>
                                  <Select2
                                    onChange={this.citydistrictselecthandle}
                                    value={this.state.sub_name_city}
                                    data={[
                                      "Adilabad",
                                      "Agar Malwa",
                                      "Agra",
                                      "Ahmedabad",
                                      "Ahmednagar",
                                      "Aizawl",
                                      "Ajmer",
                                      "Akola",
                                      "Alappuzha",
                                      "Aligarh",
                                      "Alipurduar",
                                      "Alirajpur",
                                      "Allahabad",
                                      "Almora",
                                      "Alwar",
                                      "Ambala",
                                      "Ambedkar Nagar",
                                      "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                                      "Amravati",
                                      "Amreli",
                                      "Amritsar",
                                      "Amroha",
                                      "Anand",
                                      "Anantapur",
                                      "Anantnag",
                                      "Angul",
                                      "Anjaw",
                                      "Anuppur",
                                      "Araria",
                                      "Aravalli",
                                      "Ariyalur",
                                      "Arwal",
                                      "Ashoknagar",
                                      "Auraiya",
                                      "Aurangabad (BH)",
                                      "Aurangabad (MH)",
                                      "Azamgarh",
                                      "Bagalkot",
                                      "Bageshwar",
                                      "Baghpat",
                                      "Bahraich",
                                      "Baksa",
                                      "Balaghat",
                                      "Balangir",
                                      "Balasore",
                                      "Ballari (Bellary)",
                                      "Ballia",
                                      "Balod",
                                      "Baloda Bazar",
                                      "Balrampur (CG)",
                                      "Balrampur (UP)",
                                      "Banaskantha (Palanpur)",
                                      "Banda",
                                      "Bandipore",
                                      "Banka",
                                      "Bankura",
                                      "Banswara",
                                      "Barabanki",
                                      "Baramulla",
                                      "Baran",
                                      "Bareilly",
                                      "Bargarh",
                                      "Barmer",
                                      "Barnala",
                                      "Barpeta",
                                      "Barwani",
                                      "Bastar",
                                      "Basti",
                                      "Bathinda",
                                      "Beed",
                                      "Begusarai",
                                      "Belagavi (Belgaum)",
                                      "Bemetara",
                                      "Bengaluru (Bangalore) Rural",
                                      "Bengaluru (Bangalore) Urban",
                                      "Betul",
                                      "Bhadohi",
                                      "Bhadradri Kothagudem",
                                      "Bhadrak",
                                      "Bhagalpur",
                                      "Bhandara",
                                      "Bharatpur",
                                      "Bharuch",
                                      "Bhavnagar",
                                      "Bhilwara",
                                      "Bhind",
                                      "Bhiwani",
                                      "Bhojpur",
                                      "Bhopal",
                                      "Bidar",
                                      "Bijapur",
                                      "Bijnor",
                                      "Bikaner",
                                      "Bilaspur (CG)",
                                      "Bilaspur (HP)",
                                      "Birbhum",
                                      "Bishnupur",
                                      "Biswanath",
                                      "Bokaro",
                                      "Bongaigaon",
                                      "Botad",
                                      "Boudh",
                                      "Budaun",
                                      "Budgam",
                                      "Bulandshahr",
                                      "Buldhana",
                                      "Bundi",
                                      "Burhanpur",
                                      "Buxar",
                                      "Cachar",
                                      "Central Delhi",
                                      "Chamarajanagar",
                                      "Chamba",
                                      "Chamoli",
                                      "Champawat",
                                      "Champhai",
                                      "Chandauli",
                                      "Chandel",
                                      "Chandigarh",
                                      "Chandrapur",
                                      "Changlang",
                                      "Charaideo",
                                      "Charkhi Dadri",
                                      "Chatra",
                                      "Chengalpattu",
                                      "Chennai",
                                      "Chhatarpur",
                                      "Chhindwara",
                                      "Chhota Udepur",
                                      "Chikballapur",
                                      "Chikkamagaluru (Chikmagalur)",
                                      "Chirang",
                                      "Chitradurga",
                                      "Chitrakoot",
                                      "Chittoor",
                                      "Chittorgarh",
                                      "Churachandpur",
                                      "Churu",
                                      "Coimbatore",
                                      "Cooch Behar",
                                      "Cuddalore",
                                      "Cuttack",
                                      "Dadra & Nagar Haveli",
                                      "Dahod",
                                      "Dakshin Dinajpur (South Dinajpur)",
                                      "Dakshina Kannada",
                                      "Daman",
                                      "Damoh",
                                      "Dangs (Ahwa)",
                                      "Dantewada (South Bastar)",
                                      "Darbhanga",
                                      "Darjeeling",
                                      "Darrang",
                                      "Datia",
                                      "Dausa",
                                      "Davangere",
                                      "Dehradun",
                                      "Deogarh",
                                      "Deoghar",
                                      "Deoria",
                                      "Devbhoomi Dwarka",
                                      "Dewas",
                                      "Dhalai",
                                      "Dhamtari",
                                      "Dhanbad",
                                      "Dhar",
                                      "Dharmapuri",
                                      "Dharwad",
                                      "Dhemaji",
                                      "Dhenkanal",
                                      "Dholpur",
                                      "Dhubri",
                                      "Dhule",
                                      "Dibang Valley",
                                      "Dibrugarh",
                                      "Dima Hasao (North Cachar Hills)",
                                      "Dimapur",
                                      "Dindigul",
                                      "Dindori",
                                      "Diu",
                                      "Doda",
                                      "Dumka",
                                      "Dungarpur",
                                      "Durg",
                                      "East Champaran (Motihari)",
                                      "East Delhi",
                                      "East Garo Hills",
                                      "East Godavari",
                                      "East Jaintia Hills",
                                      "East Kameng",
                                      "East Khasi Hills",
                                      "East Siang",
                                      "East Sikkim",
                                      "East Singhbhum",
                                      "Ernakulam",
                                      "Erode",
                                      "Etah",
                                      "Etawah",
                                      "Faizabad",
                                      "Faridabad",
                                      "Faridkot",
                                      "Farrukhabad",
                                      "Fatehabad",
                                      "Fatehgarh Sahib",
                                      "Fatehpur",
                                      "Fazilka",
                                      "Ferozepur",
                                      "Firozabad",
                                      "Gadag",
                                      "Gadchiroli",
                                      "Gajapati",
                                      "Ganderbal",
                                      "Gandhinagar",
                                      "Ganjam",
                                      "Garhwa",
                                      "Gariyaband",
                                      "Gautam Buddha Nagar",
                                      "Gaya",
                                      "Ghaziabad",
                                      "Ghazipur",
                                      "Gir Somnath",
                                      "Giridih",
                                      "Goalpara",
                                      "Godda",
                                      "Golaghat",
                                      "Gomati",
                                      "Gonda",
                                      "Gondia",
                                      "Gopalganj",
                                      "Gorakhpur",
                                      "Gumla",
                                      "Guna",
                                      "Guntur",
                                      "Gurdaspur",
                                      "Gurugram (Gurgaon)",
                                      "Gwalior",
                                      "Hailakandi",
                                      "Hamirpur (HP)",
                                      "Hamirpur (UP)",
                                      "Hanumangarh",
                                      "Hapur (Panchsheel Nagar)",
                                      "Harda",
                                      "Hardoi",
                                      "Haridwar",
                                      "Hassan",
                                      "Hathras",
                                      "Haveri",
                                      "Hazaribag",
                                      "Hingoli",
                                      "Hisar",
                                      "Hojai",
                                      "Hooghly",
                                      "Hoshangabad",
                                      "Hoshiarpur",
                                      "Howrah",
                                      "Hyderabad",
                                      "Idukki",
                                      "Imphal East",
                                      "Imphal West",
                                      "Indore",
                                      "Jabalpur",
                                      "Jagatsinghapur",
                                      "Jagtial",
                                      "Jaipur",
                                      "Jaisalmer",
                                      "Jajpur",
                                      "Jalandhar",
                                      "Jalaun",
                                      "Jalgaon",
                                      "Jalna",
                                      "Jalore",
                                      "Jalpaiguri",
                                      "Jammu",
                                      "Jamnagar",
                                      "Jamtara",
                                      "Jamui",
                                      "Jangaon",
                                      "Janjgir-Champa",
                                      "Jashpur",
                                      "Jaunpur",
                                      "Jayashankar Bhoopalpally",
                                      "Jehanabad",
                                      "Jhabua",
                                      "Jhajjar",
                                      "Jhalawar",
                                      "Jhansi",
                                      "Jhargram",
                                      "Jharsuguda",
                                      "Jhunjhunu",
                                      "Jind",
                                      "Jiribam",
                                      "Jodhpur",
                                      "Jogulamba Gadwal",
                                      "Jorhat",
                                      "Junagadh",
                                      "Kabirdham (Kawardha)",
                                      "Kachchh",
                                      "Kaimur (Bhabua)",
                                      "Kaithal",
                                      "Kakching",
                                      "Kalaburagi (Gulbarga)",
                                      "Kalahandi",
                                      "Kalimpong",
                                      "Kallakurichi",
                                      "Kamareddy",
                                      "Kamjong",
                                      "Kamle",
                                      "Kamrup",
                                      "Kamrup Metropolitan",
                                      "Kanchipuram",
                                      "Kandhamal",
                                      "Kangpokpi",
                                      "Kangra",
                                      "Kanker (North Bastar)",
                                      "Kannauj",
                                      "Kannur",
                                      "Kanpur Dehat",
                                      "Kanpur Nagar",
                                      "Kanshiram Nagar (Kasganj)",
                                      "Kanyakumari",
                                      "Kapurthala",
                                      "Karaikal",
                                      "Karauli",
                                      "Karbi Anglong",
                                      "Kargil",
                                      "Karimganj",
                                      "Karimnagar",
                                      "Karnal",
                                      "Karur",
                                      "Kasaragod",
                                      "Kathua",
                                      "Katihar",
                                      "Katni",
                                      "Kaushambi",
                                      "Kendrapara",
                                      "Kendujhar (Keonjhar)",
                                      "Khagaria",
                                      "Khammam",
                                      "Khandwa",
                                      "Khargone",
                                      "Kheda (Nadiad)",
                                      "Khordha",
                                      "Khowai",
                                      "Khunti",
                                      "Kinnaur",
                                      "Kiphire",
                                      "Kishanganj",
                                      "Kishtwar",
                                      "Kodagu",
                                      "Koderma",
                                      "Kohima",
                                      "Kokrajhar",
                                      "Kolar",
                                      "Kolasib",
                                      "Kolhapur",
                                      "Kolkata",
                                      "Kollam",
                                      "Komaram Bheem Asifabad",
                                      "Kondagaon",
                                      "Koppal",
                                      "Koraput",
                                      "Korba",
                                      "Korea (Koriya)",
                                      "Kota",
                                      "Kottayam",
                                      "Kozhikode",
                                      "Kra Daadi",
                                      "Krishna",
                                      "Krishnagiri",
                                      "Kulgam",
                                      "Kullu",
                                      "Kupwara",
                                      "Kurnool",
                                      "Kurukshetra",
                                      "Kurung Kumey",
                                      "Kushinagar (Padrauna)",
                                      "Lahaul & Spiti",
                                      "Lakhimpur",
                                      "Lakhimpur - Kheri",
                                      "Lakhisarai",
                                      "Lakshadweep",
                                      "Lalitpur",
                                      "Latehar",
                                      "Latur",
                                      "Lawngtlai",
                                      "Leh",
                                      "Lepa Rada",
                                      "Lohardaga",
                                      "Lohit",
                                      "Longding",
                                      "Longleng",
                                      "Lower Dibang Valley",
                                      "Lower Siang",
                                      "Lower Subansiri",
                                      "Lucknow",
                                      "Ludhiana",
                                      "Lunglei",
                                      "Madhepura",
                                      "Madhubani",
                                      "Madurai",
                                      "Mahabubabad",
                                      "Mahabubnagar",
                                      "Maharajganj",
                                      "Mahasamund",
                                      "Mahe",
                                      "Mahendragarh",
                                      "Mahisagar",
                                      "Mahoba",
                                      "Mainpuri",
                                      "Majuli",
                                      "Malappuram",
                                      "Malda",
                                      "Malkangiri",
                                      "Mamit",
                                      "Mancherial",
                                      "Mandi",
                                      "Mandla",
                                      "Mandsaur",
                                      "Mandya",
                                      "Mansa",
                                      "Mathura",
                                      "Mau",
                                      "Mayurbhanj",
                                      "Medak",
                                      "Medchal",
                                      "Meerut",
                                      "Mehsana",
                                      "Mirzapur",
                                      "Moga",
                                      "Mokokchung",
                                      "Mon",
                                      "Moradabad",
                                      "Morbi",
                                      "Morena",
                                      "Morigaon",
                                      "Muktsar",
                                      "Mumbai City",
                                      "Mumbai Suburban",
                                      "Mungeli",
                                      "Munger (Monghyr)",
                                      "Murshidabad",
                                      "Muzaffarnagar",
                                      "Muzaffarpur",
                                      "Mysuru (Mysore)",
                                      "Nabarangpur",
                                      "Nadia",
                                      "Nagaon",
                                      "Nagapattinam",
                                      "Nagarkurnool",
                                      "Nagaur",
                                      "Nagpur",
                                      "Nainital",
                                      "Nalanda",
                                      "Nalbari",
                                      "Nalgonda",
                                      "Namakkal",
                                      "Namsai",
                                      "Nanded",
                                      "Nandurbar",
                                      "Narayanpur",
                                      "Narmada (Rajpipla)",
                                      "Narsinghpur",
                                      "Nashik",
                                      "Navsari",
                                      "Nawada",
                                      "Nawanshahr (Shahid Bhagat Singh Nagar)",
                                      "Nayagarh",
                                      "Neemuch",
                                      "New Delhi",
                                      "Nicobar",
                                      "Nilgiris",
                                      "Nirmal",
                                      "Nizamabad",
                                      "Noney",
                                      "North 24 Parganas",
                                      "North Delhi",
                                      "North East Delhi",
                                      "North Garo Hills",
                                      "North Goa",
                                      "North Sikkim",
                                      "North Tripura",
                                      "North West Delhi",
                                      "North and Middle Andaman",
                                      "Nuapada",
                                      "Nuh",
                                      "Osmanabad",
                                      "Pakke Kessang",
                                      "Pakur",
                                      "Palakkad",
                                      "Palamu",
                                      "Palghar",
                                      "Pali",
                                      "Palwal",
                                      "Panchkula",
                                      "Panchmahal (Godhra)",
                                      "Panipat",
                                      "Panna",
                                      "Papum Pare",
                                      "Parbhani",
                                      "Paschim (West) Burdwan (Bardhaman)",
                                      "Paschim Medinipur (West Medinipur)",
                                      "Patan",
                                      "Pathanamthitta",
                                      "Pathankot",
                                      "Patiala",
                                      "Patna",
                                      "Pauri Garhwal",
                                      "Peddapalli",
                                      "Perambalur",
                                      "Peren",
                                      "Phek",
                                      "Pherzawl",
                                      "Pilibhit",
                                      "Pithoragarh",
                                      "Poonch",
                                      "Porbandar",
                                      "Prakasam",
                                      "Pratapgarh (RJ)",
                                      "Pratapgarh (UP)",
                                      "Puducherry",
                                      "Pudukkottai",
                                      "Pulwama",
                                      "Pune",
                                      "Purba Burdwan (Bardhaman)",
                                      "Purba Medinipur (East Medinipur)",
                                      "Puri",
                                      "Purnia (Purnea)",
                                      "Purulia",
                                      "RaeBareli",
                                      "Raichur",
                                      "Raigad",
                                      "Raigarh",
                                      "Raipur",
                                      "Raisen",
                                      "Rajanna Sircilla",
                                      "Rajgarh",
                                      "Rajkot",
                                      "Rajnandgaon",
                                      "Rajouri",
                                      "Rajsamand",
                                      "Ramanagara",
                                      "Ramanathapuram",
                                      "Ramban",
                                      "Ramgarh",
                                      "Rampur",
                                      "Ranchi",
                                      "Rangareddy",
                                      "Ranipet",
                                      "Ratlam",
                                      "Ratnagiri",
                                      "Rayagada",
                                      "Reasi",
                                      "Rewa",
                                      "Rewari",
                                      "Ri Bhoi",
                                      "Rohtak",
                                      "Rohtas",
                                      "Rudraprayag",
                                      "Rupnagar",
                                      "Sabarkantha (Himmatnagar)",
                                      "Sagar",
                                      "Saharanpur",
                                      "Saharsa",
                                      "Sahibganj",
                                      "Sahibzada Ajit Singh Nagar (Mohali)",
                                      "Saiha",
                                      "Salem",
                                      "Samastipur",
                                      "Samba",
                                      "Sambalpur",
                                      "Sambhal (Bhim Nagar)",
                                      "Sangareddy",
                                      "Sangli",
                                      "Sangrur",
                                      "Sant Kabir Nagar",
                                      "Saran",
                                      "Satara",
                                      "Satna",
                                      "Sawai Madhopur",
                                      "Sehore",
                                      "Senapati",
                                      "Seoni",
                                      "Sepahijala",
                                      "Seraikela-Kharsawan",
                                      "Serchhip",
                                      "Shahdara",
                                      "Shahdol",
                                      "Shahjahanpur",
                                      "Shajapur",
                                      "Shamali (Prabuddh Nagar)",
                                      "Sheikhpura",
                                      "Sheohar",
                                      "Sheopur",
                                      "Shi Yomi",
                                      "Shimla",
                                      "Shivamogga (Shimoga)",
                                      "Shivpuri",
                                      "Shopian",
                                      "Shravasti",
                                      "Siang",
                                      "Siddharth Nagar",
                                      "Siddipet",
                                      "Sidhi",
                                      "Sikar",
                                      "Simdega",
                                      "Sindhudurg",
                                      "Singrauli",
                                      "Sirmaur (Sirmour)",
                                      "Sirohi",
                                      "Sirsa",
                                      "Sitamarhi",
                                      "Sitapur",
                                      "Sivaganga",
                                      "Sivasagar",
                                      "Siwan",
                                      "Solan",
                                      "Solapur",
                                      "Sonbhadra",
                                      "Sonepur",
                                      "Sonipat",
                                      "Sonitpur",
                                      "South 24 Parganas",
                                      "South Andaman",
                                      "South Delhi",
                                      "South East Delhi",
                                      "South Garo Hills",
                                      "South Goa",
                                      "South Salamara-Mankachar",
                                      "South Sikkim",
                                      "South Tripura",
                                      "South West Delhi",
                                      "South West Garo Hills",
                                      "South West Khasi Hills",
                                      "Sri Ganganagar",
                                      "Sri Potti Sriramulu Nellore ",
                                      "Srikakulam",
                                      "Srinagar",
                                      "Sukma",
                                      "Sultanpur",
                                      "Sundargarh",
                                      "Supaul",
                                      "Surajpur",
                                      "Surat",
                                      "Surendranagar",
                                      "Surguja",
                                      "Suryapet",
                                      "Tamenglong",
                                      "Tapi (Vyara)",
                                      "Tarn Taran",
                                      "Tawang",
                                      "Tehri Garhwal",
                                      "Tengnoupal",
                                      "Tenkasi",
                                      "Thane",
                                      "Thanjavur",
                                      "Theni",
                                      "Thiruvananthapuram",
                                      "Thoothukudi (Tuticorin)",
                                      "Thoubal",
                                      "Thrissur",
                                      "Tikamgarh",
                                      "Tinsukia",
                                      "Tirap",
                                      "Tiruchirappalli",
                                      "Tirunelveli",
                                      "Tirupathur",
                                      "Tiruppur",
                                      "Tiruvallur",
                                      "Tiruvannamalai",
                                      "Tiruvarur",
                                      "Tonk",
                                      "Tuensang",
                                      "Tumakuru (Tumkur)",
                                      "Udaipur",
                                      "Udalguri",
                                      "Udham Singh Nagar",
                                      "Udhampur",
                                      "Udupi",
                                      "Ujjain",
                                      "Ukhrul",
                                      "Umaria",
                                      "Una",
                                      "Unakoti",
                                      "Unnao",
                                      "Upper Siang",
                                      "Upper Subansiri",
                                      "Uttar Dinajpur (North Dinajpur)",
                                      "Uttara Kannada (Karwar)",
                                      "Uttarkashi",
                                      "Vadodara",
                                      "Vaishali",
                                      "Valsad",
                                      "Varanasi",
                                      "Vellore",
                                      "Vidisha",
                                      "Vijayapura (Bijapur)",
                                      "Vikarabad",
                                      "Viluppuram",
                                      "Virudhunagar",
                                      "Visakhapatnam",
                                      "Vizianagaram",
                                      "Wanaparthy",
                                      "Warangal (Rural)",
                                      "Warangal (Urban)",
                                      "Wardha",
                                      "Washim",
                                      "Wayanad",
                                      "West Champaran",
                                      "West Delhi",
                                      "West Garo Hills",
                                      "West Godavari",
                                      "West Jaintia Hills",
                                      "West Kameng",
                                      "West Karbi Anglong",
                                      "West Khasi Hills",
                                      "West Siang",
                                      "West Sikkim",
                                      "West Singhbhum",
                                      "West Tripura",
                                      "Wokha",
                                      "YSR District, Kadapa (Cuddapah)",
                                      "Yadadri Bhuvanagiri",
                                      "Yadgir",
                                      "Yamunanagar",
                                      "Yanam",
                                      "Yavatmal",
                                      "Zunheboto",
                                    ]}
                                    options={{
                                      placeholder:
                                        "Select your city/district...",
                                    }}
                                    disabled={this.state.citydisabled ? 'disabled' : null}
                                  />
                                </div>
                              </div>
                              <div className="white_boxscrolldiv checkouth">
                                <div className="checkoutmainpadding">
                                  <div className="">
                                    <div className=" ">
                                      <div className="detailsdivision mt-5">
                                        <div className="detailsdivisionpadding">
                                        <p className="Detailsheading">Communication Mode:</p>

                                        <select className="communic" value={this.state.communicationMode} onChange={(e) => this.setState({
                                          communicationMode : e.target.value
                                        })}>
                                          <option value="">Select Communication</option>
                                          <option value="With Me">With Me</option>
                                          <option value="Customer Directly">Customer Directly</option>
                                        </select>
                                        <p className="" id="choosecommunication">
                                    <img
                                      className="mr-2 codemessageimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon_feather-alert-circle.svg"
                                      }
                                    />
                                    &nbsp; Select Communication Mode
                                  </p>

                                          <p className="Detailsheading">
                                            Details:
                                          </p>
                                          <div className="detailsdivisioninsidepadding">
                                            <div className="row">
                                              {this.state.sendotpsucess ==
                                              true ? (
                                                <>
                                                  <div className="col-5">
                                                    <p className="serviceregistername2">
                                                      Mobile OTP:
                                                    </p>
                                                  </div>
                                                  <div className="col-7">
                                                    <input
                                                      type="tel"
                                                      className="codeinput"
                                                      placeholder="OTP"
                                                      maxLength="6"
                                                      value={
                                                        this.state.contactOtp
                                                      }
                                                      onChange={(e) =>
                                                        this.setState({
                                                          contactOtp:
                                                            e.target.value,
                                                        })
                                                      }
                                                    />
                                                    {this.state.mtime != 0 ? (
                                                      <button
                                                        className="applybtn op"
                                                        disabled
                                                      >
                                                        Resend (
                                                        {this.state.mtime} s)
                                                      </button>
                                                    ) : (
                                                      <>
                                                        <button
                                                          id="mobileresendcheck"
                                                          className="applybtn "
                                                          onClick={
                                                            this
                                                              .handlemobileresend
                                                          }
                                                        >
                                                          Resend
                                                        </button>
                                                        <img
                                                          id="mobileresend-loadercheck"
                                                          src="/img/loader.gif"
                                                          height="50px"
                                                          alt=""
                                                        />{" "}
                                                      </>
                                                    )}

                                                    <p
                                                      className=""
                                                      id="mobileotpsentcheck"
                                                    >
                                                      OTP sent successfully{" "}
                                                    </p>
                                                    <p
                                                      className=""
                                                      id="invalidmobileotpcheck"
                                                    >
                                                      <img
                                                        className="mr-2 codemessageimg"
                                                        src={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          "/img/Icon_feather-alert-circle.svg"
                                                        }
                                                      />
                                                      &nbsp; Invalid OTP
                                                    </p>
                                                  </div>
                                                  <div className="col-5">
                                                    <p className="serviceregistername2">
                                                      Email OTP:
                                                    </p>
                                                  </div>
                                                  <div className="col-7">
                                                    <input
                                                      type="tel"
                                                      className="codeinput"
                                                      placeholder="OTP"
                                                      maxLength="6"
                                                      id="emailotpinput"
                                                      value={
                                                        this.state.emailOtp
                                                      }
                                                      onChange={(e) =>
                                                        this.setState({
                                                          emailOtp:
                                                            e.target.value,
                                                        })
                                                      }
                                                    />
                                                    {this.state.etime != 0 ? (
                                                      <button
                                                        className="applybtn op"
                                                        disabled
                                                      >
                                                        Resend (
                                                        {this.state.etime} s)
                                                      </button>
                                                    ) : (
                                                      <>
                                                        <button
                                                          className="applybtn"
                                                          id="emailresendcheck"
                                                          onClick={
                                                            this
                                                              .handleemailresend
                                                          }
                                                        >
                                                          Resend
                                                        </button>
                                                        <img
                                                          id="emailresend-loadercheck"
                                                          src="/img/loader.gif"
                                                          height="50px"
                                                          alt=""
                                                        />{" "}
                                                        <p
                                                          className=""
                                                          id="invalidemailotpcheck2"
                                                          onClick={
                                                            this.handleemailotp
                                                          }
                                                        >
                                                          Email OTP Not Received
                                                        </p>
                                                        <p
                                                          className=""
                                                          id="emailotpsentcheck2"
                                                          onClick={
                                                            this.handlemobileotp
                                                          }
                                                        >
                                                          OTP Received
                                                        </p>
                                                      </>
                                                    )}

                                                    <p
                                                      className=""
                                                      id="emailotpsentcheck"
                                                    >
                                                      OTP sent successfully{" "}
                                                    </p>
                                                    <p
                                                      className=""
                                                      id="invalidemailotpcheck"
                                                    >
                                                      <img
                                                        src={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          "/img/Icon_feather-alert-circle.svg"
                                                        }
                                                        className="codemessageimg"
                                                      />{" "}
                                                      &nbsp; Invalid OTP
                                                    </p>
                                                  </div>
                                                </>
                                              ) : null}

                                              <div className="col-5">
                                                <p className="serviceregistername2">
                                                  Fees:
                                                </p>
                                              </div>
                                              <div className="col-7">
                                                <p className="serviceregistername2">
                                                  <span className="copunorginalprice">
                                                    ₹
                                                    {this.showservicetotal(
                                                      this.state.serviceprice
                                                    )}
                                                  </span>{" "}
                                                  <span className="copunappliedtext">
                                                    ₹
                                                    {this.showservicetotal(
                                                      this.state
                                                        .servicepriceaftercoupun
                                                    )}
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="col-5">
                                                <p className="serviceregistername2">
                                                  GST(18%):
                                                </p>
                                              </div>
                                              <div className="col-7 ">
                                                <p className="serviceregistername2">
                                                  ₹
                                                  {this.showservicetotal(
                                                    this.state.servicegst
                                                  )}
                                                </p>
                                              </div>

                                              {
                                                this.state.Servicesortcode ?

                                                  
                                                  <div className="col-5 govtfeesdiv">
                                                  <p className="serviceregistername2">
                                                  Non Taxable Amount:
                                                  </p>
                                                </div>

                                                  :

                                                  <div className="col-5 govtfeesdiv">
                                                    <p className="serviceregistername2">
                                                      Govt. fees:
                                                    </p>
                                                  </div>

                                              }
                                              
                                              <div className="col-7 govtfeesdiv">
                                                <p className="serviceregistername2">
                                                  ₹{this.state.servicegovtfees}
                                                </p>
                                              </div>

                                              <div
                                                id="accordion"
                                                className="col-12 gstAccordion2"
                                              >
                                                <div className="card gstnoCard2 bg-transparent col-12 w-100  p-0">
                                                  <div
                                                    className="card-header pb-0 gstnoHeader2 pl-0"
                                                    id="headingOne"
                                                  >
                                                    <div className="col-12 p-0">
                                                      <p
                                                        className="serviceregistername2 addGst2"
                                                        data-toggle="collapse"
                                                        data-target="#collapseOne"
                                                        aria-expanded="false"
                                                        aria-controls="collapseOne"
                                                      >
                                                        Add GST No. +
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div
                                                    id="collapseOne"
                                                    className="collapse"
                                                    aria-labelledby="headingOne"
                                                    data-parent="#accordion"
                                                  >
                                                    <div className="card-body row pl-0">
                                                      <div
                                                        className="col-5  pr-0"
                                                        id="gstnumber"
                                                      >
                                                        <p className="serviceregistername2">
                                                          GST No :
                                                        </p>
                                                      </div>
                                                      <div
                                                        className="col-lg-7 col-12"
                                                        id="gstnoInput"
                                                      >
                                                        <input
                                                          type="text"
                                                          className="codeinput2 gstno2"
                                                          placeholder="GST no."
                                                          maxLength="15"
                                                          value={
                                                            this.state.gstno
                                                          }
                                                          onChange={(e) =>
                                                            this.setState({
                                                              gstno:
                                                                e.target.value.toUpperCase(),
                                                            })
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-5">
                                                <p className="serviceregistername2">
                                                  Apply promo code:
                                                </p>
                                              </div>

                                              <div className="col-7">
                                                <input
                                                  type="text"
                                                  className="codeinput applycoupen"
                                                  placeholder="Code"
                                                  value={this.state.coupun}
                                                  onChange={
                                                    this.handlecoupunChange
                                                  }
                                                />

                                                <button
                                                  className="applybtn"
                                                  id="applybtnmain"
                                                  onClick={this.applycoupun}
                                                >
                                                  Apply
                                                </button>
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/img/loader.gif"
                                                  }
                                                  className="couponapplyloader"
                                                />
                                                <button
                                                  className="delbtn"
                                                  onClick={this.delcoupun}
                                                >
                                                  <img
                                                    className="delbtnimg"
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/img/Path_20158.svg"
                                                    }
                                                  />
                                                </button>
                                                <p className="success">
                                                  Promocode applied for{" "}
                                                  {this.state.coupunpercentage}
                                                  {this.state.flat == "P" ? (
                                                    <span>&nbsp;%</span>
                                                  ) : (
                                                    <span>&nbsp;₹</span>
                                                  )}
                                                </p>
                                                <p className="failure">
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/img/Icon_feather-alert-circle.svg"
                                                    }
                                                    className="codemessageimg"
                                                  />
                                                  Invalid promocode
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="checkouthrpadding">
                                          <hr className="checkoutdivhr" />
                                        </div>
                                        <div className="grandtotalpadding">
                                          <div className="row">
                                            <div className="col-5">
                                              <p className="totalheading">
                                                Grand total:
                                              </p>
                                            </div>
                                            <div className="col-7">
                                              <p className="totalheading">
                                                ₹
                                                {this.showservicetotal(
                                                  this.state.servicetotal
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="paynowpadding">
                                          <center>
                                            {" "}
                                            {this.state.sendotpsucess2 ==
                                            true ? (
                                              <button
                                                className="paynowbtn sendcartotpbtn"
                                                onClick={this.SendOTP}
                                              >
                                                Send Otp{" "}
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/img/longarrow.svg"
                                                  }
                                                  alt=""
                                                />
                                              </button>
                                            ) : (
                                              <button
                                                className="paynowbtn"
                                                onClick={this.createcartorder}
                                              >
                                                PAY NOW{" "}
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/img/longarrow.svg"
                                                  }
                                                  alt=""
                                                />
                                              </button>
                                            )}
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/img/loader.gif"
                                              }
                                              className="paymentloader"
                                            />
                                            <p className="govfeecheckout">
                                              *Government Fees Extra as per
                                              applicable.
                                            </p>
                                          </center>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ScriptTag src="https://checkout.razorpay.com/v1/checkout.js" />
            <Footer />
            <div
              className="modal fade"
              id="paymentLinkModal"
              tabIndex="-1"
              role="dialog"
              data-backdrop="static"
              data-keyboard="false"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered "
                role="document"
              >
                <div className="modal-content back-modal " id="unlockotp">
                  <div className="w-100 d-flex ml-auto ">
                    <button
                      type="button"
                      className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                      data-dismiss="modal"
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                        width="17px"
                      />
                    </button>
                  </div>
                  <div className="modal-body border-0 text-center pb-0">
                    <h4 className="my-warn-text unlock-text text-black">
                      Payment link Generated Successfully
                    </h4>
                    <img
                      className="p-2"
                      src={process.env.PUBLIC_URL + "/img/Group 5485.svg"}
                      width="100px"
                    />
                  </div>
                  <div className="modal-footer border-0 justify-content-center mb-4">
                    <h4>
                      <b>Payment link:</b>
                    </h4>
                    <br />
                    <b>{this.state.paymentLink}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Cart;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { Redirect } from "react-router";
import Sidebar from "./Sidebar";
import AllServices from "../AllServices.json"

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
var pendingpaymentGSTR = [];
var pendingpaymentGSTRF = [];
var pendingpaymentITRF = [];
var pendingpaymentTR = [];
var pendingpaymentOA = [];
var pendingpaymentGUMASTA = [];
var pendingpaymentTradeLicence = [];
var pendingpaymentFoodLicence = [];
var completepaymentGSTR = [];
var completepaymentGSTRF = [];
var completepaymentITRF = [];
var completepaymentTR = [];
var completepaymentOA = [];
var completepaymentGUMASTA = [];
var completepaymentTradeLicence = [];
var completepaymentFoodLicence = [];
let Decagentid;
let Decuserid;
let Decjwttoken;
let temp = "general";
class PaymentInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingpaymentstate: "Latest",
      completepaymentstate: "Latest",
      completepaymentmainarray: [],
      pendingpaymentmainarray: [],
      userid: "",
      email: "",
      contact: "",
      username: "",
    };
  }

  backbutton = () => {
    if (
      this.state.userid != "" &&
      this.state.email != "" &&
      this.state.contact != "" &&
      this.state.username != ""
    ) {
      this.props.history.push({
        pathname: `/myuser/${Decuserid}`,
        search: "?query=abc",
        state: {
          userid: this.state.userid,
          email: this.state.email,
          contact: this.state.contact,
          name: this.state.username,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/paymentinvoices",
      });
    }
  };

  pendingpaymentGSTRfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "GSTR",
      },
      () => {
        if (pendingpaymentGSTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };

  pendingpaymentGSTRFfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "GSTRF",
      },
      () => {
        if (pendingpaymentGSTRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentITRFfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "ITRF",
      },
      () => {
        if (pendingpaymentITRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentTRfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "TR",
      },
      () => {
        if (pendingpaymentTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };

  pendingpaymentOAfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "OA",
      },
      () => {
        if (pendingpaymentOA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };

  pendingpaymentGUMASTAfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "GUMASTA",
      },
      () => {
        if (pendingpaymentGUMASTA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };

  pendingpaymentTradeLicencefilter = () => {
    this.setState(
      {
        pendingpaymentstate: "TL",
      },
      () => {
        if (pendingpaymentTradeLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };

  pendingpaymentFoodLicencefilter = () => {
    this.setState(
      {
        pendingpaymentstate: "FL",
      },
      () => {
        if (pendingpaymentFoodLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };

  completepaymentGSTRfilter = () => {
    this.setState(
      {
        completepaymentstate: "GSTR",
      },
      () => {
        if (completepaymentGSTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentGSTRFfilter = () => {
    this.setState(
      {
        completepaymentstate: "GSTRF",
      },
      () => {
        if (completepaymentGSTRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentITRFfilter = () => {
    this.setState(
      {
        completepaymentstate: "ITRF",
      },
      () => {
        if (completepaymentITRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentTRfilter = () => {
    this.setState(
      {
        completepaymentstate: "TR",
      },
      () => {
        if (completepaymentTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentOAfilter = () => {
    this.setState(
      {
        completepaymentstate: "OA",
      },
      () => {
        if (completepaymentOA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentGUMASTAfilter = () => {
    this.setState(
      {
        completepaymentstate: "GUMASTA",
      },
      () => {
        if (completepaymentGUMASTA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentTradeLicencefilter = () => {
    this.setState(
      {
        completepaymentstate: "TL",
      },
      () => {
        if (completepaymentTradeLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentFoodLicencefilter = () => {
    this.setState(
      {
        completepaymentstate: "FL",
      },
      () => {
        if (completepaymentFoodLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    pendingpaymentGSTR = [];
    pendingpaymentGSTRF = [];
    pendingpaymentITRF = [];
    pendingpaymentTR = [];
    pendingpaymentOA = [];
    pendingpaymentGUMASTA = [];
    pendingpaymentTradeLicence = [];
    pendingpaymentFoodLicence = [];
    completepaymentGSTR = [];
    completepaymentGSTRF = [];
    completepaymentITRF = [];
    completepaymentTR = [];
    completepaymentOA = [];
    completepaymentGUMASTA = [];
    completepaymentTradeLicence = [];
    completepaymentFoodLicence = [];
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let paramsUserId = this.props.match.params.id2;
    window.addEventListener("click", function (event) {
      if (event.target.className != "search-box") {
        $(".search-box").removeClass("search-focus");
      }
    });
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
    } else {
      temp = "general"
    }

    // ----------------------------temporary -----------------------------------------

    if (paramsUserId != Decuserid) {
      this.props.history.push("/dashboard");
    } else {
      this.handlepaymentinvoiceapi();
    }



    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)
  }

  handleSearchBox1 = (e) => {
    e.stopPropagation();
    $("#search-box-1").addClass("search-focus");
  };

  handleSearchBox2 = (e) => {
    e.stopPropagation();
    $("#search-box-2").addClass("search-focus");
  };

  checkexpiry = (expiry) => {
    if (expiry == "true") {
      return (
        <img
          alt="loading..."
          src={process.env.PUBLIC_URL + "/img/Group 3201.svg"}
          className=" img-expired"
        />
      );
    }
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };

  getservicefullname(name) {
    var fullname=  AllServices[name]
    return <>{fullname}</>;
   }

  handlepaymentinvoiceapi = () => {
    $(".myseriveloaderdiv").show();
    $(".header-btn").hide();
    axios({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + "p=userInvoice",
      // url: "https://2a15-223-236-24-102.ngrok.io/Legal251AgentPanelAPI/api/lgapp/?p=userInvoice",
      data: {
        userId: Decuserid,
        requestType: temp,
        agentId: Decagentid,
      },
      headers: {
        Authkey: process.env.REACT_APP_API_KEY,
        "App-Token-Access": Decjwttoken,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then((resp) => {

        $(".myseriveloaderdiv").hide();
        $(".header-btn").show();
        if (resp.data.code == 200 && resp.data.status == "success") {
          this.setState(
            {
              pendingpaymentmainarray: resp.data.pendingPayments,
              completepaymentmainarray: resp.data.completedInvoices,
              userid: resp.data.userId,
              email: resp.data.email,
              contact: resp.data.contact,
              username: resp.data.username,
            },
            () => {
              if (
                this.state.pendingpaymentmainarray.length == 0 ||
                this.state.completepaymentmainarray.length == 0
              ) {
                $(".myservicequiteimgstart").show();
              }
            }
          );
        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        } else if (resp.data.code == "201" && resp.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (resp.data.code == "203" && resp.data.status == "notfound") {
          this.setState(
            {
              pendingpaymentmainarray: [],
              completepaymentmainarray: [],
            },
            () => {
              if (
                this.state.pendingpaymentmainarray.length == 0 ||
                this.state.completepaymentmainarray.length == 0
              ) {
                $(".myservicequiteimgstart").show();
              }
            }
          );
        } else {
          this.showNotification(resp.data.message);
        }
      })
      .catch((error) => {
        $(".myseriveloaderdiv").hide();
      });
  };

  Checkplan = (plan) => {
    if (plan == "S") {
      return <>Starter</>;
    } else if (plan == "B") {
      return <>Basic</>;
    } else if (plan == "P") {
      return <>Pro</>;
    } else if (plan == "PR") {
      return <>Premium </>;
    } else if (plan == "PP") {
      return <>Pro+</>;
    } else if (plan == null) {
      return <>-</>;
    }
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  upgradeservice = (serviceName, serviceId, purchaseName, city, state) => {
    if(temp== "temporary"){  
      $(".payment-btn-upgrade").attr("disabled", true)
        $(".payment-btn-upgrade").css("opacity" , "0.5")
        $(".payment-btn-upgrade").css("cursor" , "not-allowed")
    }else {
      $(".payment-btn-upgrade").css("opacity" , "1")
        $(".payment-btn-upgrade").css("cursor" , "default")
      sessionStorage.removeItem("lksadnlkdnalksdnkksnd");
      if (serviceName == "GSTRF") {
        sessionStorage.setItem(
          "ielndmlgeaefelvsdrlucaa",
          encryptor.encrypt(purchaseName.trim())
        );
        sessionStorage.setItem(
          "ylcvciretaireditdgisdtacesl",
          encryptor.encrypt(city)
        );
        sessionStorage.setItem(
          "srleleddgaeaemvcnia",
          encryptor.encrypt("GST Return Filing")
        );
        sessionStorage.setItem(
          "hjtsrevanxckawshdyeh",
          encryptor.encrypt(state)
        );
        sessionStorage.setItem(
          "ptfgfgjhlqwemcggdfg",
          encryptor.encrypt(serviceId)
        );
        sessionStorage.setItem(
          "lashkjdasfurtghghj",
          encryptor.encrypt(true)
        );
        this.props.history.push({
          pathname: `/addservice/${Decuserid}/addservice/gstrf`
        });
      } else if (serviceName == "ITRF") {
        sessionStorage.setItem(
          "ielndmlgeaefelvsdrlucaa",
          encryptor.encrypt(purchaseName.trim())
        );
        sessionStorage.setItem(
          "ylcvciretaireditdgisdtacesl",
          encryptor.encrypt(city)
        );
        sessionStorage.setItem(
          "srleleddgaeaemvcnia",
          encryptor.encrypt("ITR Filing")
        );
        sessionStorage.setItem(
          "hjtsrevanxckawshdyeh",
          encryptor.encrypt(state)
        );
        sessionStorage.setItem(
          "ptfgfgjhlqwemcggdfg",
          encryptor.encrypt(serviceId)
        );
        sessionStorage.setItem(
          "lashkjdasfurtghghj",
          encryptor.encrypt(true)
        );
        this.props.history.push({
          pathname: `/addservice/${Decuserid}/addservice/itrf`
        });
      } else if (serviceName == "OA") {
        sessionStorage.setItem(
          "ielndmlgeaefelvsdrlucaa",
          encryptor.encrypt(purchaseName.trim())
        );
        sessionStorage.setItem(
          "ylcvciretaireditdgisdtacesl",
          encryptor.encrypt(city)
        );
        sessionStorage.setItem(
          "srleleddgaeaemvcnia",
          encryptor.encrypt("Online Accounting")
        );
        sessionStorage.setItem(
          "hjtsrevanxckawshdyeh",
          encryptor.encrypt(state)
        );
        sessionStorage.setItem(
          "ptfgfgjhlqwemcggdfg",
          encryptor.encrypt(serviceId)
        );
        sessionStorage.setItem(
          "lashkjdasfurtghghj",
          encryptor.encrypt(true)
        );
        this.props.history.push({
          pathname: `/addservice/${Decuserid}/addservice/oa`
        });
      }
    }
    

  }
  renewservice = (serviceName, serviceId, purchaseName, city, state, plan) => {
    if(temp == "temporary"){
      $(".payment-btn-renew").attr("disabled", true)
        $(".payment-btn-renew").css("opacity" , "0.5")
        $(".payment-btn-renew").css("cursor" , "not-allowed")
    }
    else {
      $(".payment-btn-renew").removeAttr("disabled")
        $(".payment-btn-renew").css("opacity" , "1")
        $(".payment-btn-renew").css("cursor" , "default")
  
    sessionStorage.removeItem("ptfgfgjhlqwemcggdfg");
    sessionStorage.removeItem("lashkjdasfurtghghj");
    axios({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + 'p=createPurchaseReqSpecial',
      data: {
        userId: Decuserid,
        agentId: Decagentid,
        serviceName: serviceName,
        serviceId: serviceId,
        duration: "",
        plan: "",
        turnover: "",
        package: "",
        renew: "true",
        upgradeplan: "false",
        comeFrom:"AGP"
      },
      headers: {
        'AuthKey': process.env.REACT_APP_API_KEY,
        'App-Token-Access': Decjwttoken
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      }
    })
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          if (serviceName == "GSTRF") {
            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(purchaseName.trim())
            );
            sessionStorage.setItem(
              "ylcvciretaireditdgisdtacesl",
              encryptor.encrypt(city)
            );
            sessionStorage.setItem(
              "srleleddgaeaemvcnia",
              encryptor.encrypt("GST Return Filing")
            );
            sessionStorage.setItem(
              "hjtsrevanxckawshdyeh",
              encryptor.encrypt(state)
            );
            sessionStorage.setItem("uegiqlsartdele", encryptor.encrypt(res.data.requestid));
            sessionStorage.setItem("aprcillgee", encryptor.encrypt(parseFloat(res.data.price)));
            sessionStorage.setItem("ssdfsdfwekrfewpfk", encryptor.encrypt(parseFloat(res.data.gstvalue)));
            sessionStorage.setItem("lslgerotnscmvheearaei", encryptor.encrypt("GSTRF"));
            sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
            sessionStorage.setItem("egalllpna", encryptor.encrypt(plan));
            sessionStorage.setItem("lksadnlkdnalksdnkksnd", encryptor.encrypt(true));
            this.props.history.push({
              pathname: `/addservice/${Decuserid}/addservice/checkout`
            });
          } else if (serviceName == "ITRF") {
            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(purchaseName.trim())
            );
            sessionStorage.setItem(
              "ylcvciretaireditdgisdtacesl",
              encryptor.encrypt(city)
            );
            sessionStorage.setItem(
              "srleleddgaeaemvcnia",
              encryptor.encrypt("ITR Filing")
            );
            sessionStorage.setItem(
              "hjtsrevanxckawshdyeh",
              encryptor.encrypt(state)
            );

            sessionStorage.setItem(
              "uegiqlsartdele",
              encryptor.encrypt(res.data.requestid)
            );
            sessionStorage.setItem(
              "aprcillgee",
              encryptor.encrypt(parseFloat(res.data.price))
            );
            sessionStorage.setItem("ssdfsdfwekrfewpfk", encryptor.encrypt(parseFloat(res.data.gstvalue)));
            sessionStorage.setItem("lslgerotnscmvheearaei", encryptor.encrypt("ITRF"));
            sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
            sessionStorage.setItem("lksadnlkdnalksdnkksnd", encryptor.encrypt(true));
            sessionStorage.setItem(
              "egalllpna",
              encryptor.encrypt(plan)
            );
            this.props.history.push({
              pathname: `/addservice/${Decuserid}/addservice/checkout`
            });
          } else if (serviceName == "OA") {
            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(purchaseName.trim())
            );
            sessionStorage.setItem(
              "ylcvciretaireditdgisdtacesl",
              encryptor.encrypt(city)
            );
            sessionStorage.setItem(
              "srleleddgaeaemvcnia",
              encryptor.encrypt("Online Accounting")
            );
            sessionStorage.setItem(
              "hjtsrevanxckawshdyeh",
              encryptor.encrypt(state)
            );

            sessionStorage.setItem(
              "uegiqlsartdele",
              encryptor.encrypt(res.data.requestid)
            );
            sessionStorage.setItem(
              "aprcillgee",
              encryptor.encrypt(parseFloat(res.data.price))
            );
            sessionStorage.setItem("ssdfsdfwekrfewpfk", encryptor.encrypt(parseFloat(res.data.gstvalue)));
            sessionStorage.setItem("lslgerotnscmvheearaei", encryptor.encrypt("OA"));
            sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
            sessionStorage.setItem("lksadnlkdnalksdnkksnd", encryptor.encrypt(true));
            sessionStorage.setItem(
              "egalllpna",
              encryptor.encrypt(plan)
            );
            this.props.history.push({
              pathname: `/addservice/${Decuserid}/addservice/checkout`
            });
          }
        }
        else if (
          res.data.code == "201" &&
          res.data.status == "invalidauth"
        ) {
          this.logout();
        }
        else {
          this.showNotification(res.data.message);
        }
      })
      .catch((error) => {

      });
    }
  }

  renderpendingpaymentData() {
    if (this.state.pendingpaymentstate == "Latest") {
      pendingpaymentGSTR = [];
      pendingpaymentGSTRF = [];
      pendingpaymentITRF = [];
      pendingpaymentTR = [];
      pendingpaymentOA = [];
      pendingpaymentGUMASTA = [];
      pendingpaymentTradeLicence = [];
      pendingpaymentFoodLicence = [];
      if (this.state.pendingpaymentmainarray.length > 0) {
        return this.state.pendingpaymentmainarray.map((activedoc, index) => {
          if (
            this.state.pendingpaymentmainarray[index].serviceName.includes(
              "GSTR"
            ) &&
            !this.state.pendingpaymentmainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            pendingpaymentGSTR.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            pendingpaymentGSTRF.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes(
              "ITRF"
            )
          ) {
            pendingpaymentITRF.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("TR")
          ) {
            pendingpaymentTR.push(this.state.pendingpaymentmainarray[index]);
          }
          else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("OA")
          ) {
            pendingpaymentOA.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("GUMASTA")
          ) {
            pendingpaymentGUMASTA.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("TL")
          ) {
            pendingpaymentTradeLicence.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("FL")
          ) {
            pendingpaymentFoodLicence.push(this.state.pendingpaymentmainarray[index]);
          }
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (this.state.pendingpaymentmainarray.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/dontactivesevice.svg"}
              className="myservicequiteimgstart"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "GSTR") {
      if (pendingpaymentGSTR.length > 0) {
        return pendingpaymentGSTR.map((activedoc, index) => {
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (pendingpaymentGSTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "GSTRF") {
      if (pendingpaymentGSTRF.length > 0) {
        return pendingpaymentGSTRF.map((activedoc, index) => {
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (pendingpaymentGSTRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "ITRF") {
      if (pendingpaymentITRF.length > 0) {
        return pendingpaymentITRF.map((activedoc, index) => {
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (pendingpaymentITRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "TR") {
      if (pendingpaymentTR.length > 0) {
        return pendingpaymentTR.map((activedoc, index) => {
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (pendingpaymentTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }

    else if (this.state.pendingpaymentstate == "OA") {
      if (pendingpaymentOA.length > 0) {
        return pendingpaymentOA.map((activedoc, index) => {
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (pendingpaymentOA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }else if (this.state.pendingpaymentstate == "GUMASTA") {
      if (pendingpaymentGUMASTA.length > 0) {
        return pendingpaymentGUMASTA.map((activedoc, index) => {
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (pendingpaymentGUMASTA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }else if (this.state.pendingpaymentstate == "TL") {
      if (pendingpaymentTradeLicence.length > 0) {
        return pendingpaymentTradeLicence.map((activedoc, index) => {
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (pendingpaymentTradeLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }else if (this.state.pendingpaymentstate == "FL") {
      if (pendingpaymentFoodLicence.length > 0) {
        return pendingpaymentFoodLicence.map((activedoc, index) => {
          if (activedoc.upgradeplan == "true" && activedoc.renew == "true") {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-50 py-1 mr-2" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew w-50 py-1 " onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.upgradeplan == "true" &&
            activedoc.renew == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-upgrade w-100 py-1" onClick={() => this.upgradeservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state)}
                      >
                        Upgrade plan{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path 19472.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          } else if (
            activedoc.renew == "true" &&
            activedoc.upgradeplan == "false"
          ) {
            return (
              <div className="col-md-6 col-12 card-gap" key={index}>
                <div className="card border-0">
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.purchaseName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Expire:-&nbsp;</span>
                        {activedoc.expire}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Amount:-&nbsp;</span>
                        &#8377; {activedoc.amount}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn-sm payment-btn-renew py-1 w-100" onClick={() => this.renewservice(activedoc.serviceName, activedoc.serviceId, activedoc.purchaseName, activedoc.city, activedoc.state, activedoc.plan)}
                      >
                        Renew now{" "}
                        <span>
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/Path_1021.svg"}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.checkexpiry(activedoc.expiredtag)}
                </div>
              </div>
            );
          }
        });
      } else if (pendingpaymentFoodLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }
  }

  rendercompletepaymentData() {
    if (this.state.completepaymentstate == "Latest") {
      completepaymentGSTR = [];
      completepaymentGSTRF = [];
      completepaymentITRF = [];
      completepaymentTR = [];
      completepaymentOA = [];
      completepaymentGUMASTA = [];
      completepaymentTradeLicence = [];
      completepaymentFoodLicence = [];
      if (this.state.completepaymentmainarray.length > 0) {
        return this.state.completepaymentmainarray.map((activedoc, index) => {
          if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "GSTR"
            ) &&
            !this.state.completepaymentmainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            completepaymentGSTR.push(
              this.state.completepaymentmainarray[index]
            );
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            completepaymentGSTRF.push(
              this.state.completepaymentmainarray[index]
            );
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "ITRF"
            )
          ) {
            completepaymentITRF.push(
              this.state.completepaymentmainarray[index]
            );
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "TR"
            )
          ) {
            completepaymentTR.push(this.state.completepaymentmainarray[index]);
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "OA"
            )
          ) {
            completepaymentOA.push(this.state.completepaymentmainarray[index]);
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "GUMASTA"
            )
          ) {
            completepaymentGUMASTA.push(this.state.completepaymentmainarray[index]);
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "TL"
            )
          ) {
            completepaymentTradeLicence.push(this.state.completepaymentmainarray[index]);
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "FL"
            )
          ) {
            completepaymentFoodLicence.push(this.state.completepaymentmainarray[index]);
          }
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (this.state.completepaymentmainarray.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/dontactivesevice.svg"}
              className="myservicequiteimgstart"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "GSTR") {
      if (completepaymentGSTR.length > 0) {
        return completepaymentGSTR.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (completepaymentGSTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "GSTRF") {
      if (completepaymentGSTRF.length > 0) {
        return completepaymentGSTRF.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (completepaymentGSTRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "ITRF") {
      if (completepaymentITRF.length > 0) {
        return completepaymentITRF.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (completepaymentITRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "TR") {
      if (completepaymentTR.length > 0) {
        return completepaymentTR.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (completepaymentTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }else if (this.state.completepaymentstate == "OA") {
      if (completepaymentOA.length > 0) {
        return completepaymentOA.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (completepaymentOA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }else if (this.state.completepaymentstate == "GUMASTA") {
      if (completepaymentGUMASTA.length > 0) {
        return completepaymentGUMASTA.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (completepaymentGUMASTA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }else if (this.state.completepaymentstate == "TL") {
      if (completepaymentTradeLicence.length > 0) {
        return completepaymentTradeLicence.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (completepaymentTradeLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }else if (this.state.completepaymentstate == "FL") {
      if (completepaymentFoodLicence.length > 0) {
        return completepaymentFoodLicence.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.purchaseName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Paid on:-&nbsp;</span>
                      {activedoc.paidOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Amount:-&nbsp;</span>
                      &#8377; {activedoc.amount}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Invoice no.:-&nbsp;</span>
                      {activedoc.invoiceNo}
                    </p>
                  </div>
                  <a
                    href={activedoc.invoiceLink}
                    target="blank"
                    className="btn-sm payment-btn-download w-100 py-1 mr-1"
                  >
                    Download invoice{" "}
                    <span>
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                      />
                    </span>
                  </a>
                </div>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path 1018.svg"}
                  className=" img-paid"
                />
              </div>
            </div>
          );
        });
      } else if (completepaymentFoodLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }
  }

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
    return (
      <div className="cont">
        <div id="notifContainer"></div>
        <Helmet>
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/select2.css"}
          />
          <link rel="stylesheet" href="css/services.css" />
        </Helmet>

        <Header />

        <img
          alt="loading..."
          src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
          className="sidebaropenicon"
        />
        <img
          alt="loading..."
          src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
          className="sidebarbackicon"
        />
        <img
          alt="loading..."
          src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
          className="sidebarexpandicon"
        />

        <div className="overlay1"></div>
        <Sidebar/>

        <div className="arrow-left3"></div>
        <div className="white_div">
          <div className="white_box">
            <div className="row main-row">
              <div className="myservicesortbyfilterbystatus ml-1">

              </div>
              {/* <!-- pending payment --> */}
              <div className="col-xl-6 col-12">
                {/* <!-- header --> */}
                <div className="inner-header d-flex flex-md-row justify-content-between px-2">
                  {/* <!-- search --> */}
                  <div className="pb-md-0 pb-2">
                    <img
                      alt="loading..."
                      src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                      className="backicon__btn"
                      onClick={this.backbutton}
                    />
                  </div>

                  {/* <!-- nav-tabs --> */}
                  <div className="payment-nav-tab">
                    <header className="position-relative">
                      <ul className="payment-nav-tab-ul">
                        <li className="payment-nav-tab-li">Pending payments</li>
                      </ul>
                    </header>
                  </div>

                  {/* <!-- dropdowns -->              */}
                  <div className="dropdown ml-auto">
                    <button
                      className="btn header-btn py-1 px-2"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/Path 210.svg"}
                      />{" "}
                      <span>Filter by</span>
                    </button>
                    <div
                      className="dropdown-menu drop mar-l-05"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.pendingpaymentGSTRfilter}
                      >
                        GSTR
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.pendingpaymentGSTRFfilter}
                      >
                        GSTRF
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.pendingpaymentITRFfilter}
                      >
                        ITRF
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.pendingpaymentTRfilter}
                      >
                        TR
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.pendingpaymentOAfilter}
                      >
                        OA
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.pendingpaymentGUMASTAfilter}
                      >
                        GUMASTA
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.pendingpaymentTradeLicencefilter}
                      >
                        Trade Licence
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.pendingpaymentFoodLicencefilter}
                      >
                        Food Licence
                      </a>
                    </div>
                  </div>
                </div>

                {/* <!-- page-content --> */}
                <div className="tabs-content py-md-3 pt-5 pb-2 mt-md-0 mt-2">
                  <div className="myservicescrolldiv">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="myseriveloaderdiv">
                          <div className="myseriveloaderinsidediv2">
                            <center>
                              <img
                                src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                className="myseriveloader"
                              />
                            </center>
                          </div>
                        </div>
                        {this.renderpendingpaymentData()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- All payment invoices --> */}

              <div className="col-xl-6 col-12 pt-xl-0 pt-4 partition-border">
                {/* <!-- header --> */}
                <div className="inner-header d-flex flex-md-row justify-content-between px-2">
                  {/* <!-- search --> */}
                  <div className="pb-md-0 pb-2">
                    {/* <form>
                        <input
                          type="text"
                          className="search-box"
                          id="search-box-2"
                          placeholder="Search"
                          onClick={this.handleSearchBox2}
                        />
                      </form> */}
                  </div>

                  {/* <!-- nav-tabs --> */}
                  <div className="payment-nav-tab">
                    <header className="position-relative">
                      <ul className="payment-nav-tab-ul">
                        <li className="payment-nav-tab-li">
                          All payment invoices
                        </li>
                      </ul>
                    </header>
                  </div>

                  {/* <!-- dropdowns -->              */}
                  <div className="dropdown ml-auto">
                    <button
                      className="btn header-btn py-1 px-2"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/Path 210.svg"}
                      />{" "}
                      <span>Filter by</span>
                    </button>
                    <div
                      className="dropdown-menu drop mar-l-05"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.completepaymentGSTRfilter}
                      >
                        GSTR
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.completepaymentGSTRFfilter}
                      >
                        GSTRF
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.completepaymentITRFfilter}
                      >
                        ITRF
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.completepaymentTRfilter}
                      >
                        TR
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.completepaymentOAfilter}
                      >
                        OA
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.completepaymentGUMASTAfilter}
                      >
                        GUMASTA
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.completepaymentTradeLicencefilter}
                      >
                        Trade Licence
                      </a>
                      <a
                        className="dropdown-item cursorpointer"
                        onClick={this.completepaymentFoodLicencefilter}
                      >
                        Food Licence
                      </a>
                    </div>
                  </div>
                </div>

                {/* <!-- page-content --> */}
                <div className="tabs-content py-md-3 py-5 mt-md-0 mt-1">
                  <div className="myservicescrolldiv">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="myseriveloaderdiv">
                          <div className="myseriveloaderinsidediv2">
                            <center>
                              <img
                                src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                className="myseriveloader"
                              />
                            </center>
                          </div>
                        </div>
                        {this.rendercompletepaymentData()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default PaymentInvoice;
